<template>
  <div v-if="show_window" id="ai">
    <div @click="_closeWindow()" class="ai-fullscreen"></div>
    <div :id="is_mobile_device ? 'mobile-white-window' : 'white-window'">
      <div id="tool-window-container" v-for="(tool, index) in tools" :key="tool.name" @click="_removeTool(index)">
        <hr />
        <p id="tools">{{tool.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
export default {
  mixins: [APICaller],
  name: 'AiWindow',
  props: {
    show_window: { type: Boolean},
    repush_tool: {type: Object},
    coordinates: {type: Object},

  },
  data: () => ({
    tools: [],
    preferences: [],
    open_positions: [],
    preferences_portfolio: [],
  }),
  watch: {
    repush_tool: {
      handler: function() {
        this._repushToolToWindow(this.repush_tool);
      },
      deep: true
    },

    ticker:{
      handler: function(){
        this._prepareArrayTools();
      }
    }
  },
  mounted() {
    setTimeout(function(){   
      this._getPreferences()
    }.bind(this), 10);
  },
  created: function() {
  },
  computed: {
    ...mapGetters(['_g_SelectedTicker', "_g_portfolio_id_selected"]),
    ...mapGetters(['_g_IsUserLogged', "is_mobile_device"]),
    ...mapGetters(['_g_User']),
  },
  methods: {
    _closeWindow: function(){
      this.show_window = false;
        this.$emit("show_window", this.show_window);
    },
    _prepareArrayTools: function(){
      this.tools=[];
      let tool_empty = {};
      //let tool2 = {field: "es_ipo", name: "Es IPO", sorter: "", type: "", help_text: "", subfix: ""};
      let tool2 = {field: "in_price", name: "Entrada", sorter: "Entrada", type: "", help_text: "", subfix: ""};
      let tool3 = {field: "amount", name: "Montante", sorter: "Montante", type: "", help_text: "", subfix: ""};
      let tool4 = {field: "exchange_rate", name: "Divisa", sorter: "Divisa", type: "", help_text: "", subfix: ""};
      let tool5 = {field: "entry_type", name: "Tipo de entrada", sorter: "T. entrada", type: "", help_text: "", subfix: ""};
      let tool6 = {field: "instrument_number", name: "Acciones", sorter: "Acciones", type: "", help_text: "", subfix: ""};
      let tool7 = {field: "tthh_profit", name: "Rentabilidad", sorter: "Rentab.", type: "", help_text: "", subfix: ""};
      let tool8 = {field: "entry_distance", name: "Distancia de entrada", sorter: "Dist. Ent.", type: "", help_text: "", subfix: ""};
      let tool9 = {field: "out_price", name: "Stop", sorter: "Stop", type: "", help_text: "", subfix: ""};
      let tool10 = {field: "support_price", name: "Soporte", sorter: "Soporte", type: "", help_text: "", subfix: ""};
      let tool11 = {field: "support_date", name: "Fecha de soporte", sorter: "Fecha sop", type: "", help_text: "", subfix: ""};
      let tool12 = {field: "resistance", name: "Resistencia", sorter: "Resist.", type: "", help_text: "", subfix: ""};
      let tool13 = {field: "anual_var", name: "Variación anual", sorter: "Var. Anual", type: "", help_text: "", subfix: ""};
      let tool14 = {field: "wave_number", name:"Número de onda", sorter: "Nº onda", type: "", help_text: "", subfix: ""};
      let tool15 = {field: "next_entry_lt", name:"Siguiente LT", sorter: "Sig. LT", type: "", help_text: "", subfix: ""};
      let tool16 = {field: "first_trading_date", name:"Primera fecha", sorter: "1ª fecha", type: "", help_text: "", subfix: ""};
      let tool17 = {field: "risk_reward", name:"Inversión/Riesgo", sorter: "Inv/Riesgo", type: "", help_text: "", subfix: ""};
      let tool18 = {field: "valor_actual", name:"Valor actual", sorter: "Valor act.", type: "", help_text: "", subfix: ""};
      let tool19 = {field: "user_platinum_compatibility", name:"Compatibilidad", sorter: "Compat.", type: "", help_text: "", subfix: ""};
      let tool20 = {field: "open_fee", name:"Comisión de entrada", sorter: "Comis. ent.", type: "", help_text: "", subfix: ""};
      let tool21 = {field: "benefit", name:"Beneficio", sorter: "Beneficio", type: "", help_text: "", subfix: ""};
      let tool22 = {field: "insured_benefit", name:"Beneficio asegurado", sorter: "Benef. Aseg.", type: "", help_text: "", subfix: ""};
      let tool23 = {field: "in_date", name:"Fecha de entrada", sorter: "F. entrada", type: "", help_text: "", subfix: ""};
      this.tools.push(tool2,tool3,tool4,tool5,tool6,tool7,tool8,tool9,tool10,tool11,tool12,tool13,tool14,tool15,tool16,tool17, tool18, tool19, tool20, tool21, tool22, tool23);


      for(var j = 0; j < this.tools.length; j++){
        
        for(var i = 0; i < this.preferences.length; i++) {
          if(this.tools[j].field == this.preferences[i].field){
            this.tools[j].name = ""
          }
          
        }
      }
      let aux = []
      this.tools.forEach(element => {
        if(element.name != ""){
          aux.push(element)
        }
      })
      this.tools = aux;
      
      if(this.tools.length == 0){
        this.tools.push(tool_empty)
      }

    },

    _removeTool: function(index){
      this.$emit("tool_to_add", this.tools[index]);
      this.$emit("addTool");
      this.tools.splice(index, 1); 
      this._closeWindow();
    },
    _repushToolToWindow: function(tool){
      this.tools.push(tool);
    },

    _getPreferences: function() {
      if(!this._g_User.is_only_authenticated_user){
        let success = response => {
          this.preferences = response.data;
          this._getPortfolioPreferences()
          //this.tools = response.data;
        }
        
        let url = '/api/v1/stock-finder/preferences'
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('040', 'Error cargando valores.');
  
        this._getAPICall(url, successHandler, failureHandler);
      }
    },

    _getPortfolioPreferences: function(){
      let success = response =>{
        if(response.data.indexOf("open_fee") != -1){
          this.preferences.push({field: "open_fee", help_text: "", name:"Comis. ent.", sorter:"text", subfix: "", type: ""})
        }
        if(response.data.indexOf("benefit") != -1){
          this.preferences.push({field: "benefit", help_text: "", name:"Beneficio", sorter:"text", subfix: "", type: ""})
        }
        if(response.data.indexOf("insured_benefit") != -1){
          this.preferences.push({field: "insured_benefit", help_text: "", name:"Benef. Aseg.", sorter:"text", subfix: "", type: ""})
        }
        if(response.data.indexOf("in_date") != -1){
          this.preferences.push({field: "in_date", help_text: "", name:"F. entrada", sorter:"text", subfix: "", type: ""})
        }
        this._mapGetPreferences();
      }

      let url = "/api/v1/portfolio-preferences"

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('041', 'Error cargando preferencias de cartera.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _mapGetPreferences: function(){
      this.preferences.forEach(element => {
        if(element.field == "ticker"){
          element.field = "ticker";
        }else if(element.field == "entry_type_name"){
          element.field = "entry_type"
        }else if(element.field == "instrument_number"){
          element.field = "instrument_number";
        }else if(element.field == "real_entry"){
          element.field = "in_price";
        }else if(element.field == "out_price"){
          element.field = "out_price"
        }else if(element.field == "soport"){
          element.field = "support_price"
        }else if(element.field == "data_soport"){
          element.field = "support_date"
        }else if(element.field == "current_price"){
          element.field = "current_price"
        }else if(element.field == "amount"){
          element.field = "amount"
        }else if(element.field == "in_currency"){
          element.field = "in_currency"
        }else if(element.field == "tae_lt"){
          element.field = "tthh_profit"
        }else if(element.field == "entry_distance"){
          element.field = "entry_distance"
        }else if(element.field == "resistance"){
          element.field = "resistance"
        }else if(element.field == "tae"){
          element.field = "anual_var"
        }else if(element.field == "num_ona_actual"){
          element.field = "wave_number"
        }else if(element.field == "entrada_seg"){
          element.field = "next_entry_lt"
        }else if(element.field == "fecha_primera_barra"){
          element.field = "first_trading_date"
        }else if(element.field == "risk_reward"){
          element.field = "risk_reward"
        }else if(element.field == "user_platinum_compatibility"){
          element.field = "user_platinum_compatibility"
        }else if(element.field == "open_fee"){
          element.field = "open_fee"
        }else if(element.field == "benefit"){
          element.field = "benefit"
        }else if(element.field == "insured_benefit"){
          element.field = "insured_benefit"
        }else if(element.field == "in_date"){
          element.field = "in_date"
        }
      })
      this._prepareArrayTools()
    },
  }
}


</script>

<style lang="scss">
  .ai-fullscreen {      
    background: rgba(0, 0, 0, 0.15);
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: fixed;
    z-index: -2;
  }
  #white-window {
    height: 500px;
    overflow-y: scroll;
    z-index: 1;
    left: 1645px;
    top: 66px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    width: 119px;
    margin-left: 5px;
  }
  #mobile-white-window{
    height: 250px;
    overflow-y: scroll;
    z-index: 1;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  #tools{
    font-size:12px;
    margin: 0;
    padding: 15px 0;
    text-align:center;
  }

  #tool-window-container {
    cursor: pointer;
  }

  #tool-window-container:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

</style>