<template>
    <div>
        <div class="filters-container">
            <div v-if="local_array_stocks_filters.length != 0" class="inline">

                
                <div class="row-one-mobile">
                    <div class="rentabilidad">
                        <div class="items-container">
                            <h4 class="tittle-name">
                                Rentabilidad específica:
                            </h4>
                        </div>
                        <div v-if="_g_UserFeatures.permissions.specific_rentability_filter">
                            <SelectOutline v-for="(iSelect, selectIndex) in third_config[0].componentsConfig" :key="selectIndex" :options="iSelect.options" :helper_select_permission="iSelect.helper_select_permission" :default_value="local_array_stocks_filters[selectIndex].value" :helper_text_select="iSelect.helper_text_select" :component_class="iSelect.component_class" @_emit_method_order_select="_getValueFromSelect" class="inlined second-mobile-select" />
                        </div>
                        <div v-else>
                            <FilterNoPermission
                                :link="buy_link"
                            ></FilterNoPermission>
                            
                        </div>
                    </div>

                    <div class="n_onda">
                        <div class="items-container">
                            <h4 class="tittle-name">
                                Número de onda:
                            </h4>
                        </div>
                        <div v-if="_g_UserFeatures.permissions.wave_number_filter">
                            <SelectOutline v-for="(iSelect, selectIndex) in third_config[1].componentsConfig" :key="selectIndex" :options="iSelect.options" :helper_select_permission="iSelect.helper_select_permission" :default_value="local_array_stocks_filters[selectIndex+2].value" :helper_text_select="iSelect.helper_text_select" :component_class="iSelect.component_class" @_emit_method_order_select="_getValueFromSelect" class="inlined second-mobile-select" />
                        </div>
                        <div v-else>
                            <FilterNoPermission
                                :link="buy_link"
                            ></FilterNoPermission>
                            
                        </div>

                    </div>


                    <div class="fecha_soporte">
                        <div class="items-container">
                            <h4 class="tittle-name">
                                Fecha de soporte:
                            </h4>
                        </div>
                        <div v-if="_g_UserFeatures.permissions.soport_date_filter">
                            <Datepicker placeholder="Fecha inicio" v-model="datepicker_soporte_inferior" :language="es" :full-month-name="true" :mondayFirst="true" :clearButton="true" format="dd/MM/yyyy" input-class="datepicker-input" class="inlined datepick" />
                            <Datepicker placeholder="Fecha fin" v-model="datepicker_soporte_superior" :language="es" :full-month-name="true" :mondayFirst="true" :clearButton="true" format="dd/MM/yyyy" input-class="datepicker-input" class="inlined datepick" />
                        </div>
                        <div v-else>
                            <FilterNoPermission
                                :link="buy_link"
                            ></FilterNoPermission>
                            
                        </div>

                    </div>
                </div>
                <div class="row-two-mobile">
                    <div class="fecha_estabilizar">
                        <div class="items-container">
                            <h4 class="tittle-name">
                                Fecha para estabilizar:
                            </h4>
                        </div>
                        <div v-if="_g_UserFeatures.permissions.wave_stabilizer">
                            <Datepicker placeholder="Fecha inicio" v-model="datepicker_estabilizar_inferior" :language="es" :full-month-name="true" :mondayFirst="true" :clearButton="true" format="dd/MM/yyyy" input-class="datepicker-input" class="inlined datepicker-third-finder datepick" />
                            <Datepicker placeholder="Fecha fin" v-model="datepicker_estabilizar_superior" :language="es" :full-month-name="true" :mondayFirst="true" :clearButton="true" format="dd/MM/yyyy" input-class="datepicker-input" class="inlined datepick" />
                        </div>
                        <div v-else>
                            <FilterNoPermission
                                :link="buy_link"
                            ></FilterNoPermission>
                            
                        </div>

                    </div>

                    <div class="fecha_max_historico">
                        <div class="items-container">
                            <h4 class="tittle-name">
                                Fecha en máximo histórico:
                            </h4>
                        </div>
                        <div v-if="_g_UserFeatures.permissions.historical_highs_dates_filter">
                            <Datepicker placeholder="Fecha inicio" v-model="datepicker_maxHistorico_inferior" :language="es" :full-month-name="true" :mondayFirst="true" :clearButton="true" format="dd/MM/yyyy" input-class="datepicker-input" class="inlined datepick" />
                            <Datepicker placeholder="Fecha fin" v-model="datepicker_maxHistorico_superior" :language="es" :full-month-name="true" :mondayFirst="true" :clearButton="true" format="dd/MM/yyyy" input-class="datepicker-input" class="inlined datepick" />
                        </div>
                        <div v-else>
                            <FilterNoPermission
                                :link="buy_link"
                            ></FilterNoPermission>
                            
                        </div>

                    </div>
                    <div class="cfd_habilitados" v-if="crypto_enabled==false">
                        <div class="items-container">
                            <h4 class="tittle-name">
                                CFDs habilitados:
                            </h4>
                        </div>
                        <div v-if="_g_UserFeatures.permissions.CFDs_enabled">
                            <SelectInputFiltersNoNotch v-for="(iSelect, selectIndex) in third_config[5].componentsConfig" :key="selectIndex" :options="iSelect.options" :helper_select_permission="iSelect.helper_select_permission" :default_value="local_array_stocks_filters[selectIndex+4].value" :helper_text_select="iSelect.helper_text_select" :component_class="iSelect.component_class" @_emit_method_order_select="_getValueFromSelect" class="inlined" />
                        </div>
                        <div v-else>
                            <FilterNoPermission
                                :link="buy_link"
                            ></FilterNoPermission>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ThirdConfig from "@/components/stockchart/components/Finder/configs/Third.json";
import ThirdConfigCripto from "@/components/stockchart/components/Finder/configs/Third_cripto.json";
import SelectOutline from '@/components/helpers/newMaterial/SelectInputFilters.vue';
import SelectInputFiltersNoNotch from '@/components/helpers/newMaterial/SelectInputFiltersNoNotch.vue';
//import SelectOutlineDisabled from "@/components/helpers/SelectOutline_disabled";
import Datepicker from 'vuejs-datepicker';
import {en, es} from 'vuejs-datepicker/dist/locale'
import { mapMutations, mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import FilterNoPermission from "@/components/stockchart/components/Finder/FilterNoPermission";


export default {
    mixins: [APICaller],
    name: 'Third_mobile',
    components: {
        SelectOutline,
        SelectInputFiltersNoNotch,
        //SelectOutlineDisabled,
        Datepicker,
        FilterNoPermission
    },
    props: {
        filters_user_json:{type: Object},
        recargar:{type: Boolean},
    },
    data: () => ({
        third_config: ThirdConfig,
        third_config_cripto: ThirdConfigCripto,
        local_array_stocks_filters: [],
        local_array_stocks_filters_default: [],
        local_array_criptos_filters: [],
        local_array_criptos_filters_default: [],
        en: en,
        es: es,
        finder:"",
        finder_original:"",

        datepicker_soporte_inferior: "",
        datepicker_soporte_superior: "",
        datepicker_estabilizar_inferior:"",
        datepicker_estabilizar_superior:"",
        datepicker_maxHistorico_inferior:"",
        datepicker_maxHistorico_superior:"",

        datepicker_soporte_inferior_cripto: "",
        datepicker_soporte_superior_cripto: "",
        datepicker_estabilizar_inferior_cripto:"",
        datepicker_estabilizar_superior_cripto:"",
        datepicker_maxHistorico_inferior_cripto:"",
        datepicker_maxHistorico_superior_cripto:"",

        rentabilidad_especifica_permission:"",
        numero_de_onda_permission:"",
        cfd_permission:"",
        fecha_de_soporte_permission:"",
        fecha_para_estabilizar_permission:"",
        fecha_maximo_historico_permission:"",
        permissions_loaded: false,
        buy_link: ""
    }),
    mounted() {
        this.buy_link = this.$detectEnvironment() + '/app/store';
    },
    created: function() {   
        var created_interval = setInterval(() => {
            if (this.filters_user_json != "") {
                this._getFinderParameters();
                clearInterval(created_interval);
            }
        }, 200);     
    },

    watch: {

        datepicker_soporte_inferior:{
            handler: function(newVal, oldVal){
                if (newVal != 'undefined//undefined') {
                    if(newVal == null) {
                        this.datepicker_soporte_inferior = "";
                    }
                    this._parseDatepickerFormat(this.datepicker_soporte_inferior.toString(), "soporteInferior")
                }
            },
            deep: true
        },

        datepicker_soporte_superior:{
            handler: function(newVal, oldVal){
                if (newVal != 'undefined//undefined') {
                    if(newVal == null) {
                        this.datepicker_soporte_superior = "";
                    }
                    this._parseDatepickerFormat(this.datepicker_soporte_superior.toString(), "soporteSuperior")
                }
            },
            deep: true
        },

        datepicker_estabilizar_inferior:{
            handler: function(newVal, oldVal){
                if (newVal != 'undefined//undefined') {
                    if(newVal == null) {
                        this.datepicker_estabilizar_inferior = "";
                    }
                    this._parseDatepickerFormat(this.datepicker_estabilizar_inferior.toString(), "estabilizarInferior")
                }
            },
            deep: true
        },

        datepicker_estabilizar_superior:{
            handler: function(newVal, oldVal){
                if (newVal != 'undefined//undefined') { 
                    if(newVal == null) {
                        this.datepicker_estabilizar_superior = "";
                    }
                    this._parseDatepickerFormat(this.datepicker_estabilizar_superior.toString(), "estabilizarSuperior")
                }
            },
            deep: true
        },

        datepicker_maxHistorico_inferior:{
            handler: function(newVal, oldVal){
                if (newVal != 'undefined//undefined') {
                    if(newVal == null) {
                        this.datepicker_maxHistorico_inferior = "";
                    }
                    this._parseDatepickerFormat(this.datepicker_maxHistorico_inferior.toString(), "maxHistoricoInferior")
                }
            },
            deep: true
        },

        datepicker_maxHistorico_superior:{
            handler: function(newVal, oldVal){
                if (newVal != 'undefined//undefined') {
                    if(newVal == null) {
                        this.datepicker_maxHistorico_superior = "";
                    }
                    this._parseDatepickerFormat(this.datepicker_maxHistorico_superior.toString(),"maxHistoricoSuperior")
                }
            },
            deep: true
        },

    },

    computed: {
        ...mapGetters(['_g_ArrayFiltersStocks', '_g_ArrayFiltersStocksDefault', '_g_ArrayFiltersCriptos', '_g_ArrayFiltersCriptosDefault', '_g_UserFeatures']),
        ...mapGetters({crypto_enabled:'_g_crypto_enable'}),
    },
    methods: {
        ...mapGetters(['_g_FinderFilters']),
        ...mapMutations(['setFinderFilters']),
        _parseFromFinderToDate:function(){
            if(this.finder["min_support_date"] == "01/01/1970"){
                this.datepicker_soporte_inferior = "";
            }else{
                let array = this.finder["min_support_date"].split("/");
                this.datepicker_soporte_inferior = array[1] + "/" + array[0] + "/" + array[2];
            }  
            
            if(this.finder["max_support_date"] == "01/01/1970"){
                this.datepicker_soporte_superior = "";
            }else{
                let array = this.finder["max_support_date"].split("/");
                this.datepicker_soporte_superior = array[1] + "/" + array[0] + "/" + array[2];
            }

            if(this.finder["profit_date"][0] == "01/01/1970"){
                this.datepicker_estabilizar_inferior = "";
            }else{
                let array = this.finder["profit_date"][0].split("/");
                this.datepicker_estabilizar_inferior = array[1] + "/" + array[0] + "/" + array[2];
            }

            if(this.finder["profit_date"][1] == "01/01/1970"){
                this.datepicker_estabilizar_superior = "";
            }else{
                let array = this.finder["profit_date"][1].split("/");
                this.datepicker_estabilizar_superior = array[1] + "/" + array[0] + "/" + array[2];
            }

            if(this.finder["max_min_bar_date"][0] == "01/01/1970"){
                this.datepicker_maxHistorico_inferior = "";
            }else{
                let array = this.finder["max_min_bar_date"][0].split("/");
                this.datepicker_maxHistorico_inferior = array[1] + "/" + array[0] + "/" + array[2];
            }

            if(this.finder["max_min_bar_date"][1] == "01/01/1970"){
                this.datepicker_maxHistorico_superior = "";
            }else{
                let array = this.finder["max_min_bar_date"][1].split("/");
                this.datepicker_maxHistorico_superior = array[1] + "/" + array[0] + "/" + array[2];
            }

        },
        _parseDatepickerFormat: function(date, datepicker){
            let array = date.split(" ");
            let day = array[2];
            let year = array[3];
            let month = array[1];
            switch(month){
                case "Jan":
                    month = "01";
                    break;
                case "Feb":
                    month = "02";
                    break;
                case "Mar":
                    month = "03";
                    break;
                case "Apr":
                    month = "04";
                    break;
                case "May":
                    month = "05";
                    break;
                case "Jun":
                    month = "06";
                    break;
                case "Jul":
                    month = "07";
                    break;
                case "Aug":
                    month = "08";
                    break;
                case "Sep":
                    month = "09";
                    break;
                case "Oct":
                    month = "10";
                    break;
                case "Nov":
                    month = "11";
                    break;
                case "Dec":
                    month = "12";
                    break;
            }

            let final_date = day + "/" + month + "/" + year;
            if(final_date == "undefined/undefined/undefined"){
                final_date = "";
            }
            switch(datepicker){
                case "soporteInferior":
                    this.finder["min_support_date"] = final_date;
                    break;
                case "soporteSuperior":
                    this.finder["max_support_date"] = final_date;
                    break;
                case "estabilizarInferior":
                    this.finder["profit_date"][0] = final_date;
                    break;
                case "estabilizarSuperior":
                    this.finder["profit_date"][1] = final_date;
                    break;
                case "maxHistoricoInferior":
                    this.finder["max_min_bar_date"][0] = final_date;
                    break;
                case "maxHistoricoSuperior":
                    this.finder["max_min_bar_date"][1] = final_date;
                    break;
            }

            setTimeout(() => {
                this._updateLastSearch2();
            }, 200);
            
        },

        ...mapMutations(['setFinderFilters','setRechargeState']),
        _getValueFromSelect: function(value){
            if(this.show_criptos){
                //djiwhnfswnfwaf
            }else{
                if(value[1] == "rentabilidadInferior"){
                    if(value[0] == "< 35"){
                        this.finder["min_tae_lt"] = "";
                    }else{
                        this.finder["min_tae_lt"] = parseInt(value[0]);
                    }                    
                }
                if(value[1] == "rentabilidadSuperior"){
                    if(value[0] == "> 400"){
                        this.finder["min_tae_lt"] = "";
                    }
                    else{
                        this.finder["max_tae_lt"] = parseInt(value[0]);
                    }
                }

                if(value[1] == "numOndaInferior"){
                    if(value[0] == "All"){
                        this.finder.min_ona = "";
                    }else{
                        this.finder.min_ona = parseInt(value[0]);
                    }
                }
                if(value[1] == "numOndaSuperior"){
                    if(value[0] == "> 25"){
                        this.finder["max_ona"] = "";
                    }
                    else{
                        this.finder["max_ona"] = parseInt(value[0]);
                    }                                
                }

                if(value[1] == "CFDsSelect"){
                    if(value[0] == "No (Muestra todos los valores)"){
                        this.finder["extrad_cfds_direction"] = "0"
                    }else{
                        this.finder["extrad_cfds_direction"] = "1"
                    }
                }
                
                setTimeout(() => {
                    this._updateLastSearch2();
                }, 200);
            }

        },
        _dataToNewFormat: function(){
            var min_tae_lt_value;
            var max_tae_lt_value;
            //default value rentabilidad especifica
            if(this.finder["min_tae_lt"] == ""){
                min_tae_lt_value = "< 35"
            }
            else{
                min_tae_lt_value = this.finder["min_tae_lt"].toString();
            }

            if(this.finder["max_tae_lt"] == ""){
                max_tae_lt_value = "> 400"
            }else{
                max_tae_lt_value = this.finder["max_tae_lt"].toString();
            }
            //default value nºonda
            var min_onda;
            var max_onda;
            if(this.finder["min_ona"] == ""){
                min_onda = "All"
            }else{
                min_onda = this.finder["min_ona"].toString();
            }

            if(this.finder["max_ona"] == ""){
                max_onda = "> 25"
            }else{
                max_onda = this.finder["max_ona"].toString();
            }
            //default cfd
            var value_cfd;
            if(this.finder["extrad_cfds_direction"]== 0){
                value_cfd = "No (Muestra todos los valores)";
            }
            else{
                value_cfd = "Si (Muestra sólo CFDs habilitados)";
            }
            var select = [
                {
                    name : "R.especifica-li",
                    value: min_tae_lt_value,
                },
                {
                    name : "R.especifica-ls",
                    value: max_tae_lt_value,
                },
                {
                    name : "N.onda-li",
                    value: min_onda,
                },
                {
                    name : "N.onda-ls",
                    value: max_onda,
                },
                {
                    name : "CFD",
                    value: value_cfd,
                },
            ];
            if(this.finder["min_support_date"] == "01/01/1970"){
                this.datepicker_soporte_inferior = "";
            }else{
                this.datepicker_soporte_inferior = this.finder["min_support_date"];
            }

            if(this.finder["max_support_date"] == "01/01/1970"){
                this.datepicker_soporte_superior = "";
            }else{
                this.datepicker_soporte_superior = this.finder["max_support_date"];
            }

            if(this.finder["profit_date"][0] == "01/01/1970"){
                this.datepicker_estabilizar_inferior = "";
            }else{
                this.datepicker_estabilizar_inferior = this.finder["profit_date"][0];
            }

            if(this.finder["profit_date"][1] == "01/01/1970"){
                this.datepicker_estabilizar_superior = "";
            }else{
                this.datepicker_estabilizar_superior = this.finder["profit_date"][1];
            }

            if(this.finder["max_min_bar_date"][0] == "01/01/1970"){
                this.datepicker_maxHistorico_inferior = "";
            }else{
                this.datepicker_maxHistorico_inferior = this.finder["max_min_bar_date"][0];
            }

            if(this.finder["max_min_bar_date"][1] == "01/01/1970"){
                this.datepicker_maxHistorico_superior = "";
            }else{
                this.datepicker_maxHistorico_superior = this.finder["max_min_bar_date"][1];
            }

            this.local_array_stocks_filters = select;

        },
        _getFinderParameters: function(){
            this.finder = this._g_FinderFilters();
            this.finder_original = this._g_FinderFilters();
            this._dataToNewFormat();
            this._parseFromFinderToDate();
        },

        _updateLastSearch2: function(){
            this.setFinderFilters(this.finder);
            this.$emit("updateLastSearch", this.finder);
        },
    },
}
</script>

<style scoped>
    .filters-container:nth-child(2){
        content: '\A';
        white-space: pre;
    }
    .inline{
        display:inline-block;
    }
    h4{
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        letter-spacing: 0.1px;

        /* 02. Secondary/400 */

        color: #626777;
        margin-top:0px;
    }
    .information-text{
        visibility: hidden;
        width: 120px;
        background-color: #151929;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        margin-left: 2px;
    }
    .interrogant-container:hover .information-text{
        visibility: visible;
    }
    .row-one{
        display:relative;
    }
    .row-two{
        display:flex;
    }

    .datepicker-input{
        text-align:  center;
        font-size: 16px;
        margin-top: 10px;
        width:140px;
        height: 38px;
        border-radius: 5px;
        border-color: #aaadb7;
        margin-left: 8px;
    }

    .datepicker-input{
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        background-color: var(--color-background-background-casillas);
        height: 35px !important;
        margin-top: 0 !important;
    }

    .datepicker-input{
        border: 1px solid #c7c8cc !important;
        outline: none;
        margin: 0 !important;
        width: 150px !important;
        height: 50px !important;
    }

    .datepicker-input:hover, .datepicker-input:focus {
        border: 1px solid #343948 !important;
    }

    /*#my-helper-text{
        display: none;
    }*/

    @media (max-width: 900px){
        #filters-second-montante-inf-component, #filters-second-montante-sup-component, .inlined.second-mobile-select {
            margin-top: 15px;
            width: 150px;    
        }
    }
</style>