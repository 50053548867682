<template>
  <div id="simulator">
    <HeaderSidebarRight :id_header="id_header" :title_header="title_header" />
    <div v-if="_g_UserFeatures.permissions.index_creator" id="simulator-full-screen">
      <div class="simulator-container">
        <div class="simulator-table">
          <div class="table-wrapper">
            <table class="custom-table">
              <thead>
                <tr class="table-header-row">
                  <th v-for="(item, index) in table_header" :key="index" class="table-header-cell">{{item}}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="table-row">
                  <td class="table-cell" :class="profit >= 0 ? 'positive-value' : 'negative-value'">
                    {{profit ? (profit > 0 ? '+' : '') + profit.toFixed(2) + '%' : '0.00%'}}
                  </td>
                  <td class="table-cell">
                    {{max_drawdown ? max_drawdown.toFixed(2) + '%' : '0.00%'}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="simulator-highchart">
          <div id="highchart-content"></div>
        </div>

        <div class="info-panel">
          <p class="info-text-style">
            Añada tickers a su índice personalizado para simular su comportamiento. Puede guardar múltiples índices y cargarlos posteriormente.
          </p>
        </div>

        <div class="simulator-section-title">
          <p>TICKERS SELECCIONADOS</p>
        </div>

        <div class="simulator-chips-container">
          <div class="simulator-chips-box" v-for="(ticker, index) in user_tickers" :key="ticker">
            <span class="ticker-label">{{ticker}}</span>
            <button @click="_deleteTicker(index)" class="icon-button delete-button">
              <i class="material-icons">cancel</i>
            </button>
          </div>
        </div>

        <div class="ticker-info-container">
          <p>
            <span>Ticker:</span> 
            <b>{{_g_SelectedTicker.ticker}}</b>       
            <button 
              class="action-btn simulator-button-add" 
              :class="isCurrentTickerAdded ? 'added-btn' : 'primary-btn'"
              @click="_addSelectedTicker(_g_SelectedTicker.ticker)" 
              :disabled="isCurrentTickerAdded">
              {{ isCurrentTickerAdded ? 'Añadido' : 'Añadir' }}
            </button> 
          </p>
        </div>

        <div class="simulator-input-section">
          <button class="action-btn primary-btn add-ticker-btn" @click="_openTickerSearchModal">
            <i class="material-icons">add</i>
            <span>Añadir otro ticker</span>
          </button>
        </div>

        <hr class="separator" />

        <!-- Selected index information and actions -->
        <div class="selected-index-container" v-if="selected_index && selectedIndexFromModal">
          <div class="selected-index-header">
            <h3 class="selected-index-title">Viendo índice: <span>{{ selectedIndexFromModal.name }}</span></h3>
            <p v-if="hasIndexChanges" class="changes-status">
              <i class="material-icons">edit</i>
              <span>Se han realizado cambios</span>
            </p>
          </div>
          <div class="selected-index-actions">
            <button v-if="hasIndexChanges"
                   class="action-btn success-btn equal-width-btn" 
                   @click="_saveIndexChanges" 
                   :disabled="user_tickers.length === 0">
              <i class="material-icons">save</i>
              <span>Guardar cambios</span>
            </button>
            <button v-if="hasIndexChanges"
                   class="action-btn warning-btn equal-width-btn" 
                   @click="_discardIndexChanges">
              <i class="material-icons">replay</i>
              <span>Descartar cambios</span>
            </button>
            <button class="action-btn tertiary-btn equal-width-btn" @click="_deselectIndex">
              <i class="material-icons">close</i>
              <span>Deseleccionar</span>
            </button>
          </div>
        </div>

        <div class="load-save-actions">
          <div class="index-actions-buttons">
            <button class="action-btn primary-btn index-btn" @click="_openLoadIndexModal">
              <i class="material-icons">folder_open</i>
              Cargar índice
            </button>
            <button class="action-btn primary-btn index-btn" @click="_openSaveIndexModal">
              <i class="material-icons">save</i>
              Guardar nuevo índice
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="not-available-data-container">
      <div class="info-ticker-box">
        <p class="text-info-ticker">Ticker: <span class="text-bold">{{_g_SelectedTicker.ticker}}</span></p>
      </div>
      <p class="not-available-data typo-body-2"> Su plan actual no tiene acceso a esta herramienta. </p>
      <a href="/app/store" class="link-to-store typo-body-2"> Pulse aquí para cambiar de plan. </a>
    </div>

    <!-- Modal para cargar índices -->
    <div class="modal-overlay" v-if="showLoadIndexModal" @click="_closeLoadIndexModal">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3 class="modal-title">Cargar índice</h3>
          <button class="modal-close-btn" @click="_closeLoadIndexModal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-indices-list">
            <div 
              v-for="index in user_indices_creator" 
              :key="index.icid" 
              class="modal-index-item"
              @click="_selectIndexFromModal(index)"
              :class="{ 'selected-index': selected_index == index.icid }"
            >
              <div class="modal-index-info">
                <span class="modal-index-name">{{index.name}}</span>
                <span class="modal-index-tickers">{{index.tickers}}</span>
              </div>
              <div class="modal-index-actions">
                <button @click.stop="_editIndex(index)" class="icon-button edit-index-btn">
                  <i class="material-icons">edit</i>
                </button>
                <button @click.stop="_removeIndex(index)" class="icon-button delete-index-btn">
                  <i class="material-icons">delete</i>
                </button>
              </div>
            </div>
            <div v-if="user_indices_creator.length === 0" class="no-indices-message">
              No hay índices guardados
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="action-btn secondary-btn" @click="_closeLoadIndexModal">Cancelar</button>
          <button 
            class="action-btn primary-btn" 
            @click="_confirmLoadIndex" 
            :disabled="!selected_index"
          >
            Cargar
          </button>
        </div>
      </div>
    </div>

    <!-- Modal de confirmación para eliminar índice -->
    <div class="modal-overlay" v-if="showDeleteConfirmModal" @click="_closeDeleteConfirmModal">
      <div class="modal-container delete-confirm-modal" @click.stop>
        <div class="modal-header">
          <h3 class="modal-title">Confirmar eliminación</h3>
          <button class="modal-close-btn" @click="_closeDeleteConfirmModal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body">
          <p class="delete-confirm-message">
            ¿Está seguro que desea eliminar el índice "<strong>{{ indexToDelete ? indexToDelete.name : '' }}</strong>"?
          </p>
          <p class="delete-warning">
            <i class="material-icons warning-icon">warning</i>
            Esta acción no se puede deshacer.
          </p>
        </div>
        <div class="modal-footer">
          <button class="action-btn secondary-btn" @click="_closeDeleteConfirmModal">Cancelar</button>
          <button class="action-btn delete-btn" @click="_confirmDeleteIndex">
            Eliminar
          </button>
        </div>
      </div>
    </div>

    <!-- Modal para guardar índice -->
    <div class="modal-overlay" v-if="showSaveIndexModal" @click="_closeSaveIndexModal">
      <div class="modal-container save-index-modal" @click.stop>
        <div class="modal-header">
          <h3 class="modal-title">Guardar nuevo índice</h3>
          <button class="modal-close-btn" @click="_closeSaveIndexModal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body">
          <div class="save-index-form">
            <div class="form-group">
              <label for="index-name-input" class="input-label">Nombre del índice</label>
              <input 
                type="text" 
                id="index-name-input" 
                class="vanilla-input" 
                v-model="index_creator_name" 
                placeholder="Ingrese nombre del índice"
                :maxlength="30"
              />
              <div class="input-helper" v-if="index_creator_name">
                <span>{{index_creator_name.length}} / 30</span>
              </div>
            </div>
            <div class="index-tickers-summary" v-if="user_tickers.length > 0">
              <p class="tickers-label">Tickers seleccionados:</p>
              <p class="tickers-list">{{user_tickers.join(', ')}}</p>
            </div>
            <div class="no-tickers-message" v-else>
              <p>No hay tickers seleccionados.</p>
              <p class="tickers-helper">Añada tickers desde la pantalla principal para incluirlos en su índice personalizado.</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="action-btn secondary-btn" @click="_closeSaveIndexModal">Cancelar</button>
          <button 
            class="action-btn primary-btn" 
            @click="_saveIndex" 
            :disabled="!index_creator_name || user_tickers.length === 0"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>

    <!-- Modal para buscar ticker -->
    <div class="modal-overlay" v-if="showTickerSearchModal" @click="_closeTickerSearchModal">
      <div class="modal-container ticker-search-modal" @click.stop>
        <div class="modal-header">
          <h3 class="modal-title">Buscar ticker</h3>
          <button class="modal-close-btn" @click="_closeTickerSearchModal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body">
          <div class="ticker-search-form">
            <div class="form-group">
              <label for="ticker-search-input" class="input-label">Buscar ticker para añadir</label>
              <div class="autocomplete-wrapper">
                <AutocompleteInput
                  :options="options"
                  :show="showTickerSearchModal"
                  :disabled="disabled_ticker_to_check"
                  @select="_selectSuggestions"
                  @suggestions="_autocompleteSuggestions"
                  placeholder="Introduzca el ticker a buscar">
                  <template slot="item" scope="option">
                    <article class="autocomplete-item">
                      <div class="autocomplete-item-content">
                        <p class="autocomplete-item-title">{{ option.title }}</p>
                        <p class="autocomplete-item-description">{{ option.description }}</p>
                      </div>
                    </article>
                  </template>
                </AutocompleteInput>
              </div>
              <p class="search-helper-text">Introduzca un término para buscar tickers disponibles</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="action-btn secondary-btn" @click="_closeTickerSearchModal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Vue from 'vue';
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";
import Highcharts from "highcharts";
import AutocompleteInput from "@/components/helpers/AutocompleteInput";
import Button from "@/components/helpers/Button.vue";
import HeaderSidebarRight from './HeaderSidebarRight.vue';
import inputIndexName from './configs/input_simulator.json';
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';


export default {
  mixins:[APICaller],
  components: {
    AutocompleteInput,
    Button,
    HeaderSidebarRight,
    InputTextOutline
    },
  name: 'Simulator',
  props: {
    stock:{type: Object},
  },
  data: () => ({
    selected_index: "",
    input_IndexName : inputIndexName,
    show: true,
    disabled_ticker_to_check: false,
    options: [],
    user_tickers: [],
    original_tickers: [],
    index_creator_name: '',
    user_indices_creator: [],
    series: [{
      name: 'Indice de cartera',
      data: [0, 0]
    }],
    categories: [],
    user_portfolios: [],

    table_header: ['Rentabilidad', 'Max.Drawdown'],
    profit: 0,
    max_drawdown: 0,

    num: 2,
    button_name_save: "Guardar índice",
    button_icon_save: "none",
    button_key_save: "button_key_save",

    id_header: 'simulator',
    title_header: 'Creador de índice',
    input_IndexName_value: '',
    showLoadIndexModal: false,
    selectedIndexFromModal: null,
    showDeleteConfirmModal: false,
    indexToDelete: null,
    _shouldReopenLoadModal: false,
    showSaveIndexModal: false,
    showTickerSearchModal: false,
  }),
  computed: {
    ...mapGetters(['_g_SelectedTicker']),
    ...mapGetters(['_g_UserFeatures']),
    
    // Check if the current ticker is already added to the list
    isCurrentTickerAdded() {
      return this._g_SelectedTicker && this._g_SelectedTicker.ticker && 
             this.user_tickers.includes(this._g_SelectedTicker.ticker);
    },
    
    // Check if there are changes to the currently selected index
    hasIndexChanges() {
      if (!this.selectedIndexFromModal) return false;
      if (!this.original_tickers || !this.user_tickers) return false;
      if (this.original_tickers.length === 0) return false;
      
      if (this.user_tickers.length !== this.original_tickers.length) return true;
      
      // Compare arrays element by element
      for (let i = 0; i < this.user_tickers.length; i++) {
        if (this.user_tickers[i] !== this.original_tickers[i]) {
          return true;
        }
      }
      
      return false;
    }
  },
  created() {
  },
  mounted() {
    this._addDataToHighchart();
    this._loadAllIndexCreator();
    this._getData();
    
    // Force redraw after the component is fully rendered
    this.$nextTick(() => {
      // Delayed redraw to ensure container is properly sized
      setTimeout(() => {
        if (this.$_chart && typeof this.$_chart.reflow === 'function') {
          this.$_chart.reflow();
        }
      }, 100);
    });
  },
  beforeDestroy() {
    // Clean up resize observer to prevent memory leaks
    if (this.$_resizeObserver) {
      this.$_resizeObserver.disconnect();
    }
  },
  methods: {
    //Method that selects the color of the profit text according to whether the profitability is positive or negative.
    _colorProfitIndex: function(profit){
      if(profit == 0){
        return "#B00020";
      }
      else if(profit < 0){
        return 'red';
      } else{
        return 'green';
      }
    },

    //Method that configures the data with which the graphics will be printed
    _addDataToHighchart: function(){
      let self = this;
      Vue.nextTick(function() {
        // Store chart reference for later use
        self.$_chart = Highcharts.chart('highchart-content',{
          chart: {
            type: 'line',
            zoomType: 'x',
            backgroundColor: 'transparent',
            reflow: true,
            spacingTop: 30,
            spacingBottom: 20,
            spacingLeft: 20,
            spacingRight: 25, // Extra padding on right side
            marginRight: 25, // Add explicit right margin
            marginLeft: 5,   // Add explicit left margin
            events: {
              // Ensure chart remains responsive
              load: function() {
                this.reflow();
                
                // Adjust extremes to ensure all data is visible
                const xAxis = this.xAxis[0];
                if (xAxis && self.series && self.series[0] && self.series[0].data && self.series[0].data.length > 0) {
                  const minX = 0;
                  const maxX = self.series[0].data.length > 1 ? self.series[0].data.length - 1 : 1;
                  xAxis.setExtremes(minX, maxX, true, false);
                }
              }
            }
          },
          title: {
            text: '',
          },
          xAxis: {
            visible: false,
            gridLineWidth: 0,
            lineWidth: 0,
            endOnTick: false,
            startOnTick: false,
            // Remove min/max to allow data to drive the scale
            min: null,
            max: null,
            // Add padding to prevent lines from touching the edges
            minPadding: 0.05,
            maxPadding: 0.05,
          },
          yAxis: {
            title: '',
            type: 'logarithmic',
            gridLineColor: 'rgba(0, 0, 0, 0.08)',
            gridLineWidth: 1,
            lineWidth: 0,
            tickWidth: 0,
            endOnTick: false,
            // Add padding to prevent lines from touching the edges
            minPadding: 0.1,
            maxPadding: 0.25,
            labels: {
              style: {
                color: 'var(--color-text-secondary, #606577)'
              }
            }
          },
          tooltip: {
            shared: true,
            crosshairs: {
              width: 1,
              color: 'var(--color-outline, rgba(0, 0, 0, 0.2))',
              dashStyle: 'shortdot'
            },
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            borderColor: 'var(--color-outline, rgba(0, 0, 0, 0.12))',
            borderWidth: 1,
            borderRadius: 5,
            shadow: false
          },
          plotOptions: {
            line: {
              lineWidth: 3,
              states: {
                hover: {
                  lineWidth: 4
                }
              },
              marker: {
                enabled: false,
                radius: 3,
                states: {
                  hover: {
                    enabled: true,
                    radius: 5
                  }
                }
              }
            },
            series: {
              animation: {
                duration: 300
              },
              // Disable clipping to prevent data from being cut off
              clip: false
            }
          },
          series: self.series && self.series.length 
            ? self.series 
            : [{
                name: 'Índice de cartera',
                data: [0, 0],
                color: 'var(--primary, #00aedd)'
              }],
          colors: ['var(--primary, #00aedd)', 'var(--color-text-primary, #0F0F1A)'],
          credits: {
            enabled: false
          },
          legend: {
            enabled: true,
            align: 'center',
            verticalAlign: 'top',
            itemStyle: {
              color: 'var(--color-text-primary, #0F0F1A)',
              fontWeight: '500',
              fontSize: '13px'
            },
            itemHoverStyle: {
              color: 'var(--primary, #00aedd)'
            }
          },
          responsive: {
            rules: [{
              condition: { maxWidth: 500 },
              chartOptions: {
                legend: { 
                  enabled: true,
                  floating: true
                }
              }
            }]
          }
        });
        
        // Force a reflow on window resize to ensure chart is responsive
        const resizeObserver = new ResizeObserver(() => {
          if (self.$_chart && typeof self.$_chart.reflow === 'function') {
            self.$_chart.reflow();
          }
        });
        
        resizeObserver.observe(document.getElementById('highchart-content'));
        
        // Store the observer for cleanup
        self.$_resizeObserver = resizeObserver;
      });
    },
    //call to api to load the indexes created by the user
    _loadAllIndexCreator: function(){
        let success = response => {
          this.user_indices_creator = response.data;
        };
        let failure = () =>{};
        let url = '/api/v1/stock-finder/list-index-creator';
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('077', 'Error cargando índices.');

        this._getAPICall(url, successHandler, failureHandler);
    },

    //method to add the selected ticker to the index that is being created
    _addSelectedTicker:function(ticker){
      if(this.user_tickers.indexOf(ticker) == -1){
        this.user_tickers.push(ticker);

        this._getData();
      } else {
        this.$vueOnToast.pop('error', "Error", "No pueden haber elementos repetidos. Este ticker ya se encuentra entre los seleccionados.");
      }
    },
    _getValueFromComponentIndexName(value) {
      this.input_IndexName_value = value[0];
      this.index_creator_name = this.input_IndexName_value;
    },
    //call to api to collect all the data about the user's tickers and indexes
    _getData: function(){
      if (!this.user_tickers || this.user_tickers.length === 0) {
        // Reset chart with default empty data
        this.series = [{
          name: 'Índice de cartera',
          data: [0, 0]
        }];
        this.categories = [];
        this.max_drawdown = 0;
        this.profit = 0;
        this._addDataToHighchart();
        return;
      }

      let query = "?tickers=" + this.user_tickers.toString();

      let success = response => {
        if (response.data && response.data.series) {
          // Store the series data
          this.series = response.data.series;
          this.categories = response.data.categories && response.data.categories[0] 
            ? response.data.categories[0].data 
            : [];
          this.max_drawdown = response.data.max_drawdown || 0;
          this.profit = response.data.profit || 0;

          // Redraw the chart
          this._addDataToHighchart();
          
          // Additional post-render adjustment
          this.$nextTick(() => {
            if (this.$_chart) {
              // Force a reflow after data is loaded
              this.$_chart.reflow();
              
              // Delayed update to ensure proper rendering
              setTimeout(() => {
                if (this.$_chart) {
                  this.$_chart.redraw();
                }
              }, 150);
            }
          });
        } else {
          // Handle empty response
          this._addDataToHighchart();
        }
      };

      let url = "/api/v1/portfolio/calculate-portfolio-index-from-tickers" + query;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('078', 'Error cargando valores.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    //method to remove one of the selected tickers
    _deleteTicker : function(index){
      this.user_tickers.splice(index, 1)
      
      this._getData();
    },
    //method to load the tickers of the selected index
    _loadIndex: function(index){
      this.selected_index = index.icid;
      this.selectedIndexFromModal = index;
      
      if(index){
        this.user_tickers = index.tickers.split(',');
        this._getData();
      }
    },
    //method to remove the index on which you click - updated to use custom modal
    _removeIndex: function(index){
      this.indexToDelete = index;
      this.showDeleteConfirmModal = true;
      
      // Hide the load index modal if open and set flag to reopen it later
      if (this.showLoadIndexModal) {
        this.showLoadIndexModal = false;
        this._shouldReopenLoadModal = true;
      }
    },
    //new method to confirm deletion after modal confirmation
    _confirmDeleteIndex: function() {
      if (!this.indexToDelete) return;
      
      let params = new URLSearchParams();
      params.append('icid', this.indexToDelete.icid);

      let success = response => {
        if (response.data.status == 'error') {
          this.$vueOnToast.pop('error', "Error" + response.data.message);
        } else {
          this._loadAllIndexCreator();
          this.$vueOnToast.pop('success', "IC", "borrado correctamente!");
        }
        
        // Reset delete modal state
        this.showDeleteConfirmModal = false;
        this.indexToDelete = null;
        
        // Reopen the load index modal if it was previously open
        if (this._shouldReopenLoadModal) {
          this._shouldReopenLoadModal = false;
          setTimeout(() => {
            this.showLoadIndexModal = true;
          }, 50);
        }
      };

      let failure = () => {
        // Reset and close modal even on failure
        this.showDeleteConfirmModal = false;
        this.indexToDelete = null;
        
        // Reopen the load index modal if it was previously open
        if (this._shouldReopenLoadModal) {
          this._shouldReopenLoadModal = false;
          setTimeout(() => {
            this.showLoadIndexModal = true;
          }, 50);
        }
      };

      let url = '/api/v1/stock-finder/delete-index-creator';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('276', 'Error borrando índice.', failure);

      this._postAPICall(url, params, successHandler, failureHandler);
    },
    //method to save all selected tickers in a new index - updated
    _saveIndex: function(){
      if (!this.index_creator_name || this.user_tickers.length === 0) {
        return; // Don't proceed if name is empty or no tickers selected
      }
      
      let params = new URLSearchParams();
      params.append('name', this.index_creator_name);
      params.append('tickers', this.user_tickers);

      let success= response => {
        if (response.data.status == 'error') {
          this.$vueOnToast.pop('error', "Error" + response.data.message);
        } else {
          this.index_creator_name = '';
          this._loadAllIndexCreator();
          this.$vueOnToast.pop('success', "IC", "guardado bien!");
          this._closeSaveIndexModal(); // Close modal after successful save
        }
      };

      let url = '/api/v1/stock-finder/save-index-creator';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('277', 'Error guardando índice.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    _autocompleteSuggestions: function(option) {
      this.options = [];
      this.ticker = option;
      this.currency = 'EUR';
      this.entries_analysis = [];

      let success = response => {
        this.options = [];

        for (var key in response.data) {
          this.options.push({ title: key, description: response.data[key] });
        }
      };


      let url = '/api/v1/portfolio/waitings/autocomplete/' + option;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('079', 'Error cargando autocompletado.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _getValueFromSimulatorIndex(value) {
      this.input_simulator_value = value[0];
    },

    ...mapMutations(['setSelectedTicker']),

    // New methods for modal
    _openLoadIndexModal: function() {
      // Make sure delete modal is closed
      this.showDeleteConfirmModal = false;
      this.indexToDelete = null;
      
      // Open the load modal
      this.showLoadIndexModal = true;
      this._loadAllIndexCreator(); // Refresh the index list when opening modal
    },
    
    _closeLoadIndexModal: function() {
      this.showLoadIndexModal = false;
    },
    
    _selectIndexFromModal: function(index) {
      this.selectedIndexFromModal = index;
      this.selected_index = index.icid;
    },
    
    _confirmLoadIndex: function() {
      if (this.selected_index && this.selectedIndexFromModal) {
        const index = this.selectedIndexFromModal;
        this.user_tickers = index.tickers.split(',');
        this.original_tickers = [...this.user_tickers];
        this._getData();
        this._closeLoadIndexModal();
      }
    },

    _editIndex: function(index){
      // Set the selected index and close the modal
      this.selectedIndexFromModal = index;
      this.selected_index = index.icid;
      
      // Store both current and original tickers
      this.user_tickers = index.tickers.split(',');
      this.original_tickers = [...this.user_tickers]; // Create a copy
      
      this._getData();
      this._closeLoadIndexModal();
      
      // Show a toast notification that we're now viewing
      this.$vueOnToast.pop('info', "Viendo índice", index.name);
    },

    // Add method to close delete confirmation
    _closeDeleteConfirmModal: function() {
      this.showDeleteConfirmModal = false;
      this.indexToDelete = null;
      
      // Reopen the load index modal if it was previously open
      if (this._shouldReopenLoadModal) {
        this._shouldReopenLoadModal = false;
        this.showLoadIndexModal = true;
      }
    },

    // Make sure other modals are closed when opening save modal
    _openSaveIndexModal: function() {
      // Close other modals
      this.showLoadIndexModal = false;
      this.showDeleteConfirmModal = false;
      this.indexToDelete = null;
      
      // Show save modal
      this.showSaveIndexModal = true;
    },

    _closeSaveIndexModal: function() {
      this.showSaveIndexModal = false;
    },

    // New methods for selected index
    _saveIndexChanges: function() {
      if (!this.selectedIndexFromModal || !this.hasIndexChanges) return;
      
      let params = new URLSearchParams();
      params.append('name', this.selectedIndexFromModal.name);
      params.append('tickers', this.user_tickers.join(','));
      params.append('icid', this.selectedIndexFromModal.icid);

      let success = response => {
        if (response.data.status == 'error') {
          this.$vueOnToast.pop('error', "Error: " + response.data.message);
        } else {
          this._loadAllIndexCreator();
          // Update original_tickers after successful save
          this.original_tickers = [...this.user_tickers];
          this.$vueOnToast.pop('success', "Índice", "actualizado correctamente");
        }
      };

      let url = '/api/v1/stock-finder/edit-index-creator';
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('582', 'Error guardando cambios del índice.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },
    
    _discardIndexChanges: function() {
      // Reload the original index data
      if (this.selectedIndexFromModal && this.original_tickers.length && this.hasIndexChanges) {
        this.user_tickers = [...this.original_tickers]; // Use the stored original tickers
        this._getData();
        this.$vueOnToast.pop('info', "Cambios descartados");
      }
    },
    
    _deselectIndex: function() {
      // Clear selection, data and tickers
      this.selected_index = "";
      this.selectedIndexFromModal = null;
      this.original_tickers = []; // Clear original tickers
      this.user_tickers = []; // Clear all selected tickers
      this._getData(); // Update chart with empty tickers
      this.$vueOnToast.pop('info', "Índice deseleccionado");
    },

    // New methods for ticker search modal
    _openTickerSearchModal: function() {
      this.showTickerSearchModal = true;
      this.options = []; // Clear previous options
    },
    
    _closeTickerSearchModal: function() {
      this.showTickerSearchModal = false;
    },
    
    _selectSuggestions: function(option) {
      // Add the selected ticker to the list
      if (this.user_tickers.indexOf(option.title) === -1) {
        this.user_tickers.push(option.title);
        this._getData();
        this._closeTickerSearchModal(); // Close the modal after selection
        this.$vueOnToast.pop('success', "Ticker añadido", option.title);
      } else {
        this.$vueOnToast.pop('error', "Error", "Este ticker ya está en la lista");
        // Keep the modal open if there's an error
      }
    },
  },
}
</script>


<style lang="scss" scoped>
  .simulator-container {
    max-width: 600px;
    margin: 20px auto;
    margin-bottom: 20px;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background);
    box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
    width: 85%;
  }
  
  .table-wrapper {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(15, 15, 26, 0.1);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }
  
  .table-header-row {
    background-color: var(--color-surface, #F3F4F7);
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  }
  
  .table-header-cell {
    color: var(--color-text-secondary, rgba(0, 0, 0, 0.6));
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 12px 16px;
    font-weight: 600;
  }
  
  .table-row {
    background-color: var(--color-background, white);
  }
  
  .table-cell {
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
  }
  
  .table-cell:last-child {
    border-right: none;
  }
  
  .positive-value {
    color: var(--color-success, #57a869);
  }
  
  .negative-value {
    color: var(--color-error, #B00020);
  }
  
  .simulator-highchart {
    margin: 20px 0;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 6px;
    overflow: visible;
    min-height: 330px;
    background-color: var(--color-surface, #F3F4F7);
    position: relative;
  }
  
  #highchart-content {
    height: 330px;
    width: 100% !important;
    min-height: 330px;
    padding: 15px;
    display: block;
    position: relative;
    overflow: visible;
  }

  /* Fix for Highcharts responsive layout */
  :deep(.highcharts-container),
  :deep(.highcharts-root) {
    width: 100% !important;
    height: 100% !important;
    overflow: visible !important;
  }
  
  :deep(.highcharts-series-group) {
    overflow: visible !important;
  }
  
  :deep(.highcharts-point) {
    stroke-width: 0;
  }
  
  :deep(.highcharts-grid-line) {
    stroke-dasharray: 2, 2;
  }
  
  :deep(.highcharts-plot-border) {
    stroke-width: 0;
  }
  
  .info-panel {
    background-color: var(--color-surface, #f5f7fa);
    border-radius: 6px;
    padding: 15px;
    margin: 20px 0;
    border-left: 4px solid var(--primary, #00aedd);
  }
  
  .info-text-style {
    margin: 0;
    font-size: 13px;
    line-height: 1.5;
    color: var(--color-text-primary, #0F0F1A);
  }
  
  .ticker-info-container {
    background-color: var(--color-surface, #f5f7fa);
    border-radius: 6px;
    padding: 15px 20px;
    margin: 15px 0;
  }
  
  .ticker-info-container p {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: var(--color-text-primary, #0F0F1A);
  }
  
  .ticker-info-container span {
    color: var(--color-text-secondary, #606577);
  }

  .simulator-section-title {
    margin: 25px 0 15px;
    text-align: left;
  }
  
  .simulator-section-title p {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-primary, #0F0F1A);
    margin: 0;
    letter-spacing: 0.1px;
  }

  .simulator-chips-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 15px 0;
  }

  .simulator-chips-box {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    color: var(--color-text-primary, #0F0F1A);
    border-radius: 16px;
    padding: 6px 12px;
    transition: all 0.2s ease;
  }
  
  .simulator-chips-box.active-index {
    background-color: var(--color-highlight, #000000);
    color: white;
  }
  
  .ticker-label, .index-name {
    cursor: pointer;
  }

  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .delete-button i {
    font-size: 18px;
    color: var(--color-text-secondary, #606577);
    transition: color 0.2s ease;
  }
  
  .delete-button:hover i {
    color: var(--error, #B00020);
  }
  
  .confirm-button i {
    font-size: 18px;
    color: var(--success, #57a869);
  }

  .separator {
    border: none;
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    margin: 25px 0;
  }

  .simulator-input-section {
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }
  
  .add-ticker-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 200px;
  }
  
  .add-ticker-btn i {
    font-size: 20px;
  }
  
  /* Ticker search modal styles */
  .ticker-search-modal {
    max-width: 600px;
    width: 95%;
  }
  
  .ticker-search-form {
    width: 100%;
  }
  
  .autocomplete-wrapper {
    margin-bottom: 12px;
    position: relative;
    width: 100%;
  }
  
  .search-helper-text {
    font-size: 12px;
    color: var(--color-text-secondary, #606577);
    margin-top: 6px;
    text-align: center;
    font-style: italic;
  }
  
  .autocomplete-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    width: 100%;
  }
  
  .autocomplete-item-content {
    flex: 1;
    min-width: 0;
    overflow: hidden;
  }
  
  .autocomplete-item-action {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-highlight, #00aedd);
    margin-left: 10px;
    flex-shrink: 0;
  }
  
  .autocomplete-item-action i {
    font-size: 20px;
  }
  
  .autocomplete-item-title {
    font-weight: 600;
    font-size: 16px;
    color: var(--color-text-primary, #0F0F1A);
    margin: 0 0 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .autocomplete-item-description {
    font-size: 13px;
    color: var(--color-text-secondary, #606577);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .not-available-data-container {
    text-align: center;
    max-width: 700px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    background-color: var(--color-background, white);
    box-shadow: 0 1px 3px rgba(15, 15, 26, 0.1);
  }

  .info-ticker-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .text-info-ticker {
    text-align: center;
    background-color: #F3F4F7;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 16px;
    padding: 7px 25px;
    border-radius: 4px;
  }

  .text-info-ticker span {
    font-size: 18px;
  }

  .text-bold {
    font-weight: bold;
  }

  .not-available-data {
    color: #FF9B00;
    margin: 20px 0;
  }

  .link-to-store {
    color: #35add8;
    text-decoration: none;
    font-weight: 500;
  }
  
  .link-to-store:hover {
    text-decoration: underline;
  }

  .load-save-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .index-actions-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 20px 0;
    flex-wrap: wrap;
  }
  
  .index-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 220px;
  }
  
  .index-btn i {
    font-size: 20px;
  }
  
  .secondary-btn {
    background-color: var(--color-surface, #F3F4F7);
    color: var(--color-text-primary, #0F0F1A);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  }
  
  .secondary-btn:hover {
    background-color: var(--color-background, #EAECEF);
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(15, 15, 26, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-container {
    background-color: var(--color-background, #FFFFFF);
    border-radius: 8px;
    width: 95%;
    max-width: 500px;
    max-height: 90vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 20px rgba(15, 15, 26, 0.15);
  }
  
  .modal-header {
    padding: 16px 20px;
    border-bottom: 1px solid var(--color-outline, rgba(15, 15, 26, 0.2));
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text-primary, #0F0F1A);
  }
  
  .modal-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-text-secondary, #606577);
  }
  
  .modal-body {
    padding: 20px;
    overflow-y: auto;
    max-height: 60vh;
    box-sizing: border-box;
    position: relative;
  }
  
  .modal-footer {
    padding: 16px 20px;
    border-top: 1px solid var(--color-outline, rgba(15, 15, 26, 0.2));
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .modal-indices-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .modal-index-item {
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid var(--color-outline, rgba(15, 15, 26, 0.2));
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .modal-index-item:hover {
    background-color: var(--color-surface, #F3F4F7);
  }
  
  .modal-index-item.selected-index {
    background-color: var(--color-active-surface, #d9ecf3);
    border-color: var(--color-highlight, #00aedd);
  }
  
  .modal-index-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .modal-index-name {
    font-weight: 600;
    font-size: 16px;
    color: var(--color-text-primary, #0F0F1A);
  }
  
  .modal-index-tickers {
    font-size: 13px;
    color: var(--color-text-secondary, #606577);
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .modal-index-actions {
    display: flex;
    gap: 8px;
  }
  
  .delete-index-btn i {
    color: var(--color-text-secondary, #606577);
    transition: color 0.2s ease;
  }
  
  .delete-index-btn:hover i {
    color: var(--color-error, #B00020);
  }
  
  .edit-index-btn i {
    color: var(--color-text-secondary, #606577);
    transition: color 0.2s ease;
  }
  
  .edit-index-btn:hover i {
    color: var(--color-highlight, #00aedd);
  }
  
  .no-indices-message {
    text-align: center;
    padding: 30px 0;
    color: var(--color-text-secondary, #606577);
    font-style: italic;
  }
  
  @media (max-width: 767px) {
    .modal-container {
      width: 95%;
      max-height: 80vh;
    }
    
    .modal-index-tickers {
      max-width: 200px;
    }
  }

  /* Delete confirmation modal styles */
  .delete-confirm-modal {
    max-width: 450px;
  }
  
  .delete-confirm-message {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 16px;
    color: var(--color-text-primary, #0F0F1A);
  }
  
  .delete-warning {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-warning, #FF9B00);
    font-size: 14px;
  }
  
  .warning-icon {
    font-size: 20px;
  }
  
  .delete-btn {
    background-color: var(--color-error, #B00020);
    color: white;
  }
  
  .delete-btn:hover {
    background-color: #d32f2f;
  }

  /* Save Index Modal Styles */
  .save-index-modal {
    max-width: 450px;
  }
  
  .form-group {
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
  }
  
  .input-label {
    display: block;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-primary, #0F0F1A);
  }
  
  .vanilla-input {
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid var(--color-outline, rgba(15, 15, 26, 0.2));
    border-radius: 4px;
    background-color: var(--color-background, #FFFFFF);
    color: var(--color-text-primary, #0F0F1A);
    transition: border-color 0.2s ease;
    box-sizing: border-box;
  }
  
  .vanilla-input:focus {
    outline: none;
    border-color: var(--color-highlight, #00aedd);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.2);
  }
  
  .vanilla-input::placeholder {
    color: var(--color-text-secondary, #606577);
    opacity: 0.6;
  }
  
  .input-helper {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: var(--color-text-secondary, #606577);
    margin-top: 4px;
  }
  
  .index-tickers-summary {
    background-color: var(--color-surface, #F3F4F7);
    border-radius: 6px;
    padding: 12px 16px;
  }
  
  .tickers-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
    color: var(--color-text-primary, #0F0F1A);
  }
  
  .tickers-list {
    font-size: 13px;
    line-height: 1.5;
    color: var(--color-text-secondary, #606577);
    word-break: break-all;
  }
  
  .no-tickers-message {
    background-color: var(--color-surface, #F3F4F7);
    border-radius: 6px;
    padding: 15px;
    text-align: center;
    color: var(--color-text-secondary, #606577);
  }
  
  .tickers-helper {
    font-size: 13px;
    line-height: 1.4;
    margin-top: 8px;
    font-style: italic;
  }

  .save-index-form {
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Selected index styles */
  .selected-index-container {
    background-color: var(--color-active-surface, #d9ecf3);
    border: 1px solid var(--color-highlight, #00aedd);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .selected-index-header {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .selected-index-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text-primary, #0F0F1A);
    margin: 0;
  }
  
  .selected-index-title span {
    color: var(--color-highlight, #00aedd);
  }
  
  .changes-status {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: 10px 0 0;
    font-size: 13px;
    color: var(--color-highlight, #00aedd);
  }
  
  .changes-status i {
    font-size: 16px;
  }
  
  .selected-index-content {
    margin-bottom: 15px;
  }
  
  .selected-tickers-summary {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 6px;
    padding: 10px 15px;
    margin-bottom: 15px;
  }
  
  .selected-tickers-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--color-text-primary, #0F0F1A);
  }
  
  .selected-tickers-list {
    font-size: 13px;
    line-height: 1.5;
    color: var(--color-text-secondary, #606577);
    word-break: break-all;
    margin: 0 0 5px;
  }
  
  .changes-indicator {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    margin: 8px 0 0;
    color: var(--color-highlight, #00aedd);
  }
  
  .changes-indicator i {
    font-size: 16px;
  }
  
  .selected-index-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .equal-width-btn {
    min-width: 180px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .equal-width-btn i {
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  
  .equal-width-btn span {
    line-height: 1;
  }
  
  .success-btn {
    background-color: var(--color-success, #57a869);
    color: white;
  }
  
  .success-btn:hover {
    background-color: #4c9a5d;
  }
  
  .warning-btn {
    background-color: var(--color-warning, #FF9B00);
    color: white;
  }
  
  .warning-btn:hover {
    background-color: #e08c00;
  }
  
  .tertiary-btn {
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  }
  
  .tertiary-btn:hover {
    background-color: var(--color-surface, #F3F4F7);
  }
  
  /* Mobile responsiveness for selected index */
  @media (max-width: 767px) {
    .selected-index-container {
      padding: 12px;
    }
    
    .selected-index-actions {
      flex-direction: column;
    }
    
    .selected-index-actions .action-btn {
      width: 100%;
    }
    
    .equal-width-btn {
      min-width: 100%;
      margin-bottom: 5px;
    }
  }

  .action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 36px;
    transition: all 0.2s ease;
    font-weight: 600;
  }

  .primary-btn {
    background-color: var(--color-highlight, #00aedd);
    color: white;
  }

  .primary-btn:hover {
    background-color: var(--color-muted, #35add8);
  }

  .simulator-button-add {
    min-width: 100px;
    margin-left: 10px;
    border-radius: 4px;
    height: 32px;
  }

  .added-btn {
    background-color: var(--color-surface, #F3F4F7);
    color: var(--color-text-secondary, #606577);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    cursor: default;
  }

  /* Mobile responsiveness */
  @media (max-width: 767px) {
    .simulator-container {
      padding: 15px;
    }
    
    .simulator-table {
      margin: 10px 0;
    }
    
    .ticker-info-container p {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .simulator-button-add {
      margin: 10px 0 0;
      width: 100%;
    }
    
    .add-ticker-btn {
      width: 100%;
    }
  }
</style>