<template>
  <div id="broker-connection" style="text-align: center;">

    <div class="header-container">
      <div class="title-container">
        <span>Trabajar orden</span>
      </div>
      <div class="close-btn-container">
        <span class="material-icons-outlined" @click="_closeComponent()">close</span>
      </div>
    </div>

    <div v-if="_g_UserFeatures.permissions.broker_connection">
      <div v-if="is_broker_enabled">
        <div id="broker-connection-login">

        </div>


        <div v-if="connected">
          <div v-if="show_order_resume">
            <div class="send-order-info-container">
              <p><b>¿Realmente quiere trabajar con estos datos?</b></p>
              <div class="info-ticker">
                <p><span>Símbolo:</span> <b>{{ _g_SelectedTicker.ticker }}</b> </p>
                <p><span>Tipo de Orden:</span> <b>{{ _g_SelectedTicker.entry_type_name }}</b> </p>
                <p><span>Tipo de instrumento:</span> <b>{{ this.instrument_selected }}</b> </p>
                <p><span>Precio de entrada:</span> <b>{{ _g_SelectedTicker.real_entry.toFixed(2) }}</b> </p>
                <p><span>Número de instrumentos:</span> <b>{{ _g_SelectedTicker.instrument_number }}</b> </p>
                <p><span>Orden Stop Loss:</span> <b>Stop Sell</b> </p>
                <p><span>Precio de salida:</span> <b>{{ _g_SelectedTicker.out_price.toFixed(2) }}</b> </p>
              </div>
              <div class="container-broker-connection">
                <div class="checkbox-container">
                  <input type="checkbox" class="checkbox-style" id="checkbox-id" required v-model="is_checked">
                </div>
                <div class="info-text-container">
                  <p class="info-text-style">
                    Soy consciente de que los precios y las cantidades de las órdenes pueden ser modificadas debido a las
                    restricciones de los reguladores. Voy a comprobar que la orden está correcta en el broker en el que la
                    voy a enviar.
                  </p>
                </div>
              </div>
              <div style="padding:25px;" class="info-text-style">
                <span><strong style="color:red;">*IMPORTANTE*</strong>Estos son los datos de la orden que vas a poder
                  enviar a tu broker. Al pulsar COPIAR ENLACE, tendrás el enlace copiado, y tendrás que abrir una ventana
                  nueva donde poder poner el enlace que hemos creado para que puedas trabajar con la orden.</span>
              </div>
              <div class="btns-container">
                <button id="send_order_btn" class="send_order_btn_style" @click="_sendOrder()">COPIAR ENLACE</button>
                <br>
                <button @click="_cancelOrder" class="edit-btn">Cancelar</button>
              </div>
              <div style="padding:25px; padding-bottom: 0;" class="info-text-style">
                <textarea style="width: 100%; height: 80px;" v-on:focus="$event.target.select()" id="input_url_copy"
                  readonly :value="url_to_copy" />
              </div>
              <div style="padding:25px; padding-top: 0;" class="info-text-style">
                <span>
                  Copia este enlace y pégalo en una pestaña o ventana nueva de navegador para poder trabajar una orden con
                  los datos vistos.
                </span>
              </div>
            </div>
          </div>

          <div v-else>
            <div style="text-align: center;" class="ticker-container">
              <span class="ticker-value"> Ticker: <strong>{{ _g_SelectedTicker.ticker }}</strong></span>
            </div>
            <br>
            <div class="connected-content-container">
              <h4> {{ broker_company_selected }} </h4>
              <br>
              <div>
                <p><strong>Tipos de instrumento:</strong></p>
                <SelectOutline :ref="select_order[0].ref" :component_class="select_order[0].component_class"
                  :label_name="select_order[0].label_name" :options="select_order[0].options"
                  :default_value="select_order[0].options[0].value"
                  :helper_select_permission="select_order[0].helper_select_permission"
                  @_emit_method_order_select="_getValueFromInstrumentSelect" class="select-style" />
              </div>
              <div class="ticker-info-container">
                <p><span>Ticker:</span> <b>{{ _g_SelectedTicker.ticker }}</b></p>
                <p><span>Tipo de entrada:</span> <b>{{ _g_SelectedTicker.entry_type_name }}</b></p>
                <p><span>Precio de entrada:</span> <b>{{ _g_SelectedTicker.real_entry.toFixed(2) }}</b></p>
                <p><span>Numero de instrumentos:</span> <b>{{ _g_SelectedTicker.instrument_number }}</b></p>
                <p><span>Precio de salida:</span> <b>{{ _g_SelectedTicker.out_price.toFixed(2) }}</b></p>
              </div>
              <hr class="separator" />
              <div class="send-order-container">
                <button class="send-order-btn" @click="_createOrder()"><b>TRABAJAR DATOS</b></button>
                <br>
                <br>
                <button class="send-order-btn" @click="_disconnectFromBroker()"><b>Volver a seleccionar servicio
                    externo</b></button>
              </div>
            </div>
          </div>

        </div>

        <div v-else>
          <div style="text-align: center;" class="ticker-container">
            <span class="ticker-value">Acceso a procesar ORDEN</span>
          </div>

          <div class="info-text-container">
            <br>
            <h3 class="info-text-style">ORDEN</h3>
            <br>
            <br>
            <p class="info-text-style">
              Selecciona el servicio de broker que tienes contratado para poder enviar la información necesaria a su
              entorno fuera de EUREKERS y poder trabajar la orden de compra.
            </p>
            <br>
            <p class="info-text-style">
              Por favor, tenga en cuenta que el acceso con el broker se establece a través del navegador vía encriptación
              segura con protocolos SSL/TSL y no tienen nada que ver con los servidores EUREKERS.
            </p>
          </div>
          <br>

          <div class="operation-btn broker-disconnected-options">
            <SelectOutline :ref="select_order[2].ref" :component_class="select_order[2].component_class"
              :label_name="select_order[2].label_name" :options="select_order[2].options"
              :default_value="select_order[2].options[0].value"
              :helper_select_permission="select_order[2].helper_select_permission"
              @_emit_method_order_select="_getValueFromBrokerCompanySelect" class="select-style" />
          </div>
        </div>
      </div>
      <div v-else>
        <p class="info-text-style">Actualmente el acceso al procesado de datos de Orden no se encuentra disponible</p>
      </div>
    </div>
    <div v-else class="not-available-data-container">
      <div class="info-ticker-box">
        <p class="text-info-ticker">Ticker: <span class="text-bold">{{ _g_SelectedTicker.ticker }}</span></p>
      </div>
      <p class="not-available-data typo-body-2"> Su plan actual no tiene acceso a esta herramienta. </p>
      <a href="/app/store" class="link-to-store typo-body-2"> Pulse aquí para cambiar de plan. </a>
    </div>
  </div>
</template>


<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

import axios from 'axios';

import SelectOutline from '@/components/helpers/SelectOutline.vue';

import selectOrder from './configs/select_order.json';

import HeaderSidebarRight from './HeaderSidebarRight.vue';

import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins: [APICaller],
  name: 'Order',
  components: {
    SelectOutline,
    HeaderSidebarRight
  },
  data: () => ({
    connected: false,
    is_fullscreen: true,
    select_order: selectOrder,
    instrument_selected: 'Stock',
    broker_company_selected: '',
    is_checked: false,
    login_access: null,
    is_broker_enabled: false,
    accept_conditions: false,
    order: null,
    lot_size: null,
    tick_size: null,
    order_exist: null,
    show_order_resume: null,
    url_to_copy: '',

    id_header: "broker-connection-header",
    title_header: "Trabajar orden",
  }),
  computed: {
    ...mapGetters(['_g_SelectedTicker', '_g_UserId', '_g_UserFeatures']),
  },

  created() {
  },

  mounted() {
    this._isBrokerConnected();
    this._isBrokerEnabled();
  },

  methods: {

    _isBrokerConnected: function () {
      axios.get(process.env.VUE_APP_API + '/broker/status')
        .then(
          response => {
            this.connected = false;
            if (this.connected) {
              this._getAccounts();
              this.$emit('_setBrokerConnectionStatus', [true])
            }
            else {
              this.$emit('_setBrokerConnectionStatus', [false])
            }
          },
          error => {
            this.$vueOnToast.pop('error', 'Error al consultar el estado de la conexion broker:' + error);
          }
        );
    },
    _getAccounts: function () {
      this.accounts = [];
      axios.get(process.env.VUE_APP_API + '/broker/accounts')
        .then(
          response => {

          },
          error => {
            this.$vueOnToast.pop('error', 'Error al consultar cuentas del broker disponible:' + error);
          }
        );
    },
    _isBrokerEnabled: function () {
      axios.get(process.env.VUE_APP_API + '/broker/enabled-status')
        .then(
          response => {
            this.is_broker_enabled = response.data;
          },
          error => {
            this.$vueOnToast.pop('error', 'Error al consultar disponibilidad del broker:' + error);
          }
        );
    },
    _disconnectFromBroker: function () {
      axios.get(process.env.VUE_APP_API + '/broker/disconnect')
        .then(
          response => {
            this.connected = false;
            this._isBrokerConnected();
          },
          error => {
            this.$vueOnToast.pop('error', 'Error desconectar con el broker:' + error);
          }
        );
    },
    _createOrder: function () {
      this.accept_conditions = false;
      var params = new URLSearchParams();
      params.append('analysis', JSON.stringify(this._g_SelectedTicker));
      params.append('user_id', this._g_UserId);
      params.append('broker_company', this.broker_company_selected);
      params.append('asset_type', 'Stock'); //TODO: hay que poner bien el select que no funciona bien

      axios.post(process.env.VUE_APP_API + '/broker/create-order-from-analysis', params)
        .then(
          response => {
            if (response.data.status == 'error') {
              this.$vueOnToast.pop('error', 'Error al generar la orden a enviar en el broker:' + response.data.message);
            } else {
              this.order = response.data.order;
              this.lot_size = response.data.order.order_format.lot_size;
              this.tick_size = response.data.order.order_format.tick_size;
              this.order_exist = response.data.order_exist;
              this.show_order_resume = true;
            }
          },
          error => {
            this.$vueOnToast.pop('error', 'Error al establecer conexión para generar la orden del broker:' + error);
          }
        );
    },
    _sendOrder: function () {
      if (!this.is_checked) {
        this.$vueOnToast.pop('Error', 'Has de aceptar las condiciones');
      }
      else {
        var params = new URLSearchParams();
        params.append('order_id', this.order.order_id);

        axios.post(process.env.VUE_APP_API + '/broker/send-order', params)
          .then(
            response => {
              if (response.data.status == 'error') {
                this.$vueOnToast.pop('error', 'Error al enviar la orden generada');
              } else {
                var result_order = response.data.order;
                var related_orders_params = '';
                var orderType = (result_order.order_type == "Stop") ? "StopIf" : "Limit";

                for (var i = 0; i < result_order.related_orders.length; i++) {
                  var sufix = "&relatedOrders.stopLoss.";

                  if (result_order.related_orders[i].order_relation == "IfDoneSlave" && result_order.related_orders[i].order_type == "Stop") {
                    related_orders_params += sufix + "orderType=StopIf";
                    related_orders_params += sufix + "price=" + String(result_order.related_orders[i].price).trim();
                  }
                }

                api_environment = "";
                if (window.location.hostname != "eurekers.com" && window.location.hostname != "www.eurekers.com") {
                  var api_environment = "/sim";
                }

                var company_url = '';
                for (var i = 0; i < this.select_order[2].options.length; i++) {
                  if (this.broker_company_selected == this.select_order[2].options[i].value) {
                    company_url = this.select_order[2].options[i].url;
                  }
                }

                var order_url_to_copy = company_url + api_environment + "/d/trade/" + result_order.instrument_id + "-" + result_order.asset_type + "?&amount=" + result_order.quantity + "&orderType=" + orderType + "&orderPrice=" + result_order.price + related_orders_params;
                this.url_to_copy = order_url_to_copy;

                setTimeout(() => {
                  document.getElementById("input_url_copy").focus();
                  document.execCommand('copy');
                }, 200);

                //window.open(company_url + api_environment + "/d/trade/" + result_order.instrument_id + "-" + result_order.asset_type + "?&amount=" + result_order.quantity + "&orderType=" + orderType + "&orderPrice=" + result_order.price + related_orders_params, '_blank', 'location=yes,height=650,width=520,scrollbars=yes,status=yes');

                this.$vueOnToast.pop('success', 'Se ha copiado el enlace en el portapapeles');
              }
            },
            error => {
              this.$vueOnToast.pop('error', 'Error en la conexión al enviar la orden generada:' + error);
            }
          );
      }
    },
    _cancelOrder: function () {
      this._isBrokerConnected();
      var params = new URLSearchParams();
      this.show_order_resume = false;
      params.append('order_id', this.order.order_id);

      axios.post(process.env.VUE_APP_API + '/broker/cancel-order', params)
        .then(
          response => {
            if (response.data.status == 'error') {
              this.$vueOnToast.pop('error', 'Error al cancelar la orden generada.');
            } else {
              this.$vueOnToast.pop('success', 'La orden ha sido cancelada correctamente');
            }
          },
          error => {
            this.$vueOnToast.pop('error', 'Error al conectar para cancelar la orden generada:' + error);
          }
        );
    },

    _getValueFromInstrumentSelect(value) {
      this.instrument_selected = value[0];
    },

    _getValueFromBrokerCompanySelect(value) {
      this.broker_company_selected = value[0];

      if (this.broker_company_selected != this.select_order[2].options[0].value) {
        this.connected = true;
      }
      else {
        this.connected = false;
      }

      this.$emit('_setBrokerConnectionStatus', [this.connected]);
    },

    ...mapActions(['addWaiting', 'addPortfolio', 'addFavorites']),
    ...mapMutations(['setSelectedTicker']),

    _closeComponent: function () {
      this.$emit("_closeComponentOrderMobile")
    },
  },
}
</script>


<style lang="scss" scoped>
.connect-btn,
.send-order-btn {
  border: none;
  border-radius: 20px;
  background-color: #00aedd;
  color: white;
  cursor: pointer;
  width: 170px;
  height: 40px;
}

.open-account-text {
  color: #1f7aa0;
  font-weight: bold;
  text-decoration: none;
}

.legal-warnings-text,
.terms-of-use-text {
  text-decoration: none;
  color: #00aedd;
}

.difbroker-text {
  text-decoration: none;
  color: #1f7aa0;
  font-weight: bold;
}

.ticker-container {
  background-color: var(--custom-color-right-menu-highlight);
  width: 250px;
  height: 30px;
  margin: auto;
  padding-top: 10px;
}

.disconnected-container {
  background-color: #df2f4f;
  color: white;
  text-align: center;
  border-radius: 30px;
  margin: 0 40px 0 40px;
}

.ticker-info-container span {
  color: #898d9d;
}

.separator {
  border-top: 1px solid #898d9d;
  opacity: 0.3;
  width: 90%;
  margin: auto;
  margin-top: 35px;
}

.send-order-container {
  margin-top: 40px;
}

.disconect-broker-btn {
  color: #df2f4f;
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 15px;
}

.disconnect-broker-container {
  margin-top: 20px;
  margin-left: 110px;
}

.select-style {
  width: 350px;
  margin: auto;
}

.ticker-info-container {
  margin-top: 40px;
}

.account-title-container {
  margin-top: 30px;
}

.connected-container {
  background-color: #57a869;
  text-align: center;
  border-radius: 30px;
  margin: 10px 40px 0px 40px;
}

.info-ticker span {
  color: #898d9d;
}

.header-divs-container {
  display: inline-flex;
}

.close_btn {
  background-color: transparent;
  border: none;
  color: white;
  margin-top: 10px;
  margin-left: 30px;
  cursor: pointer;
}

.container-broker-connection {
  display: inline-flex;
  margin-top: 30px;
  margin-left: 30px;
}

.checkbox-style {
  margin-top: 30px;
  transform: scale(1.5);
}

.info-text-container {
  margin-left: 20px;
  margin-right: 10px;
}


.edit-btn {
  border: none;
  background-color: transparent;
  font-weight: bold;
  color: #0e5f85;
  cursor: pointer;
  margin-top: 10px;
}

.send_order_btn_style {
  border: none;
  border-radius: 20px;
  background-color: #00aedd;
  color: white;
  cursor: pointer;
  width: 170px;
  height: 40px;
  font-weight: bold;
}

.send-order-info-container {
  margin-top: 40px;
}

.info-ticker {
  margin-top: 50px;
}

.info-text-style {
  text-align: left;
  font-size: 12px;
}

.btns-container {
  margin-top: 30px;
}

a {
  color: #0fafdb;
}

#broker-connection span,
#broker-connection h3,
#broker-connection p {
  color: var(--custom-color-right-menu-font);
}

#broker-connection {
  min-height: 500px;
}

.header-container {
  display: flex;
  justify-content: center;
  padding: 10px 0px 20px 0px !important;
}

.title-container {
  margin-left: 30px;
  font-size: 20px;
  font-weight: 600;
}

.close-btn-container {
  margin-left: auto;
  order: 2;
  margin-right: 10px;
}

.not-available-data-container {
  text-align: center;
}

.not-available-data {
  color: #FF9B00 !important;
}

.link-to-store {
  color: #35add8;
}

.info-ticker-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text-info-ticker {
  text-align: center;
  background-color: #F3F4F7;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
  padding: 7px 25px;

  span {
    font-size: 18px;
  }
}

.text-bold {
  font-weight: bold;
}
</style>