<template>
  <div id="broker-connection" style="text-align: center;">

    <HeaderSidebarRight :id_header="id_header" :title_header="title_header" />

    <div v-if="_g_UserFeatures.permissions.broker_connection">
      <div v-if="is_broker_enabled">
        <div id="broker-connection-login">
          
        </div>
        
  
        <div v-if="connected">
          <div v-if="show_order_resume">
            <div class="send-order-info-container">
              <div class="order-resume-header">
                <p><b>¿Realmente quiere trabajar con estos datos?</b></p>
              </div>
              <div class="info-ticker">
                <p><span>Símbolo:</span> <b>{{_g_SelectedTicker.ticker}}</b> </p>
                <p><span>Tipo de Orden:</span> <b>{{_g_SelectedTicker.entry_type_name}}</b> </p>
                <p><span>Tipo de instrumento:</span> <b>{{this.instrument_selected}}</b> </p>
                <p><span>Precio de entrada:</span> <b>{{_g_SelectedTicker.real_entry.toFixed(2)}}</b> </p>
                <p><span>Número de instrumentos:</span> <b>{{_g_SelectedTicker.instrument_number}}</b> </p>
                <p><span>Orden Stop Loss:</span> <b>Stop Sell</b> </p>
                <p><span>Precio de salida:</span> <b>{{_g_SelectedTicker.out_price.toFixed(2)}}</b> </p>
              </div>
              <div class="container-broker-connection">
                <div class="checkbox-container">
                  <input type="checkbox" class="checkbox-style" id="checkbox-id" required v-model="is_checked">
                </div>
                <div class="info-text-container">
                  <p class="info-text-style">
                    Soy consciente de que los precios y las cantidades de las órdenes pueden ser modificadas debido a las restricciones de los reguladores. Voy a comprobar que la orden está correcta en el broker en el que la voy a enviar.
                  </p>
                </div>
              </div>
              <div class="important-notice">
                <p class="info-text-style">
                  <strong>*IMPORTANTE*</strong> Estos son los datos de la orden que vas a poder enviar a tu broker. Al pulsar COPIAR ENLACE, tendrás el enlace copiado, y tendrás que abrir una ventana nueva donde poder poner el enlace que hemos creado para que puedas trabajar con la orden.
                </p>
              </div>
              <div class="btns-container">
                  <button id="send_order_btn" class="action-btn primary-btn" @click="_sendOrder()">COPIAR ENLACE</button>
                  <br>
                  <button @click="_cancelOrder" class="action-btn secondary-btn">Cancelar</button>
              </div>
              <div class="url-copy-container">
                <textarea 
                  class="url-textarea"
                  v-on:focus="$event.target.select()" 
                  id="input_url_copy" 
                  readonly 
                  :value="url_to_copy"/>
              </div>
              <div class="copy-instructions">
                <p class="info-text-style">
                  Copia este enlace y pégalo en una pestaña o ventana nueva de navegador para poder trabajar una orden con los datos vistos.
                </p>
              </div>
            </div>
          </div>
  
          <div v-else>
            <div class="connected-content-container">
              <h4 class="selected-service"> {{broker_company_selected}} </h4>
              <br>
              <div class="ticker-info-container">
                <p><span>Ticker:</span> <b>{{_g_SelectedTicker.ticker}}</b></p>
                <p><span>Tipo de instrumento:</span> <b>Stock</b></p>
                <p><span>Tipo de entrada:</span> <b>{{_g_SelectedTicker.entry_type_name}}</b></p>
                <p><span>Precio de entrada:</span> <b>{{_g_SelectedTicker.real_entry.toFixed(2)}}</b></p>
                <p><span>Numero de instrumentos:</span> <b>{{_g_SelectedTicker.instrument_number}}</b></p>
                <p><span>Precio de salida:</span> <b>{{_g_SelectedTicker.out_price.toFixed(2)}}</b></p>
              </div>
              <hr class="separator" />
              <div class="send-order-container">
                <button class="action-btn primary-btn" @click="_createOrder()"><b>TRABAJAR DATOS</b></button>
                <br>
                <br>
                <button class="action-btn secondary-btn" @click="_disconnectFromBroker()"><b>Volver atrás</b></button>
              </div>
            </div>
          </div>
  
        </div>
  
        <div v-else>  
          <div class="service-selection-container">
            
            <div class="info-panel">
              <p class="info-text-style">
                  Selecciona el servicio de broker que tienes contratado para poder enviar la información necesaria a su entorno fuera de EUREKERS y poder trabajar la orden de compra.
              </p>
              
              <p class="info-text-style">
                  Por favor, tenga en cuenta que el acceso con el broker se establece a través del navegador vía encriptación segura con protocolos SSL/TSL y no tienen nada que ver con los servidores EUREKERS.
              </p>
            </div>
  
            <div class="broker-selector">
              <p><strong>Servicio externo:</strong></p>
              
              <!-- Mode 1: Normal - Show regular dropdown -->
              <div v-if="rocket_setting == '1' || rocket_setting == '3'" class="select-wrapper">
                <select 
                  class="styled-select"
                  @change="(e) => _getValueFromBrokerCompanySelect(e.target.value)"
                  v-model="broker_company_selected">
                  <option v-for="(item, index) in select_order[2].options" :key="index" :value="item.value">
                    {{item.value}}
                  </option>
                </select>
              </div>
              
              <!-- Mode 2: No-Select - Only Activotrade available -->
              <div v-else-if="rocket_setting == '2'" class="no-select-mode">
                <div class="activotrade-fixed-option">
                  <p class="activotrade-label">Activo Trade</p>
                  <button class="action-btn primary-btn connect-activotrade" @click="() => _getValueFromBrokerCompanySelect('Activo Trade')">
                    Conectar con Activo Trade
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="info-text-style">Actualmente el acceso al procesado de datos de Orden no se encuentra disponible</p>
      </div>
    </div>
    <div v-else class="not-available-data-container">
      <div class="info-ticker-box">
        <p class="text-info-ticker">Ticker: <span class="text-bold">{{_g_SelectedTicker.ticker}}</span></p>
      </div>
      <p class="not-available-data typo-body-2"> Su plan actual no tiene acceso a esta herramienta. </p>
      <a href="/app/store" class="link-to-store typo-body-2"> Pulse aquí para cambiar de plan. </a>
    </div>
  </div>
</template>


<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

import axios from 'axios';

import selectOrder from './configs/select_order.json';

import HeaderSidebarRight from './HeaderSidebarRight.vue';

import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins:[APICaller],
  name: 'Order',
  components: {
    HeaderSidebarRight
  },
  data: () => ({
    connected: false,
    is_fullscreen: true,
    select_order: selectOrder,
    instrument_selected: 'Stock',
    broker_company_selected: 'Ninguno',
    is_checked: false,
    login_access: null,
    is_broker_enabled: false,
    accept_conditions: false,
    order: null,
    lot_size: null,
    tick_size: null,
    order_exist: null,
    show_order_resume: null,
    url_to_copy: '',
    is_activotrade_selected: false,
    rocket_setting: 1, // Default rocket setting (normal mode)

    id_header: "broker-connection-header",
    title_header: "Trabajar orden",
  }),
  computed: {
    ...mapGetters(['_g_SelectedTicker', '_g_UserId', '_g_UserFeatures']),
  },

  created() {
    this._fetchRocketSetting();
  },

  mounted() {
    this._isBrokerConnected();
    this._isBrokerEnabled();
  },

  methods: {
    /**
     * Fetch the current rocket setting from the API
     */
    _fetchRocketSetting: function() {
      axios.get(process.env.VUE_APP_API + '/broker/rocket-setting')
        .then(
          response => {
            if (response.data.success) {
              this.rocket_setting = response.data.value;
            } else {
              console.error('Error loading rocket setting');
            }
          },
          error => {
            console.error('Error fetching rocket setting:', error);
          }
        );
    },
      
    _isBrokerConnected: function() {
      axios.get(process.env.VUE_APP_API + '/broker/status')
        .then(
          response => {
            this.connected = false;
            if (this.connected) {
              this._getAccounts();
              this.$emit('_setBrokerConnectionStatus', [true])
            }
            else {
              this.$emit('_setBrokerConnectionStatus', [false])
            }
          },
          error => {
            this.$vueOnToast.pop('error', 'Error al consultar el estado de la conexion broker:' + error);
          }
        );
    },
    _getAccounts: function() {
      this.accounts = [];
      axios.get(process.env.VUE_APP_API + '/broker/accounts')
        .then(
          response => {
            
          },
          error => {
            this.$vueOnToast.pop('error', 'Error al consultar cuentas del broker disponible:' + error);
          }
        );
    },
    _isBrokerEnabled: function() {
      axios.get(process.env.VUE_APP_API +  '/broker/enabled-status')
        .then(
          response => {
            this.is_broker_enabled = response.data;
          },
          error => {
            this.$vueOnToast.pop('error', 'Error al consultar disponibilidad del broker:' + error);
          }
      );
    },
    _disconnectFromBroker: function() {
      axios.get(process.env.VUE_APP_API + '/broker/disconnect')
        .then(
          response => {
            this.connected = false;
            this.broker_company_selected = 'Ninguno';
            this._isBrokerConnected();
          },
          error => {
            this.$vueOnToast.pop('error', 'Error desconectar con el broker:' + error);
          }
        );
    },
    _createOrder: function() {
      this.accept_conditions = false;
      
      // Track click on "Trabajar Datos" (trabajar_orden)
      var trackParams = new URLSearchParams();
      trackParams.append('broker', this.broker_company_selected);
      trackParams.append('action', 'trabajar_orden');
      axios.post(process.env.VUE_APP_API + '/broker/track-click', trackParams);
      
      var params = new URLSearchParams();
      params.append('analysis', JSON.stringify(this._g_SelectedTicker));
      params.append('user_id', this._g_UserId);
      params.append('broker_company', this.broker_company_selected);
      params.append('asset_type', 'Stock'); //TODO: hay que poner bien el select que no funciona bien
      params.append('rocket_setting', this.rocket_setting); // Pass the rocket setting to the API

      axios.post(process.env.VUE_APP_API + '/broker/create-order-from-analysis', params)
        .then(
          response => {
            if (response.data.status == 'error') {
              this.$vueOnToast.pop('error', 'Error al generar la orden a enviar en el broker:' + response.data.message);
            } else {
              this.order = response.data.order;
              this.lot_size = response.data.order.order_format.lot_size;
              this.tick_size = response.data.order.order_format.tick_size;
              this.order_exist = response.data.order_exist;
              this.show_order_resume = true;
            }
          },
          error => {
            this.$vueOnToast.pop('error', 'Error al establecer conexión para generar la orden del broker:' + error);
          }
        );
    },
    _sendOrder: function() {
      if (!this.is_checked) {
        this.$vueOnToast.pop('Error', 'Has de aceptar las condiciones');
      }
      else {
        // Track click on "Copiar Enlace" (copiar_enlace)
        var trackParams = new URLSearchParams();
        trackParams.append('broker', this.broker_company_selected);
        trackParams.append('action', 'copiar_enlace');
        axios.post(process.env.VUE_APP_API + '/broker/track-click', trackParams);
        
        var params = new URLSearchParams();
        params.append('order_id', this.order.order_id);
  
        axios.post(process.env.VUE_APP_API + '/broker/send-order', params)
          .then(
            response => {
              if (response.data.status == 'error') {
                this.$vueOnToast.pop('error', 'Error al enviar la orden generada');
              } else {
                var result_order = response.data.order;
                var related_orders_params = '';
                var orderType = (result_order.order_type == "Stop") ? "StopIf" : "Limit";
  
                for (var i = 0; i < result_order.related_orders.length; i++) {
                  var sufix = "&relatedOrders.stopLoss.";
  
                  if (result_order.related_orders[i].order_relation == "IfDoneSlave" && result_order.related_orders[i].order_type == "Stop") {
                    related_orders_params += sufix + "orderType=StopIf";
                    related_orders_params += sufix + "price=" + String(result_order.related_orders[i].price).trim();
                  }
                }

                var api_environment = "";
                if (window.location.hostname != "eurekers.com" && window.location.hostname != "www.eurekers.com") {
                  api_environment = "/sim";
                }

                var company_url = '';
                for (var i = 0; i < this.select_order[2].options.length; i++) {
                  if (this.broker_company_selected == this.select_order[2].options[i].value) {
                    company_url = this.select_order[2].options[i].url;
                  }
                }

                var order_url_to_copy = '';
                
                // Create the basic URL based on broker
                var baseUrl = company_url + api_environment;
                var queryString = "/d/trade/" + result_order.instrument_id + "-" + result_order.asset_type + 
                                 "?&amount=" + result_order.quantity + 
                                 "&orderType=" + orderType + 
                                 "&orderPrice=" + result_order.price + 
                                 "&orderDurationType=GoodTillCancel" +
                                 related_orders_params;
                
                // Special handling for Activotrade
                if (this.broker_company_selected === "Activo Trade") {
                  // Create the base URL for Activotrade
                  baseUrl = "https://portal.activotrade.com/signin/?return_url=";
                  
                  // URL encode the query string for Activotrade
                  var encodedQueryString = encodeURIComponent(queryString);
                  
                  // Combine to create the final URL
                  order_url_to_copy = baseUrl + encodedQueryString;
                } else {
                  // For no-link mode (rocket_setting 3), URL encode all links
                  if (this.rocket_setting === '3') {
                    order_url_to_copy = baseUrl + encodeURIComponent(queryString);
                  } else {
                    // Standard URL construction for other brokers in normal mode
                    order_url_to_copy = baseUrl + queryString;
                  }
                }
                
                this.url_to_copy = order_url_to_copy;

                setTimeout(() => {
                  document.getElementById("input_url_copy").focus();
                  document.execCommand('copy');
                  
                  // Scroll to the bottom of the container to show the copy instructions
                  this.$nextTick(() => {
                    const container = document.querySelector('.send-order-info-container');
                    const copyInstructions = document.querySelector('.copy-instructions');
                    
                    if (copyInstructions) {
                      // Ensure the element is in view
                      copyInstructions.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    } else if (container) {
                      // Fallback: scroll container to bottom
                      container.scrollTop = container.scrollHeight;
                    }
                  });
                }, 200);

                this.$vueOnToast.pop('success', 'Se ha copiado el enlace en el portapapeles');
              }
            },
            error => {
              this.$vueOnToast.pop('error', 'Error en la conexión al enviar la orden generada:' + error);
            }
        );
      }
    },
    _cancelOrder: function() {
      this._isBrokerConnected();
      var params = new URLSearchParams();
      this.show_order_resume = false;
      this.broker_company_selected = 'Ninguno';
      this.connected = false;
      this.url_to_copy = '';
      params.append('order_id', this.order.order_id);

      axios.post(process.env.VUE_APP_API + '/broker/cancel-order', params)
        .then(
          response => {
            if (response.data.status == 'error') {
              this.$vueOnToast.pop('error', 'Error al cancelar la orden generada.');
            } else {
              this.$vueOnToast.pop('success', 'La orden ha sido cancelada correctamente');
            }
          },
          error => {
            this.$vueOnToast.pop('error', 'Error al conectar para cancelar la orden generada:' + error);
          }
        );
    },

    _getValueFromInstrumentSelect(value){
      this.instrument_selected = value;
    },

    _getValueFromBrokerCompanySelect(value){
      this.broker_company_selected = value;
      
      // Track if Activotrade is selected
      this.is_activotrade_selected = (value === 'Activo Trade');

      if (this.broker_company_selected != this.select_order[2].options[0].value) {
        this.connected = true;
      }
      else {
        this.connected = false;
      }
      
      this.$emit('_setBrokerConnectionStatus', [this.connected]);
    },

    ...mapActions(['addWaiting', 'addPortfolio', 'addFavorites']),
    ...mapMutations(['setSelectedTicker']),
  },
}
</script>


<style lang="scss" scoped>

  .connect-btn,
  .send-order-btn{
    border: none;
    border-radius: 20px;
    background-color: #00aedd;
    color: white;
    cursor: pointer;
    width: 170px;
    height: 40px;
  }

  .open-account-text{
    color: #1f7aa0;
    font-weight: bold;
    text-decoration: none;
  }

  .legal-warnings-text,
  .terms-of-use-text{
    text-decoration: none;
    color: #00aedd;
  }

  .difbroker-text{
    text-decoration: none;
    color: #1f7aa0;
    font-weight: bold;
  }

  .ticker-header {
    background-color: var(--color-surface);
    width: 250px;
    height: 36px;
    margin: 0 auto 20px auto;
    padding: 8px 0;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(15, 15, 26, 0.05);
  }

  .ticker-value {
    font-size: 14px;
    color: var(--color-text-primary);
    font-weight: 500;
  }

  .ticker-value strong {
    font-weight: 700;
    color: var(--primary);
  }

  .ticker-container{
    background-color: var(--custom-color-right-menu-highlight); 
    width: 250px;
    height: 30px;
    margin: auto;
    padding-top: 10px;
  }

  .disconnected-container{
    background-color: #df2f4f;
    color: white;
    text-align:center; 
    border-radius:30px; 
    margin:0 40px 0 40px;
  }

  .separator{
    border-top: 1px solid #898d9d;
    opacity: 0.3;
    width: 90%;
    margin: auto;
    margin-top: 35px;
  }

  .send-order-container{
    margin-top: 40px;
  }

  .disconect-broker-btn{
    color: #df2f4f;
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-size: 15px;
  }

  .disconnect-broker-container{
    margin-top: 20px;
    margin-left: 110px;
  }

  .select-style{
    width: 350px;
    margin: auto;
  }

  .account-title-container{
    margin-top: 30px;
  }

  .connected-container{
    background-color: #57a869; 
    text-align: center; 
    border-radius: 30px; 
    margin: 10px 40px 0px 40px;
  }

  .header-divs-container{
    display: inline-flex;
  }

  .close_btn{
    background-color: transparent;
    border: none;
    color: white;
    margin-top: 10px;
    margin-left: 30px;
    cursor: pointer;
  }

  .container-broker-connection {
    display: flex;
    align-items: center;
    background-color: var(--color-surface);
    border-radius: 6px;
    padding: 15px;
    margin: 15px 0;
    border-left: 4px solid var(--warning);
  }

  .checkbox-container {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  .checkbox-style {
    transform: scale(1.3);
    margin: 0;
  }

  .info-text-container{
    flex: 1;
    margin-right: 10px;  
  }

  .edit-btn{
    border: none;
    background-color: transparent;
    font-weight: bold;
    color: #0e5f85;
    cursor: pointer;
    margin-top: 10px;
  }

  .send_order_btn_style{
    border: none;
    border-radius: 20px;
    background-color: #00aedd;
    color: white;
    cursor: pointer;
    width: 170px;
    height: 40px;
    font-weight: bold;
  }

  .send-order-info-container{
    max-width: 600px;
    margin: 20px auto;
    margin-bottom: 20px;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background);
    box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
    width: 85%;
  }

  .info-ticker{
    background-color: var(--color-surface);
    border-radius: 6px;
    padding: 15px 20px;
    margin: 15px 0;
  }

  .info-text-style{
    text-align: left;
    font-size: 12px;
  }

  .btns-container{
    margin: 25px 0;
    text-align: center;
  }

  .btns-container button {
    margin: 5px 0;
  }

  a{
    color: #0fafdb;
  }

  #broker-connection span, #broker-connection h3{
    color: var(--color-text-primary);
  }

  .not-allowed-order{
    text-align: center;
    margin-top: 20px;
    color: #FF9B00;
    font-weight: 500;
  }

  .not-available-data-container{
    text-align: center;
  }

  .not-available-data{
    color: #FF9B00;
  }

  .link-to-store{
    color: #35add8;
  }

  .info-ticker-box {
     width: 100%;
     display: flex;
     justify-content: center;
  }
  
  .text-info-ticker {
    text-align: center;
    background-color: #F3F4F7;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 16px;
    padding: 7px 25px;
    border-radius: 4px;

    span {
      font-size: 18px;
    }
  }

  .text-bold{
    font-weight: bold;
  }

  .activotrade-warning {
    margin-top: 20px;
    padding: 15px;
    background-color: #fef8e8;
    border-left: 4px solid #f0ad4e;
    color: #856404;
    border-radius: 4px;
    text-align: left;
  }

  .activotrade-warning p {
    margin: 5px 0;
  }

  .select-wrapper {
    position: relative;
    width: 100%;
    max-width: 350px;
    margin: auto;
    margin-top: 5px;
  }

  .styled-select {
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    font-size: 16px;
    color: var(--color-text-primary);
    background-color: var(--color-background);
    border: 1px solid var(--color-outline);
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    outline: none;
    transition: border-color 0.3s;
  }

  .styled-select:focus {
    border-color: var(--primary);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.1);
  }

  .select-wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--color-text-secondary);
    pointer-events: none;
  }

  .broker-disconnected-options p {
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
  }

  .action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 40px;
    transition: all 0.2s ease;
    min-width: 200px;
    font-weight: 600;
  }

  .primary-btn {
    background-color: var(--primary);
    color: white;
    box-shadow: none;
  }

  .primary-btn:hover {
    background-color: var(--color-muted, var(--color-highlight));
    box-shadow: none;
  }

  .secondary-btn {
    background-color: var(--color-surface);
    color: var(--color-text-primary);
    border: 1px solid var(--color-outline);
  }

  .secondary-btn:hover {
    background-color: var(--color-muted, #e9ecf1);
    color: var(--color-highlight);
  }

  .selected-service {
    color: var(--primary);
    font-size: 18px;
    font-weight: 600;
    padding: 8px 16px;
    background-color: var(--color-active-surface);
    border-radius: 4px;
    display: inline-block;
  }

  .service-selection-container {
    max-width: 600px;
    margin: 20px auto;
    margin-bottom: 20px;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background);
    box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
    width: 85%;
  }

  .selection-header {
    font-size: 20px;
    margin-bottom: 20px;
    color: var(--color-text-primary);
    text-align: center;
  }

  .info-panel {
    background-color: var(--color-surface);
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 25px;
    border-left: 4px solid var(--primary);
  }

  .info-panel p {
    margin: 10px 0;
    line-height: 1.5;
    font-size: 13px;
    color: var(--color-text-primary);
  }

  .broker-selector {
    margin-top: 25px;
    text-align: center;
  }

  .broker-selector p {
    margin-bottom: 12px;
    font-size: 15px;
    color: var(--color-text-primary);
  }

  /* Order resume styling */
  .order-resume-header {
    background-color: var(--color-surface);
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 20px;
    border-left: 4px solid var(--primary);
    text-align: center;
  }

  .order-resume-header p {
    margin: 0;
    font-size: 16px;
    color: var(--color-text-primary);
    font-weight: 600;
  }

  .info-ticker p {
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: var(--color-text-primary);
  }

  .info-ticker span {
    color: var(--color-text-secondary);
  }

  .info-text-container p {
    color: var(--color-text-primary);
  }

  .important-notice {
    background-color: var(--color-surface);
    border-radius: 6px;
    padding: 15px;
    margin: 15px 0;
    border-left: 4px solid var(--warning);
  }

  .important-notice p {
    color: var(--color-text-primary);
  }

  .important-notice p strong {
    color: var(--error);
    margin-right: 5px;
  }

  .url-copy-container {
    margin: 15px 0;
    padding: 0 1px;
  }

  .url-textarea {
    width: calc(100% - 20px);
    min-height: 80px;
    padding: 10px;
    border: 1px solid var(--color-outline);
    border-radius: 6px;
    font-family: inherit;
    font-size: 14px;
    color: var(--color-text-primary);
    background-color: var(--color-surface);
    resize: vertical;
  }

  .copy-instructions {
    background-color: var(--color-surface);
    border-radius: 6px;
    padding: 15px;
    margin: 15px 0 0 0;
  }

  .copy-instructions p {
    margin: 0;
    font-size: 13px;
    color: var(--color-text-primary);
  }

  .connected-content-container {
    max-width: 600px;
    margin: 20px auto;
    margin-bottom: 20px;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background);
    box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
    width: 85%;
  }

  .ticker-info-container {
    background-color: var(--color-surface);
    border-radius: 6px;
    padding: 15px 20px;
    margin: 15px 0;
  }

  .ticker-info-container p {
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: var(--color-text-primary);
  }

  .ticker-info-container span {
    color: var(--color-text-secondary);
  }

  .no-select-mode {
    margin-top: 10px;
    padding: 15px;
    background-color: var(--color-surface);
    border-radius: 6px;
    border-left: 4px solid var(--primary);
  }

  .activotrade-fixed-option {
    margin-bottom: 15px;
    text-align: center;
  }

  .activotrade-label {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary);
    margin-bottom: 10px;
    display: block;
  }

  .connect-activotrade {
    margin-top: 10px;
  }

  .no-select-info, .no-link-info {
    margin-top: 10px;
    padding: 10px;
    background-color: rgba(255, 155, 0, 0.1);
    border-radius: 4px;
  }

  .warning-text {
    color: var(--warning, #FF9B00) !important;
    font-weight: 500;
  }

</style>