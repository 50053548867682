<template>
    <div id="fourth">
        <div v-if="crypto_enabled==false" class="primary-container-mobile">
            <div v-for="(item, index) in fourth_config" :key="index" class="inlined-fourth-mobile" style="width: 350px;">
                <h4>
                    {{item.name}}
                </h4>
                <template v-if="item.name == 'Divisas'">
                    <div v-if="_g_UserFeatures.permissions.currency_filter" class="select-divisas-container">
                        <div class="button-container">
                            <div>
                                <SelectOutline  v-for="(iSelect, selectIndex) in item.componentsConfig" :key="selectIndex" @_emit_method_order_select="_getValueFromSelect" :label_name="iSelect.label_name" :options="iSelect.options" :helper_select_permission="iSelect.helper_select_permission" :helper_text_select="iSelect.helper_text_select"  :component_class="iSelect.component_class" class="inlined" id="selected-component-1"/>
                            </div>
                            <div>
                                <span @click="_deleteAllCurrencies" class="material-icons-outlined" title="Borrar todos">delete</span>
                            </div> 
                        </div>
                        <div class="select-container">
                            <div v-if="select_change == true">
                                <span class="item-container" v-for="(itemm,indexx) in divisas" :key="indexx" :value="itemm.name">
                                    <span class="item-container-text">{{itemm}}</span>
                                    <span>
                                        <button :disabled="!divisasPermission" class="material-icons cancel-button" @click="_remove_element(indexx,item.name)">cancel</button>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <FilterNoPermission
                            :link="buy_link"
                        ></FilterNoPermission>
                        <!--<SelectDisabled></SelectDisabled>-->
                    </div>
                </template>
                <template v-if="item.name == 'País'">
                    <div v-if="_g_UserFeatures.permissions.country_filter" class="select-paises-container">  
                        <div class="button-container">
                            <div> 
                                <SelectOutline v-for="(iSelect, selectIndex) in item.componentsConfig" :key="selectIndex" @_emit_method_order_select="_getValueFromSelect" :label_name="iSelect.label_name" :options="iSelect.options" :helper_select_permission="iSelect.helper_select_permission" :helper_text_select="iSelect.helper_text_select"  :component_class="iSelect.component_class" class="inlined select" id = "selected-component-2"/>
                            </div>   
                            <div>
                                <span @click="_deleteAllCountries" class="material-icons-outlined" title="Borrar todos">delete</span>
                            </div>                             
                        </div>
                        <div class="select-paises">
                            <div v-if="select_change == true">
                                <span class="item-container" v-for="(itemm,indexx) in paises" :key="indexx" :value="itemm.name">
                                    <span class="item-container-text">{{itemm}}</span>
                                    <span>
                                        <button :disabled="!paisPermission" class="material-icons cancel-button" @click="_remove_element(indexx,item.name)">cancel</button>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <FilterNoPermission
                            :link="buy_link"
                        ></FilterNoPermission>
                        <!--<SelectDisabled></SelectDisabled>-->
                    </div>
                </template>
                <template v-if="item.name == 'Sector | Industria'">
                    <div v-if="_g_UserFeatures.permissions.sector_filter" class="select-sectoresIndustrias-container">
                        <div class="button-container">
                            <div>
                                <SelectOutline v-for="(iSelect, selectIndex) in item.componentsConfig" :key="selectIndex" @_emit_method_order_select="_getValueFromSelect" :label_name="iSelect.label_name" :options="iSelect.options" :helper_select_permission="iSelect.helper_select_permission" :helper_text_select="iSelect.helper_text_select"  :component_class="iSelect.component_class" class="inlined" id = "selected-component-3"/>
                            </div>
                            <div>
                                <span @click="_deleteAllSectors" class="material-icons-outlined" title="Borrar todos">delete</span>
                            </div>       
                        </div>
                        <div class="select-sectoresIndustrias">
                            <div v-if="select_change == true">
                                <span class="item-container" v-for="(itemm,indexx) in sectoresIndustrias" :key="indexx" :value="itemm.name" style="width: 500px;">
                                    <span class="item-container-text">{{itemm}}</span>
                                    <span>
                                        <button :disabled="!sectorPermission" class="material-icons cancel-button" @click="_remove_element(indexx,item.name)">cancel</button>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <FilterNoPermission
                            :link="buy_link"
                        ></FilterNoPermission>
                        <!--<SelectDisabled></SelectDisabled>-->
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import FourthConfig from "@/components/stockchart/components/Finder/configs/Fourth.json";
import SelectOutline from "@/components/helpers/SelectOutlineFourth";
//import SelectDisabled from "@/components/helpers/SelectOutlineFourth_disabled";
import FilterNoPermission from "@/components/stockchart/components/Finder/FilterNoPermission";
import { mapMutations, mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
    mixins: [APICaller],
    components: {
        SelectOutline,
        FilterNoPermission
        //SelectDisabled
        
    },

    name: 'Fourth_mobile',

    props: {
        filters_user_json:{type: Object},
        recargar:{type: Boolean},
    },

    data: () => ({
        fourth_config: FourthConfig,
        local_array_stocks_filters: [],
        local_array_stocks_filters_default: [],
        divisas: [],
        paises: [],
        mostrar_Divisas:false,
        mostrar_Paises: false,
        mostrar_sectoresIndustrias: false,
        sectoresIndustrias: [],
        select_change: false,

        divisasPermission: false,
        paisPermission: false,
        sectorPermission: false,
        finder:"",
        permissions_loaded: false,
        buy_link: "",
        all_divisas: [],
        available_country_array: [],
        available_currency_array: [],
        available_sector_array:[],
    }),    

    computed: {
       ...mapGetters({crypto_enabled:'_g_crypto_enable'}),
       ...mapGetters(['_g_UserFeatures']),
    },
    watch: {
        
    },

    created() { 
        var created_interval = setInterval(() => {
            if (this.filters_user_json != "") {
                this.finder = this._g_FinderFilters();
                this._getAvailableCurrencies();
                this._getAvailableCountries();
                this._getAvailableSectors();
                clearInterval(created_interval);
            }
        }, 200);
    },
    
    mounted() {
        this.buy_link = this.$detectEnvironment() + '/app/store';
    },

    methods: {
        ...mapGetters(['_g_FinderFilters','_g_RechargeState']),
        ...mapMutations(['setFinderFilters','setRechargeState']),
        _getValueFromSelect: function(value){
            if(this.crypto_mode==false || this.crypto_mode == undefined){   

                if(value[1] == "Divisas"){
                    if(value[0] != ""){
                        if(value[0] == "Todas las divisas"){
                            this._refreshFiltersCurrencies()
                        }
                        else if(this.divisas.includes(value[0]) == false){
                            this.divisas.push(value[0]);
                            this.select_change = true;                   
                            if(!this.finder["currency"].includes("0")){  
                                this._postAvailableCurrencies();
                            }                   
                        }                    
                    }
                }

                if(value[1] == "Paises"){
                    if(value[0] != ""){
                        if(value[0] == "Todos los paises"){
                            this._refreshFiltersCountries()
                        }                 
                        else if(this.paises.includes(value[0]) == false){
                            this.paises.push(value[0]);
                            this.select_change = true;                       
                            if(!this.finder["country"].includes("0")){                            
                                this._postAvailableCountries();
                            } 
                             
                        }  
                    }
                }

                if(value[1] == "SectorIndustria"){ 

                    if(value[0] != ""){
                        if(value[0] == "Todos los sectores | Todas las industrias"){
                            this._refreshFiltersSectors()
                        }                 
                        else if(this.sectoresIndustrias.includes(value[0]) == false){
                            this.sectoresIndustrias.push(value[0]);
                            this.select_change = true;                      
                            if(!this.finder["industry"].includes("0")){                            
                                this._postAvailableSectors();
                            }                    
                        } 
                    }
                }
            }
                            
        },
        _remove_element: function(value,tipo){
            if(tipo == "Divisas"){
                this.divisas.splice(value,1);   
                this._deleteAvailableCurrencies();   
                this.$refs.selectDivisa[0]._setValueToCustom();             
            }
            else if(tipo == "País"){
                this.paises.splice(value,1);
                this._deleteAvailableCountries();
                this.$refs.selectPais[0]._setValueToCustom();
            }
            else{
                this.sectoresIndustrias.splice(value,1);
                this._deleteAvailableSectors(); 
                 this.$refs.selectSector[0]._setValueToCustom();
            }
        },
        _show_select: function(select){     
                if(select == "Divisas"){
                    this.mostrar_Divisas = true;
                    this.mostrar_Paises = false;
                    this.mostrar_sectoresIndustrias = false;
                }
                else if(select == "País"){
                    this.mostrar_Paises= true;
                    this.mostrar_Divisas = false;
                    this.mostrar_sectoresIndustrias = false;
                }
                else{
                    this.mostrar_sectoresIndustrias= true;
                    this.mostrar_Paises = false;
                    this.mostrar_Divisas = false;
                }           
                      
        },
        _getKeyByValue: function(object, value) {
            return Object.keys(object).find(key => object[key] === value);
        },
        _getAvailableCurrencies: function(){
            let success = (response) => {      
                this.divisas = []          
                this.all_divisas = response.data;
                this.available_currency_array = Object.values(this.all_divisas)
                if(this.finder["currency"].includes("0")){
                    this.available_currency_array.forEach(element =>{
                        if(element != "Todas las divisas"){
                            this.divisas.push(element)
                        }
                    })
                }
                this.finder["currency"].forEach(element => {
                    if(element != "0"){
                        var cast = this.all_divisas[element];
                        this.divisas.push(cast);
                    }
                });
                this.select_change = true;    
            };

            let url = "/api/v1/stock-finder/available-currencies";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('107', 'Error recuperando divisas disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _postAvailableCurrencies: function(){
            let success = (response) => {
                var available_currency_post = response.data;                    
                this.divisas.forEach(element => {
                    var cast = this._getKeyByValue(available_currency_post, element);                    
                        if(!this.finder["currency"].includes(cast)){
                            this.finder["currency"].push(cast);
                        }
                });
                this._updateLastSearch2();
                
            };

            let url = "/api/v1/stock-finder/available-currencies";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('108', 'Error recuperando divisas disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _deleteAvailableCurrencies: function(){
            let success = (response) => {
                this.finder["currency"] = [];
                var available_currency_post = response.data;                   
                this.divisas.forEach(element => {
                    var cast = this._getKeyByValue(available_currency_post, element);
                    this.finder["currency"].push(cast);
                });
                this._updateLastSearch2();
            };

            let url = "/api/v1/stock-finder/available-currencies";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('109', 'Error recuperando divisas disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _refreshFiltersCurrencies: function(){
            let success = (response) => {
                this.finder["currency"] = "0"
                this.divisas = this.available_currency_array.slice(1)
                this._updateLastSearch2();
            };

            let url = "/api/v1/stock-finder/available-countries";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('110', 'Error recuperando paises disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _deleteAllCurrencies: function(){
            let success = (response) => {
                this.finder["currency"] = [];
                this.divisas=[]
                this.$refs.selectDivisa[0]._setValueToCustom();
                this._updateLastSearch2();
            };

            let url = "/api/v1/stock-finder/available-currencies";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('111', 'Error recuperando divisas disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },

        _getAvailableCountries: function(){
            let success = (response) => {
                this.paises = []
                var available_country = response.data;
                this.available_country_array = Object.values(available_country)
                if(this.finder["country"].includes("0")){
                    this.available_country_array.forEach(element =>{
                        if(element != "Todos los paises"){
                            this.paises.push(element)
                        }
                    })
                }
                this.finder["country"].forEach(element => {
                    if(element != "0"){
                        var cast = available_country[element];
                        this.paises.push(cast)
                    }
                });
                this.select_change = true;
            };

            let url = "/api/v1/stock-finder/available-countries";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('112', 'Error recuperando paises disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _postAvailableCountries: function(){
            let success = (response) => {                
                var available_paises_post = response.data;                    
                this.paises.forEach(element => {
                    var cast = this._getKeyByValue(available_paises_post, element);                    
                        if(!this.finder["country"].includes(cast)){
                            this.finder["country"].push(cast);
                        }
                });
                this._updateLastSearch2();
                
            };

            let url = "/api/v1/stock-finder/available-countries";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('113', 'Error recuperando paises disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _deleteAvailableCountries: function(){
            let success = (response) => {
                this.finder["country"] = [];
                var available_currency_post = response.data;
                /*if(this.paises.length == 0){
                    this.finder["country"].push("0");
                } */                  
                this.paises.forEach(element => {
                    var cast = this._getKeyByValue(available_currency_post, element);
                    this.finder["country"].push(cast);                    
                });
                this._updateLastSearch2();
            };

            let url = "/api/v1/stock-finder/available-countries";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('114', 'Error recuperando paises disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _refreshFiltersCountries: function(){
            let success = (response) => {
                this.finder["country"] = "0"
                this.paises = this.available_country_array.slice(1)
                this._updateLastSearch2();
            };

            let url = "/api/v1/stock-finder/available-countries";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('115', 'Error recuperando paises disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _deleteAllCountries: function(){
            let success = (response) => {
                this.finder["country"] = [];
                this.paises=[]
                this.$refs.selectPais[0]._setValueToCustom();
                this._updateLastSearch2();
            };

            let url = "/api/v1/stock-finder/available-countries";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('116', 'Error recuperando paises disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _getAvailableSectors: function(){
            let success = (response) => {
                this.sectoresIndustrias = []
                var available_sec = response.data;
                this.available_sector_array = Object.values(available_sec)
                if(this.finder["industry"].includes("0")){
                    this.available_sector_array.forEach(element =>{
                        if(element != "Todos los sectores | Todas las industrias"){
                            this.sectoresIndustrias.push(element)
                        }
                    })
                }            
                this.finder["industry"].forEach(element => {
                    if(element != "0"){
                        var cast = available_sec[element];
                        this.sectoresIndustrias.push(cast);
                    }
                });
                this.select_change = true;
            };

            let url = "/api/v1/stock-finder/available-industries";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('117', 'Error recuperando industrias disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _postAvailableSectors: function(){
            let success = (response) => {
                
                var available_industry_post = response.data;                    
                this.sectoresIndustrias.forEach(element => {
                    var cast = this._getKeyByValue(available_industry_post, element);                    
                        if(!this.finder["industry"].includes(cast)){
                            this.finder["industry"].push(cast);
                        }
                });
                this._updateLastSearch2();
                
            };

            let url = "/api/v1/stock-finder/available-industries";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('118', 'Error recuperando industrias disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _deleteAvailableSectors: function(){
            let success = (response) => {
                this.finder["industry"] = [];
                var available_currency_post = response.data;                   
                this.sectoresIndustrias.forEach(element => {
                    var cast = this._getKeyByValue(available_currency_post, element);
                    this.finder["industry"].push(cast);
                });
                this._updateLastSearch2();
            };

            let url = "/api/v1/stock-finder/available-industries";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('119', 'Error recuperando industrias disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _refreshFiltersSectors: function(){
            let success = (response) => {
                this.finder["industry"] = "0"
                this.sectoresIndustrias = this.available_sector_array.slice(1)
                this._updateLastSearch2();
            };

            let url = "/api/v1/stock-finder/available-countries";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('120', 'Error recuperando paises disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _deleteAllSectors: function(){
            let success = (response) => {
                this.finder["industry"] = [];
                this.sectoresIndustrias=[]
                this.$refs.selectSector[0]._setValueToCustom();
                this._updateLastSearch2();
            };

            let url = "/api/v1/stock-finder/available-currencies";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('121', 'Error recuperando divisas disponibles.');
            const apiCallConfig = {loadingOverlay: false};

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _updateLastSearch2: function(){
            this.setFinderFilters(this.finder);
            this.$emit("updateLastSearch", this.finder);
        }, 
        

    },

}
</script>

<style scoped>
    #fourth{
        width:100%;
    }
    .primary-container{
        display: flex;
        
    }
    .inlined {
        display: inline-block;        
    }
    .inlined-fourth-mobile {
        display: inline-block;
        width: 350px; 
        max-height: 270px; 
        overflow-y: scroll;
        margin-bottom: 10px;
    }
    h4{
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        letter-spacing: 0.1px;

        /* 02. Secondary/400 */

        color: #626777;
    }
    .item-container{
        display:block;
        border: 1px solid black;
        border-radius: 12px;
        width: 200px;
        font-size: 13px;
        text-align:center;
        align-items: center;
        height: 20px;
        background-color: white;
    }
    .cancel-button{
        border: none;
        cursor: pointer;
        float: right;
        background-color: transparent;
        font-size:15px;
        text-align:center;
    }
    .item-container-text{
        text-align:center;
    }
    .mdc-select__anchor{
        display:none;
    }
    .button-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 50%;
    }
    .select-divisas-container{
        min-height: 200px;
    }

</style>