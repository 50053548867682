<template>
  <div id="description">
    <HeaderSidebarRight :id_header="id_header" :title_header="title_header" />
    
    <div v-if="_g_UserFeatures.permissions.description_value">
      <div class="description-content-container">
        <div class="info-ticker-box">
          <p class="text-info-ticker">Ticker: <span class="text-bold">{{getFormattedSelectedTickerProperty(["ticker"])}}</span></p>
        </div>
        
        <!-- Website Section (if available) -->
        <div v-if="url" class="info-section website-section">
          <div class="section-header">
            <h3>Página web</h3>
          </div>
          <div class="section-content">
            <div class="info-row website-row">
              <a class="website-link" @click="_openNewWindow()">{{ url }}</a>
            </div>
          </div>
        </div>
        
        <!-- Main Description Section -->
        <div class="info-section description-section">
          <div class="section-header">
            <h3>Descripción</h3>
          </div>
          <div class="section-content">
            <div v-if="_g_SelectedTicker.description == null || _g_SelectedTicker.description == ''">
              <p class="no-data-message">Este ticker no dispone de descripción</p>
            </div>
            <div v-else>
              <div v-if="_g_SelectedTicker.sector || _g_SelectedTicker.industria" class="sector-industry">
                <span class="sector-label">{{ _g_SelectedTicker.sector }}</span>
                <span v-if="_g_SelectedTicker.sector && _g_SelectedTicker.industria" class="divider">|</span>
                <span class="industry-label">{{ _g_SelectedTicker.industria }}</span>
              </div>
              <p class="ticker-description typo-body-2">{{ _g_SelectedTicker.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div v-else class="not-available-container">
      <p class="not-available-message">Su plan actual no tiene acceso a esta herramienta</p>
      <a href="/app/store" class="upgrade-link">Pulse aquí para cambiar de plan</a>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import HeaderSidebarRight from './HeaderSidebarRight.vue';
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins: [APICaller],
  name: 'Description',
  components:{
    HeaderSidebarRight,
  },
  data: () => ({
    id_header: 'description',
    title_header: 'Descripción',
    url: "",
    last_stock_loaded: ""
  }),
  mounted() {
    this._getFundamentalInfo();
  },
  created: function() {
  },
  watch: {
    _g_SelectedTicker: {
      handler: function(newVal, oldVal) {
        this._getFundamentalInfo();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['_g_SelectedTicker', '_g_UserFeatures']),
  },
  methods: {
    getFormattedSelectedTickerProperty(prop_array) {
      const selected_ticker = this._g_SelectedTicker;

      let goal = "";
      prop_array.forEach(prop => {
        goal = selected_ticker[prop];
        if(goal == undefined) {
          return "";
        }
      });

      try {
        const formattedGoal = goal.toFixed(2);
        return formattedGoal;
      }
      catch(e) {
        return goal;
      }
    },
    
    // Get website URL from fundamental data
    _getFundamentalInfo: function() {
      var tickerName = this._g_SelectedTicker.ticker;
      if (this.last_stock_loaded != tickerName) {
        this.last_stock_loaded = tickerName;
        this.url = "";
        
        let success = (response) => {
          if (response.data.General && response.data.General.WebURL != undefined) {
            this.url = response.data.General.WebURL;
          }
        };
        
        let url = '/api/v1/stocks/fundamental/' + tickerName;

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('050', 'Error al obtener datos de la web.');
        const apiCallConfig = {loadingOverlay: false, timeoutMs: 0};

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      }
    },
    
    _openNewWindow: function() {
      window.open(this.url, '_blank').focus();
    }
  },
}
</script>


<style lang="scss" scoped>
  @import "@/styles/basics/globals.scss";

  #description {
    width: 100%;
  }
  
  .description-content-container {
    max-width: 600px;
    margin: 20px auto;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background);
    box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
    width: 85%;
  }
  
  .info-section {
    background-color: var(--color-surface, #F3F4F7);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(15, 15, 26, 0.05);
    margin-bottom: 22px;
    transition: transform 0.2s, box-shadow 0.2s;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(15, 15, 26, 0.08);
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .section-header {
    background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
    padding: 12px 20px;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
    
    h3 {
      margin: 0;
      color: var(--primary, #00aedd);
      font-size: 16px;
      font-weight: 600;
      text-align: left;
    }
  }
  
  .section-content {
    padding: 15px 20px;
  }
  
  .info-ticker-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
  
  .text-info-ticker {
    text-align: center;
    background-color: var(--color-surface, #F3F4F7);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 8px 30px;
    border-radius: 20px;
    color: var(--color-text-primary);
    font-weight: 500;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  .text-bold {
    font-weight: 700;
    color: var(--primary, #00aedd);
  }
  
  .sector-industry {
    margin-bottom: 12px;
    font-weight: 600;
    color: var(--color-text-secondary, #606577);
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .sector-label, .industry-label {
    display: inline-block;
  }
  
  .divider {
    margin: 0 8px;
    color: var(--color-outline, rgba(0, 0, 0, 0.3));
  }
  
  .ticker-description {
    margin: 0;
    padding: 0;
    color: var(--color-text-primary);
    font-size: 14px;
    line-height: 1.6;
    text-align: left;
  }
  
  .no-data-message {
    color: var(--color-text-secondary, #606577);
    font-size: 14px;
    font-style: italic;
    text-align: center;
    margin: 10px 0;
  }
  
  .not-available-container {
    max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    text-align: center;
  }
  
  .not-available-message {
    color: var(--warning, #FF9B00);
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .upgrade-link {
    color: var(--primary, #00aedd);
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .website-section {
    margin-bottom: 22px;
  }
  
  .website-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .website-link {
    color: var(--primary, #00aedd);
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 4px;
    transition: background-color 0.2s;
    
    &:hover {
      background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
      text-decoration: underline;
    }
  }
  
  /* Mobile responsiveness */
  @media (max-width: 767px) {
    .description-content-container {
      padding: 15px;
      width: 92%;
    }
    
    .section-header h3 {
      font-size: 15px;
    }
    
    .ticker-description {
      font-size: 14px;
    }
  }
</style>