<template>
  <div v-if="_g_IsUserLogged && !_g_User.is_only_authenticated_user" class="platinum-score-container">
    <div v-if="_g_User.is_pre_eureker_user">
      <div class="platinum-display">
        <span @click="_toastError()" class="material-icons mdc-button__icon\">visibility</span>
      </div>
    </div>
    <div v-else>
      <div v-if="showPlatinumScore" class="mdc-list-item" title="Puntuación Platino">
        <div class="platinum-display">
            <span class="typo-headline-4">{{Math.round(getFormattedSelectedTickerProperty(["platinum_compatibility"]) * 100) }}</span>
            <span class="material-label typo-caption">Puntos</span>
        </div>
      </div>
      <div v-else class="plat-button-container" @click="getPlatinumScoreForTicker()" title="Puntuación Platino">
        <div class="platinum-display">
          <i class="material-icons mdc-button__icon\">visibility</i>
        </div>
        <div class="score-display">
          {{_g_UserFeatures.consumibles.platinum_eyes}}
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="_g_User.is_only_authenticated_user" class="not-allowed-platinum-container">
    <div class="platinum-display">
      <span @click="_toastError()" class="material-icons mdc-button__icon\">visibility</span>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

/* API Caller */
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins:[APICaller],
  name: 'EurekersBrain',
  data: () => ({

    enable_score: false,
    posted: false,
    platinum_score: 0,
    info_rol: '',
    user_id: '',
    plat_score: '',
    ticker_selected: '',
    link_to_store: "",
  }),

  mounted() {
    this.link_to_store = this.$detectEnvironment() + '/app/store';
  },

  created() {
    this._getUID();
    this._getUserInfo();
  },
  watch:{
    _g_SelectedTicker: {
      handler: function(){
        this.enable_score = false;
      },
      deep: true
    },

  },

  computed: {
    ...mapGetters(["_g_IsUserLogged", "_g_SelectedTicker", "_g_Active_right_component", "_g_User", "_g_UserFeatures"]),

    showPlatinumScore() {
      const has_permissions = this._g_UserFeatures.permissions.eurekers_brain;
      const isPlatEnabledForTicker = this._g_SelectedTicker['platinum_compatibility'] != null;
      
      return has_permissions || isPlatEnabledForTicker;
    }
  },

  methods: {
    ...mapActions(['addWaiting', 'addPortfolio', 'addFavorites']),
    ...mapMutations(['setSelectedTicker', 'setStockPlatinumCompatibilityById']),
    getFormattedSelectedTickerProperty(prop_array) {
      const selected_ticker = this._g_SelectedTicker;

      let goal = "";
      prop_array.forEach(prop => {
        goal = selected_ticker[prop];
        if(goal == undefined) {
          return "";
        }
      });

      try {
        const formattedGoal = goal.toFixed(2);
        return formattedGoal;
      }
      catch(e) {
        return goal;
      }
    },

    getPlatinumScoreForTicker() {
        if(this._g_UserFeatures.consumibles.platinum_eyes > 0){
          const isSetSelectedTicker = this._g_SelectedTicker && this._g_SelectedTicker.ticker && this._g_SelectedTicker.ticker != "";
          if(isSetSelectedTicker) {
            const stock_id = this._g_SelectedTicker.id;
            const url = '/api/v1/users/user/consume-platinum-credit-for-stock/stock/' + stock_id;
            const success = (response) => {
              const creditsuccessfullySpent = response && response.data && response.data.success;
  
              if(creditsuccessfullySpent) {
                const platCompVal = response.data.data.platinum_compatibility;
                const mutationPayload = {
                  compatibility: parseFloat(platCompVal),
                  id: parseInt(stock_id)
                };
                this.setStockPlatinumCompatibilityById(mutationPayload);
                this.platinum_score = response.data.data.platinum_score;
                this._g_UserFeatures.consumibles.platinum_eyes = this._g_UserFeatures.consumibles.platinum_eyes - 1;
                this.$vueOnToast.pop('success', 'Puede visualizar la puntuación platino. Has consumido 1 crédito.');
              }
              else {
                let toast = {type: 'info', body: 'Suscríbete al plan PLATINO. Pulsa aquí para saber más.', clickHandler: function(){
                  window.location.replace(this.link_to_store);
                }};
                this.$vueOnToast.pop(toast);
              }
            };
  
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('172', 'Error canjeando créditos platino.');
  
            this._getAPICall(url, successHandler, failureHandler);
          }
        }else{
          let toast = {type: 'info', body: 'Suscríbete al plan PLATINO. Pulsa aquí para saber más.', clickHandler: function(){
            window.location.replace(this.link_to_store);
          }};
          this.$vueOnToast.pop(toast);
        }
      },

    _getUID(){
      this.user_id = this._g_User.uid;
    },

    _getUserInfo: function(){
      this.platinum_score = this._g_User.user_features.platinum_score.score;
      this.info_rol = this._g_User.user_features.platinum_score.info;
    },

    _toastError(){
      let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
        window.location.replace("/app/store");
      }};
      this.$vueOnToast.pop(toast);
    }
  },
}
</script>


<style lang="scss" scoped>
  .platinum-score-container{
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    background-color: #f3f4f7;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
  .platinum-main-container{
    /*position: fixed;
    left: 25%;
    transform: translate(-50%, 0);*/
    margin-left: 10px;
  }
  .plat-score-btn{
    background-color: #00add8;
    border: none;
    cursor: pointer;
    border-radius: 15px;
    color: white;
    width: 150px;
    height: 30px;
    margin-top: 5px;

  }

  .attempts-remaining-container{
    margin-top: 50px;
    font-size: 14px;
  }

  .attempts-style{
    color: grey;
  }

  .score-container{
    margin-top: 20px;
    background-color: #00add8;
    width: 80px;
    margin-left: 165px;
    text-align: center;
  }

  .btn-container{
    display:flex;
    text-align: center;
  }

  .score-number{
    font-size: 25px;
    color: white;
  }

  .name-style{
    font-size: 15px;
    color: white;
    font-weight: bold;
  }

  .score-style{
    color: white;
  }

  .circle{
    background-color: red;
    width: 20px;
    color: white;
    border-radius: 30px;
    text-align: center;
    position: absolute;
    margin-left: 132px;
    font-weight: bold;
    font-size: 15px;
    margin-top: -5px;
  }

  .plat-comp-style{
    color: var(--color-custom-typo-plat-mobile);
  }

  .plat-comp-container{
    height: 40px;
    background-color: var(--color-custom-plat-mobile);
    text-align: center;
  }

  .plat-comp-number-style{
    color: var(--color-custom-typo-plat-mobile);
    font-weight: bold;
    font-size: 23px;
  }

  .info-ticker-box {
     width: 100%;
     display: flex;
     justify-content: center;
  }
  .text-info-ticker {
    text-align: center;
    background-color: #F3F4F7;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 24px;
    width: 130px;
    padding: 7px 20px;
    color: black;
  }

  .text-bold {
    font-weight: bold;
  }

  .plat-button-container{
    display: flex;
    gap: 5px;
    justify-content: center;
  }

  .not-allowed-platinum-container{
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    background-color: #f3f4f7;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

</style>