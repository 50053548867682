<template>
    <!-- Use standard modal overlay structure -->
    <div v-if="_g_TickersFinder" class="modal-overlay ticker-finder-overlay" @click="handleClose">
        <div class="modal-container ticker-finder-modal" @click.stop>
            <!-- Modal Header -->
            <div class="modal-header">
                <div class="title-wrapper">
                    <span class="material-icons-outlined header-icon">search</span>
                    <h3 class="modal-title">Rastreador de Descripciones</h3>
                </div>
                <button class="modal-close-btn" @click="handleClose">
                    <i class="material-icons">close</i>
                </button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body">
                <!-- Search Controls -->
                <div class="ticker-finder-controls">
                    <div class="search-input-wrapper">
                        <i class="material-icons search-icon">search</i>
                        <input 
                            v-model="search_term" 
                            type="text" 
                            class="vanilla-input search-input" 
                            placeholder="Buscar en descripciones..." 
                            @keyup.enter="searchStocksByDescription" 
                            :disabled="isLoading"
                        />
                    </div>
                    <button 
                        class="action-btn primary-btn search-button" 
                        @click="searchStocksByDescription" 
                        :disabled="isLoading || !search_term.trim()">
                         <!-- Change button text based on loading state -->
                         <span v-if="isLoading">
                             <span class="button-spinner"></span> Buscando...
                    </span>
                         <span v-else>Buscar</span>
                    </button>
                </div>

                <!-- Results Area -->
                <div class="ticker-finder-results">
                    <!-- Loading Indicator -->
                    <div v-if="isLoading" class="loading-indicator">
                        <div class="spinner"></div>
                        <span>Cargando resultados...</span>
                    </div>
                    
                    <!-- Results List -->
                     <div v-else-if="stocks.length > 0" class="results-list">
                         <div v-for="(ticker, index) in stocks" :key="'ticker-' + index" class="result-item">
                            <div class="result-header">
                                <span class="ticker-name">{{ticker.ticker}}</span>
                                <span class="ticker-details">{{ticker.entry_type_name}} - {{ticker.sector}} / {{ticker.industria}}</span>
                            </div>
                            <!-- Use v-html carefully, ensure description is sanitized if from user input -->
                            <p class="description-text" v-html="processDescription(ticker.description, search_term_static, 5)"></p>
                            <div class="result-footer">
                                <button class="action-btn link-btn view-chart-btn" @click="setTickerHandler(ticker)">
                                     <i class="material-icons">show_chart</i>
                                     <span>Ver gráfico</span>
                                </button>
                             </div>
                        </div>
                    </div>
                    
                    <!-- No Results / Initial State Message -->
                    <div v-else class="no-results-message">
                         <!-- Show appropriate message based on whether a search was performed -->
                         {{ search_term_static ? 'No se encontraron resultados para "' + search_term_static + '"' : 'Ingrese un término y presione Buscar.' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapGetters } from "vuex";
    import APICaller from "@/components/codeComponents/APICaller.js";
    import GetStocks from "@/components/codeComponents/GetStocks.js"; // Assuming _getStocksDescriptionSearch comes from here

    export default{
        mixins: [APICaller, GetStocks],
        name: "TickerFinder",
        components:{},
        data: () =>({
            stocks: [],
            search_term: '',
            search_term_static: '', // Store the term used for the last search
            isLoading: false, 
        }),
        created(){},
        watch: {
            // Clear results when modal is closed (becomes false)
            _g_TickersFinder(newValue) {
                if (!newValue) {
                        this.stocks = [];
                        this.search_term = ''; 
                    this.search_term_static = '';
                    this.isLoading = false;
                }
            }
        },
        // Correct syntax for computed
        computed: {
            ...mapGetters(['_g_TickersFinder']),
        },
        mounted(){},
        // Correct syntax for methods
        methods: {
            ...mapMutations(['setTickersFinder', 'setSelectedTicker']),

            searchStocksByDescription: function () {
                if (!this.search_term.trim() || this.isLoading) return;
                
                this.isLoading = true;
                this.search_term_static = this.search_term.trim();
                this.stocks = []; // Clear previous results immediately
                
                // Call the method (potentially from mixin) to fetch data
                this._getStocksDescriptionSearch(this.search_term_static);
            },
            
             // Override or ensure the mixin method updates `this.stocks` and handles loading state
             // This provides explicit handling within this component.
             _getStocksDescriptionSearch: function(term) {
                 this.isLoading = true; 
                 
                 let success = (response) => {
                     this.stocks = response.data || [];
                 };
                 let failure = () => {
                     console.error("Failed to fetch stocks for term:", term);
                     this.stocks = [];
                 };
                 // Use POST as originally intended (implied) and send term in body
                 let url = `/api/v1/stock-finder/stocks/busqueda-descripcion`; 
                 let params = new URLSearchParams();
                 params.append('search_term', term); // Corrected parameter name based on GetStocks.js mixin
                 
                 const successHandler = new APICaller.SuccessHandler(success);
                 const failureHandler = new APICaller.FailureHandler('901', 'Error buscando por descripción.', failure); // Use a unique error code
                 
                 // Use _postAPICall instead of _getAPICall
                 this._postAPICall(url, params, successHandler, failureHandler)
                     .finally(() => {
                         this.isLoading = false;
                     });
            },

            setTickerHandler: function(ticker) {
                this.setSelectedTicker(ticker);
                this.handleClose();
            },

            // Simplified handleClose - just calls the mutation
            handleClose: function() {
                this.setTickersFinder();
            },

            // --- Description processing methods --- 
            splitString: function(str, search) {
                // Use regex for case-insensitive splitting and capture the delimiter
                const regex = new RegExp(`(${search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')})`, 'gi');
                const parts = str.split(regex);
                let result = [];
                let currentSegment = [];
                parts.forEach(part => {
                    if (part.toLowerCase() === search.toLowerCase()) {
                        result.push(currentSegment); // Push words before the match
                        result.push(part); // Push the matched term itself
                        currentSegment = []; // Reset for next segment
                    } else {
                         // Filter out empty strings resulting from split
                         currentSegment = currentSegment.concat(part.split(' ').filter(word => word !== '')); 
                    }
                });
                if (currentSegment.length > 0) {
                     result.push(currentSegment); // Push the last segment
                }
                return result;
            },
            truncateArrayWhole: function(arr, length) {
                 if (!Array.isArray(arr)) return [];
                 if (arr.length <= 2 * length + 1) { // Account for ellipsis
                    return arr;
                }
                 return [...arr.slice(0, length), '...', ...arr.slice(arr.length - length)];
            },
            truncateArrayBeginning: function(arr, length) {
                 if (!Array.isArray(arr)) return [];
                 if (arr.length <= length + 1) { // Account for ellipsis
                    return arr;
                }
                 return [...arr.slice(0, length), '...'];
            },
            truncateArrayEnd: function(arr, length) {
                 if (!Array.isArray(arr)) return [];
                 if (arr.length <= length + 1) { // Account for ellipsis
                    return arr;
                }
                 return ['...', ...arr.slice(arr.length - length)];
            },
            processDescription: function(description, search_string, context_size) {
                 description = description ? String(description) : ''; // Ensure it's a string
                 search_string = search_string ? String(search_string) : '';
                 context_size = context_size ? context_size : 5; // Default context

                 if (!search_string || !description) {
                      return description || 'Descripción no disponible.';
                 }

                 const segments = this.splitString(description, search_string);
                let resultDescription = '';

                 if (segments.length <= 1 && description.toLowerCase().indexOf(search_string.toLowerCase()) === -1) {
                     // Search term not found, return original (or truncated version if needed)
                     // Let's truncate if it's very long
                     const words = description.split(' ');
                     if (words.length > context_size * 4) { // Arbitrary limit for truncation
                         return this.truncateArrayBeginning(words, context_size * 2).join(' ');
                     } 
                     return description;
                 }

                 for (let i = 0; i < segments.length; i++) {
                     const segment = segments[i];
                     if (typeof segment === 'string' && segment.toLowerCase() === search_string.toLowerCase()) {
                         // This is the highlighted term
                         resultDescription += `<span class="highlight">${segment}</span> `;
                     } else if (Array.isArray(segment)) {
                         // This is an array of words between matches (or at ends)
                         let wordsToProcess = segment;
                         if (i === 0 && segments.length > 1) { // First segment before first match
                             wordsToProcess = this.truncateArrayEnd(segment, context_size);
                         } else if (i === segments.length - 1 && segments.length > 1) { // Last segment after last match
                             wordsToProcess = this.truncateArrayBeginning(segment, context_size);
                         } else if (i > 0 && i < segments.length - 1) { // Segment between matches
                              wordsToProcess = this.truncateArrayWhole(segment, context_size);
                         }
                         // If it's the only segment (match was at start/end or not found by split logic)
                         else if (segments.length === 1) {
                             // Truncate the whole description if it's too long
                             if (segment.length > context_size * 4) {
                                 wordsToProcess = this.truncateArrayBeginning(segment, context_size * 2);
                             } else {
                                 wordsToProcess = segment;
                             }
                         }
                         
                         resultDescription += wordsToProcess.join(' ') + ' ';
                     }
                 }

                 return resultDescription.trim();
            }
        }
    }
</script>

<style lang="scss" scoped>
/* Modal Overlay */
.modal-overlay.ticker-finder-overlay {
        position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 15, 26, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

/* Modal Container - Wider */
.modal-container.ticker-finder-modal {
  background-color: var(--color-background, white);
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  height: 85vh;
  max-height: 700px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(15, 15, 26, 0.15);
  color: var(--color-text-primary, #0F0F1A); 
}

/* Modal Header */
.modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.title-wrapper {
        display: flex;
  align-items: center;
  gap: 10px;
}

.header-icon {
  color: var(--primary, #00AEDD);
  font-size: 22px;
}

.modal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.modal-close-btn {
  background: none;
  border: none;
        cursor: pointer;
  color: var(--color-text-secondary, #606577);
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transition: background-color 0.2s ease-in-out;
}

.modal-close-btn:hover {
  background-color: var(--color-surface, #F3F4F7);
}

.modal-close-btn i {
  font-size: 20px;
}

/* Modal Body */
.modal-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  padding: 0;
}

/* Search Controls Section */
.ticker-finder-controls {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    flex-shrink: 0;
}

.search-input-wrapper {
    position: relative;
    flex-grow: 1;
}

.vanilla-input.search-input {
    height: 40px;
    padding-left: 45px;
    width: 100%;
    font-size: 15px;
}

.search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-text-secondary, #606577);
    font-size: 22px;
}

.action-btn.search-button {
   height: 40px;
   padding: 0 24px;
   flex-shrink: 0;
   min-width: 110px; /* Ensure minimum width */
   display: inline-flex; /* For spinner alignment */
   align-items: center;
   justify-content: center;
   gap: 8px;
   line-height: 1; /* Add line height to help with alignment */
}

/* Spinner for search button */
.button-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
        display: inline-block;
  animation: button-spin 0.8s linear infinite;
  vertical-align: middle; /* Add vertical alignment */
  position: relative;
  top: -1px; /* Slight adjustment to perfectly align with text */
}

@keyframes button-spin {
  to { transform: rotate(360deg); }
}

/* Results Area */
.ticker-finder-results {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    position: relative;
}

/* Scrollbar */
.ticker-finder-results::-webkit-scrollbar {
    width: 8px;
}
.ticker-finder-results::-webkit-scrollbar-track {
    background: var(--color-surface, #F3F4F7);
    border-radius: 4px;
}
.ticker-finder-results::-webkit-scrollbar-thumb {
    background-color: var(--color-text-secondary, #B0B4C3);
    border-radius: 4px;
    border: 2px solid var(--color-surface, #F3F4F7);
}

/* Loading Indicator */
.loading-indicator {
    /* Use a background based on surface color for theme consistency */
    position: absolute; 
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0;
        display: flex;
        flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Use a background based on surface color for theme consistency */
    background-color: var(--color-background, #F3F4F7);
    z-index: 5;
    color: var(--color-text-secondary, #606577);
}

.spinner {
    /* Use outline or muted color for the track */
    border: 4px solid var(--color-outline, rgba(0, 0, 0, 0.12)); 
    border-top: 4px solid var(--primary, #00aedd); 
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Results List */
.results-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.result-item {
    background-color: var(--color-surface, #F9F9FB); 
    border-radius: 6px;
    padding: 15px 20px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
        text-align: left;
    }

.result-header {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.ticker-name {
    font-weight: 600;
    font-size: 15px;
    color: var(--color-text-primary, #0F0F1A);
}

.ticker-details {
    font-size: 12px;
    color: var(--color-text-secondary, #606577);
}

.description-text {
    font-size: 14px;
    line-height: 1.5;
    color: var(--color-text-secondary, #606577);
    margin: 0 0 12px 0;
    /* Let v-html handle wrapping, remove webkit line clamp */
    /* display: -webkit-box; */
    /* -webkit-line-clamp: 3; */ 
    /* -webkit-box-orient: vertical; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
}

.result-footer {
    text-align: right;
}

.action-btn.link-btn {
    background: none;
    color: var(--primary, #00aedd);
    padding: 0;
    height: auto;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    padding: 4px 8px;
}

.action-btn.link-btn i {
    font-size: 18px;
}

.action-btn.link-btn:hover {
    background-color: var(--color-surface, #F3F4F7);
    text-decoration: none;
}

/* Highlight Style */
::v-deep .highlight {
    background-color: rgba(0, 174, 221, 0.2); /* Slightly stronger highlight */
    font-weight: 600;
    color: var(--color-text-primary, #0F0F1A);
    padding: 1px 3px;
    border-radius: 3px;
    margin: 0 1px; /* Add slight margin around highlight */
    display: inline-block; /* Helps with spacing */
}

/* No Results Message */
.no-results-message {
    text-align: center;
    padding: 40px 20px;
    color: var(--color-text-secondary, #606577);
    font-style: italic;
    font-size: 15px;
}


/* Vanilla Input base style */
.vanilla-input {
    height: 36px; 
    padding: 6px 12px;
    font-size: 14px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    box-sizing: border-box;
}
.vanilla-input:focus {
    outline: none;
    border-color: var(--primary, #00AEDD);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.2);
}
.vanilla-input::placeholder {
    color: var(--color-text-secondary, #606577);
    opacity: 0.6;
}
.vanilla-input:disabled {
    background-color: var(--color-surface, #F3F4F7);
    cursor: not-allowed;
    opacity: 0.7;
}

/* Action Button Base */
.action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 36px;
    transition: all 0.2s ease;
    font-weight: 600;
}
.primary-btn {
    background-color: var(--primary, #00aedd);
    color: white;
}
.primary-btn:hover:not(:disabled) {
    background-color: #0095c5;
}
.primary-btn:disabled {
    background-color: var(--color-muted, #EAECEF); 
    color: var(--color-text-inactive, rgba(0,0,0,0.4));
    cursor: not-allowed;
}

</style>