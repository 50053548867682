<template>
  <div id="fullscreen">
 
  </div>
</template>





<script>

export default {
  name: 'FullScreen',
  data: () => ({
  }),
  mounted() {
  },
  created: function() {
  },
  computed: {
  },
  methods: {
  },
}
</script>


<style lang="scss" scoped></style>