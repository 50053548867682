<template >
    <!-- Remove fullscreen wrapper, rely on parent modal -->
    <!-- <div @click="_manageExitClicksBehavior()" id="workspace-favorites-fullscreen"> -->
    <!-- Add modal content container -->
    <div class="favorites-modal-content" @click.stop>
        <!-- Remove old primary-div and user-workspaces class -->
        <!-- <div @click="_turnOnClosingShield()" id="primary-div"  class="user-workspaces" style="..."> -->
        <!-- Remove favorites-workspace-container-style -->
        <!-- <div class="favorites-workspace-container-style" ref="favoritesWorkspace"> -->
        
        <!-- Standard Modal Header -->
        <div class="modal-header">
            <div class="title-wrapper">
                <span class="material-icons-outlined header-icon">grade</span>
                <h3 class="modal-title">Favoritos</h3>
            </div>
            <button class="modal-close-btn" @click="_closeFavoritesWindow()">
                <i class="material-icons">close</i>
            </button>
        </div>

        <!-- Standard Modal Body -->
        <div class="modal-body">
            <!-- Existing content goes here, wrapped in modal-body -->
            <div v-if="!isDeleting">
                <!-- List of workspaces -->
                <div class="list-container">
                    <div v-for="workspace in workspaces" :key="workspace.name" class="list-item">
                        <div v-if="currentlyEditing.name != workspace.name" class="item-content-wrapper">
                            <div class="item-info" @click="_findFavoritesStocksByName(workspace)">
                                <span class="material-icons-outlined item-icon" :style="{ color: workspace.color }">star</span>
                                <span class="item-text workspace-name-style">{{workspace.name}}</span>
                            </div>
                            <!-- Actions shown on hover (controlled by CSS) -->
                            <div v-if="!isEditing && !isCreating" class="item-actions">
                                <button
                          v-if="workspace.name != 'Favorites'"
                          @click="_editWorkspacePrepare(workspace)"
                                class="icon-button edit-icon">
                                    <i class="material-icons">edit</i>
                                </button>
                                <button
                          v-if="workspace.name != 'Favorites'"
                          @click="_deleteWorkspacePrepare(workspace)"
                                class="icon-button delete-icon">
                                    <i class="material-icons">delete</i>
                                </button>
                    </div>
                        </div>
                        <!-- Edit mode -->
                        <div v-else class="edit-input-wrapper" @keyup.enter="_editWorkspaceConfirm()" @keyup.esc="_editWorkspaceCancel()">
                            <button class="icon-button color-trigger-btn" @click="_toggleColorGrid('edit', $event)">
                                <span class="material-icons-outlined item-icon" :style="{ color: editWorkspaceColor }">star</span>
                            </button>
                            <input 
                                type="text" 
                                class="vanilla-input edit-fav-input" 
                                v-model="editWorkspaceNewName" 
                                placeholder="Nuevo nombre" 
                                :maxlength="input_edit_favorites.length || 32"
                                ref="editFavInput"
                            />
                            <button class="icon-button confirm-edit-btn" @click="_editWorkspaceConfirm()">
                                <i class="material-icons">check</i>
                            </button>
                            <button class="icon-button cancel-edit-btn" @click="_editWorkspaceCancel()">
                                <i class="material-icons">close</i>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Add/Create New Workspace Section -->
                 <hr v-if="!isCreating && !isEditing && _g_UserFeatures.permissions.extra_favorites > workspaces.length - 1"/>
                <div>
                     <div v-if="!isCreating && !isEditing" class="add-new-section">
                         <button 
                            class="action-btn secondary-btn add-workspace-btn" 
                            @click="_createWorkspacePrepare()" 
                            :disabled="!(_g_UserFeatures.permissions.extra_favorites > workspaces.length - 1)"
                            :title="_g_UserFeatures.permissions.extra_favorites > workspaces.length - 1 ? 'Añadir nuevo espacio' : 'Límite de espacios alcanzado'">
                             <i class="material-icons">add</i>
                             <span>Añadir espacio</span>
                         </button>
            </div>
                     <div v-else-if="isEditing">
                         <!-- Placeholder while editing another item -->
                </div>
                     <div v-else class="create-input-wrapper" @keyup.enter="_createWorkspaceConfirm()" @keyup.esc="_createWorkspaceCancel()">
                         <button class="icon-button color-trigger-btn" @click="_toggleColorGrid('create', $event)">
                             <span class="material-icons-outlined item-icon" :style="{ color: createWorkspaceColor }">star</span>
                         </button>
                         <input
                              type="text" 
                              class="vanilla-input create-fav-input" 
                              v-model="createWorkspaceNewName" 
                              placeholder="Nombre del espacio" 
                              :maxlength="input_favorites.length || 32"
                              ref="createFavInput"
                          />
                          <button class="icon-button confirm-edit-btn" @click="_createWorkspaceConfirm()">
                              <i class="material-icons">check</i>
                          </button>
                          <button class="icon-button cancel-edit-btn" @click="_createWorkspaceCancel()">
                              <i class="material-icons">close</i>
                          </button>
                     </div>
                    </div>
                </div>
            <!-- Delete Confirmation View -->
            <div v-else class="delete-confirm-view">
                <p class="delete-question-text">¿Está seguro que desea eliminar este listado de favoritos?</p>
                <div class="item-to-delete-info">
                    <span class="material-icons-outlined item-icon" :style="{ color: currentlyDeleting.color }">star</span>
                    <span class="item-name-delete">{{currentlyDeleting.name}}</span>
                </div>
                 <p class="delete-warning">
                  <i class="material-icons warning-icon">warning</i>
                  Esta acción no se puede deshacer.
                </p>
            </div>
        </div>

        <!-- Standard Modal Footer (only for delete confirmation) -->
        <div class="modal-footer" v-if="isDeleting">
             <button class="action-btn secondary-btn" @click="_deleteWorkspaceCancel()">Cancelar</button>
             <button class="action-btn delete-btn" @click="_deleteWorkspaceConfirm()">Eliminar</button>
        </div>

        <!-- Custom Color Grid Popup -->
        <div v-if="showColorGrid" class="color-grid-popup" :style="colorGridStyle" ref="colorGridPopup">
             <div v-for="(color, index) in gridColors" 
                  :key="index" 
                  class="color-grid-item" 
                  :style="{ backgroundColor: color }"
                  @click="_selectColor(color)">
             </div>
         </div>
    </div>
    <!-- </div> -->
</template>



<script>
// import VSwatches from 'vue-swatches';
// import "vue-swatches/dist/vue-swatches.css";
import APICaller from "@/components/codeComponents/APICaller.js";
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';
import inputFavorites from '@/components/stockchart/components/configs/favorites-workspace.json';
import inputEditFavorites from '@/components/stockchart/components/configs/edit-favorites-workspace.json';
import { mapMutations, mapGetters } from "vuex";

export default {
    mixins: [APICaller],
    components: {
        // VSwatches, // Removed
        InputTextOutline
    },
    name: 'Favoritos',
    props: {},

    data: () => ({
        input_favorites : inputFavorites,
        input_edit_favorites : inputEditFavorites,
        workspaces: [],
        isClosingShieldOn: false,
        isCreating: false,
        isDeleting: false,
        isEditing: false,
        createWorkspaceNewName: "",
        editWorkspaceNewName: "",
        currentlyDeleting: {},
        currentlyEditing: {},
        createWorkspaceColor: "#35add8",
        editWorkspaceColor: "#000000",
        initialized: false,
        // Remove colorSwatches defined for v-swatches
        // colorSwatches: [ ... ],

        // --- Custom Color Grid Data ---
        showColorGrid: false,
        colorGridTarget: null, // 'create' or 'edit'
        colorGridStyle: {}, // For position { top: 'px', left: 'px' }
        // Define 16 grid colors
        gridColors: [
            "#ff0000", "#ff6000", "#ffbf00", "#dfff00", "#80ff00", "#20ff00", "#00ff40", "#00ff9f", "#00ffff", "#009fff", "#0040ff", "#2000ff", "#8000ff", "#df00ff", "#ff00bf", "#ff0060"
        ]
        // -------------------------------
    }),

    watch: {},
    computed: {
        ...mapGetters(["_g_UserFeatures"]),
    },

    created() {},

    mounted() {
        this._initWorkspaces();
        // Add event listeners for closing the grid
        document.addEventListener('click', this._handleClickOutside, true);
        window.addEventListener('resize', this._handleResize);
    },

    beforeDestroy() {
        // Clean up event listeners
        document.removeEventListener('click', this._handleClickOutside, true);
        window.removeEventListener('resize', this._handleResize);
    },

    methods: {
        ...mapMutations(["setWorkspaceCreated"]),
        ...mapMutations(['setSelectedFavoriteWorkspace']),

        _closeFavoritesWindow: function() {
            this.showColorGrid = false; // Ensure grid is closed
            this._cancelOngoingOperations();
            this.$emit('_closeFavoriteWorkspacesWindow');
        },

        // --- Custom Color Grid Methods ---
        _toggleColorGrid(context, event) {
            // If already shown for the same context, hide it
            if (this.showColorGrid && this.colorGridTarget === context) {
                this.showColorGrid = false;
                return;
            }

            // Find the button element that was clicked
            const triggerButton = event.target.closest('button');
            if (!triggerButton) {
                console.error("Could not find trigger button for color grid.");
                return; // Should not happen
            }

            // Get positions directly with client coordinates
            const buttonRect = triggerButton.getBoundingClientRect();
            
            // Direct approach: position directly next to the button
            // We'll use fixed positioning to bypass any container positioning issues
            
            // Get viewport dimensions
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            
            // Grid dimensions
            const gridWidth = 130; // Approximate grid width
            const gridHeight = 130; // Approximate grid height
            
            // Calculate position (prefer right side of button)
            let posLeft = buttonRect.right + 8; // 8px spacing
            let posTop = buttonRect.top;
            
            // If it would go off the right edge of the viewport, position left of the button
            if (posLeft + gridWidth > viewportWidth) {
                posLeft = buttonRect.left - gridWidth - 8;
            }
            
            // If it would still go off the left edge, position below or above
            if (posLeft < 0) {
                posLeft = buttonRect.left;
                posTop = buttonRect.bottom + 8;
                
                // If it would go off the bottom, position above
                if (posTop + gridHeight > viewportHeight) {
                    posTop = buttonRect.top - gridHeight - 8;
                }
            }
            
            // Final boundary checks
            posLeft = Math.max(8, Math.min(posLeft, viewportWidth - gridWidth - 8));
            posTop = Math.max(8, Math.min(posTop, viewportHeight - gridHeight - 8));
            
            // Apply styles using fixed positioning relative to viewport
            this.colorGridStyle = {
                position: 'fixed',
                top: `${posTop}px`,
                left: `${posLeft}px`,
            };

            // Set target context and show the grid
            this.colorGridTarget = context;
            this.showColorGrid = true;

            // Focus the first color item for accessibility
            this.$nextTick(() => {
                const firstColorItem = this.$refs.colorGridPopup?.querySelector('.color-grid-item');
                if (firstColorItem) {
                    firstColorItem.setAttribute('tabindex', '0');
                    firstColorItem.focus();
                }
            });
        },

        _selectColor(color) {
            if (this.colorGridTarget === 'create') {
                this.createWorkspaceColor = color;
            } else if (this.colorGridTarget === 'edit') {
                this.editWorkspaceColor = color;
            }
            this.showColorGrid = false;
        },

        _handleClickOutside(event) {
             if (this.showColorGrid && this.$refs.colorGridPopup && !this.$refs.colorGridPopup.contains(event.target)) {
                 // Check if the click was specifically outside the grid popup
                 // And also not on one of the trigger buttons
                 const isTrigger = event.target.closest('.color-trigger-btn');
                 if (!isTrigger) {
                     this.showColorGrid = false;
                 }
             }
        },

        _handleResize() {
            if (this.showColorGrid) {
                this.showColorGrid = false;
            }
        },
        // -------------------------------

        _initWorkspaces: function() {
             this.initialized = false; // Reset flag
            let success = response => {
                this.workspaces = [];
                if (response.data && response.data.folders) {
                     for (let index = 0; index < response.data.folders.length; index++) {
                        this.workspaces.push({
                            name: response.data.folders[index][0],
                            color: response.data.folders[index][1],
                        });
                    }
                } else {
                    console.error("Invalid response structure for folders:", response.data);
                    this.workspaces = [];
                }
                this.initialized = true;
            };

            let url = '/api/v1/stock-finder/favorite-folder/list';
            
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('229', 'Error recuperando la lista de favoritos.');

            this._getAPICall(url, successHandler, failureHandler);
        },

        _createWorkspacePrepare: function() {
            if(this.workspaces && this._g_UserFeatures.permissions.extra_favorites > this.workspaces.length - 1){
                this._editWorkspaceCancel();
                this._deleteWorkspaceCancel();
                this.createWorkspaceColor = this.gridColors[Math.floor(Math.random() * this.gridColors.length)]; // Use grid colors
                this.isCreating = true;
            }else{
                 let toast = {type: 'warning', body: 'Con su plan actual no puede crear más espacios de trabajo. PULSE AQUÍ para mejorar su plan.', clickHandler: function(){
                   window.location.replace("/app/store");
                 }};
                 this.$vueOnToast.pop(toast);
            }
        },

        _createWorkspaceConfirm: function() {
            const newName = this.createWorkspaceNewName.trim();
            if(newName !== '') {
                let params = new URLSearchParams();
                params.append('name', newName);
                params.append('color', this.createWorkspaceColor);

                let url = '/api/v1/stock-finder/favorite-folder/create';

                let success = (response) =>{
                    if (response.data?.status === 'error') {
                        this.$vueOnToast.pop('error', "Error", response.data.message || 'No se pudo crear el espacio.');
                    } else {
                         this.$vueOnToast.pop('success', "Espacio de trabajo " + newName, "creado con éxito");
                         this._initWorkspaces();
                         this.setWorkspaceCreated(true);
                    }
                     this._createWorkspaceCancel();
                };

                 let failure = () => {
                    this._createWorkspaceCancel();
                 };

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('304', 'Error creando el espacio de trabajo.', failure);

                this._postAPICall(url, params, successHandler, failureHandler);
            } else {
                 this.$vueOnToast.pop('error', "Error", "El nombre no puede estar vacío.");
            }
        },

        _createWorkspaceCancel: function() {
            this.isCreating = false;
            this.createWorkspaceNewName = "";
            this.showColorGrid = false;
        },

        _deleteWorkspacePrepare: function(workspace) {
            this._editWorkspaceCancel();
            this._createWorkspaceCancel();
            this.isDeleting = true;
            this.currentlyDeleting = workspace;
        },

        _deleteWorkspaceConfirm: function() {
             if (!this.currentlyDeleting || !this.currentlyDeleting.name) return;

            let params = new URLSearchParams();
            params.append('name', this.currentlyDeleting.name);

            let url = '/api/v1/stock-finder/favorite-folder/delete';
            let deletedName = this.currentlyDeleting.name;

            let success = (response) => {
                 if (response.data?.status === 'error') {
                        this.$vueOnToast.pop('error', "Error", response.data.message || 'No se pudo eliminar el espacio.');
                 } else {
                    this.$vueOnToast.pop('success', "Espacio de trabajo " + deletedName, "eliminado con éxito");
                    this._initWorkspaces();
                    this.setWorkspaceCreated(true);
                 }
                 this._deleteWorkspaceCancel();
            };

            let failure = () => {
                this._deleteWorkspaceCancel();
            };

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('305', 'Error borrando espacio de trabajo.', failure);

            this._postAPICall(url, params, successHandler, failureHandler);
        },

        _deleteWorkspaceCancel: function() {
            this.isDeleting = false;
            this.currentlyDeleting = {};
            this.showColorGrid = false;
        },

        _editWorkspacePrepare: function(workspace) {
            this._createWorkspaceCancel();
            this._deleteWorkspaceCancel();
            this.isEditing = true;
            this.currentlyEditing = { ...workspace };
            this.editWorkspaceNewName = workspace.name;
            this.editWorkspaceColor = workspace.color;
        },

        _editWorkspaceConfirm: function() {
            const newName = this.editWorkspaceNewName.trim();
            const originalName = this.currentlyEditing.name;
            const newColor = this.editWorkspaceColor;
            const originalColor = this.currentlyEditing.color;

            if (newName === '') {
                this.$vueOnToast.pop('error', "Error", "El nombre no puede estar vacío.");
                return;
            }

            if (newName === originalName && newColor === originalColor) {
                this.$vueOnToast.pop('info', "Info", "No se realizaron cambios.");
                this._editWorkspaceCancel();
                return;
            }

            var params = new URLSearchParams();
            params.append('name', originalName);
            params.append('new_name', newName);
            params.append('new_color', newColor); // Added new_color parameter

            let url = '/api/v1/stock-finder/favorite-folder/update';

            let success = (response) => {
                if (response.data?.status === 'error') {
                    this.$vueOnToast.pop('error', "Error", response.data.message || 'No se pudo editar el espacio.');
                } else {
                    this.$vueOnToast.pop('success', "Espacio de trabajo " + newName, "editado con éxito");
                    
                    // First cancel the editing mode to clear any popovers or state
                    this._editWorkspaceCancel();
                    
                    // THEN refresh workspaces list
                    this._initWorkspaces();
                    
                    // THEN emit the event to update colors in stock list
                    this.$nextTick(() => {
                        this.$emit("workspace_edited");
                    });
                }
            };

            let failure = () => {
                this._editWorkspaceCancel();
            };

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('306', 'Error guardando cambios.', failure);

            this._postAPICall(url, params, successHandler, failureHandler);
        },

        _editWorkspaceCancel: function() {
            this.isEditing = false;
            this.editWorkspaceNewName = "";
            this.currentlyEditing = {};
            this.showColorGrid = false;
        },

        _cancelOngoingOperations: function() {
            this.isCreating = false;
            this.isDeleting = false;
            this.isEditing = false;
            this.currentlyDeleting = {};
            this.currentlyEditing = {};
            this.createWorkspaceNewName = "";
            this.editWorkspaceNewName = "";
            this.showColorGrid = false;
        },

        _manageExitClicksBehavior: function() {
             console.warn("_manageExitClicksBehavior might be redundant");
        },
        _turnOnClosingShield: function() {
            this.isClosingShieldOn = true;
        },

        // ... (_findFavoritesStocksByName, _getStocksInWorkspace remain) ...
  },
}
</script>


<style lang="scss" scoped>
/* Remove old fullscreen and positioning styles */
/* #workspace-favorites-fullscreen { ... } */
/* .favorites-workspace-container-style { ... } */
/* .user-workspaces { ... } */

/* Basic modal content styling */
.favorites-modal-content {
    background-color: var(--color-background, white);
    border-radius: 8px;
    width: 380px; /* Slightly wider for color picker */
    max-height: 85vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: var(--color-text-primary, #0F0F1A);
    box-shadow: 0 4px 20px rgba(15, 15, 26, 0.15);
}

/* Modal Header */
.modal-header {
    padding: 16px 20px;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-icon {
    color: var(--primary, #00AEDD); /* Use theme primary */
    font-size: 22px;
}

.modal-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.modal-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-text-secondary, #606577);
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    transition: background-color 0.2s ease-in-out;
}

.modal-close-btn:hover {
    background-color: var(--color-surface, #F3F4F7);
}

.modal-close-btn i {
    font-size: 20px;
}

/* Modal Body */
.modal-body {
    padding: 15px 20px;
    overflow-y: auto;
    flex-grow: 1;
}

/* Scrollbar styles */
.modal-body::-webkit-scrollbar {
    width: 8px;
}
.modal-body::-webkit-scrollbar-track {
    background: var(--color-surface, #F3F4F7);
    border-radius: 4px;
}
.modal-body::-webkit-scrollbar-thumb {
    background-color: var(--color-text-secondary, #B0B4C3);
    border-radius: 4px;
    border: 2px solid var(--color-surface, #F3F4F7);
}

/* List styles */
.list-container {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Slightly more gap */
}

.list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px; /* Adjust padding */
    border-radius: 6px;
    /* Remove cursor pointer from the whole item if info part is clickable */
    /* cursor: pointer; */ 
    transition: background-color 0.2s ease-in-out;
}

/* Only apply hover background, not color change, actions appear on hover */
.list-item:hover {
    background-color: var(--color-surface, #F3F4F7);
}

.item-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
        width: 100%;
}

.item-info {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer; /* Make info part clickable */
    flex-grow: 1;
    overflow: hidden; /* Prevent long names breaking layout */
}

.item-icon {
    font-size: 20px; /* Standard icon size */
    flex-shrink: 0;
}

.item-text {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-text-primary, #0F0F1A);
}

.list-item:hover .item-info .item-text {
    color: var(--primary, #00AEDD);
}

.item-actions {
    display: flex; /* Always visible */
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
}

/* Show actions on list item hover */
// /* Remove rule: actions are now always visible */
// .list-item:hover .item-actions {
//     display: flex;
// }

/* Icon button base style */
.icon-button {
    background: none;
    border: none;
        cursor: pointer;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-secondary, #606577);
    transition: color 0.2s ease, background-color 0.2s ease;
}

.icon-button:hover {
    background-color: var(--color-muted, #EAECEF);
}

.icon-button i,
.icon-button .material-icons-outlined /* For color picker trigger */
{
    font-size: 18px;
}

.delete-icon:hover {
    color: var(--color-error, #B00020);
}

.edit-icon:hover {
    color: var(--primary, #00AEDD);
}

.confirm-edit-btn:hover {
    color: var(--color-success, #57a869);
}

.cancel-edit-btn:hover {
    color: var(--color-error, #B00020);
}

/* Edit and Create Input Wrappers */
.edit-input-wrapper,
.create-input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 0; /* Add slight vertical padding */
}

/* Vanilla Input Styles */
.vanilla-input {
    flex-grow: 1;
    margin: 0 10px; /* Margin left and right */
    height: 36px;
    padding: 6px 12px;
    font-size: 14px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    box-sizing: border-box;
}

.vanilla-input:focus {
    outline: none;
    border-color: var(--primary, #00AEDD);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.2);
}

/* Color Picker Styles */
.color-picker {
    flex-shrink: 0;
    /* Adjustments for VSwatches */
    margin-left: 0; 
    z-index: 10; /* Ensure popover is above other modal content */
}

.color-trigger-btn {
     padding: 6px; /* Match icon button padding */
}

.color-trigger-btn .material-icons-outlined {
    font-size: 22px; /* Slightly larger for color star */
    display: block; /* Prevent extra space */
}

/* Add New Section */
.add-new-section {
    margin-top: 15px;
    text-align: center;
}

.add-workspace-btn {
    display: inline-flex; /* Align icon and text */
    align-items: center;
    gap: 8px;
    width: auto; /* Adjust width automatically */
    padding: 0 20px; /* More horizontal padding */
}

.add-workspace-btn i {
    font-size: 20px;
}

hr {
    border: none;
    height: 1px;
    background-color: var(--color-outline, rgba(0, 0, 0, 0.12));
    margin: 15px 0;
}

/* Delete Confirmation View */
.delete-confirm-view {
    padding: 20px 0; /* More padding */
        text-align: center;
}

.delete-question-text {
    font-size: 16px;
    font-weight: 500;
        margin-bottom: 20px;
    }

.item-to-delete-info {
    display: inline-flex;
    align-items: center;
        gap: 10px;
    background-color: var(--color-surface, #F3F4F7);
    padding: 10px 18px;
    border-radius: 6px;
    margin-bottom: 20px;
    color: var(--color-text-primary, #0F0F1A);
}

.item-to-delete-info .item-icon {
    font-size: 22px; /* Match header icon size */
}

.item-name-delete {
    font-weight: 600;
    font-size: 15px;
}

.delete-warning {
      display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--color-warning, #FF9B00);
    font-size: 14px;
    margin-top: 15px;
}

.warning-icon {
    font-size: 20px;
}

/* Modal Footer */
.modal-footer {
    padding: 16px 20px;
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
      display: flex;
    justify-content: flex-end;
    gap: 12px;
    flex-shrink: 0;
}

/* Action Buttons */
.action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 36px;
    transition: all 0.2s ease;
    font-weight: 600;
}

.secondary-btn {
    background-color: var(--color-surface, #F3F4F7);
    color: var(--color-text-primary, #0F0F1A);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
}

.secondary-btn:hover:not(:disabled) {
    background-color: var(--color-background, #EAECEF);
}

.secondary-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.delete-btn {
    background-color: var(--color-error, #B00020);
    color: white;
}

.delete-btn:hover {
    background-color: #9a001b;
}


/* VSwatches Overrides */
::v-deep .vue-swatches__container {
    padding: 0 !important; 
    z-index: 10; /* Ensure popover is above other modal content */
}

::v-deep .vue-swatches__wrapper {
    padding-left: 5px !important; /* Align swatches nicely */
    padding-bottom: 5px !important;
}

::v-deep .vue-swatches__swatch {
    margin-top: 5px !important;
    margin-right: 5px !important;
    border-radius: 50% !important;
    box-shadow: none !important; 
}

::v-deep .vue-swatches__swatch .vue-swatches__check__wrapper {
    border-radius: 50% !important;
}

::v-deep .vue-swatches__check__icon {
     /* Make check icon smaller/thinner if needed */
}

/* Custom Color Grid Popup Styles */
.color-grid-popup {
    /* Change from absolute to fixed (set in JS) */
    background-color: var(--color-background, white);
    border-radius: 8px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.25);
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(4, 24px); /* 4 columns */
    grid-template-rows: repeat(4, 24px);    /* 4 rows */
    gap: 8px;
    z-index: 9999; /* Ensure it's above everything */
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.1));
}

.color-grid-item {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.1));
    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.color-grid-item:hover {
    transform: scale(1.15);
    border-color: var(--color-text-primary, #0F0F1A);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.color-grid-item:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary, #00AEDD);
    transform: scale(1.15);
}

</style>