<template>
  <div :id="id_header">
    <header id="sidebar-header-container">
      <span @click.prevent="_fullWidthAssociateComponent()"
        class="material-icons material-icons-outlined full-width" style="margin-left: 7px;">multiple_stop</span>
      <h2 class="typo-headline-6">{{ title_header }}</h2>
      <span @click.prevent="closeComponent()" class="material-icons material-icons-outlined" style="margin-right: 7px;">close</span>
    </header>
  </div>
</template>



<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: 'HeaderSidebarRight',
  props: {
    id_header: { type: String },
    title_header: { type: String }
  },

  data() {
    return {
      is_fullscreen: false,
    };
  },

  computed: {
    ...mapGetters(["_g_Active_right_component"])
  },

  methods: {
    ...mapMutations(['setActiveRightComponent']),

    closeComponent: function () {
      const parent_component = 'sidebar-content';
      const tradingview_container = 'dragable-container';
      const main_container = document.getElementById(this._g_Active_right_component);
      const parent_container = document.getElementById(parent_component);
      const graph_container = document.getElementById(tradingview_container);

      if (main_container != null && parent_container != null && graph_container != null) {
        main_container.style.display = 'none';
        parent_container.style.width = '1px';
        graph_container.style.marginRight = "52px";
        this.setActiveRightComponent("");
        this.removeSelectedClass();
      }
    },

    removeSelectedClass: function () {
      const menu_secundario_container = document.getElementById('stockchart-menu-secundario');

      if (menu_secundario_container !== null) {
        const comp_list_container = menu_secundario_container.querySelector('ul');

        if (comp_list_container !== null) {
          const component_buttons = comp_list_container.querySelectorAll('li');

          for (let i = 0; i < component_buttons.length; i++) {
            component_buttons[i].classList.remove('eurekers-parent-menu-active');
          }
        }
      }
    },


    _fullWidthAssociateComponent() {          

      if (this.is_fullscreen == false) {
        var mainMenuClosed = 64;
        var fullWidth = document.getElementById('sidebar-content');

        var screenWidth = window.innerWidth;
        var sidebarWidth = document.getElementsByClassName('main-sidebar-left')[0].offsetWidth;
        var sidebarMenuStockChart = document.getElementsByClassName('stockchart-menu')[0].offsetWidth;

        var full = screenWidth - (sidebarWidth + sidebarMenuStockChart * 2) - mainMenuClosed + 13;
        fullWidth.style.width = full + 'px';

      } else {
        document.getElementById('sidebar-content').style.width = "410px";
      }

      this.is_fullscreen = !this.is_fullscreen; 
    },
  },
}
</script>



<style lang="scss" scoped>
#sidebar-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
}

#sidebar-header-container>* {
  position: relative !important;
  left: unset !important;
  top: unset !important;
}
</style>