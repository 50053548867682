<template >
<div>
    <div @click="_manageExitClicksBehavior()" v-if="show_waiting" id="workspace-favorites-fullscreen"> 
        <div @click="_turnOnClosingShield()" id="primary-div"  class="user-workspaces" style="background-color: #f3f4f7; box-shadow: 0px 2px 5px black; border-radius: 2%;">
            <div class="addwaitings-workspace-style">
                <header>
                    <input
                    @click="_manageExitClicksBehavior()"
                    type="button" class="material-icons-outlined" style="background-color: #f3f4f7; border: none; color: #616263; float: right; margin-right: 8px; cursor: pointer;" value="close">
                    <h3 class="title-favorites">Añadir ticker a cartera</h3>                  
                </header>

                <div class="cartera-1">
                        <SelectOutlineWallet
                            id="cartera-element"
                            style="border-radius:20px;"
                            :label_name="select_items[0].label_name"
                            :options="this.user_portfolios"
                            :helper_select_permission="select_items[0].helper_select_permission"
                            :helper_text_select="this.helper_text_select"
                            :default_value="this.portfolio_selected[0].name"
                            :component_class="select_items[0].component_class"
                            @_emit_method_order_select="_changePortfolioSelected"
                         />
                </div>

                <div class="cartera-1">
                        <SelectOutlineWallet
                            id="cartera-element"
                            style="border-radius:20px;"
                            :label_name="select_type[0].label_name"
                            :options="this.instrument_type_options"
                            :helper_select_permission="select_type[0].helper_select_permission"
                            :helper_text_select="this.helper_text_select"
                            :default_value="select_type[0].default_value"
                            :component_class="select_type[0].component_class"
                            @_emit_method_order_select="_changeInstrumentSelected"
                         />
                </div>

                <div class="button-addwaitings-style">
                    <Button class="button-class" @_emit_method_button="_sendAction" :button_name="button_name" :button_key="button_key" />
                </div>
            </div>
        </div>
    </div>
</div>
    
</template>



<script>
import "vue-swatches/dist/vue-swatches.css";
import Button from "@/components/helpers/Button";
import APICaller from "@/components/codeComponents/APICaller.js";
import inputFavorites from './configs/favorites-workspace.json';
import inputEditFavorites from './configs/edit-favorites-workspace.json';
import { mapGetters } from "vuex";
import SelectOutlineWallet from "@/components/helpers/SelectOutline_wallet.vue";
import SelectWallet from "@/components/stockchart/components/configs/select_waiting.json";
import SelectType from "@/components/stockchart/components/configs/select_type_instrument.json";

export default {
    mixins: [APICaller],
    components: { 
        SelectOutlineWallet,
        Button
    },
    name: 'Workspaces',
    props: {
        stock: { type: Object },
        stocks: { type: Array },
        show_waiting: { type: Boolean},
    },

    data: () => ({
        input_favorites : inputFavorites,
        input_edit_favorites : inputEditFavorites,
        workspaces: [],
        isClosingShieldOn: false,
        isCreating: false,
        isDeleting: false,
        isEditing: false,
        createWorkspaceNewName: "",
        editWorkspaceNewName: "",
        currentlyDeleting: {},
        currentlyEditing: {},
        createWorkspaceColor: "#000000",
        editWorkspaceColor: "#000000",
        user_portfolios:[],
        select_items: SelectWallet,
        portfolio_selected:[],
        helper_text_select:"",

        instrument_type_options: [{"name": 'STOCK', "value": 'Stock'},{"name": 'CFD', "value": 'CFD'}],
        select_type: SelectType,

        button_name:"Enviar",
        button_key: "Send",
        active_button: true,

        portfolio: '',
        instrument: '',



    }),

    watch: {
        // Update workspace isFavorite property each time a new stock is selected
        stock: {
            handler() {
                this._updateViewStockFavoriteWorkspaces();
            },
            deep: true,
        },
    },

    computed: {
        ...mapGetters(['_g_positionFavoriteWorkspace']),
    },

    created() {
    },

    mounted() {
        
        setTimeout(function(){
            this._initWorkspaces();
            this._getPortfolios();
            this._defaultPortfolio();
        }.bind(this), 100);
        
    },

    methods: {

        /*
         *   Operations - Creation, edition, deletion, set/unset as favorite for stock
         */

        // #Creation
        // Gets the favorite workspaces from the API and stores them in this.workspaces
        _initWorkspaces: function() {
            let success = response => {
                for (let index = 0; index < response.data.folders.length; index++) {
                        this.workspaces.push({
                            name: response.data.folders[index][0],
                            color: response.data.folders[index][1],
                            isFavorite: false,
                        });
                    }
            };

            let url = '/api/v1/stock-finder/favorite-folder/list';
            
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('094', 'Error cargando lista de favoritos.');

            this._getAPICall(url, successHandler, failureHandler);
            
        },    
        // Terminates ongoing edition without saving if there's any, displays creation menu
        _createWorkspacePrepare: function() {
            this._editWorkspaceCancel();
            this.createWorkspaceColor = this._generateRandomHexColor();
            this.isCreating = true;
        },

        _updateViewStockFavoriteWorkspaces: function() {
            for(let workspace in this.workspaces){
                if( this.stock.folder_name.indexOf(this.workspaces[workspace].name) != -1) {
                    this.workspaces[workspace].isFavorite = true;
                }
                else {
                    this.workspaces[workspace].isFavorite = false;
                }
            }
        },

        _getPortfolios: function(){
            let success = (response) => {                
                response.data.forEach(element => {
                this.user_portfolios.push({"name": element.idc, "value": element.name});
            });
            };

            let url = '/api/v1/portfolio/list';

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('095', 'Error cargando lista de carteras.');

            this._getAPICall(url, successHandler, failureHandler);

        },


        _sendAction: function(){
            let params = new URLSearchParams();
            //params.append('stocks', JSON.stringify([this.stock]));
            params.append('instrument_type', this.instrument);
            params.append('ticker', this.stock.ticker);
            params.append('entry_type', this.stock.tipus_entrada);
            params.append('direction', this.stock.direccio);

            let url = '/api/v1/portfolio/waitings/add/' + this.portfolio;


            let success = () => {
                this.$vueOnToast.pop('success', "Ticker " + this.stock.ticker, "Enviado a correctamente!");
                this.$emit("waiting-added")
                this._manageExitClicksBehavior()
                if(this.stock.waitings_portfolio_name === undefined) {
                    this.stock.waitings_portfolio_name = [this.portfolio];
                }
                else {
                    this.stock.waitings_portfolio_name.push(this.portfolio);
                }
            };

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('280', 'Error agregando ticker a la cartera.');

            this._postAPICall(url, params, successHandler, failureHandler);

        },

        _defaultPortfolio: function(){
            let success = (response) => {               
                this.portfolio_selected.push({"name": response.data.idc, "value": response.data.name});
            };

            let url = '/api/v1/portfolio-invest-preferences';

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('096', 'Error cargando preferencias de inversión.');

            this._getAPICall(url, successHandler, failureHandler);
        },

        _changePortfolioSelected: function(value){            
            this.portfolio = value[0];

        },
        _changeInstrumentSelected: function(value){
            this.instrument = value[0];
        },

        /*_isInViewport: function(){
            var y = this.$refs.pico.getBoundingClientRect().top;
            return y <= window.innerHeight;
        },*/

       


        // Decides the behavior of a click on a closing triggering position
        // If there's an ongoing operation it will be cancelled
        // If there's not then the window will close
        _manageExitClicksBehavior: function() {
            let that = this;
            if(!this.isEditing && !this.isCreating && !this.isDeleting){
                setTimeout(function() {
                    if (!that.isClosingShieldOn) {
                        that.$emit('_closeWaitingValueWindow');
                    }
                    that.isClosingShieldOn = false;

                }, 10);
            } else {
                setTimeout(function() {
                    if(!that.isClosingShieldOn) {
                        that._cancelOngoingOperations();
                    }
                    that.isClosingShieldOn = false;
                },10);
            }

        },
        

        // Sets a flag necessary for window closing behaviour
        _turnOnClosingShield: function() {
            this.isClosingShieldOn = true;
        },


        /*
         *   Misc
         */

        //Generates a random color for the swatch
        _generateRandomHexColor: function() {
            let randomColor = Math.floor(Math.random()*16777215).toString(16);
            return "#" + randomColor;
        },
  },
}
</script>


<style lang="scss">
    
    header{
        width: 100%;
    }

    .workspace-name-container{
        text-align:center; 
        margin-top:15px;
    }

    .addwaitings-workspace-style{
        position: absolute;
        min-height: 400px;
        width: 320px;
        background: #F3F4F7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        top: 140px;
        left: 10%;
    }

    @media screen and(max-width: 350px){
        .addwaitings-workspace-style{
            width: 80%
        }

    }

    @media screen and(min-width: 351px) and(max-width: 391px){
        .addwaitings-workspace-style{
            width: 110%;
        }

    }

    @media screen and(min-width: 392px) and(max-width: 500px){
        .addwaitings-workspace-style{
            width: 120%;
        }

    }

    .user-workspaces {
        width: 300px;
        z-index: 999999;
        display: block !important;
        color: black;
        text-align: left;
        position: absolute;
        text-decoration: none;
        background-color: #f3f4f7;
        border-radius: 5px;       
    }

    .title-favorites{
        margin-top: 10px; 
        margin-left: 20px;
        font-size: 15px;
    }

    .add-button-container{
        text-align: center;
        position:relative;
    }

    .close {
        position: absolute;
        right: 4px;
        cursor:pointer;
    }

    #workspace-select-favorite-icon {
        display: inline-block;
    }
    .remove-text-container{
        text-align:center;
        font-size:13px;
        margin-top:50px;
    }
    .remove-star-container{
        text-align:center;
        margin-top:25px;
        margin-bottom:25px;
    }
    .remove-button-container input{
        width:140px;
        height:35px;
        color:white;
        font-weight:bold;
        background-color:#00aedd;
        border-radius:30px;
        border-color:transparent;
       
    }
    .cancel-button-container input{
        border:none;
        color:#2e81a5;
        font-weight:bold;
        margin-top:15px;

    }

    .remove-star-containter{
        text-align: center;
        margin-bottom: 20px;
    }

    .cartera-1{
        margin-top: 30px;
        width: 70%;
        margin-left: 47px;
    }

    .button-addwaitings-style{
        text-align:center;
    }

    .button-class{
        margin-top: 50px;
    }
</style>