<template>
<div>
  <div v-if="_g_IsUserLogged && !_g_User.is_only_authenticated_user" class="stocks-container" style="height: 410px;">
    <ul id="my-list" class="mdc-list" role="listbox" style="height: inherit;">
      <div v-if="_g_User.is_pre_eureker_user" class="pre-eurekers-stocks-info">Usted dispone de <br><span class="pre-eurekers-number">{{pre_eurekers_tries}}</span><br> consumibles para visualizar los valores.</div>
      <li class="mdc-list-item" :class="{'mdc-list-item--selected' : stock.ticker == selected_ticker.ticker}" role="option" v-for="(stock, index) in stocks" :key="index">
        <span class="mdc-list-item__ripple"></span>
        <span v-if="_g_User.is_pre_eureker_user && stock.nom == '---'" class="mdc-list-item__text typo-body-2" 
          @click="askPreEurekersTickerInfo(stock.ticker)">
          <span class="material-symbols-outlined">visibility</span>
          <div style="visibility:hidden; height:0;">{{stock.ticker}}</div>
        </span>
        <span class="mdc-list-item__text typo-body-2">{{ stock.ticker }}</span>
        <span v-if="stock.waitings_portfolio_name == undefined && !(_g_User.roles[21] || _g_User.roles[20])"
          @click="_sendPositionToWorkspace()" 
          data-action="addWaiting" 
          class="alarm push mdc-list-item__meta material-icons" 
          aria-hidden="true">more_time</span>
          <span v-if="stock.waitings_portfolio_name != undefined && !(_g_User.roles[21] || _g_User.roles[20])"
          style="color: black;"
          @click="_sendPositionToWorkspace()" 
          data-action="addWaiting" 
          class="alarm push mdc-list-item__meta material-icons" 
          aria-hidden="true">schedule</span>
        <span v-if="stock.folder_name.length == 0"
            :id="'icon'+index"
            @click="_sendPositionToWorkspace()"
            data-action="addFavorites" 
            class="favorite mdc-list-item__meta material-icons" 
            :class="{'active' : stock.ticker == 'D', 'star-margin' : _g_User.roles[21] || _g_User.roles[20]}"
            aria-hidden="true">star_border</span>
        <span v-if="stock.folder_name.length == 1 && workspaces_names.includes(stock.folder_name[0])"
            :style="{color: _getColorByWorkspace(stock.folder_name[0])}"
            :id="'icon'+index"
            @click="_sendPositionToWorkspace()"
            data-action="addFavorites" 
            class="favorite mdc-list-item__meta material-icons" 
            :class="{'active' : stock.ticker == 'D', 'star-margin' : _g_User.roles[21] || _g_User.roles[20]}"
            aria-hidden="true">star</span>
        <span v-if="stock.folder_name.length > 1"
            style="color: black"
            :id="'icon'+index"
            @click="_sendPositionToWorkspace()"
            data-action="addFavorites" 
            class="favorite mdc-list-item__meta material-icons" 
            :class="{'active' : stock.ticker == 'D', 'star-margin' : _g_User.roles[21] || _g_User.roles[20]}"
            aria-hidden="true">star</span>

        <!--<span data-action="addPortfolio" class="folder mdc-list-item__meta material-icons" aria-hidden="true">folder_open</span>
        <span data-action="addNotification" class="notifications mdc-list-item__meta material-icons" aria-hidden="true">notifications_none</span>-->
      </li>
    </ul>
    <div v-if="selected_ticker && selected_ticker!=''">
      <div>
          <WorkspacesMobile
              :stocks="stocks"
              :stock="selected_ticker"
              :show_workspace="show_workspace"
              @_closeFavoriteWorkspacesWindow="_closeFavoriteWorkspacesWindow">
          </WorkspacesMobile>

          <WaitingValuesMobile
              :stocks="stocks"
              :stock="selected_ticker"
              :show_waiting="show_waiting"
              @_closeWaitingValueWindow="_closeWaitingValueWindow">
          </WaitingValuesMobile>
      </div>
    </div>
  </div>
  <div v-else-if="_g_User.is_only_authenticated_user" class="stocks-container" style="height: 410px;">
    <ul id="my-list" class="mdc-list blured" role="listbox" style="height: inherit;">
      <li class="mdc-list-item" role="option" style="cursor: not-allowed" v-for="(stock, index) in stock_anonymous" :key="index">
        <span class="mdc-list-item" style="margin-left: -30px;"></span>
        <span class="mdc-list-item__text typo-body-2">{{ stock }}</span>
        <span class="alarm push mdc-list-item__meta material-icons" aria-hidden="true">schedule</span>
        <span
            class="favorite mdc-list-item__meta material-icons" 
            :class="{'active' : stock == 'D'}"
            aria-hidden="true">{{ iconFavorite(stock) }}</span>
      </li>
    </ul>
    <div style="position:absolute; top: 100px; text-align: center; padding: 40px; cursor: not-allowed">
      <p><strong>Su plan no tiene acceso al listado de tickers. Pulse aquí para <a class="link-to-store" href="/app/store">cambiar de plan</a>.</strong></p>
    </div>
  </div>
  <div v-else class="stocks-container" style="height: 410px;">
    <ul id="my-list" class="mdc-list blured" role="listbox" style="height: inherit;">
      <li class="mdc-list-item" role="option" style="cursor: not-allowed" v-for="(stock, index) in stock_anonymous" :key="index">
        <span class="mdc-list-item" style="margin-left: -30px;"></span>
        <span class="mdc-list-item__text typo-body-2">{{ stock }}</span>
        <span class="alarm push mdc-list-item__meta material-icons" aria-hidden="true">schedule</span>
        <span
            class="favorite mdc-list-item__meta material-icons" 
            :class="{'active' : stock == 'D'}"
            aria-hidden="true">{{ iconFavorite(stock) }}</span>
        <!--<span data-action="addPortfolio" class="folder mdc-list-item__meta material-icons" aria-hidden="true">folder_open</span>
        <span data-action="addNotification" class="notifications mdc-list-item__meta material-icons" aria-hidden="true">notifications_none</span>-->
      </li>
    </ul>

    <div style="position:absolute; top: 100px; text-align: center; padding: 40px; cursor: not-allowed">
      <p><strong>Si quieres acceder al listado de valores Eurekers inicia sesión.</strong></p>
    </div>
  </div>

</div>
</template>



<script>
import WorkspacesMobile from '@/components/stockchart/components/Workspaces_mobile.vue';
import APICaller from "@/components/codeComponents/APICaller.js";
import GetStocks from "@/components/codeComponents/GetStocks.js";
import WaitingValuesMobile from '@/components/stockchart/components/WaitingValuesMobile.vue';


import { mapMutations, mapGetters } from "vuex";

import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';

export default {
  mixins: [APICaller, GetStocks],
  components: {
      WorkspacesMobile,
      WaitingValuesMobile,
      
  },
  name: 'Stocks',
  data: () => ({
    stocks: [],
    aux: [],
    ordered_stocks: [],
    selected_ticker: '',
    show_workspace: false,
    show_waiting: false,
    order_by: false,
    order_class: true,
    order_type: 'Nombre A-Z',
    title_break_line: "\n",
    position: [],
    select_order_type: false,
    not_ordered: true,
    stock_anonymous:[],
    workspaces: [],
    workspaces_names: [],
    workspaces_colors: [],
    color: "",
    modeOfStocks:'',
    last_ticker_selected: '',
    pre_eurekers_tries: 0,
  }),
  mounted() {
    this._changeModeToStockMarket();    
    this.initMaterialComponent();

    var mode_waiting = setInterval(() => {
      if(this.modeOfStocks != ''){
        if(this._g_IsUserLogged){
          this.getStocks();
          clearInterval(mode_waiting); 
        }
      }
    }, 200);    
    
    this._createAnonymousStock();
    var url = window.location.pathname;
    this._initWorkspaces();

    var stock_waiting = setInterval(()=>{
      if(this.stocks && this.stocks.length != 0){
        this._cloneStockList();
        clearInterval(stock_waiting);
      }
    },200)
  },
  created: function() {
    window.addEventListener('keyup', (e) => {
      if (e.key == 'ArrowDown') {
        this._nextTicker();
      }else if(e.key == "ArrowUp"){
        this._previousTicker();
      }
    });
    this.initPreEurekersRemainingTickers();
  },
  watch:{ 
    _g_stocks: {
      handler: function(newVal, oldVal){
        this.stocks = newVal;
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters({stock_type:'_g_GetTypeStocks'}),
    ...mapGetters({_g_stocks:'_g_Stocks'}),
    ...mapGetters({filters:'_g_ShowStockFinderFilter'}),
    ...mapGetters(["_g_IsUserLogged", "_g_SelectedTicker", "_g_is_loading_stocks"]),
    ...mapGetters(["_g_UserFeatures"]),
    ...mapGetters(["_g_User"]),
    
    order_by_icon: function() {
      return (this.order_class) ? 'expand_more' : 'expand_less';
    },
    icon_sort: function() {
      return (this.order_by) ? 'sort' : 'sort';
    }
    
  },
  methods: {
    ...mapMutations(['setStockValue']),
    ...mapMutations(['setMenuSelectionOut']),

    _favorite_added: function(stock) {
      for (var i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i].ticker == stock.ticker) {
          stock.favorites = true;
          this.stocks[i] = stock;
        }
      }
    },

    _favorite_removed: function(stock) {
      for (var i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i].ticker == stock.ticker) {
          if (stock.folder_name.length == 1) {
            stock.favorites = false;
          }
          else {
            stock.favorites = true;
          }
          this.stocks[i] = stock;
        }
      }
    },

    _changeModeToStockMarket: function(){
        let success = (response) => {
          this.modeOfStocks = response;
        };

        let url = "/api/v1/stock-finder/change-search-mode/stock-market";

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('080', 'Error cambiando modo.');

        this._getAPICall(url, successHandler, failureHandler);

    },

    _previousTicker: function(){
      var index = 0;
      var flag = false;
      if(this.not_ordered){
        this.stocks.forEach(element =>{
          if(element.ticker == this._g_SelectedTicker.ticker){
            flag = true;
            return true;
          }
          if(!flag){
            index++;
          }
        });
        if(index != 0){
          if(this.stocks[index + 1] == this.last_ticker_selected){
            index++;     
          }
          this.last_ticker_selected = this.stocks[index - 1];
          this.setSelectedActiveTicker2(this.stocks[index - 1]);
        }
      }
      else{
        this.ordered_stocks.forEach(element =>{
          if(element.ticker == this._g_SelectedTicker.ticker){
            flag = true;
            return true;
          }
          if(!flag){
            index++;
          }
        });
        if(index != 0){
          if(this.ordered_stocks[index + 1] == this.last_ticker_selected){
            index++;     
          }
          this.last_ticker_selected = this.ordered_stocks[index - 1];
          this.setSelectedActiveTicker2(this.ordered_stocks[index - 1]);
        }
      }
    },

    _nextTicker: function(){
      var index = 0;
      var flag = false;
      if(this.not_ordered){
        this.stocks.forEach(element =>{
          if(element.ticker == this._g_SelectedTicker.ticker){
            flag = true;
            return true;
          }
          if(!flag){
            index++;
          }
        });
        if(index < this.stocks.length - 1){
          if(this.stocks[index + 1] == this.last_ticker_selected){
            index++;     
          }
          this.last_ticker_selected = this.stocks[index + 1];
          this.setSelectedActiveTicker2(this.stocks[index + 1]);
        }
      }
      else{
        this.ordered_stocks.forEach(element =>{
          if(element.ticker == this._g_SelectedTicker.ticker){
            flag = true;
            return true;
          }
          if(!flag){
            index++;
          }
        });
        if(index < this.stocks.length - 1){
          if(this.ordered_stocks[index + 1] == this.last_ticker_selected){
            index++;     
          }
          this.last_ticker_selected = this.ordered_stocks[index + 1];
          this.setSelectedActiveTicker2(this.ordered_stocks[index + 1]);
        }
      }
    },

    _createAnonymousStock: function(){
      for (var i = 0; i < 36; i++){
        let word = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 4);
        this.stock_anonymous.push(word.toUpperCase());
      }
    },
    counter_stocks() {
      if (this._g_is_loading_stocks == false) {
        return this.stocks.length;
      }
      else {
        return "...";
      }
    },

    initMaterialComponent() {
      const list = new MDCList(document.getElementById('my-list'));
      const adapter = list.getDefaultFoundation().adapter;
      this.counter = adapter.getListItemCount();

      // set properties
      list.vertical = true;
      list.wrapFocus = true;
      list.hasTypeahead = true;
      list.singleSelection = true;
      list.listElements.map((listItemEl) => new MDCRipple(listItemEl));
      if(this._g_IsUserLogged){
        list.listen('MDCList:action', (event) => {
          if(this.not_ordered){
            this.setSelectedActiveTicker2(this.stocks[event.detail.index]);
          }else{
            this.setSelectedActiveTicker2(this.ordered_stocks[event.detail.index]);
          }
        })
      }
      if(this._g_IsUserLogged){
        document.getElementById('my-list').addEventListener('click', (e) => {
            if (e.target.dataset.action != undefined) {
                this.addStockAction(e.target.dataset.action);

                if (e.target.dataset.action === 'addFavorites' && this.show_workspace == false) {
                  var posx = e.clientX;	
                  var posy = e.clientY;
                  this.show_workspace = true;
                  /*var position = function getPos(e) {	
                      for (var lx=0, ly=0;	
                        e.target != null;	
                        lx += e.target.offsetLeft, ly += e.target.offsetTop, e.target = e.target.offsetParent);	
                        return {x: lx,y: ly};	
                    }*/
                    /*this.position = {x:posx, y:posy};*/	
                    /*this.setPositionFavoriteWorkspace({name:"x", value:this.posx}, {name:"y", value:this.posy});*/	
                    this._sendPositionToWorkspace(posx, posy);
                }

                if (e.target.dataset.action === 'addWaiting' && this.show_waiting == false) {
                  var posx_waiting = e.clientX;	
                  var posy__waiting = e.clientY;
                  this.show_waiting = true;
                  /*var position = function getPos(e) {	
                      for (var lx=0, ly=0;	
                        e.target != null;	
                        lx += e.target.offsetLeft, ly += e.target.offsetTop, e.target = e.target.offsetParent);	
                        return {x: lx,y: ly};	
                    }*/
                    /*this.position = {x:posx, y:posy};*/	
                    /*this.setPositionFavoriteWorkspace({name:"x", value:this.posx}, {name:"y", value:this.posy});*/	
                    this._sendPositionToWaiting(posx_waiting, posy__waiting);
                }
            }
        }, false);

        // set keydown event
      //   document.getElementById('my-list').addEventListener('keydown', () => {
      //     list.listElements[adapter.getFocusedElementIndex()].click();
      //     let ticker = adapter.getPrimaryTextAtIndex(adapter.getFocusedElementIndex());

      //     this.setSelectedActiveTicker(ticker);
      //   }, false);
      }
    },
    _sendPositionToWorkspace(x, y){	
      let sendPositionToWorkspaceInterval = setInterval(() => {
        if (this.$refs.WorkspaceRef != undefined) {
          var posicionX = x;	
          var posicionY = y;	
          this.$refs.WorkspaceRef._updateWorkspacePosition(posicionX, posicionY);	
          clearInterval(sendPositionToWorkspaceInterval);
        }
      }, 120);
    },

    _sendPositionToWaiting(x, y){	
      let sendPositionToWaitingInterval = setInterval(() => {
        if (this.$refs.WorkspaceRef != undefined) {
          var posicionX = x;	
          var posicionY = y;	
          this.$refs.waiting._updateWorkspacePosition(posicionX, posicionY);	
          clearInterval(sendPositionToWaitingInterval);
        }
      }, 120);
    },

    addStockAction(action) {
        if (action === 'addFavorites') { this._manageEventFavorite();}
    },

    _manageEventFavorite() {
        // mostrar el componente que toca como en el graficador dinamico
    },

    ...mapMutations(['setSelectedTicker']),
    setSelectedActiveTicker(ticker) {
      if(this._g_SelectedTicker != {}){
        //hace bucle
        let flag = false;
        this.stocks.forEach(element => {
          if(element.ticker == this._g_SelectedTicker.ticker){
            flag = true;
            this.selected_ticker = this._g_SelectedTicker;
          }
        })
        if(!flag){
          this.setSelectedTicker(ticker);
          this.selected_ticker = ticker;
        }
      
      }else{
        this.setSelectedTicker(ticker);
        this.selected_ticker = ticker;
      }        
    },

    setSelectedActiveTicker2(ticker){
      this.setSelectedTicker(ticker);
      this.selected_ticker = ticker;
    },

    iconFavorite(stock){
      return (stock.favorites == true) ? 'star' : 'star_border';
    },

    _initWorkspaces: function() {
      if(!this._g_User.is_only_authenticated_user){
        let success = response => {
          if (response.data == [] || response.data.folders == undefined) {
            this.workspaces = [];
          }
          else {
            for (let index = 0; index < response.data.folders.length; index++) {
              this.workspaces.push({
                name: response.data.folders[index][0],
                color: response.data.folders[index][1],
                isFavorite: false,
              });
              this.workspaces_names.push(response.data.folders[index][0]);
              this.workspaces_colors.push(response.data.folders[index][1]);
            }
          }
        };
  
        let url = '/api/v1/stock-finder/favorite-folder/list';
              
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('081', 'Error cargando lista de favoritos.');
  
        this._getAPICall(url, successHandler, failureHandler);
      }
            
    },

    _getColorByWorkspace(workspace){
      var index = 0;
      if(this.workspaces_names.includes(workspace)){
        index = this.workspaces_names.indexOf(workspace);
      }
      return this.workspaces_colors[index];
    },

    iconSchedule(stock){
      return (stock.waitings_portfolio_name != undefined) ? 'schedule' : 'more_time';  
    },

    orderBy() {
      this.order_by = !this.order_by;
    },

    _cloneStockList: function(){
      this.ordered_stocks = [];
      for (let i = 0; i < this.stocks.length; i++) {
        this.ordered_stocks[i] = this.stocks[i];
      }
    },

    _closeFavoriteWorkspacesWindow() {
      this.show_workspace = false;
    },

    _closeWaitingValueWindow() {
      this.show_waiting = false;
    },

    _changeOrderType: function(){
      this.select_order_type = !this.select_order_type;
      this.order_class = !this.order_class;
    },

    _orderedStock: function(value){
        for(let i = 0; i < this.stocks.length; i++){
          this.ordered_stocks[i] = this.stocks[i];
        }
        switch(value){
          case "name_ASC":
            this.ordered_stocks.sort((a,b) => (a.ticker > b.ticker) ? 1 : -1)
            this.not_ordered = true;
            this.order_type = "Nombre A-Z"
            break;
          case "name_DESC":
            this.ordered_stocks.sort((a,b) => (a.ticker < b.ticker) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Nombre Z-A"
            break;
          case "mont_ASC":
            this.ordered_stocks.sort((a,b) => (a.amount > b.amount) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Montante ASC"
            break;
          case "mont_DESC":
            this.ordered_stocks.sort((a,b) => (a.amount < b.amount) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Montante DESC"
            break;
          case "rent_ASC": 
            this.ordered_stocks.sort((a,b) => (a.tae_lt > b.tae_lt) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Rentabilidad ASC"
            break;
          case "rent_DESC":
            this.ordered_stocks.sort((a,b) => (a.tae_lt < b.tae_lt) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Rentabilidad DESC"
            break;
          case "onda_ASC":
            this.ordered_stocks.sort((a,b) => (a.num_ona_actual > b.num_ona_actual) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Onda ASC"
            break;
          case "onda_DESC":
            this.ordered_stocks.sort((a,b) => (a.num_ona_actual < b.num_ona_actual) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Onda DESC"
            break;
          case "soporte_ASC":
            this.ordered_stocks.sort((a,b) => (a.data_soport > b.data_soport) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Fecha soporte ASC"
            break;
          case "soporte_DESC":
            this.ordered_stocks.sort((a,b) => (a.data_soport < b.data_soport) ? 1 : -1) 
            this.not_ordered = false;
            this.order_type = "Fecha soporte DESC"
            break;
          case "entry_ASC":
            this.ordered_stocks.sort((a,b) => (a.entry_distance > b.entry_distance) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Distancia entrada ASC"
            break;
          case "entry_DESC": 
            this.ordered_stocks.sort((a,b) => (a.entry_distance < b.entry_distance) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Distancia entrada DESC"
            break;
          case "inv_risk_ASC":
            this.ordered_stocks.sort((a,b) => (a.risk_reward > b.risk_reward) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Inversión/Riesgo ASC"
            break;
          case "inv_risk_DESC":
            this.ordered_stocks.sort((a,b) => (a.risk_reward < b.risk_reward) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Inversión/Riesgo DESC"
            break;
          case "divisa_ASC":
            this.ordered_stocks.sort((a,b) => (a.divisa > b.divisa) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Divisa ASC"
            break;
          case "divisa_DESC":
            this.ordered_stocks.sort((a,b) => (a.divisa < b.divisa) ? 1 : -1)
            this.not_ordered = false;
            this.order_type = "Divisa DESC"
            break;
        }
        this._changeOrderType();
    },

    askPreEurekersTickerInfo(id) {
      let params = new URLSearchParams();
      params.append('ticker_id', id);
      params.append('uid', this._g_User.uid);

      let success= response => {
        if (response.data == true) {
          this.getStocks();
          this.getPreEurekersRemainingTickers();
        }
        else {
          this.$vueOnToast.pop('error', 'No te quedan visualizaciones disponibles');
        }
      };

      let url = '/usuarios/add-requested-ticker';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('278', 'Error agregando ticker.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    initPreEurekersRemainingTickers() {
      let success = response => {
        this.pre_eurekers_tries = response.data;
      };
      
      let url = '/usuarios/get-remaining-ticker-requests';
            
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('084', 'Error cargando visualizaciones disponibles.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    getPreEurekersRemainingTickers() {
      let success = response => {
        this.pre_eurekers_tries = response.data;
      };
      
      let url = '/usuarios/get-remaining-ticker-requests';
            
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('085', 'Error cargando visualizaciones disponibles.');

      this._getAPICall(url, successHandler, failureHandler);
    },
  },
}
</script>


<style lang="scss" scoped>
@use "@material/list";

@include list.core-styles;
@include list.item-selected-text-color(black);

.mdc-list-item {
  @include list.item-primary-text-ink-color(#444);
  @include list.list-item-height(36px);
  border-bottom: 1px solid #e9e9e9;
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple[data-v-1d7efbc2]::before {
    background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, red));
}

li.mdc-list-item {display:flex;}
li span.mdc-list-item__meta.material-icons {margin:unset; margin-left: 4px}
li span.mdc-list-item__meta.material-icons.push {margin-left: auto;}

.mdc-list-item.mdc-list-item--selected::before {
  display:none;
}

.mdc-list-item--selected .typo-body-2{
  font-weight:bold;
}

li.mdc-list-item--selected .material-icons {
  &.alarm:hover {color:green}
  &.notifications:hover {color:orange;}
  &.favorite:hover {color:#00add8;}
  &.folder:hover{color: #89430C;}
}

li.mdc-list-item .material-icons {
  &.alarm.active {color:green}
  &.notifications.active {color:orange;}
  &.favorite.active {color:#00add8;}
  &.folder.active{color: #89430C;}
}

.favorite, .alarm {
  font-size: 22px;
}

.ekers-event-click {
  cursor:pointer;
}
.stocks-container{
  margin-left: 12px;
  background-color: var(--color-background-background);
}

.stocks-order-query{
  height: 30px;
  background: var(--color-background-outline);
  border: 1px solid rgba(15, 15, 26, 0.2);
}

.stocks-order-query:hover{
  cursor: pointer;

}

.order-text{
  margin: 10px 0 10px 7px;
}
.div-text-order:hover{
  background-color: #e5e5e5;
}
.div-text-order{
  padding: 10px 0 10px 7px;
}

.order-menu{
  position: absolute; 
  background: #FBFBFB;
  border: 1px solid #E0E1E6;
  width: 95.7%; 
  opacity: 1; 
  z-index:1; 
  box-shadow: 0 2px 8px rgb(0 0 0 / 33%);

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.4px;

  color: var(--color-primary-900);
}

.blured{
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: white;
}

.mdc-list-item__text{
  color: var(--color-primary-secondary);
}

.stocks-info-query{
  background-color: var(--color-primary-900);
}

.star-margin{
  margin-left: auto !important;
}

.link-to-store{
  color: #00add9;
}

.pre-eurekers-stocks-info{
  background-color: #000010FF;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 13px;
  font-weight: bold;
}

.pre-eurekers-number{
  color: #00add9 !important;
  font-size: 18px !important;
  font-weight: bold;
}
</style>