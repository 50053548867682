<template>
  <div id="stock-info" class="stock-info mobile-content">
    <div class="header-container">
      <div class="title-container">
          <span>Información del ticker</span>
      </div>
      <div class="close-btn-container">
          <span class="material-icons-outlined" @click="_closeComponent()">close</span>
      </div>
    </div>
    <div class="info-ticker-box">
      <p class="text-info-ticker">Ticker: <span class="text-bold">{{_g_SelectedTicker.ticker}}</span></p>
    </div>

    <div class="not-available-data-container">
      <p class="not-available-data typo-body-2"> Su plan actual no tiene acceso a determinados datos. </p>
      <a href="/app/store" class="link-to-store typo-body-2"> Pulse aquí para cambiar de plan. </a>
    </div>

    <br>

    <div class="card-info" id="card-info-id">
      <div class="card-info-box">
        <p class="text-info-grey">Divisa: <span class="text-info-dark text-bold">{{_g_SelectedTicker.divisa}}</span></p>
        
        <p class="text-info-grey">Valor de la divisa: <span class="text-info-dark">{{ _g_SelectedTicker.cotitzacio_divisa }}</span></p>

        <p class="text-info-grey">Índice: <span class="text-info-dark">{{ _g_SelectedTicker.index_valor }}</span></p>
      
        <p class="text-info-grey">Sector: <span class="text-info-dark">{{ _g_SelectedTicker.sector }}</span></p>
  
        <p class="text-info-grey">Industria: <span class="text-info-dark">{{ _g_SelectedTicker.industria }}</span></p>
    
        <p class="text-info-grey">País: <span class="text-info-dark">{{ _g_SelectedTicker.country }}</span></p>

        <hr />
      </div>
      <div class="card-info-box">
        <p class="text-info-grey">Valor: <span class="not-allowed-data">{{ _g_SelectedTicker.valor_actual }}</span></p>

        <p class="text-info-grey">Entrada: <span class="text-info-dark">{{ _g_SelectedTicker.real_entry }}</span></p>

        <p class="text-info-grey">Stop: <span class="text-info-dark">{{ _g_SelectedTicker.out_price }}</span></p>
        
        <p class="text-info-grey">Cantidad: <span class="text-info-dark">{{ _g_SelectedTicker.amount }}</span></p>
  
        <p class="text-info-grey">Distancia de entrada: <span class="text-info-dark">{{ _g_SelectedTicker.entry_distance }}</span></p>
      
        <p class="text-info-grey">Acciones: <span class="text-info-dark">{{ _g_SelectedTicker.instrument_number }}</span></p>

        <p class="text-info-grey">Resistencia: <span class="text-info-dark">{{ this._g_SelectedTicker.resistance }}</span></p>

        <hr />
      </div>
      <div class="card-info-box">
        <p class="text-info-grey">Tipo de entrada: <span class="text-info-dark">{{ _g_SelectedTicker.entry_type_name }}</span></p>
        
        <p class="text-info-grey">Número de onda: <span class="not-allowed-data">{{ _g_SelectedTicker.num_ona_actual }}</span></p>
        
        <p class="text-info-grey">Rentabilidad: <span class="not-allowed-data">{{ _g_SelectedTicker.tae_lt }}</span></p>
      
        <p class="text-info-grey">Soporte: <span class="text-info-dark">{{ _g_SelectedTicker.soport }}</span></p>
        
        <p class="text-info-grey">Fecha de soporte: <span class="not-allowed-data">{{ _g_SelectedTicker.data_soport }}</span></p>
      
        <p class="text-info-grey">1ª Fecha: <span class="not-allowed-data">{{ _g_SelectedTicker.fecha_primera_barra }}</span></p>
    
        <hr />
      </div>
      <div class="card-info-box">
        <p class="text-info-grey">Dividendos: <span class="text-info-dark">{{ _g_SelectedTicker.avg_dividends }}</span></p>
    
        <p class="text-info-grey">CFD: <span class="not-allowed-data">{{ _g_SelectedTicker.cfd_margin }}</span></p>
        
        <p class="text-info-grey">Margen CFD: <span class="not-allowed-data"> --- </span></p>
      
        <p class="text-info-grey">Fecha de análisis: <span class="not-allowed-data">{{ _g_SelectedTicker.data_analisi }}</span></p>
    
        <p class="text-info-grey">Inversión/Riesgo: <span class="not-allowed-data">{{ _g_SelectedTicker.risk_reward }}</span></p>

        <p class="text-info-grey">Compatibilidad: <span class="not-allowed-data">{{ _g_SelectedTicker.user_platinum_compatibility}}</span></p>
        
        <hr />
      </div>
      <div class="card-info-box">
        <p class="text-info-grey">Nombre de la empresa: <span class="text-info-dark">{{ _g_SelectedTicker.nom}}</span></p>
    
        <div v-if="this._g_SelectedTicker && this._g_SelectedTicker.description">
          <p class="text-info-grey">Descripción: 
            <span class="text-info-dark">{{ this._g_SelectedTicker.description.substring(0, this._g_SelectedTicker.description.indexOf('.')) }}</span>
            <span id="dots">...</span>
            <span id="remaining-text" class="text-info-dark">{{ this._g_SelectedTicker.description.substring(this._g_SelectedTicker.description.indexOf('.'), this._g_SelectedTicker.description.length) }}</span>
          </p>
          <div class="read-more-btn-container">
            <button id="btn_read_more" class="read-more-btn" @click="_read_more()">- Leer Más -</button>
          </div>
        </div>
        <hr />
      </div>
      <div class="card-info-box">
        <p class="text-is-fullscreen text-info-grey">Brain: <span class="not-allowed-data"> --- </span></p>
      </div>
    </div>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins:[APICaller],
  name: 'Info',
  components: {
  },
  data() {
    return {
      id_header: 'stock-info',
      title_header: 'Información del ticker',
      user_id: "",
      info_rol: "",
    };
  },
  mounted() {
   
  },
  created: function() {
    this._getUID();
    this._getUserInfo();
  },
  computed: {
      ...mapGetters(['_g_SelectedTicker', '_g_User']),
  },
  methods: {
    _dataFormat: function(value){
      value= value.split("-");
      let new_value = value[2] + "-" + value[1] + "-" + value[0];
      return new_value;
    },
    _dataFormat2: function(value){
      let year = value.substring(0,4);
      let month = value.substring(4,6);
      let day = value.substring(6,8);
      let new_value = day + "-" + month + "-" + year;
      return new_value;
    },

    _getUID: function(){
      this.user_id = this._g_User.uid;
    },

    _getUserInfo: function(){
      this.info_rol = this._g_User.user_features.platinum_score.info;
    },

    _closeComponent: function() {
      this.$emit("_closeComponentInfoMobile");
    },

    _read_more(){
      var dots = document.getElementById("dots");
      var moreText = document.getElementById("remaining-text");
      var btnText = document.getElementById("btn_read_more");

      if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "- Leer Más -";
        moreText.style.display = "none";
      } else {
        dots.style.display = "none";
        btnText.innerHTML = "- Leer Menos -";
        moreText.style.display = "inline";
      }

    },
  },
}
</script>


<style lang="scss" scoped>

  @import "@/styles/basics/globals.scss";
  
  
  p {
    margin: 0;
    padding: 0;
    line-height: 24px;
    color: #7D8294;
  }

  span {
    color: black;
  }

  a {
    text-decoration: none;
    color: #35add8;
  }

  hr {
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 22px 5%;
  }

  .info-ticker-box {
     width: 100%;
     display: flex;
     justify-content: center;
  }
  .text-info-ticker {
    text-align: center;
    background-color: #F3F4F7;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 24px;
    width: 130px;
    padding: 7px 20px;
    color: black;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-info-grey {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.25px;
    color: #7D8294;
  }

  .text-info-dark {
    color: #000000;
  }

  .card-info {
    margin: 0 35px;
    display: flex;
    flex-wrap: wrap;
  }

  .card-info-box {
    width: 100%;
  }
  
  .is-fullscreen {
    width: 30%;
    margin: 0 10%
  }

  #text-is-fullscreen {
    margin-bottom: 50px;
  }

  .mobile-content {
    margin-top: 15px;
    height: 460px;
    min-height: 420px;
    overflow-y: scroll;
  }

  .header-container{
    display: flex;
    justify-content: center;
    padding: 10px 0px 30px 0px !important;
  }

  .title-container{
    margin-left: 30px;
    font-size: 20px;
    font-weight: 600;
  }

  .close-btn-container{
    margin-left: auto;
    order: 2;
    margin-right: 10px;
  }

  .not-available-data-container{
    text-align: center;
  }

  .not-available-data{
    color: #FF9B00;
  }

  .not-allowed-data{
    color: #FF9B00;
  }

  .link-to-store{
    color: #35add8;
  }
</style>