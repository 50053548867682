<template>
  <div v-if="_g_IsUserLogged && !_g_User.is_only_authenticated_user" class="stocks-container">
    <div v-if="Object.keys(stock).length != 0" class="actions-container">
        <span @click="_showWaiting()" v-if="stock && stock.waitings_portfolio_name == undefined && !(_g_User.roles[21] || _g_User.roles[20])" class="material-icons-outlined add-to-portfolio">more_time</span>
        <span @click="_showWaiting()" v-if="stock && stock.waitings_portfolio_name != undefined && !(_g_User.roles[21] || _g_User.roles[20])" class="material-icons-outlined add-to-portfolio">schedule</span>
        <span @click="_showFavorites()" v-if="stock && stock.folder_name.length == 0" class="material-icons-outlined add-to-favorites">star_border</span>
        <span @click="_showFavorites()" v-if="stock && stock.folder_name.length == 1 && workspaces_names.includes(stock.folder_name[0])" class="material-icons-outlined add-to-favorites" :style="{color: _getColorByWorkspace(stock.folder_name[0])}">{{iconFavorite(stock)}}</span>
        <span @click="_showFavorites()" v-if="stock.folder_name.length > 1" class="material-icons-outlined add-to-favorites" style="color: black">{{iconFavorite(stock)}}</span>
    </div>

    <WorkspacesMobile v-if="this.info_loaded"
        @added_to_favorite="getStocks"
        :stock="this.stock"
        :stocks="this.stocks"
        :show_workspace="show_workspace"
        @_closeFavoriteWorkspacesWindow="_closeFavoriteWorkspacesWindow">
    </WorkspacesMobile>

    <WaitingValuesMobile v-if="this.info_loaded && !(_g_User.roles[21] || _g_User.roles[20])"
        :stock="this.stock"
        :stocks="this.stocks"
        :show_waiting="show_waiting"
        @_closeWaitingValueWindow="_closeWaitingValueWindow">
    </WaitingValuesMobile>
  </div>
  <div v-else-if="_g_User.is_only_authenticated_user" class="not-allowed-actions-mobile-container">
    <div>
      <span @click="_toastError()" class="material-icons-outlined">schedule</span>
    </div>
    <div>
      <span @click="_toastError()" class="material-icons-outlined">grade</span>
    </div>
  </div>
  <div v-else class="stocks-container" style="height: 410px;">
    <ul id="my-list" class="mdc-list blured" role="listbox" style="height: inherit;">
      <li class="mdc-list-item" role="option" style="cursor: not-allowed" v-for="(stock, index) in stock_anonymous" :key="index">
        <span class="mdc-list-item" style="margin-left: -30px;"></span>
        <span class="mdc-list-item__text typo-body-2">{{ stock }}</span>
        <span class="alarm push mdc-list-item__meta material-icons" aria-hidden="true">schedule</span>
        <span
            class="favorite mdc-list-item__meta material-icons" 
            :class="{'active' : stock == 'D'}"
            aria-hidden="true">{{ iconFavorite(stock) }}</span>
        <!--<span data-action="addPortfolio" class="folder mdc-list-item__meta material-icons" aria-hidden="true">folder_open</span>
        <span data-action="addNotification" class="notifications mdc-list-item__meta material-icons" aria-hidden="true">notifications_none</span>-->
      </li>
    </ul>

    <div style="position:absolute; top: 100px; text-align: center; padding: 40px; cursor: not-allowed">
      <p><strong>Si quieres acceder al listado de valores Eurekers inicia sesión.</strong></p>
    </div>
  </div>
</template>



<script>
import WorkspacesMobile from '@/components/stockchart/components/Workspaces_mobile.vue';
import APICaller from "@/components/codeComponents/APICaller.js";
import GetStocks from "@/components/codeComponents/GetStocks.js";
import WaitingValuesMobile from '@/components/stockchart/components/WaitingValuesMobile.vue';


import { mapMutations, mapGetters } from "vuex";

import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';

export default {
  mixins: [APICaller, GetStocks],
  components: {
      WorkspacesMobile,
      WaitingValuesMobile,
      
  },
  name: 'Actions',
  data: () => ({
    show_workspace: false,
    show_waiting: false,
    workspaces_names: [],
    workspaces: [],
    workspaces_colors: [],
    stock: '',
    stocks: '',
    info_loaded: false,

  }),
  mounted() {
    var waiting_for_stock = setInterval(()=>{
    if(this._g_SelectedTicker.ticker != undefined){    
        this.info_loaded = true;
        this._initWorkspaces();
        clearInterval(waiting_for_stock);
    }
    }, 20); 
    
  },
  created: function() {
  },
  watch:{
    _g_selectedticker: {
      handler: function(newVal, oldVal){
        this.stock = newVal;
      },
      deep: true
    },

    _g_stocks: {
      handler: function(newVal, oldVal){
        this.stocks = newVal;
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters(["_g_IsUserLogged"]),
    ...mapGetters(["_g_User"]),
    ...mapGetters({_g_selectedticker: "_g_SelectedTicker"}),
    ...mapGetters({_g_stocks: "_g_Stocks"}),
  },
  methods: {
    _closeFavoriteWorkspacesWindow() {
      this.show_workspace = false;
    },

    _closeWaitingValueWindow() {
      this.show_waiting = false;
    },

    _showFavorites: function(){
        this.show_workspace = true;
    },

    _showWaiting: function(){
        this.show_waiting = true;
    },

    iconFavorite(stock){
      return (stock.favorites == true) ? 'star' : 'star_border';
    },

    _initWorkspaces: function() {
      if(!this._g_User.is_only_authenticated_user){
        let success = response => {
          for (let index = 0; index < response.data.folders.length; index++) {
            this.workspaces.push({
              name: response.data.folders[index][0],
              color: response.data.folders[index][1],
              isFavorite: false,
            });
            this.workspaces_names.push(response.data.folders[index][0]);
            this.workspaces_colors.push(response.data.folders[index][1]);
          }
        };
  
        let url = '/api/v1/stock-finder/favorite-folder/list';
              
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('027', 'Error cargando la lista de favoritos.');
  
        this._getAPICall(url, successHandler, failureHandler);
      }
    },

    _getColorByWorkspace(workspace){
      var index = 0;
      if(this.workspaces_names.includes(workspace)){
        index = this.workspaces_names.indexOf(workspace);
      }
      return this.workspaces_colors[index];
    },

    _toastError(){
      let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
        window.location.replace("/app/store");
      }};
      this.$vueOnToast.pop(toast);
    }
  }
}
</script>


<style lang="scss" scoped>
@use "@material/list";

@include list.core-styles;
@include list.item-selected-text-color(black);

.mdc-list-item {
  @include list.item-primary-text-ink-color(#444);
  @include list.list-item-height(36px);
  border-bottom: 1px solid #e9e9e9;
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple[data-v-1d7efbc2]::before {
    background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, red));
}

li.mdc-list-item {display:flex;}
li span.mdc-list-item__meta.material-icons {margin:unset; margin-left: 4px}
li span.mdc-list-item__meta.material-icons.push {margin-left: auto;}

.mdc-list-item.mdc-list-item--selected::before {
  display:none;
}

li.mdc-list-item--selected .material-icons {
  &.alarm:hover {color:green}
  &.notifications:hover {color:orange;}
  &.favorite:hover {color:#00add8;}
  &.folder:hover{color: #89430C;}
}

li.mdc-list-item .material-icons {
  &.alarm.active {color:green}
  &.notifications.active {color:orange;}
  &.favorite.active {color:#00add8;}
  &.folder.active{color: #89430C;}
}

.favorite, .alarm {
  font-size: 22px;
}

.ekers-event-click {
  cursor:pointer;
}
.stocks-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: #f3f4f7;
  /*background-color: var(--color-background-background);*/
}

.stocks-order-query{
  height: 30px;
  background: var(--color-background-outline);
  border: 1px solid rgba(15, 15, 26, 0.2);
}

.stocks-order-query:hover{
  cursor: pointer;

}

.order-text{
  margin: 10px 0 10px 7px;
}
.div-text-order:hover{
  background-color: #e5e5e5;
}
.div-text-order{
  padding: 10px 0 10px 7px;
}

.order-menu{
  position: absolute; 
  background: #FBFBFB;
  border: 1px solid #E0E1E6;
  width: 95.7%; 
  opacity: 1; 
  z-index:1; 
  box-shadow: 0 2px 8px rgb(0 0 0 / 33%);

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.4px;

  color: var(--color-primary-900);
}

.blured{
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: white;
}

.mdc-list-item__text{
  color: var(--color-primary-secondary);
}

.stocks-info-query{
  background-color: var(--color-primary-900);
}
.actions-container{
    display: flex;
    //justify-content: space-between;
    //background-color: var(--color-background-surface);
    height: 100%;
    color: var(--color-custom-typo-stocklist);
}

.add-to-portfolio{
    margin-left:20%; 
    margin-top: 7px;
}

.add-to-favorites{
    margin-right:20%; 
    margin-top: 7px;
}

.not-allowed-actions-mobile-container{
  height: 100%;
  width: 100%;
  background-color: #f3f4f7;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>