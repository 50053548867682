<template>
  <div id="fundamental-mobile" class="simulator-style mobile-content">
      <div class="header-container">
        <div class="title-container">
            <span>Análisis fundamental</span>
        </div>
        <div class="close-btn-container">
            <span class="material-icons-outlined" @click="_closeComponentFundamentalMobile()">close</span>
        </div>
      </div>
      <div id="fundamental" v-if="_g_UserFeatures.permissions.fundamental_analisis">
        
        <div class="info-ticker-box">
          <p class="text-info-ticker">Ticker: <span class="text-bold">{{ getFormattedSelectedTickerProperty(["ticker"]) }}</span></p>
        </div>
        <div class="info-fundamental-container">
          <hr v-if="fundamental_info.SharesOutstanding || fundamental_info.SharesFloat || fundamental_info.PercentInsiders || fundamental_info.PercentInstitutions">
            <p v-if="fundamental_info.SharesOutstanding || fundamental_info.SharesFloat || fundamental_info.PercentInsiders || fundamental_info.PercentInstitutions" class="text-info-title">Estado de las acciones</p>
            <p v-if="fundamental_info.SharesOutstanding" class="text-info-grey">Acciones en circulación: <span class="text-info-dark">{{ fundamental_info.SharesOutstanding }}</span></p>
            <p v-if="fundamental_info.SharesFloat" class="text-info-grey">Acciones activas: <span class="text-info-dark">{{ fundamental_info.SharesFloat }}</span></p>
            <p v-if="fundamental_info.PercentInsiders" class="text-info-grey">% de acciones propias: <span class="text-info-dark">{{  fundamental_info.PercentInsiders }}</span></p>
            <p v-if="fundamental_info.PercentInstitutions" class="text-info-grey">% de acciones en instituciones: <span class="text-info-dark">{{   fundamental_info.PercentInstitutions }}</span></p>
          <hr v-if="fundamental_info.BookValue || fundamental_info.PEGRatio || fundamental_info.PERatio">
            <p v-if="fundamental_info.BookValue || fundamental_info.PEGRatio || fundamental_info.PERatio" class="text-info-title">Highlights</p>
            <p v-if="fundamental_info.BookValue" class="text-info-grey">BookValue: <span class="text-info-dark">{{ fundamental_info.BookValue }}</span></p>
            <p v-if="fundamental_info.PEGRatio" class="text-info-grey">PEGRatio: <span class="text-info-dark">{{  fundamental_info.PEGRatio }}</span></p>
            <p v-if="fundamental_info.PERatio" class="text-info-grey">PERatio: <span class="text-info-dark">{{   fundamental_info.PERatio }}</span></p>
          <hr v-if="fundamental_info.EnterpriseValue || fundamental_info.EnterpriseValueEbitda || fundamental_info.EnterpriseValueRevenue">
            <p v-if="fundamental_info.EnterpriseValue || fundamental_info.EnterpriseValueEbitda || fundamental_info.EnterpriseValueRevenue" class="text-info-title">Valuation</p>
            <p v-if="fundamental_info.EnterpriseValue" class="text-info-grey">EnterpriseValue: <span class="text-info-dark">{{ fundamental_info.EnterpriseValue }}</span></p>
            <p v-if="fundamental_info.EnterpriseValueEbitda" class="text-info-grey">EnterpriseValueEbitda: <span class="text-info-dark">{{ fundamental_info.EnterpriseValueEbitda }}</span></p>
            <p v-if="fundamental_info.EnterpriseValueRevenue" class="text-info-grey">EnterpriseValueRevenue: <span class="text-info-dark">{{ fundamental_info.EnterpriseValueRevenue }}</span></p>
          <div v-if="user_is_developer_or_jota">
            <hr>
            <p class="text-info-title">Número de empleados</p>
            <div v-if="cargando_numero_empleados">
              <img id="overlay-image" src="@/assets/img/cargandoB.gif">
            </div>
            <div v-else>
              <div v-if="no_employees_info">
                <p>No tenemos informacion de este ticker</p>
              </div>
              <div class="balance-highchart" v-else>
                <FundamentalChart :series="employees_data" :chart_id="employees_highchart" :title="employees_title" ref="FundamentalChartEmployees" />
              </div>
            </div>
          </div>
          <hr>
            <p class="text-info-title">Financials</p>
            <p class="text-info-grey">Balance: <span class="text-info-dark">{{ balance_currency }}</span></p>
            <div class="balance-highchart">
              <FundamentalChart :series="balance_data" :chart_id="balance_highchart" :title="balance_title" ref="FundamentalChartBalance" />
            </div>
            <p class="text-info-grey">Cash Flow: <span class="text-info-dark">{{ cash_flow_currency }}</span></p>
            <div class="cash-flow-highchart">
              <FundamentalChart :series="cash_flow_data" :chart_id="cash_flow_highchart" :title="cash_flow_title" ref="FundamentalChartCashFlow" />
            </div>
            <p class="text-info-grey">Resultados de ingresos: <span class="text-info-dark">{{ income_statement_currency }}</span></p>
            <div class="income-statement-highchart">
              <FundamentalChart :series="income_statement_data" :chart_id="income_statement_highchart" :title="income_statement_title" ref="FundamentalChartIncomeStatement" />
            </div>
          <br><hr>
            <p class="text-info-grey">Ganancias por acción</p>
            <div class="earnings-highchart">
              <FundamentalChart :series="earnings_data" :chart_id="earnings_highchart" :title="earnings_title" ref="FundamentalChartEarnings" />
            </div>
            <p class="text-info-grey">Acciones en mercado</p>
            <div class="outstanding-shares-highchart">
              <FundamentalChart :series="outstanding_shares_data" :chart_id="outstanding_shares_highchart" :title="outstanding_shares_title" ref="FundamentalChartOutstandingShares" />
            </div>
            <br><hr>
            <p class="text-info-grey">Holders</p>
            <div>
              <TableCustom :table_header="table_header" :table_body="table_body"/> 
            </div>
          <br><hr>
            <p class="text-info-grey">Ejecutivos</p>
            <div>
              <TableCustom :table_header="table_header_officers" :table_body="table_body_officers"/> 
            </div>
          <br>
          <hr>
          <br>
        </div>
      </div>
      <div v-else>
        <div class="info-ticker-box">
          <p class="text-info-ticker">Ticker: <span class="text-bold">{{ getFormattedSelectedTickerProperty(["ticker"]) }}</span></p>
        </div>
        <div class="not-available-data-container">
          <p class="not-available-data typo-body-2"> Su plan actual no tiene acceso a esta herramienta. </p>
          <a href="/app/store" class="link-to-store typo-body-2"> Pulse aquí para cambiar de plan. </a>
        </div>
      </div>
    </div>
</template>


<script>
import Vue from 'vue';
import { mapGetters } from "vuex";
import FundamentalChart from './Fundamentals/FundamentalChart.vue';
import APICaller from "@/components/codeComponents/APICaller.js";
import Highcharts from "highcharts";
import TableCustom from "@/components/helpers/Table_custom.vue";

export default {
  mixins: [APICaller],
  name: 'FundamentalMobile',
  components:{
    FundamentalChart,
    TableCustom
  },
  data: function() {
    return {
      last_stock_loaded : "",
      id_header: 'fundamental-header',
      title_header: 'Análisis fundamental',
      balance_highchart: "highchart-content-balance",
      balance_data: [],
      balance_title: "Balance",
      balance_currency: "",
      employees_highchart: "highchart-content-employees",
      employees_data: [],
      employees_title: "Empleados",
      cash_flow_highchart: "highchart-content-cash-flow",
      cash_flow_data: [],
      cash_flow_title: "Cash Flow",
      cash_flow_currency: "",
      income_statement_highchart: "highchart-content-income-statement",
      income_statement_data: [],
      income_statement_title: "Income Statement",
      income_statement_currency: "",
      earnings_highchart: "highchart-content-earnings",
      earnings_data: [],
      earnings_title: "Beneficios por acción",
      outstanding_shares_highchart: "highchart-content-outstanding-shares",
      outstanding_shares_data: [],
      outstanding_shares_title: "Acciones en el mercado",
      is_shown: "",
      fundamental_info: {
        SharesOutstanding: "",
        SharesFloat: "",
        PercentInsiders: "",
        PercentInstitutions: "",
        BookValue: "",
        PEGRatio: "",
        PERatio: "",
        EnterpriseValue: "",
        EnterpriseValueEbitda: "",
        EnterpriseValueRevenue: "",
      },
      table_header: ['Instituciones','Acciones actuales'],
      table_body: [],

      table_header_officers: ['Nombre','Cargo'],
      table_body_officers: [],
      ESGScores: {
        TotalESG: "",
        EnvironmentScore: "",
        SocialScore: "",
        GovernanceScore: "",
        ControversyLevel: "",
      },
      ESGexist: false,
      url: "",
      cargando_numero_empleados: true,
      no_employees_info: false,
      user_is_developer_or_jota: false,
    }
  },
  watch: {
    selected_stock: {
      handler: function(newVal, oldVal) {
        if(this._g_UserFeatures.permissions.fundamental_analisis){
          this._getFundamentalInfo();
        }
      },
      deep: true,
    },
    _g_open_fundamental: {
      handler: function(newVal, oldVal) {
        if(this._g_UserFeatures.permissions.fundamental_analisis){
          this._getFundamentalInfo();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if(this._g_UserFeatures.permissions.fundamental_analisis){
      this._getFundamentalInfo();     
    }
  },
  created: function() {
  },
  computed: {
    ...mapGetters({selected_stock : "_g_SelectedTicker"}),
    ...mapGetters(["_g_open_fundamental", "_g_User"]),
    ...mapGetters(["_g_UserFeatures"]),
  },
  methods: {
    _initHighchartsEmployees: function () {
      var intervalInitHighChart = setInterval(() => {
        if (this.$refs.FundamentalChartEmployees != undefined) {
          this.$refs.FundamentalChartEmployees._initHighcharts();
          clearInterval(intervalInitHighChart);
        }
      }, 230);
    },
    _initHighchartsBalance: function () {
      var intervalInitHighChart = setInterval(() => {
        if (this.$refs.FundamentalChartBalance != undefined) {
          this.$refs.FundamentalChartBalance._initHighcharts();
          clearInterval(intervalInitHighChart);
        }
      }, 230);
    },
    _initHighchartsCashFlow: function () {
      var intervalInitHighChart = setInterval(() => {
        if (this.$refs.FundamentalChartBalance != undefined) {
          this.$refs.FundamentalChartCashFlow._initHighcharts();
          clearInterval(intervalInitHighChart);
        }
      }, 230);
    },
    _initHighchartsIncomeStatement: function () {
      var intervalInitHighChart = setInterval(() => {
        if (this.$refs.FundamentalChartBalance != undefined) {
          this.$refs.FundamentalChartIncomeStatement._initHighcharts();
          clearInterval(intervalInitHighChart);
        }
      }, 230);
    },
    _initHighchartsEarnings: function () {
      var intervalInitHighChart = setInterval(() => {
        if (this.$refs.FundamentalChartEarnings != undefined) {
          this.$refs.FundamentalChartEarnings._initHighcharts();
          clearInterval(intervalInitHighChart);
        }
      }, 230);
    },
    _initHighchartsOutstandingShares: function () {
      var intervalInitHighChart = setInterval(() => {
        if (this.$refs.FundamentalChartOutstandingShares != undefined) {
          this.$refs.FundamentalChartOutstandingShares._initHighcharts();
          clearInterval(intervalInitHighChart);
        }
      }, 230);
    },
    _initHighcharts: function () {
      this._initHighchartsBalance();
      this._initHighchartsCashFlow();
      this._initHighchartsIncomeStatement();
      this._initHighchartsEarnings();
      this._initHighchartsOutstandingShares();
      this._initHighchartsEmployees();
    },
    _getDataFromScrapper: function(){
      this.cargando_numero_empleados = true
      this.employees_data = [] 
      let success = (response) => {
        this.cargando_numero_empleados = false   
        if(response.data.length == 1 && response.data[0].employees == "0"){
          this.no_employees_info = true
        }else{
          this.no_employees_info = false
          let data = response.data
          data.forEach((element) =>{
            this.employees_data.push([parseFloat(element.year), parseFloat(element.employees)])
          })
          this._initHighchartsEmployees();
        }
      };

      let url = '/api/v1/stocks/scrap_employees/' + this.selected_stock.ticker;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('313', 'Error recuperando los datos de empleados.');
      const apiCallConfig = {loadingOverlay: false, timeoutMs: 0};

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },
    _addDataToHighchartBalance: function(data_feed){
      this.balance_data = [];
      Object.values(data_feed.Financials.Balance_Sheet.yearly).forEach(currentValue => {
        var newElement = [currentValue.date, parseFloat(currentValue.cash)];
        this.balance_data.push(newElement);
      });
      this.balance_data = this.balance_data.reverse();

      this._initHighchartsBalance();
    },
    _addDataToHighchartCashFlow: function(data_feed){
      this.cash_flow_data = [];
      Object.values(data_feed.Financials.Cash_Flow.yearly).forEach(currentValue => {
        var newElement = [currentValue.date, parseFloat(currentValue.endPeriodCashFlow)];
        this.cash_flow_data.push(newElement);
      });
      this.cash_flow_data = this.cash_flow_data.reverse();

      this._initHighchartsCashFlow();
    },
    _addDataToHighchartIncomeStatement: function(data_feed){
      this.income_statement_data = [];
      Object.values(data_feed.Financials.Income_Statement.yearly).forEach(currentValue => {
        var newElement = [currentValue.date, parseFloat(currentValue.totalRevenue)];
        this.income_statement_data.push(newElement);
      });
      this.income_statement_data = this.income_statement_data.reverse();

      this._initHighchartsIncomeStatement();
    },
    _addDataToHighchartEarnings: function(data_feed){
      this.earnings_data = [];
      Object.values(data_feed.Earnings.Annual).forEach(currentValue => {
        var newElement = [currentValue.date, parseFloat(currentValue.epsActual)];
        this.earnings_data.push(newElement);
      });
      this.earnings_data = this.earnings_data.reverse();

      this._initHighchartsEarnings();
    },
    _addDataToHighchartOutstandingShares: function(data_feed){
      this.outstanding_shares_data = [];
      Object.values(data_feed.outstandingShares.annual).forEach(currentValue => {
        var newElement = [currentValue.dateFormatted, parseFloat(currentValue.shares)];
        this.outstanding_shares_data.push(newElement);
      });
      this.outstanding_shares_data = this.outstanding_shares_data.reverse();

      this._initHighchartsOutstandingShares();
    },
    _addHoldersToTable: function(holders){
      this.table_body = [];
      for(var i=0; i < holders.length; i++){
        var name = holders[i].name;
        var acciones = holders[i].currentShares;
        this.table_body.push({'Instituciones' : name.toString(), 'Acciones actuales' : acciones.toString()});
      } 
    },
    _addOfficers: function(officers){
      this.table_body_officers = [];
      for(var i=0; i < officers.length; i++){
        var name = officers[i].Name;
        var cargo = officers[i].Title;
        this.table_body_officers.push({'Nombre' : name.toString(), 'Cargo' : cargo.toString()});
      } 

    },
    _getFundamentalInfo : function() {
      this.user_is_developer_or_jota = this._g_User.is_developer_user || this._g_User.uid == "15";
      var tickerName = this.selected_stock.ticker;
      if (this.last_stock_loaded != tickerName) {
        this.last_stock_loaded = tickerName;
        let success = (response) => {   
          this.fundamental_info.SharesOutstanding = response.data.SharesStats.SharesOutstanding;
          this.fundamental_info.SharesFloat = response.data.SharesStats.SharesFloat;
          this.fundamental_info.PercentInsiders = response.data.SharesStats.PercentInsiders.toFixed(2);
          this.fundamental_info.PercentInstitutions = response.data.SharesStats.PercentInstitutions.toFixed(2);            
          this.fundamental_info.BookValue = response.data.Highlights.BookValue;
          this.fundamental_info.PEGRatio = response.data.Highlights.PEGRatio;
          this.fundamental_info.PERatio = response.data.Highlights.PERatio;
          this.fundamental_info.EnterpriseValue = response.data.Valuation.EnterpriseValue;
          this.fundamental_info.EnterpriseValueEbitda = response.data.Valuation.EnterpriseValueEbitda;
          this.fundamental_info.EnterpriseValueRevenue = response.data.Valuation.EnterpriseValueRevenue;
          this.balance_currency = response.data.Financials.Balance_Sheet.currency_symbol;
          this.cash_flow_currency = response.data.Financials.Cash_Flow.currency_symbol;
          this.income_statement_currency = response.data.Financials.Income_Statement.currency_symbol;

          this._addHoldersToTable(response.data.Holders.Institutions);
          this._addOfficers(response.data.General.Officers);
          
          this._addDataToHighchartBalance(response.data);
          this._addDataToHighchartCashFlow(response.data);
          this._addDataToHighchartIncomeStatement(response.data);
          this._addDataToHighchartEarnings(response.data);
          this._addDataToHighchartOutstandingShares(response.data);
          this._getDataFromScrapper();
        };
        let url = '/api/v1/stocks/fundamental/' + tickerName;

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('049', 'Error al obtener análisis fundamental.');

        this._getAPICall(url, successHandler, failureHandler);
      }
    }, 
    getFormattedSelectedTickerProperty(prop_array) {
      const selected_ticker = this.selected_stock;

      let goal = "";
      prop_array.forEach(prop => {
        goal = selected_ticker[prop];
        if(goal == undefined) {
          return "";
        }
      });

      try {
        const formattedGoal = goal.toFixed(2);
        return formattedGoal;
      }
      catch(e) {
        return goal;
      }
    },

    _closeComponentFundamentalMobile() {
      this.$emit("_closeComponentFundamentalMobile");
    }
  },
}
</script>


<style lang="scss" scoped>
  @import "@/styles/basics/globals.scss";

  .info-ticker-box {
     width: 100%;
     display: flex;
     justify-content: center;
  }

  .mobile-content {
    margin-top: 15px;
    height: 460px;
    min-height: 420px;
    overflow-y: scroll;
  }

  .text-info-ticker {
    text-align: center;
    background-color: #F3F4F7;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 24px;
    margin-top: 0px;
    width: 130px;
    padding: 7px 20px;
    color: black;
  }

  .text-bold {
    font-weight: bold;
  }

  .info-fundamental-container {
    padding: 20px;
  }

  .text-info-grey {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.25px;
    color: #7D8294;
  }

  .text-info-dark {
    color: #000000;
  }
  .text-info-title {
    font-weight: bold;
  }
  #highchart-content-balance,
  #highchart-content-cash-flow,
  #highchart-content-income-statement,
  #highchart-content-earnings,
  #highchart-content-outstanding-shares {
    height: 250px;
    width: 100%;
    border: 1px solid black;
  }
  .highcharts-container {
    width: 100% !important;
  }
  .highcharts-root {
    width: 100% !important;
  }

  .header-container{
    display: flex;
    justify-content: center;
    padding: 10px 0px 30px 0px !important;
  }

  .title-container{
    margin-left: 30px;
    font-size: 20px;
    font-weight: 600;
  }

  .close-btn-container{
    margin-left: auto;
    order: 2;
    margin-right: 10px;
  }

  .not-available-data-container{
    text-align: center;
  }

  .not-available-data{
    color: #FF9B00;
  }

  .link-to-store{
    color: #35add8;
  }
</style>