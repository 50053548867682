<template>
  <div :id="chart_id"></div>
</template>


<script>
import Vue from 'vue';
import { mapGetters } from "vuex";
import Highcharts from "highcharts";

export default {
  name: 'FundamentalChart',
  components:{
  },
  props: {
    series: {},
    title: {},
    chart_id: {},
  },
  data: () => ({
    $_chart: null,
    $_resizeObserver: null
  }),
  mounted() {
    this._initHighcharts();
    
    // Force redraw after the component is fully rendered
    this.$nextTick(() => {
      // Delayed redraw to ensure container is properly sized
      setTimeout(() => {
        if (this.$_chart && typeof this.$_chart.reflow === 'function') {
          this.$_chart.reflow();
        }
      }, 100);
    });
  },
  beforeDestroy() {
    // Clean up resize observer to prevent memory leaks
    if (this.$_resizeObserver) {
      this.$_resizeObserver.disconnect();
    }
  },
  created: function() {
  },
  computed: {
  },
  methods: {
    _initHighcharts: function () {
      let self = this;
      Vue.nextTick(function() {
        // Store chart reference for later use
        self.$_chart = Highcharts.chart(self.chart_id, {
          chart: {
            type: 'line',
            zoomType: 'x',
            backgroundColor: 'transparent',
            reflow: true,
            spacingTop: 20,
            spacingBottom: 20,
            spacingLeft: 10,
            spacingRight: 20,
            events: {
              // Ensure chart remains responsive
              load: function() {
                this.reflow();
              }
            }
          },
          title: {
            text: '',
          },
          xAxis: {
            visible: false,
            gridLineWidth: 0,
            lineWidth: 0,
            minPadding: 0.05,
            maxPadding: 0.05,
          },
          yAxis: {
            title: '',
            type: 'logarithmic',
            gridLineColor: 'rgba(0, 0, 0, 0.08)',
            gridLineWidth: 1,
            lineWidth: 0,
            tickWidth: 0,
            endOnTick: false,
            minPadding: 0.1,
            maxPadding: 0.1,
            labels: {
              style: {
                color: 'var(--color-text-secondary, #606577)'
              }
            }
          },
          tooltip: {
            shared: true,
            crosshairs: {
              width: 1,
              color: 'var(--color-outline, rgba(0, 0, 0, 0.2))',
              dashStyle: 'shortdot'
            },
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            borderColor: 'var(--color-outline, rgba(0, 0, 0, 0.12))',
            borderWidth: 1,
            borderRadius: 5,
            shadow: false
          },
          plotOptions: {
            line: {
              lineWidth: 2,
              states: {
                hover: {
                  lineWidth: 3
                }
              },
              marker: {
                enabled: false,
                radius: 3,
                states: {
                  hover: {
                    enabled: true,
                    radius: 4
                  }
                }
              }
            },
            series: {
              animation: {
                duration: 300
              },
              clip: false
            }
          },
          series: [{
            name: self.title,
            data: self.series,
            color: 'var(--primary, #00aedd)'
          }],
          colors: ['var(--primary, #00aedd)', 'var(--color-text-primary, #0F0F1A)'],
          credits: {
            enabled: false
          },
          legend: {
            enabled: false
          },
          responsive: {
            rules: [{
              condition: { maxWidth: 500 },
              chartOptions: {
                yAxis: {
                  labels: {
                    style: {
                      fontSize: '10px'
                    }
                  }
                }
              }
            }]
          }
        });
        
        // Force a reflow on window resize to ensure chart is responsive
        const resizeObserver = new ResizeObserver(() => {
          if (self.$_chart && typeof self.$_chart.reflow === 'function') {
            self.$_chart.reflow();
          }
        });
        
        const chartElement = document.getElementById(self.chart_id);
        if (chartElement) {
          resizeObserver.observe(chartElement);
        }
        
        // Store the observer for cleanup
        self.$_resizeObserver = resizeObserver;
      });
    }
  },
}
</script>


<style lang="scss" scoped>
  @import "@/styles/basics/globals.scss";
  
  /* Fix for Highcharts responsive layout */
  :deep(.highcharts-container),
  :deep(.highcharts-root) {
    width: 100% !important;
    height: 100% !important;
    overflow: visible !important;
  }
  
  :deep(.highcharts-series-group) {
    overflow: visible !important;
  }
  
  :deep(.highcharts-point) {
    stroke-width: 0;
  }
  
  :deep(.highcharts-grid-line) {
    stroke-dasharray: 2, 2;
  }
  
  :deep(.highcharts-plot-border) {
    stroke-width: 0;
  }
</style>