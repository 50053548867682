<template>  
    <div @click="_cancelOngoingOperations()" v-if="_g_ShowStockFinderFilter" id="stocks-finder" class="stocks-finder">
      <div class="separator">
        <span class="material-icons" @click="_closeWindow()">close</span>
        <header>
            <h1 class="filter-tittle">Filtros</h1>
            <br>
            <span id="mobile-filters-results-span">Resultados: {{ _g_Stocks.length }}</span>
        </header>
        <div class="sencond-container-c"> 
            <div v-if="_g_IsUserLogged" class='reset-button-c'>
                <button @click="_resetFinder()" class="reset-button">RESTABLECER</button>
            </div>     
            <div v-else class="reset-button-c">
                <button @click="_onToastMessage()" class="reset-button">RESTABLECER</button>
            </div>
            <div>
                <div @click="_turnOnClosingShield()" v-if="show_button" class="save-button-c">
                    <button @click.stop="_showInput()" class="reset-button" style="margin-left: 30px; background-color: #343948">GUARDAR BÚSQUEDA</button>
                </div>
                <div v-else class="add-search-c">
                    <div @click="_turnOnClosingShield()">          
                        <InputTextOutline class="input-search-value" @_emit_method_order_input="_saveSearch"
                        :input_label="input_searches.input_label"  :input_helper="input_searches.input_helper" :has_focus="input_searches.has_focus" :icon_class="input_searches.icon_class" :input_key_to_save_user_values="input_searches.input_key_to_save_user_values" :input_class_character_counter="input_searches.input_class_character_counter" :permission="input_searches.permission" :icon_permission="input_searches.icon_permission" :character_counter_permission="input_searches.character_counter_permission" :helper_permission="input_searches.helper_permission" :test_field_is_numeric="input_searches.test_field_is_numeric" :length="input_searches.length" :is_input_textarea="input_searches.is_input_textarea" />
                    </div>
                    <div>
                        <button class="input-accept-button" style="background-color: #343948" @click="_saveSearchToApi()">Aceptar</button>
                    </div>
                </div>
            </div>         
        </div>
        <div id="filters-user" v-if="filters_user_json != {}">
            <FirstMobile            
                :filters_user_json="filters_user_json"
                @updateLastSearch="_isFilteringASearch"
                />            
            <hr />
            <SecondMobile
                :filters_user_json="filters_user_json"
                @updateLastSearch="_isFilteringASearch"
                />
            <hr />
            <ThirdMobile
                :filters_user_json="filters_user_json"
                @updateLastSearch="_isFilteringASearch"
                />
            <hr />
            <FourthMobile
                :filters_user_json="filters_user_json"
                @updateLastSearch="_isFilteringASearch"
            />
            <hr v-if="_g_User.roles[21] || _g_User.roles[20]" />
            <FifthMobile v-if="_g_User.roles[21] || _g_User.roles[20]"
                :filters_user_json="filters_user_json"
                @updateLastSearch="_isFilteringASearch"
            />
        </div>
        <div v-if="!(_g_User.roles[21] || _g_User.roles[20])">
            <hr />
            <div class="crypto-toggle" v-if="_g_User['has_cryptos_enabled']">
                <div v-if="_g_IsUserLogged">
                    <div v-if="crypto_enabled" class="cripto-container">
                        <label class="switch">
                            <input id="toggle" type="checkbox" @click="_getValueFromToggle()" checked>                       
                            <span class="slider round"></span>                        
                        </label>
                        <span class="cripto-text">CRIPTOMONEDAS</span>
                    </div>
                    <div v-else class="cripto-container">
                        <label class="switch">
                            <input id="toggle" type="checkbox" @click="_getValueFromToggle()">                       
                            <span class="slider round"></span>                        
                        </label>
                        <span class="cripto-text">CRIPTOMONEDAS</span>
                    </div>                
                </div>
                <div v-else @click="_onToastMessage()">
                    <Toggle class="inline-block"  style="margin-top:47px;margin-bottom:25px; cursor: not-allowed" ref="_comp_toggle_oco"/>
                </div>
                
            </div>
            <div v-else style="margin-bottom: 30px;">
                <br>
                <strong>¿Interesado en el módulo de criptomonedas? Mas info <a style="color:#00aedd" href="/modulo-cryptos">AQUÍ</a></strong>
            </div>
        </div>
    </div>
  </div> 
</template>


<script>
import { mapMutations, mapGetters } from "vuex";
import Toggle from "@/components/helpers/Toggle.vue";
import FirstMobile from "@/components/stockchart/components/Finder/First_mobile.vue";
import SecondMobile from "@/components/stockchart/components/Finder/Second_mobile.vue";
import ThirdMobile from "@/components/stockchart/components/Finder/Third_mobile.vue";
import FourthMobile from "@/components/stockchart/components/Finder/Fourth_mobile.vue";
import FifthMobile from "@/components/stockchart/components/Finder/Fifth_mobile.vue";
import APICaller from "@/components/codeComponents/APICaller.js";
import GetStocks from "@/components/codeComponents/GetStocks.js";
import InputSearches from "@/components/stockchart/components/configs/input_searches.json";
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';


export default {
    mixins: [APICaller, GetStocks],
    name: 'Finder',
    components: {
        Toggle,
        FirstMobile,
        SecondMobile,
        ThirdMobile,
        FourthMobile,
        FifthMobile,
        InputTextOutline,
    },
    data: () => ({
        toggle_cripto_name: "toggle_cripto",
        toggle_cripto_id: "toggle_cripto_id",
        show_criptos: false,
        finder: "",
        filters_user_json: {},
        changes:"",
        recargar:false,
        show_button: true,
        input_searches: InputSearches,
        closingShield: false,
    }),
    watch: {
        crypto_enabled: {
            handler: function() {                 
                this.show_criptos = this.crypto_enabled;            
            },
            deep: true
        },
        changes: {
            handler: function() {  
                this.recargar = true;
            },
            deep: true
        },

    },
    mounted() { 
        this._getUserAttributtes();
        this._getFinderParameters();
    },
    created: function() {
        import("@/components/stockchart/components/Finder/FinderBaseCssFix.css");
    },
    computed: {
        ...mapGetters({crypto_enabled:'_g_crypto_enable'}),
        ...mapGetters({table_chart: "_g_ToggleBetweenTableAndChart"}),
        ...mapGetters(["_g_User", "_g_user_info", '_g_ShowStockFinderFilter', "_g_ArrayFiltersStocks" , '_g_ShowStockChartMenu', "_g_IsUserLogged", "_g_UserId", "_g_open_filters", "_g_toggle_chart_stocks", "_g_crypto_enable", "_g_Stocks" ]),   
    },
    methods: {
        ...mapMutations(['setShowStockFinderFilters', 'setShowStockChartMenu']),
        ...mapMutations(['setMenuSelectionOut']),
        ...mapMutations(['setOptionMobile']),
        ...mapMutations(['setOpenFilters']),
        ...mapMutations(['setChartView']),
        ...mapMutations(['setStocksView']),
        ...mapMutations(['setStocks']),
        ...mapMutations(['setRechargeState','setCrypto']),

        _onToastMessage: function(){
            this.$vueOnToast.pop('error', "Error", "Para poder utilizar esta herramienta debe iniciar sesión.");
        },        
        _closeWindow(){                                   
            if(this.is_mobile_device || this.is_tablet_device){

                this.setOpenFilters();
                if (this._g_open_filters == false) {
                    if(this._g_toggle_chart_stocks == 'chart'){
                    this.setChartView(false);
                    } else{
                    this.setStocksView(false);
                    }
                }        
                this.setShowStockFinderFilters();
            }else{
                this.setMenuSelectionOut(0);  
                this.setShowStockFinderFilters(); 
            }
                                                                                    
        },
        _getValueFromToggle(){
            if(this._g_IsUserLogged){
                var toggle = document.getElementById("toggle");
                toggle.checked = !this.show_criptos;
                if(!this.show_criptos == true){
                    let success = () => {
                        this.setCrypto(); 
                        this.getStocks();                           
                    };

                    let url = "/api/v1/stock-finder/change-search-mode";

                    const successHandler = new APICaller.SuccessHandler(success);
                    const failureHandler = new APICaller.FailureHandler('099', 'Error cambiando el modo de búsqueda.');

                    this._getAPICall(url, successHandler, failureHandler);
                }
                else{
                   let success = () => {
                        this.setCrypto();
                        this.getStocks();                             
                    };

                    let url = "/api/v1/stock-finder/change-search-mode";

                    const successHandler = new APICaller.SuccessHandler(success);
                    const failureHandler = new APICaller.FailureHandler('100', 'Error cambiando el modo de búsqueda.');

                    this._getAPICall(url, successHandler, failureHandler);
                }
            }
            else{
                this.$vueOnToast.pop('error', "Error", "Para poder utilizar esta herramienta debe iniciar sesión.");
            }
        },
        ...mapGetters(['_g_FinderFilters']),
        ...mapMutations(['setFinderFilters']),
        _getFinderParameters: function(){
            let success = (response) => {               
                this.finder = response.data;
                this.setFinderFilters(response.data);                
            };

            let url = "/api/v1/stock-finder/default-finder";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('101', 'Error cargando la búsqueda por defecto.');

            this._getAPICall(url, successHandler, failureHandler);
        },
        _getUserAttributtes: function(){
            this.filters_user_json = this._g_User["permissions"]["permissions"];
        },
        _resetFinder: function(){
            if(this._g_IsUserLogged){
                window.sessionStorage.removeItem('ticker_search_by_user');
                let success = () => {                
                    this.$router.push('/app/graficador-dinamico');
                    window.location.reload()
                };

                let url = "/api/v1/stock-finder/delete-last-search";

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('102', 'Error borrando la última búsqueda.');

                this._getAPICall(url, successHandler, failureHandler);
            }else{
                this.$
            }
        },
        _isFilteringASearch: function(){
            if(this.$route.params.param1 != undefined){
                this._getDefaultStocks();
            }
            this._updateLastSearch2();
        },
        _updateLastSearch2: function(){
            var params = new URLSearchParams();
            this.finder = this._g_FinderFilters();
            params.append('finder', JSON.stringify(this.finder));

            let success = (response) => {
                if(this.$route.params.param1 != undefined){
                    this._getDefaultStocks();
                }else{
                    this.getStocks();
                }
            };

            let url = "/api/v1/stock-finder/update-last-search";
            
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('264', 'Error actualizando la última búsqueda.');
            const apiCallConfig = {loadingOverlay: false};

            this._postAPICall(url, params, successHandler, failureHandler, apiCallConfig);
        },
        _showInput: function(){
            this.show_button = !this.show_button;
        },
        _saveSearchToApi: function(){                
            var params = new URLSearchParams();
            params.append('name', this.saved_search);
            params.append('finder', JSON.stringify(this._g_FinderFilters()));

            let success = () => {
                this.$vueOnToast.pop("success", "Búsqueda", "Guardada con éxito!");
                this._cancelOngoingOperations();
            };

            let url = "/api/v1/stock-finder/create-finder";
            
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('291', 'Error guardando búsqueda.');

            this._postAPICall(url, params, successHandler, failureHandler);
        },
        _saveSearch(value) {                
            var search = value[0];                
            this.saved_search = search;                        
        },
        _cancelOngoingOperations: function(){
            setTimeout(()=>{
                if(!this.closingShield){
                    this.show_button = true;
                }else{
                    this.closingShield = false;
                }
            }, 1)
        },
        _turnOnClosingShield: function(){
            this.closingShield = true;
        },
    },
}
</script>


<style lang="scss" scoped>
    .separator{
        margin-left:59px;
        margin-top:26px;
    }
    @media (max-width: 900px){
        .stocks-finder{
            z-index: 2;
            left: 0px;
            background: #f3f4f7;
            padding-top: 20px;
            border-left: 1px solid #dddddd;
            width: 100%;
            height:45%;           
            overflow-y:scroll;
        }
        .cripto-text{
            
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */
        margin-left:10px;
        letter-spacing: 0.1px;

        /* 02. Secondary/400 */

        color: #626777;
        }
        .filter-tittle{
            position: none !important;
            font-weight: 600;
        }
        .inline-block {
            display: inline-block;
            width: 124px;
            height: 24px;
            right: 139px;
            top: 50px;
        }
        .crypto-toggle div{
            display: inline-block;
        }
    }
    @media (min-width: 901px) {
        .stocks-finder {
            position: absolute;
            z-index: 3;
            top: 0px;
            left: 13.3vw;
            background: #ebebeb;
            padding: 20px;
            border-left: 1px solid #dddddd;
            width: calc(77vw);
            height: calc(88vh);
            overflow:scroll;
        }
        .inline-block {
            display: inline-block;
            position: absolute;
            width: 124px;
            height: 24px;
            right: 139px;
            top: 50px;
        }
        hr {
            margin: 20px 0 !important;
            width: 100% !important;
        }

        #filters-user {
            padding-right: 59px !important;
        }
    }        

    h4 {
        line-height: 3px;
    }
    
    @media screen and(min-width: 901px){
        #stocks-finder{
            z-index: 99;
            background-color: #f3f4f7;
            position: fixed;
            left: 332px;
            right: 80px;
            top: 77px;
            bottom: 0px;
            width: unset;
            //box-shadow: -4px 4px 6px rgba(0, 0, 0, 0.2);
            border: 0.5px solid rgba(0, 0, 0, 0.12);
        }

        .filter-tittle {
            position: absolute;
            width: 62px;
            height: 24px;
            left: 50px;
            top: 19px;
            margin-left:63px;
            margin-top:26px;
    
            /* Headline 6 */
    
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            /* identical to box height, or 120% */
    
            letter-spacing: 0.15px;
    
            /* 02. Secondary/900 */
    
            color: #000010;
    
        }
        .cripto-text {        

        /* Subtitle 2 */

        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        letter-spacing: 0.1px;
        margin-left:10px;

        /* 02. Secondary/400 */

        color: #626777;
    }
    }
    .material-icons {
        font-size: 24px;
        cursor: pointer;
        margin:none;
    }
    .reset-button{
        border-radius: 50px !important;
        color:white !important;
        background-color: #00aedd;
        border:none !important;
        height:25px !important;
        cursor: pointer !important;
        font-weight: 400 !important;
        letter-spacing: 1px !important;
        font-size: 12px !important;
        margin-bottom: 25px !important;
        padding: 3px 30px !important;
        box-sizing: content-box !important;
        margin: 0px !important;
        margin-top: 20px !important;
        margin-right: 10px !important;
    }
    /*.cripto-text{
        margin-top:47px;
        margin-bottom: 25px;

    }*/

    .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.cripto-container{
    margin-top:30px;
}

.save-button-container{
    text-align: center; 
}
.save-search-btn{
    background-color: #00aedd;
    color: white;
    border-radius: 15px;
    font-size: 11px;
    font-weight: bold;
    border: none;
    width: 190px;
    height: 30px;
    margin-top: 10px;
    cursor:pointer;
}
.add-search{
    margin-bottom: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.input-search-value{
    margin-left: 15px;
    margin-top: 10px;
    width:200px; 
}
.input-accept-button{
    width:83px;
    height:32px;
    color: white;
    background-color:#00aedd;
    border:none;    
    border-radius: 25px;
    margin-left: 15px;
    font-weight: bold;
    cursor:pointer;
    margin-bottom: 10px;
}
.reset-button-c * {
    margin: 0;
}

.save-button-c * {
    margin: 0;
}
.sencond-container-c {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}
.second-container-c .reset-button {
    margin-left: 0 !important;
}
.second-container-c div {
    margin: 5px 0;
    width: 200px;
}

.second-container-c button {
    width: 100%;
}

.add-search-c {
    margin-left: 40px !important;
}

.sencond-container {
    display: flex;
    justify-content: space-between;
    margin-right: 100px;
}

#stocks-finder{
    hr{
        margin-top: 10px;
    }
}

#mobile-filters-results-span {
    font-size: 14px;
}

.save-button-c .reset-button {
    background-color: #494c5a !important;
}
</style>