<template>
<div id="registro">
    <HeaderSidebarRight :id_header="id_header" :title_header="title_header" />
    <div class="registry-container">
        <div class="info-ticker-box">
            <p class="text-info-ticker">Ticker: <span class="text-bold">{{_g_SelectedTicker.ticker}}</span></p>
        </div>

        <!-- Filter Buttons -->
        <div class="filter-buttons-container">
            <button class="filter-btn" :class="{ 'active': show_favorites_registry }" @click="show_favorites_registry = !show_favorites_registry">
                Favoritos
            </button>
            <button class="filter-btn" :class="{ 'active': show_portfolio_registry }" @click="show_portfolio_registry = !show_portfolio_registry">
                Cartera
            </button>
            <button class="filter-btn" :class="{ 'active': show_notes_registry }" @click="show_notes_registry = !show_notes_registry">
                Notas
            </button>
            <!-- <button class="filter-btn" :class="{ 'active': show_alarms_registry }" @click="show_alarms_registry = !show_alarms_registry">
                Alarmas
            </button> -->
        </div>

        <!-- Entries List -->
        <div class="entries-list-container" v-if="_g_UserFeatures.permissions.stock_registry">
            <div v-for="(item, index) in filteredData" :key="index" class="registry-item" :class="'registry-item-' + item.type">
                <div class="registry-item-header">
                    <p class="registry-date">{{item.date}}</p>
                    <button v-if="item.type === 'note'" class="icon-button delete-button" @click="_deleteNote(item.nid)">
                      <i class="material-icons">delete</i>
                    </button>
                </div>
                <p class="registry-text">{{item.text}}</p>
            </div>
             <div v-if="filteredData.length === 0" class="no-entries-message">
                No hay entradas para mostrar con los filtros seleccionados.
            </div>
        </div>
        <div v-else class="no-access-container">
            <p>No tiene acceso al registro.</p>
        </div>

        <!-- New Note Input -->
        <div class="note-input-container" v-if="_g_UserFeatures.permissions.stock_registry">
            <textarea 
              v-model="new_note" 
              class="note-textarea" 
              :placeholder="input_placeholder"
              rows="4"
            ></textarea>
            <button 
              id="comment-button-id" 
              @click="_createNote" 
              class="action-btn primary-btn save-note-btn"
              :disabled="!new_note.trim()">
                <i class="material-icons" v-if="button_icon !== 'none'">{{ button_icon }}</i>
                <span>Guardar Nota</span>
            </button>
        </div>
    </div>
</div>

</template>

<script>
    import APICaller from "@/components/codeComponents/APICaller.js";
    import HeaderSidebarRight from './HeaderSidebarRight.vue';
    // Remove unused Button and InputTextarea imports if they are fully replaced
    // import Button from "@/components/helpers/Button.vue"; 
    // import InputTextarea from "@/components/helpers/InputTextarea.vue";

    import { mapGetters } from "vuex";
    export default {
        mixins: [APICaller],
        name: "Registro",
        components:{
            HeaderSidebarRight,
            // Button, // Remove if unused
            // InputTextarea // Remove if unused
        },
        props: {
        },
        data: () =>({
            id_header: "registro-header",
            title_header: "Registro",

            // Keep button icon if needed for the save note button
            button_icon: "save", 
            input_placeholder: "Escriba su nota aqui...",


            // Flags to control visibility
            show_favorites_registry: true,
            show_portfolio_registry: true,
            show_notes_registry: true,
            show_alarms_registry: true,
            
            alarms_in_registry: [], // Keep if needed for preprocessing logic
            data: [], 
            new_note: "",
            alarmsDict: {}, // Keep if needed for preprocessing logic
            callback_counter: 0, // Keep if needed for async logic

        }),
        created(){
            // Initialization logic can remain
        },
        watch: {
            _g_SelectedTicker(newVal, oldVal){
                if(newVal && oldVal && newVal.ticker !== oldVal.ticker){
                    this._getRegistry();
                    this.new_note = ''; // Clear note input on ticker change
                }
            }
        }, 
        computed: {
            ...mapGetters(['_g_SelectedTicker', '_g_UserId', '_g_UserFeatures']),
            
            // Computed property to filter data based on active toggles
            filteredData() {
              return this.data.filter(item => {
                if (item.type === 'favorite' && this.show_favorites_registry) return true;
                if (item.type === 'portfolio' && this.show_portfolio_registry) return true;
                if (item.type === 'note' && this.show_notes_registry) return true;
                if (item.type === 'alarm' && this.show_alarms_registry) return true;
                return false;
              });
            }
        },
        mounted(){
            let waiting_for_selected_ticker = setInterval(()=>{
                if(this._g_SelectedTicker && this._g_SelectedTicker.ticker != undefined){
                    this._getRegistry()
                    clearInterval(waiting_for_selected_ticker)
                }
            },20)
        },
        methods: {
            // Remove old _changeColor* methods as they are replaced by v-model and :class
            
            // Method to handle input value (if needed, otherwise v-model handles it)
            // _getInputValue(value) { 
            //     this.new_note = value[0] 
            // },

            _createNote: function(){
                if (!this.new_note || this.new_note.trim() === "") {
                  this.$vueOnToast.pop("warning", "La nota no puede estar vacía.");
                  return;
                }
                this._postNote();
            },

            _postNote: function(){
                let params = new URLSearchParams();
                params.append('uid', this._g_UserId);
                params.append('ticker', this._g_SelectedTicker.ticker);
                params.append('title', ""); // Title seems unused, maybe remove?
                params.append('body', this.new_note.trim());

                let success = response => {
                    this.new_note = ""; // Clear the input field after successful post
                    this._getNotes(); // Refresh notes list
                    this.$vueOnToast.pop("success", "Nota guardada correctamente.");
                }
                
                let failure = () => {
                   this.$vueOnToast.pop("error", "No se pudo guardar la nota."); 
                }

                let url = "/api/v1/eurekers-notes/create";

                const successHandler = new APICaller.SuccessHandler(success);
                // Add failure callback to the FailureHandler if desired
                const failureHandler = new APICaller.FailureHandler('261', 'Error en la creación de la nota.', failure); 

                this._postAPICall(url, params, successHandler, failureHandler);
            },

            _deleteNote: function(nid){
                let params = new URLSearchParams();
                params.append('uid', this._g_UserId);
                params.append('nid', nid);

                let success = response =>{
                    this._getRegistry(); // Refresh the whole registry or just notes
                    this.$vueOnToast.pop("success", "Nota eliminada.");
                }
                
                let failure = () => {
                   this.$vueOnToast.pop("error", "No se pudo eliminar la nota."); 
                }

                let url = "/api/v1/eurekers-notes/delete";

                const successHandler = new APICaller.SuccessHandler(success);
                // Add failure callback
                const failureHandler = new APICaller.FailureHandler('262', 'Error en el borrado de la nota.', failure);

                this._postAPICall(url, params, successHandler, failureHandler);
            },

            _getRegistry: function(){
                this.data = []; // Clear data before fetching
                this.callback_counter = 0; // Reset counter
                
                let success = response =>{
                    // --- FIX: Remove existing favorites/portfolio before adding ---
                    this.data = this.data.filter(item => item.type !== 'favorite' && item.type !== 'portfolio');
                    // --- End FIX ---
                    
                    // Process favorites
                    response.data["favorites"]?.forEach(element =>{
                        if(element.ticker == this._g_SelectedTicker.ticker){
                            this.data.push({
                                date: this._timeConverter(element.timestamp), 
                                text: this._checkFavoriteAction(element.favorite_action, element.ticker, element.favorite_folder_name), 
                                type:"favorite", 
                                timestamp: element.timestamp
                            });
                        }
                    });
                    
                    // Store alarms for preprocessing (if needed, otherwise process directly)
                    this.alarms_in_registry = response.data["alerts"]?.filter(
                        element => element.ticker == this._g_SelectedTicker.ticker
                    ) || [];
                    
                    // Process portfolio entries
                    response.data["portfolio"]?.forEach(element =>{
                        if(element.ticker == this._g_SelectedTicker.ticker){
                            this.data.push({
                                date: this._timeConverter(element.timestamp), 
                                text: this._checkPortfolioAction(element.portfolio_action, element.ticker), 
                                type:"portfolio", 
                                timestamp: element.timestamp
                            });
                        }
                    });
                    
                    // Fetch related data (notes and alarms)
                    this._getAlarms(); // Fetches and processes alarms
                    this._getNotes(); // Fetches and processes notes
                }
                
                let failure = () => {
                    this.$vueOnToast.pop("error", "No se pudo cargar el registro.");
                }

                let url = `/api/v1/eurekers-historical-registry/get-historical-registry-by-user/user/${this._g_UserId}`;

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('064', 'Error recuperando el registro de usuario.', failure);

                this._getAPICall(url, successHandler, failureHandler);
            },
            
            _getNotes: function(){
                let success = response => {
                    let notes = response.data || [];
                    // --- FIX: Remove existing notes before adding new ones ---
                    this.data = this.data.filter(item => item.type !== 'note');
                    // --- End FIX ---
                    
                    // Add notes to the main data array
                    notes.forEach(element =>{
                        if(element.ticker == this._g_SelectedTicker.ticker){
                            this.data.push({
                                date: this._timeConverter(element.timestamp), 
                                text: element.body, 
                                type:"note", 
                                nid: element.nid, 
                                timestamp: element.timestamp
                            });
                        }
                    });
                    this.callback_counter++;
                    this._sortDataIfReady();
                }
                
                let failure = () => {
                   this.$vueOnToast.pop("error", "Error recuperando las notas."); 
                }

                let url = `/api/v1/eurekers-notes/user/${this._g_UserId}/get`;

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('065', 'Error recuperando las notas de usuario.', failure);

                this._getAPICall(url, successHandler, failureHandler);
            },

            _getAlarms: function(){
                let params = new URLSearchParams();
                params.append('uid', this._g_UserId);

                let success = response =>{
                    this.alarmsDict = this._groupById(response.data || []);
                    this._preprocessAlarms(this.alarmsDict); // Process alarms and add to data
                }
                
                let failure = () => {
                   this.$vueOnToast.pop("error", "Error recuperando las alarmas."); 
                   // Increment counter even on failure to prevent blocking sort
                   this.callback_counter++;
                   this._sortDataIfReady();
                }


                let url = "/api/v1/eurekers-alerts/get-user-alerts";

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('263', 'Error recuperando alarmas.', failure);

                this._postAPICall(url, params, successHandler, failureHandler);
            },

             _preprocessAlarms: function(diccionario_alarmas){
                // --- FIX: Remove existing alarms before adding new ones ---
                this.data = this.data.filter(item => item.type !== 'alarm');
                // --- End FIX ---

                (this.alarms_in_registry || []).forEach(element =>{
                    const alarmDetail = diccionario_alarmas[element.alert_id]?.[0];
                    let date, text, type = "alarm", timestamp = element.timestamp;

                    if (alarmDetail && alarmDetail.alert_field_string && alarmDetail.alert_mode_string && alarmDetail.alert_data) {
                        if(element.alert_action === "ALERT_CREATED") {
                             date = this._timeParser(alarmDetail.created);
                             text = `La alarma ${alarmDetail.alert_field_string} ${alarmDetail.alert_mode_string} ${alarmDetail.alert_data} ha sido creada.`;
                             timestamp = new Date(alarmDetail.created).getTime() / 1000;
                        } else if (element.alert_action === "ALERT_EXECUTED") {
                            date = this._timeConverter(element.timestamp);
                            text = `La alarma ${alarmDetail.alert_field_string} ${alarmDetail.alert_mode_string} ${alarmDetail.alert_data} ha sido ejecutada.`;
                        } else {
                            console.warn("Unknown alert action in registry:", element.alert_action);
                            return;
                        }
                    } else {
                        date = this._timeConverter(element.timestamp);
                        if (element.alert_action === "ALERT_CREATED") {
                             text = `Alarma creada para ${element.ticker}. (Detalles no disponibles)`;
                        } else if (element.alert_action === "ALERT_EXECUTED") {
                             text = `Alarma ejecutada para ${element.ticker}. (Detalles no disponibles)`;
                        } else {
                            console.warn("Unknown alert action in registry (fallback):", element.alert_action);
                            text = `Evento de alarma (${element.alert_action}) para ${element.ticker}.`;
                        }
                        if (!alarmDetail) {
                            console.warn(`Details not found for alert_id ${element.alert_id} from registry.`);
                        } else {
                            console.warn(`Incomplete details found for alert_id ${element.alert_id}:`, alarmDetail);
                        }
                    }

                    this.data.push({ date, text, type, timestamp });
                });
                
                this.callback_counter++;
                this._sortDataIfReady();
            },
            
            _sortDataIfReady: function() {
              // Check if both async calls (_getNotes, _preprocessAlarms) have completed
              if (this.callback_counter >= 2) {
                this.data.sort((a, b) => (b.timestamp || 0) - (a.timestamp || 0)); // Sort descending by timestamp
              }
            },

            _timeConverter: function(UNIX_timestamp){
                if(!UNIX_timestamp){
                    return "Fecha no disponible";
                }
                try {
                    let a = new Date(UNIX_timestamp * 1000);
                    if (isNaN(a.getTime())) {
                        return "Fecha inválida";
                    }
                    let year = a.getFullYear();
                    let month = String(a.getMonth() + 1).padStart(2, '0');
                    let date = String(a.getDate()).padStart(2, '0');
                    return `${date}/${month}/${year}`;
                } catch (e) {
                    console.error("Error converting timestamp:", UNIX_timestamp, e);
                    return "Error de fecha";
                }
            },

            _checkPortfolioAction: function(portfolio_action, ticker){
                const messages = {
                    PORTFOLIO_CREATED_OPEN_POSITION: `Ticker ${ticker} añadido a posiciones abiertas.`,
                    PORTFOLIO_CLOSED_OPEN_POSITION: `Ticker ${ticker} cerrado de posiciones abiertas.`,
                    PORTFOLIO_CREATED_WAITING_VALUE: `Ticker ${ticker} añadido a valores en espera.`,
                    PORTFOLIO_REMOVED_WAITING_VALUE: `Ticker ${ticker} cerrado de valores en espera.`,
                    PORTFOLIO_REMOVED_VALUE: `Ticker ${ticker} eliminado de tu cartera.`
                };
                return messages[portfolio_action] || `Acción de cartera desconocida: ${portfolio_action}`;
            },
            
            _checkFavoriteAction: function(favorite_action, ticker, folder_name){
                 const messages = {
                    FAVORITE_ADDED: `Ticker ${ticker} añadido a la carpeta ${folder_name || 'Favoritos'}.`,
                    FAVORITE_REMOVED: `Ticker ${ticker} eliminado de la carpeta ${folder_name || 'Favoritos'}.`
                };
                return messages[favorite_action] || `Acción de favoritos desconocida: ${favorite_action}`;
            },
           
            // _checkAlertsStatus remains largely the same but is integrated into _preprocessAlarms
            
            _groupById: function(elements) {
                const result = {};
                if (!Array.isArray(elements)) return result; // Handle non-array input

                for (const element of elements) {
                    if (!element || typeof element.id === 'undefined') continue; // Skip invalid elements
                    
                    if (!result[element.id]) {
                        result[element.id] = [];
                    }
                    result[element.id].push(element);
                }
                return result;
            },

            _timeParser: function(fechaString){
                 if (!fechaString) return "Fecha no disponible";
                 try {
                     const fecha_obj = new Date(fechaString);
                     if (isNaN(fecha_obj.getTime())) return "Fecha inválida";
                     
                     const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
                     return fecha_obj.toLocaleDateString('es-ES', options);
                 } catch (e) {
                     console.error("Error parsing date string:", fechaString, e);
                     return "Error de fecha";
                 }
            }
        },
    }
</script>

<style lang="scss" scoped>
  @import "@/styles/basics/globals.scss"; // Make sure this path is correct

  #registro {
    width: 100%;
    display: flex;
    flex-direction: column; // Ensure header is above content
  }

  .registry-container {
    max-width: 600px;
    margin: 0 auto 40px auto; // No top margin, let Header handle spacing
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background);
    box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
    width: 85%;
  }

  // Common Ticker Info Box Style (from other components)
  .info-ticker-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
  
  .text-info-ticker {
    text-align: center;
    background-color: var(--color-surface, #F3F4F7);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 8px 30px;
    border-radius: 20px;
    color: var(--color-text-primary);
    font-weight: 500;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .text-bold {
    font-weight: 700;
    color: var(--primary, #00aedd);
  }

  // Filter Buttons
  .filter-buttons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 25px;
    flex-wrap: wrap;
  }

  .filter-btn {
    padding: 8px 15px;
    border-radius: 20px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    background-color: var(--color-surface, #F3F4F7);
    color: var(--color-text-secondary, #606577);
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 13px;
    font-weight: 500;

    &:hover {
      background-color: var(--color-background, #EAECEF);
      border-color: var(--color-text-secondary, #606577);
    }

    &.active {
      background-color: var(--primary, #00aedd);
      color: white;
      border-color: var(--primary, #00aedd);
      font-weight: 600;
    }
    
    // Assign specific active colors if needed
    // &.active[data-type="favorite"] { background-color: #E39B0F; border-color: #E39B0F; }
    // &.active[data-type="portfolio"] { background-color: #BA91BB; border-color: #BA91BB; }
    // &.active[data-type="note"] { background-color: #494C5A; border-color: #20202B; } // Adjusted note color
    // &.active[data-type="alarm"] { background-color: #1F7AA0; border-color: #1F7AA0; } 
  }

  // Entries List
  .entries-list-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    // max-height: calc(100vh - 350px); // Adjust as needed for scroll height
    // overflow-y: auto; // Add scroll if needed
    margin-bottom: 25px;
  }

  .registry-item {
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 5px;
    padding: 10px 0; // Padding top/bottom
    border-left-width: 5px;
    border-left-style: solid;
  }

  // Define border colors per type
  .registry-item-favorite { border-left-color: #E39B0F; }
  .registry-item-portfolio { border-left-color: #BA91BB; }
  .registry-item-note { border-left-color: #494C5A; } // Darker grey/blue
  .registry-item-alarm { border-left-color: #1F7AA0; } // Consistent alarm blue

  .registry-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 5px 15px; // Padding left/right/bottom
  }

  .registry-date {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-primary, #0F0F1A);
    margin: 0;
  }

  .icon-button { // Copied from Alarmas.vue
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .delete-button i { // Copied from Alarmas.vue
    font-size: 18px;
    color: var(--color-text-secondary, #606577);
    transition: color 0.2s ease;
  }

  .delete-button:hover i { // Copied from Alarmas.vue
    color: var(--color-error, #B00020);
  }

  .registry-text {
    font-size: 13px;
    line-height: 1.5;
    color: var(--color-text-primary, #0F0F1A);
    padding: 0 15px 5px 15px; // Padding left/right/bottom
    margin: 0;
    word-break: break-word;
  }
  
  .no-entries-message {
    text-align: center;
    padding: 20px 0;
    color: var(--color-text-secondary, #606577);
    font-style: italic;
  }

  // No Access Message
  .no-access-container {
      text-align: center;
      padding: 30px 0;
      color: var(--color-text-secondary, #606577);
      font-style: italic;
  }


  // Note Input Area
  .note-input-container {
    margin-top: 20px;
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .note-textarea {
    width: 100%; 
    min-height: 80px;
    padding: 10px 12px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 6px;
    font-family: inherit;
    font-size: 14px;
    color: var(--color-text-primary);
    background-color: var(--color-surface, #F3F4F7);
    resize: vertical;
    box-sizing: border-box; // Ensure padding is included in width

     &:focus {
        outline: none;
        border-color: var(--primary, #00aedd);
        box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.1);
        background-color: var(--color-background, white);
    }
    
    &::placeholder {
        color: var(--color-text-secondary, #606577);
        opacity: 0.7;
    }
  }

  .save-note-btn {
    display: inline-flex; // To align icon and text
    align-items: center;
    justify-content: center;
    gap: 8px;
    align-self: flex-end; // Position button to the right
    min-width: 150px; // Give it some default width
  }
  
  .save-note-btn i {
      font-size: 18px; // Adjust icon size
  }

  // General Action Button Styles (copied from Error.vue/Alarmas.vue)
  .action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 40px;
    transition: all 0.2s ease;
    font-weight: 600;
  }

  .primary-btn {
    background-color: var(--primary, #00aedd);
    color: white;
    box-shadow: none;

    &:hover:not(:disabled) {
      background-color: var(--color-muted, #35add8); // Use muted color from globals
      box-shadow: none;
    }

    &:disabled {
      background-color: var(--color-muted, #e9ecf1); // Use muted color from globals
      color: var(--color-text-secondary, #606577);
      cursor: not-allowed;
    }
  }

  /* Mobile responsiveness */
  @media (max-width: 767px) {
    .registry-container {
      width: 92%;
      padding: 15px;
    }

    .filter-buttons-container {
        gap: 8px;
    }
    
    .filter-btn {
        padding: 6px 12px;
        font-size: 12px;
    }
    
    .entries-list-container {
        // Adjust max-height for smaller screens if needed
    }
    
    .save-note-btn {
        width: 100%; // Make button full width on mobile
        align-self: center; // Center button on mobile
    }
  }

</style>