<template>
  <div id="stock-info" style="text-align: center;">
    <HeaderSidebarRight :id_header="id_header" :title_header="title_header" />

    <div class="info-content-container">
      <div class="info-ticker-box">
        <p class="text-info-ticker">Ticker: <span class="text-bold">{{getFormattedSelectedTickerProperty(["ticker"])}}</span></p>
      </div>

      <div class="info-sections">
        <!-- General Information Section -->
        <div class="info-section">
          <div class="section-header">
            <h3>Información General</h3>
          </div>
          <div class="section-content">
            <div class="info-row">
              <span class="info-label">Divisa:</span>
              <span class="info-value">{{getFormattedSelectedTickerProperty(["divisa"])}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Valor de la divisa:</span>
              <span class="info-value">{{ formatTwoDecimals(getFormattedSelectedTickerProperty(["cotitzacio_divisa"])) }} {{ getFormattedSelectedTickerProperty(["divisa"]) }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Índice:</span>
              <span class="info-value">{{ getFormattedSelectedTickerProperty(["index_valor"])}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Sector:</span>
              <span class="info-value">{{ getFormattedSelectedTickerProperty(["sector"])}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Industria:</span>
              <span class="info-value industry-value">{{ getFormattedSelectedTickerProperty(["industria"])}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">País:</span>
              <span class="info-value">{{ getFormattedSelectedTickerProperty(["country"])}}</span>
            </div>
          </div>
        </div>
        
        <!-- Price and Position Section -->
        <div class="info-section">
          <div class="section-header">
            <h3>Posición</h3>
          </div>
          <div class="section-content">
            <div class="info-row">
              <span class="info-label">Valor:</span>
              <span class="info-value important">{{ formatTwoDecimals(getFormattedSelectedTickerProperty(["valor_actual"])) }} {{_selectDivisa(getFormattedSelectedTickerProperty(["divisa"]))}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Entrada:</span>
              <span class="info-value important">{{ formatTwoDecimals(getFormattedSelectedTickerProperty(["real_entry"])) }} {{_selectDivisa(getFormattedSelectedTickerProperty(["divisa"]))}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Stop:</span>
              <span class="info-value important">{{ formatTwoDecimals(getFormattedSelectedTickerProperty(["out_price"])) }} {{_selectDivisa(getFormattedSelectedTickerProperty(["divisa"]))}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Cantidad:</span>
              <span class="info-value">{{ formatTwoDecimals(getFormattedSelectedTickerProperty(["amount"])) }} {{_selectDivisa(this.user_portfolio.currency)}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Distancia de entrada:</span>
              <span class="info-value">{{ formatTwoDecimals(getFormattedSelectedTickerProperty(["entry_distance"]))}} %</span>
            </div>
            <div class="info-row">
              <span class="info-label">Acciones:</span>
              <span class="info-value">{{ getFormattedSelectedTickerProperty(["instrument_number"])}}</span>
            </div>
          </div>
        </div>
        
        <!-- Entry Type and Wave Information Section -->
        <div class="info-section">
          <div class="section-header">
            <h3>Análisis Técnico</h3>
          </div>
          <div class="section-content">
            <div class="info-row">
              <span class="info-label">Tipo de entrada:</span>
              <span class="info-value">{{ getFormattedSelectedTickerProperty(["entry_type_name"])}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Número de onda:</span>
              <span class="info-value">{{ getFormattedSelectedTickerProperty(["num_ona_actual"])}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Rentabilidad:</span>
              <span class="info-value">{{ formatTwoDecimals(getFormattedSelectedTickerProperty(["tae_lt"]))}} %</span>
            </div>
            <div class="info-row">
              <span class="info-label">Soporte:</span>
              <span class="info-value">{{ formatTwoDecimals(getFormattedSelectedTickerProperty(["soport"])) }} {{_selectDivisa(getFormattedSelectedTickerProperty.divisa)}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Fecha de soporte:</span>
              <span class="info-value">{{ _dataFormat(getFormattedSelectedTickerProperty(["data_soport"])) }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">1ª Fecha:</span>
              <span class="info-value">{{ _dataFormat(getFormattedSelectedTickerProperty(["fecha_primera_barra"])) }}</span>
            </div>
          </div>
        </div>
        
        <!-- Financial Information Section -->
        <div class="info-section">
          <div class="section-header">
            <h3>Información Financiera</h3>
          </div>
          <div class="section-content">
            <div class="info-row">
              <span class="info-label">Dividendos:</span>
              <span class="info-value">{{ formatTwoDecimals(getFormattedSelectedTickerProperty(["avg_dividends"]))}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">CFD:</span>
              <span class="info-value">{{ formatTwoDecimals(getFormattedSelectedTickerProperty(["cfd_margin"]))}} %</span>
            </div>
            <div class="info-row">
              <span class="info-label">Margen CFD:</span>
              <span class="info-value">Alcista y Bajista habilitado</span>
            </div>
            <div class="info-row">
              <span class="info-label">Fecha de análisis:</span>
              <span class="info-value">{{ _dataFormat2(getFormattedSelectedTickerProperty(["data_analisi"])) }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Inversión/Riesgo:</span>
              <span class="info-value">{{ formatTwoDecimals(getFormattedSelectedTickerProperty(["risk_reward"]))}}</span>
            </div>
            <div class="info-row">
              <span class="info-label">Compatibilidad:</span>
              <span class="info-value highlight">{{ formatTwoDecimals(this._g_SelectedTicker.user_platinum_compatibility * 100)}} %</span>
            </div>
          </div>
        </div>
        
        <!-- Company Information Section -->
        <div class="info-section">
          <div class="section-header">
            <h3>Perfil de Empresa</h3>
          </div>
          <div class="section-content">
            <div class="info-row">
              <span class="info-label">Nombre:</span>
              <span class="info-value">{{ getFormattedSelectedTickerProperty(["nom"])}}</span>
            </div>
            
            <div v-if="this._g_SelectedTicker && this._g_SelectedTicker.description" class="description-container">
              <div class="info-row description-header">
                <span class="info-label">Descripción:</span>
              </div>
              <div class="description-content">
                <p class="description-text">
                  <span class="description-first">{{ this._g_SelectedTicker.description.substring(0, this._g_SelectedTicker.description.indexOf('.')) }}</span>
                  <span id="dots">...</span>
                  <span id="remaining-text" class="description-rest">{{ this._g_SelectedTicker.description.substring(this._g_SelectedTicker.description.indexOf('.'), this._g_SelectedTicker.description.length) }}</span>
                </p>
                <div class="read-more-btn-container">
                  <button id="btn_read_more" class="read-more-btn" @click="_read_more()">
                    <span class="read-more-text">Leer Más</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Brain Information Section -->
        <div class="info-section brain-section" v-if="showPlatinumScore">
          <div class="section-header">
            <h3>Brain Score</h3>
          </div>
          <div class="section-content">
            <div class="brain-score">
              <div class="score-circle">
                <span class="score-value">{{Math.round(getFormattedSelectedTickerProperty(["platinum_compatibility"]) * 100) }}</span>
              </div>
              <p class="score-label">Puntuación de compatibilidad</p>
            </div>
          </div>
        </div>
        
        <!-- Brain Information "Eye" Section (for users who can view with credits) -->
        <div class="info-section brain-section" v-else-if="_g_UserFeatures.consumibles && _g_UserFeatures.consumibles.platinum_eyes_remaining > 0">
          <div class="section-header">
            <h3>Brain Score</h3>
          </div>
          <div class="section-content">
            <div class="brain-score eye-option">
              <button class="view-score-btn" @click="getPlatinumScoreForTicker()">
                <i class="material-icons">visibility</i>
                <span>Ver puntuación ({{_g_UserFeatures.consumibles.platinum_eyes_remaining}} restantes)</span>
              </button>
            </div>
          </div>
        </div>
        
        <!-- Brain Information Not Available Section -->
        <div class="info-section brain-section" v-else>
          <div class="section-header">
            <h3>Brain Score</h3>
          </div>
          <div class="section-content">
            <div class="brain-score not-available">
              <div class="score-circle disabled">
                <span class="score-value">N/A</span>
              </div>
              <p class="score-label">No disponible en su plan actual</p>
              <a href="/app/store" class="upgrade-link">Pulse aquí para cambiar de plan</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import HeaderSidebarRight from './HeaderSidebarRight.vue';
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins:[APICaller],
  name: 'Info',
  components: {
      HeaderSidebarRight
  },
  data() {
    return {
      id_header: 'stock-info',
      title_header: 'Análisis Eurekers',
      user_id: "",
      info_rol: "",
      user_portfolio: "",
      link_to_store: "/app/store",
    };
  },
  mounted() {
    this._defaultPortfolio();
  },
  created: function() {
    this._getUID();
    this._getUserInfo();
  },
  computed: {
      ...mapGetters(['_g_SelectedTicker', '_g_User', '_g_UserFeatures']),
      
      showPlatinumScore() {
        const isPlat = this._g_UserFeatures.permissions.eurekers_brain;
        const isPlatEnabledForTicker = this._g_SelectedTicker['platinum_compatibility'] != null;
        
        return isPlat || isPlatEnabledForTicker;
      },
  },
  methods: {
    ...mapMutations(['setStockPlatinumCompatibilityById']),
    
    formatTwoDecimals(value) {
      if (value === undefined || value === null || isNaN(value)) {
        return "";
      }
      
      // Handle if value is already a string
      const numValue = typeof value === 'string' ? parseFloat(value) : value;
      
      if (isNaN(numValue)) {
        return value;
      }
      
      return numValue.toFixed(2);
    },

    getFormattedSelectedTickerProperty(prop_array) {
      const selected_ticker = this._g_SelectedTicker;

      let goal = "";
      prop_array.forEach(prop => {
        goal = selected_ticker[prop];
        if(goal == undefined) {
          return "";
        }
      });

      return goal;
    },
    _selectDivisa : function(valor){
      if (valor == 'EUR'){
        return '€';
      }
      else if (valor == 'EURUSD') {
        return '$';
      }
      else {
        return valor;
      }
    },
    _dataFormat: function(value){
      if(value != undefined) {
        value= value.split("-");
        let new_value = value[2] + "-" + value[1] + "-" + value[0];
        return new_value; 
      }
      else {
        return "";
      }
    },
    _dataFormat2: function(value){
      if(value != undefined) {
        let year = value.substring(0,4);
        let month = value.substring(4,6);
        let day = value.substring(6,8);
        let new_value = day + "-" + month + "-" + year;
        return new_value;
      }
      else {
        return "";
      }

    },
    _defaultPortfolio: function(){
      let success = (response) => {
        this.user_portfolio = response.data
      };

      let url = '/api/v1/portfolio-invest-preferences';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('053', 'Error al recuperar las preferencias de cartera.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _getUID: function(){
      this.user_id = this._g_User.uid;
    },

    _getUserInfo: function(){
      this.info_rol = this._g_User.user_features.platinum_score.info;
    },

    _read_more(){
      var dots = document.getElementById("dots");
      var moreText = document.getElementById("remaining-text");
      var btnText = document.getElementById("btn_read_more");

      if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "<span class='read-more-text'>Leer Más</span>";
        moreText.style.display = "none";
      } else {
        dots.style.display = "none";
        btnText.innerHTML = "<span class='read-more-text'>Leer Menos</span>";
        moreText.style.display = "inline";
      }

    },

    getPlatinumScoreForTicker() {
      if(this._g_UserFeatures.consumibles.platinum_eyes_remaining > 0){
        const isSetSelectedTicker = this._g_SelectedTicker && this._g_SelectedTicker.ticker && this._g_SelectedTicker.ticker != "";
        if(isSetSelectedTicker) {
          const stock_id = this._g_SelectedTicker.id;
          const url = '/api/v1/users/user/consume-platinum-credit-for-stock/stock/' + stock_id;
          const success = (response) => {
            const creditsuccessfullySpent = response && response.data && response.data.success;
            if(response.data.data.platinum_compatibility == 0){
              this.$vueOnToast.pop("info", "No hay información de puntuación platino para este ticker actualmente.")
            }
            else if(creditsuccessfullySpent) {
              const platCompVal = response.data.data.platinum_compatibility;
              const mutationPayload = {
                compatibility: parseFloat(platCompVal),
                id: parseInt(stock_id)
              };
              this.setStockPlatinumCompatibilityById(mutationPayload);
              this._g_UserFeatures.consumibles.platinum_eyes_remaining = this._g_UserFeatures.consumibles.platinum_eyes_remaining - 1;
              this.$vueOnToast.pop('success', 'Puede visualizar la puntuación platino. Has consumido 1 crédito.');
            }
            else {
              let toast = {type: 'info', body: 'Suscríbete al plan PLATINO. Pulsa aquí para saber más.', clickHandler: function(){
                window.location.replace(this.link_to_store);
              }};
              this.$vueOnToast.pop(toast);
            }
          };

          const successHandler = new APICaller.SuccessHandler(success);
          const failureHandler = new APICaller.FailureHandler('172', 'Error canjeando créditos platino.');

          this._getAPICall(url, successHandler, failureHandler);
        }
      } else {
        if(this._g_User.is_pre_eureker_user){
          let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
            window.location.replace(this.link_to_store);
          }};
          this.$vueOnToast.pop(toast);
        } else {
          let toast = {type: 'info', body: 'Suscríbete al plan PLATINO. Pulsa aquí para saber más.', clickHandler: function(){
            window.location.replace(this.link_to_store);
          }};
          this.$vueOnToast.pop(toast);
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "@/styles/basics/globals.scss";

  #stock-info {
    width: 100%;
  }

  .info-content-container {
    max-width: 600px;
    margin: 20px auto;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background);
    box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
    width: 85%;
  }

  .info-ticker-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
  
  .text-info-ticker {
    text-align: center;
    background-color: var(--color-surface, #F3F4F7);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 8px 30px;
    border-radius: 20px;
    color: var(--color-text-primary);
    font-weight: 500;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .text-bold {
    font-weight: 700;
    color: var(--primary, #00aedd);
  }
  
  .info-sections {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

  .info-section {
    background-color: var(--color-surface, #F3F4F7);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(15, 15, 26, 0.05);
    transition: transform 0.2s, box-shadow 0.2s;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(15, 15, 26, 0.08);
    }
  }

  .section-header {
    background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
    padding: 12px 20px;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
    
    h3 {
      margin: 0;
      color: var(--primary, #00aedd);
      font-size: 16px;
      font-weight: 600;
      text-align: left;
    }
  }

  .section-content {
    padding: 15px 20px;
  }

  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 0;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.04));
    
    &:last-child {
      border-bottom: none;
    }
  }

  .info-label {
    color: var(--color-text-secondary, #606577);
    font-size: 14px;
    font-weight: 500;
    flex-shrink: 0;
    margin-right: 15px;
    width: 40%;
    text-align: left;
  }

  .info-value {
    color: var(--color-text-primary);
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    word-break: break-word;
    
    &.industry-value {
      white-space: normal;
      line-height: 1.4;
    }
    
    &.important {
      color: var(--primary, #00aedd);
      font-weight: 700;
    }
    
    &.highlight {
      background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
      padding: 4px 10px;
      border-radius: 12px;
      color: var(--primary, #00aedd);
      display: inline-block;
    }
  }

  .description-container {
    margin-top: 5px;
  }

  .description-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .description-content {
    background-color: var(--color-background);
    border-radius: 6px;
    padding: 15px;
    margin-top: 10px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
  }

  .description-text {
    text-align: left;
    font-size: 14px;
    line-height: 1.6;
    color: var(--color-text-primary);
    margin: 0;
  }

  .description-first, .description-rest {
    color: var(--color-text-primary) !important;
  }

  #remaining-text {
    display: none;
  }

  .read-more-btn-container {
    text-align: center;
    margin-top: 15px;
  }

  .read-more-btn {
    background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
    border: none;
    border-radius: 15px;
    cursor: pointer;
    color: var(--primary, #00aedd);
    font-size: 14px;
    padding: 6px 16px;
    transition: all 0.2s;
    font-weight: 500;
    
    &:hover {
      background-color: var(--color-active-surface, rgba(0, 174, 221, 0.15));
      color: var(--primary, #00aedd);
    }
    
    .read-more-text {
      position: relative;
      
      &:before, &:after {
        content: "-";
        position: relative;
        display: inline-block;
        margin: 0 5px;
      }
    }
  }
  
  .brain-section {
    text-align: center;
  }
  
  .brain-score {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    
    &.eye-option {
      padding: 15px 0;
    }
  }
  
  .score-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: var(--primary, #00aedd);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    
    &.disabled {
      background-color: var(--color-muted, #e9ecf1);
    }
  }
  
  .score-value {
    color: white;
    font-size: 28px;
    font-weight: 700;
  }
  
  .score-label {
    font-size: 14px;
    color: var(--color-text-secondary, #606577);
    margin: 0;
  }
  
  .not-available .score-label {
    color: var(--warning, #FF9B00);
    margin-bottom: 10px;
  }
  
  .upgrade-link {
    color: var(--primary, #00aedd);
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    
    &:hover {
      text-decoration: underline;
    }
  }

  .view-score-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: var(--primary, #00aedd);
    font-size: 14px;
    padding: 15px 20px;
    transition: all 0.2s;
    font-weight: 500;
    
    i {
      font-size: 32px;
      margin-bottom: 8px;
    }
    
    span {
      font-size: 13px;
    }
    
    &:hover {
      background-color: var(--color-active-surface, rgba(0, 174, 221, 0.15));
    }
  }

  /* Mobile responsiveness */
  @media (max-width: 767px) {
    .info-content-container {
      padding: 15px;
      width: 92%;
    }
    
    .info-row {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      padding: 10px 0;
    }
    
    .info-label {
      font-weight: 600;
      margin-bottom: 4px;
      width: 100%;
    }
    
    .info-value {
      width: 100%;
      text-align: left;
    }
    
    .score-circle {
      width: 70px;
      height: 70px;
    }
    
    .score-value {
      font-size: 24px;
    }
    
    .section-header h3 {
      font-size: 15px;
    }
  }
</style>