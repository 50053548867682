<template>
  <div id="ticker-finder" class="ticker-finder-style mobile-content">
    <div class="header-container">
      <div class="title-container">
          <span>Rastreador de descripciones</span>
      </div>
      <div class="close-btn-container">
          <span class="material-icons-outlined" @click="handleClose">close</span>
      </div>
    </div>

    <div id="ticker-finder-content">
      <div id="ticker-finder-control-container">
        <div class="search-container">
          <input v-model="search_term" type="text" placeholder="Search..." @keyup.enter="searchStocksByDescription">
          <i class="material-icons search-icon">search</i>
        </div>
        <button class="description-search-button" @click="searchStocksByDescription">Buscar</button>
      </div>

      <div id="ticker-finder-results-container">
        <div v-for="(ticker, index) in this.stocks" :key="'ticker' + index" class="description-search-result-container">
          <span class="description-search-ticker-name">
                {{ticker.ticker}} - {{ticker.entry_type_name}} - {{ticker.sector}} / {{ticker.industria}}
            </span>
          <p v-html="processDescription(ticker.description, search_term_static, 5)">
          </p>
          <span class="description-search-graph-link" @click="setTickerHandler(ticker)">
            Ver gráfico
          </span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    import { mapMutations, mapGetters } from "vuex";
    import APICaller from "@/components/codeComponents/APICaller.js";
    import GetStocks from "@/components/codeComponents/GetStocks.js";

    export default{
        mixins: [APICaller, GetStocks],
        name: "TickerFinder",
        components:{
        },
        data: () =>({
            stocks: [],
            search_term: '',
            search_term_static: ''
        }),
        created(){

        },
        watch: {
            _g_Stocks: {
                handler: function(oldVal, newVal) {
                    if(!this._g_TickersFinder) {
                        this.stocks = [];
                        this.search_term = '';
                        this.search_term_static = ''
                    }
                    else {
                        this.search_term_static = this.search_term;
                    }
                },
                deep: true
            }
        },
        computed:{
            ...mapGetters(['_g_TickersFinder', '_g_Stocks']),
        },
        mounted(){
        },
        methods:{
            ...mapMutations(['setTickersFinder', 'setSelectedTicker']),

            searchStocksByDescription: function () {
                this._getStocksDescriptionSearch(this.search_term);
            },

            setTickerHandler: function(ticker) {
                this.setSelectedTicker(ticker);
                this.handleClose();
            },

            handleClose: function() {
                this.$emit("_closeComponentDescriptionSearchMobile");
            },

            splitString: function(str, search) {
                let splitIndex = str.indexOf(search);
                let result = [];
                while (splitIndex !== -1) {
                    let before = str.substring(0, splitIndex).trim();
                    result.push(before.split(' '));
                    str = str.substring(splitIndex + search.length).trim();
                    splitIndex = str.indexOf(search);
                }
                result.push(str.split(' '));
                return result;
            },

            truncateArrayWhole: function(arr, length) {
                let result = [];
                if (arr.length <= 2 * length) {
                    return arr;
                }
                for (let i = 0; i < length; i++) {
                    result.push(arr[i]);
                }
                result.push('...');
                for (let i = arr.length - length; i < arr.length; i++) {
                    result.push(arr[i]);
                }
                return result;
            },

            truncateArrayBeginning: function(arr, length) {
                let result = [];
                if (arr.length <= length) {
                    return arr;
                }
                for (let i = 0; i < length; i++) {
                    result.push(arr[i]);
                }
                result.push('...');
                return result;
            },

            truncateArrayEnd: function(arr, length) {
                let result = [];
                if (arr.length <= length) {
                    return arr;
                }
                result.push('...');
                for (let i = arr.length - length; i < arr.length; i++) {
                    result.push(arr[i]);
                }
                return result;
            },

            processDescription: function(description, search_string, context_size) {
                description = description ? description : '';
                search_string = search_string ? search_string : '';
                context_size = context_size ? context_size : 0;

                const preparedDescription = this.splitString(description, search_string);
                let resultDescription = '';

                if(preparedDescription.length > 1) {
                    let firstSegment = preparedDescription.shift();
                    firstSegment = this.truncateArrayEnd(firstSegment, context_size);
                    firstSegment.forEach(element => {
                        resultDescription += element + ' ';
                    });
                    resultDescription += '<span class="highlight">[' + search_string + ']</span> ';

                    while(preparedDescription.length > 1) {
                        let midSegment = preparedDescription.shift();
                        midSegment = this.truncateArrayWhole(midSegment, context_size);
                        midSegment.forEach(element => {
                            resultDescription += element + ' ';
                        });
                        resultDescription += '<span class="highlight">[' + search_string + ']</span> ';
                    }

                    let lastSegment = preparedDescription.shift();
                    lastSegment = this.truncateArrayBeginning(lastSegment, context_size);
                    lastSegment.forEach(element => {
                        resultDescription += element + ' ';
                    });
                }

                return resultDescription == '' ? description : resultDescription;
            }
        }
    }

</script>

<style scoped>

    .ticker-finder-main-container{
        z-index: 99;
        background-color: var(--color-custom-filters-bg);
        position: fixed;
        left: 332px;
        right: 80px;
        top: 78px;
        bottom: 0px;
        width: unset;
        border: 0.5px solid rgba(0, 0, 0, 0.12);
    }

    .tickers-finder-title-container{
        display: flex;
        margin-left: 50px;
        margin-top: 50px;
        font-weight: bold;
    }

    .title-text{
        margin-left: 20px;
    }

    .close-style{
        cursor: pointer;
    }

    #ticker-finder-content {
        text-align: center;
        height: 100%;
        box-sizing: border-box;
        padding-bottom: 200px;
    }

    #ticker-finder-control-container {
        margin-top: 25px;
        width: 90%;
        display: inline-block;
        text-align: left;
    }

    #ticker-finder-control-container input {
        box-sizing: border-box;
    }

    #ticker-finder-control-container button {
        
    }

    #ticker-finder-results-container {
        width: 90%;
        height: 100%;
        padding-bottom: 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin: auto;
        row-gap: 15px;
        overflow-y: scroll;
    }

    #ticker-finder-results-container p{
        text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 15px 0px;
    }

    .description-search-result-container {
        background-color: white;
        border-radius: 10px;
        padding: 10px 25px;
        text-align: left;
    }

    .description-search-ticker-name {
        font-weight: bold;
    }

    .description-search-graph-link {
        font-weight: bold;
        color: #1F7AA0;
        cursor: pointer;
    }

    .description-search-graph-link:hover {
        text-decoration: underline;
    }

    .description-search-graph-link:active {
        color: #0F6A90;
    }

    .search-container {
        display: inline-block;
        margin-right: 20px;
        position: relative; /* necesario para posicionar el icono */
    }

    .search-container input {
        display: inline-block;
        border-radius: 20px; /* redondea los extremos del input */
        border: 1px solid #ccc; /* añade un borde gris suave */
        height: 40px; /* establece la altura del input */
        width: 100%; /* hace que el input ocupe todo el ancho disponible */
        padding: 0 40px 0 60px; /* añade espacio a ambos lados del input */
        box-sizing: border-box; /* hace que el padding no afecte al ancho del input */
    }

    .search-icon {
        position: absolute; /* posiciona el icono en relación al elemento padre */
        top: 50%; /* centra el icono verticalmente */
        transform: translateY(-50%); /* centra el icono verticalmente */
        left: 20px; /* añade espacio entre el icono y el borde izquierdo del input */
        font-size: 24px; /* ajusta el tamaño del icono */
        color: #000; /* establece el color del icono */
    }

    .description-search-button {
        border-radius: 50px !important;
        color: white !important;
        background-color: #00aedd;
        border: none !important;
        height: 35px !important;
        cursor: pointer !important;
        font-weight: 400 !important;
        letter-spacing: 1px !important;
        font-size: 15px !important;
        margin-top: 21px !important;
        margin-bottom: 25px !important;
        padding: 3px 50px !important;
        box-sizing: content-box !important;
    }

    .description-search-button:hover {
        background-color: #009ecd;
    }

    .description-search-button:active {
        background-color: #00aedd;
    }

    .mobile-content {
      margin-top: 15px;
    }

    .highlight {
        background-color: yellow;
    }

    .header-container{
        display: flex;
        justify-content: center;
        padding: 10px 0px 20px 0px !important;
    }

    .title-container{
        margin-left: 30px;
        font-size: 20px;
        font-weight: 600;
    }

    .close-btn-container{
        margin-left: auto;
        order: 2;
        margin-right: 10px;
    }
</style>