<template>
  <div v-if="_g_IsUserLogged && !_g_User.is_only_authenticated_user">

    <div v-if="_g_ShowStockChartMenu" id="main-container" class="mdc-menu mdc-menu-surface mobile-stockchart-menu">
      <ul class="mdc-list top-row" role="menu" aria-hidden="true" aria-orientation="horizontal" tabindex="-1" style="display: flex; width: 100%">
        <li id="0" @click="_openFilters()" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">filter_alt</span>
              <span >Filtros</span>
          </span>
        </li>
        <li id="1" @click="_openFavorites()" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">grade</span>
              <span>Favoritos</span>
          </span>
        </li>
        <li id="2" @click="_setPlatinumActive()" class="mdc-list-item" role="menuitem"  style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-label-ekers-platinum-letter">P</span>
              <span>Platinum</span>
          </span>
        </li>
        <li id="3" @click="_openSearches()" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">folder</span>
              <span>Búsquedas</span>
          </span>
        </li>
      </ul>


      <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="horizontal" tabindex="-1" style="display: flex; width: 100%">
        
        <li @click="_openSimulator()" id="1" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">auto_awesome</span>
              <span>Creador</span>
          </span>
        </li>
        <li @click="_openInfo()" id="2" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">info</span>
              <span>Análisis E.</span>
          </span>
        </li>
        <li @click="_openFundamental()" id="0" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">manage_search</span>
              <span>Análisis F.</span>
          </span>
        </li>
        <li @click="_openDescription()" id="3" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">home_work</span>
              <span>Descripcion</span>
          </span>
        </li>
      </ul>


      <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="horizontal" tabindex="-1" style="display: flex; width: 100%">
        <li @click="_openComments()" id="0" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">chat_bubble_outline</span>
              <span>Comentarios</span>
          </span>
        </li>
        <li @click="_openNotas()" id="1" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">edit</span>
              <span>Registro</span>
          </span>
        </li>
        <li @click="_openError()" id="2" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">info</span>
              <span>Error</span>
          </span>
        </li>
        <li @click="_openBroker()" id="3" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">rocket_launch</span>
              <span>Orden</span>
          </span>
        </li>
      </ul>

      <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="horizontal" tabindex="-1" style="display: flex; width: 100%">
        <li @click="_openDescriptionSearch()" id="0" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">search</span>
              <span>Rastrear</span>
          </span>
        </li>
      </ul>

      <hr class="bottom-space-margin">
      <br>
      <br>
      <br>
      <br>
      <br>
    </div>
  </div>

  <div v-else-if="_g_User.is_only_authenticated_user">
    <div v-if="_g_ShowStockChartMenu" id="main-container" class="mdc-menu mdc-menu-surface mobile-stockchart-menu" style="background-color: #F3F4F7;">
      <ul class="mdc-list top-row" role="menu" aria-hidden="true" aria-orientation="horizontal" tabindex="-1" style="display: flex; width: 100%">
        <li id="0" @click="_toastError()" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">filter_alt</span>
              <span >Filtros</span>
          </span>
        </li>
        <li id="1" @click="_toastError()" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">grade</span>
              <span>Favoritos</span>
          </span>
        </li>
        <li id="2" @click="_toastError()" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-label-ekers-platinum-letter">P</span>
              <span>Platinum</span>
          </span>
        </li>
        <li id="3" @click="_toastError()" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">folder</span>
              <span>Búsquedas</span>
          </span>
        </li>
      </ul>


      <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="horizontal" tabindex="-1" style="display: flex; width: 100%">
        
        <li id="1" @click="_toastError()" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">auto_awesome</span>
              <span>Creador</span>
          </span>
        </li>
        <li id="2" @click="_toastError()" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">info</span>
              <span>Análisis E.</span>
          </span>
        </li>
        <li id="0" @click="_toastError()" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">manage_search</span>
              <span>Análisis F.</span>
          </span>
        </li>
        <li id="3" @click="_toastError()" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">home_work</span>
              <span>Descripcion</span>
          </span>
        </li>
      </ul>


      <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="horizontal" tabindex="-1" style="display: flex; width: 100%">
        <li id="0" @click="_toastError()" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">chat_bubble_outline</span>
              <span>Comentarios</span>
          </span>
        </li>
        <li id="1" @click="_toastError()" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">edit</span>
              <span>Registro</span>
          </span>
        </li>
        <li id="2" @click="_toastError()" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">info</span>
              <span>Error</span>
          </span>
        </li>
        <li id="3" @click="_toastError()" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">rocket_launch</span>
              <span>Orden</span>
          </span>
        </li>
      </ul>
    </div>
  </div>

  <div v-else>
    <div v-if="_g_ShowStockChartMenu" id="main-container" class="mdc-menu mdc-menu-surface mobile-stockchart-menu" style="background-color: #F3F4F7;">
      <ul class="mdc-list top-row" role="menu" aria-hidden="true" aria-orientation="horizontal" tabindex="-1" style="display: flex; width: 100%">
        <li id="0" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">filter_alt</span>
              <span >Filtros</span>
          </span>
        </li>
        <li id="1" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">grade</span>
              <span>Favoritos</span>
          </span>
        </li>
        <li id="2" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-label-ekers-platinum-letter">P</span>
              <span>Platinum</span>
          </span>
        </li>
        <li id="3" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">folder</span>
              <span>Búsquedas</span>
          </span>
        </li>
      </ul>


      <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="horizontal" tabindex="-1" style="display: flex; width: 100%">
        
        <li id="1" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">auto_awesome</span>
              <span>Creador</span>
          </span>
        </li>
        <li id="2" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">info</span>
              <span>Análisis E.</span>
          </span>
        </li>
        <li id="0" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">manage_search</span>
              <span>Análisis F.</span>
          </span>
        </li>
        <li id="3" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">home_work</span>
              <span>Descripcion</span>
          </span>
        </li>
      </ul>


      <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="horizontal" tabindex="-1" style="display: flex; width: 100%">
        <li id="0" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">chat_bubble_outline</span>
              <span>Comentarios</span>
          </span>
        </li>
        <li id="1" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">edit</span>
              <span>Registro</span>
          </span>
        </li>
        <li id="2" class="mdc-list-item" role="menuitem" data-routername="Stockchart" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">info</span>
              <span>Error</span>
          </span>
        </li>
        <li id="3" class="mdc-list-item" style="width: 25%">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">rocket_launch</span>
              <span>Orden</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>



<script>
  import APICaller from "@/components/codeComponents/APICaller.js";
  import { mapMutations, mapGetters } from "vuex";
  import { MDCMenu } from '@material/menu';

  export default {
    mixins:[APICaller],
    name: 'StockchartMenu',
    components: {
    },
    data() {
      return {
        workspaces: [],
        last_element_active: 1,
        show_link_favorites: false,
        sidebar_right_menu_width: 52,
        parent_component: 'sidebar-content',
        tradingview_container: 'dragable-container',
        show_searches: false,
        numberStocks: '',
        workspace: '',

        open_info: false,
        open_brain: false,
        open_description: false,
        open_comments: false,
        open_notas: false,
        open_error: false,
        open_broker: false,
        open_simulator: false,
      };
    },
    watch:{  
      changeSelection: {      
        handler: function() {
          var element2 = document.getElementById(this.lastIndex.toString());
          element2.classList.remove("eurekers-parent-menu-active");            
          this.last_element_active = this.lastIndex;                                   
        },
        deep: true
      },   
    },
    mounted() {
      const menu = new MDCMenu(document.querySelector('.mdc-menu'));
      const adapter = menu.getDefaultFoundation().adapter;     
      
      if(this._g_IsUserLogged){
        menu.listen('MDCMenu:selected', (event) => {
        var item = document.getElementById(event.detail.index.toString());
        var hasClase = item.classList.contains('eurekers-parent-menu-active');
        if(event.detail.index == 3){          
          var item1 = document.getElementById(event.detail.index.toString());
            var hasClase1 = item1.classList.contains('eurekers-parent-menu-active');
            if(hasClase1){
              this._closeFavorites();
              this.$router.push('/app/graficador-dinamico');
            }
        }        
        if(hasClase){
           
            if(event.detail.index == 3){              
              this.$router.push('/app/graficador-dinamico');              
            }
            else if(event.detail.index == 1){
              adapter.addClassToElementAtIndex(2, 'eurekers-parent-menu-active');
              adapter.removeClassFromElementAtIndex(1, 'eurekers-parent-menu-active');
              var filters3 = document.getElementById('0');
              var hasFilters3 = filters3.classList.contains('eurekers-parent-menu-active');              
              if(hasFilters3){
                adapter.removeClassFromElementAtIndex(0, 'eurekers-parent-menu-active');
                this._closeFilters();
              }
              this._toggleBetweenListAndChartInStockchart();              
            }
            else if(event.detail.index == 2){
              adapter.addClassToElementAtIndex(1, 'eurekers-parent-menu-active');
              adapter.removeClassFromElementAtIndex(2, 'eurekers-parent-menu-active');
              var filters2 = document.getElementById('0');
              var hasFilters2 = filters2.classList.contains('eurekers-parent-menu-active');              
              if(hasFilters2){
                adapter.removeClassFromElementAtIndex(0, 'eurekers-parent-menu-active');
                this._closeFilters();
              }
              this._setStockChart();
            }            
            else{
              adapter.removeClassFromElementAtIndex(event.detail.index, 'eurekers-parent-menu-active');
              this.last_element_active = event.detail.index;
            }
          
        }
        else{
          let route = menu['items'][event.detail.index].dataset.routername;
          if(event.detail.index == 1){
              adapter.addClassToElementAtIndex(event.detail.index, 'eurekers-parent-menu-active');
              adapter.removeClassFromElementAtIndex(2, 'eurekers-parent-menu-active');
              var filterss = document.getElementById('0');
              var hasFilterss = filterss.classList.contains('eurekers-parent-menu-active');
              if(hasFilterss){
                adapter.removeClassFromElementAtIndex(0, 'eurekers-parent-menu-active');
                this._closeFilters();
              }       
          }
          else if(event.detail.index == 2){            
              adapter.addClassToElementAtIndex(event.detail.index, 'eurekers-parent-menu-active');
              adapter.removeClassFromElementAtIndex(1, 'eurekers-parent-menu-active');
              var filters = document.getElementById('0');
              var hasFilters = filters.classList.contains('eurekers-parent-menu-active');
              if(hasFilters){
                adapter.removeClassFromElementAtIndex(0, 'eurekers-parent-menu-active');
                this._closeFilters();
              }
          }          
          else{
            if(!this.last_element_active == 1 || !this.last_element_active == 2){
              adapter.removeClassFromElementAtIndex(this.last_element_active, 'eurekers-parent-menu-active');
            }
            adapter.addClassToElementAtIndex(event.detail.index, 'eurekers-parent-menu-active');            
          }
                  
          this.last_element_active = event.detail.index;

          this.$router.push({ name: route });
        }        
      }); 
      }else{
        menu.listen('MDCMenu:selected', (event) => {
        var item = document.getElementById(event.detail.index.toString());
        var hasClase = item.classList.contains('eurekers-parent-menu-active');
        if(event.detail.index == 3){          
          var item1 = document.getElementById(event.detail.index.toString());
            var hasClase1 = item1.classList.contains('eurekers-parent-menu-active');
            if(hasClase1){
              this._closeFavorites();
              this.$router.push('/app/graficador-dinamico');
            }
        }        
        if(hasClase){
           
            if(event.detail.index == 3){              
              this.$router.push('/app/graficador-dinamico');              
            }
            else if(event.detail.index == 1){
              adapter.addClassToElementAtIndex(2, 'eurekers-parent-menu-active');
              adapter.removeClassFromElementAtIndex(1, 'eurekers-parent-menu-active');
              this._toggleBetweenListAndChartInStockchart();
              this._showFilters();
            }
            else if(event.detail.index == 2){
              adapter.addClassToElementAtIndex(1, 'eurekers-parent-menu-active');
              adapter.removeClassFromElementAtIndex(2, 'eurekers-parent-menu-active');
              this._setStockChart();
            }
            else if(event.detail.index == 0){
              adapter.addClassToElementAtIndex(1, 'eurekers-parent-menu-active');
              adapter.removeClassFromElementAtIndex(2, 'eurekers-parent-menu-active');
            }                 
        }
        else{
          let route = menu['items'][event.detail.index].dataset.routername;
          if(event.detail.index == 1){
              adapter.addClassToElementAtIndex(event.detail.index, 'eurekers-parent-menu-active');
              adapter.removeClassFromElementAtIndex(2, 'eurekers-parent-menu-active');        
          }
          else if(event.detail.index == 2){            
              adapter.addClassToElementAtIndex(event.detail.index, 'eurekers-parent-menu-active');
              adapter.addClassToElementAtIndex(0, 'eurekers-parent-menu-active');
              adapter.removeClassFromElementAtIndex(1, 'eurekers-parent-menu-active');
          }
          else if(event.detail.index == 0){            
              adapter.addClassToElementAtIndex(event.detail.index, 'eurekers-parent-menu-active');
              adapter.addClassToElementAtIndex(0, 'eurekers-parent-menu-active');
              adapter.removeClassFromElementAtIndex(1, 'eurekers-parent-menu-active');
          }        
                  
          this.last_element_active = event.detail.index;

          this.$router.push({ name: route });
        }        
      }); 
      }
    
      //adapter.addClassToElementAtIndex(this.last_element_active, 'eurekers-parent-menu-active');
      this._listWorkspaces();

      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var mode = urlParams.get('mode');
      if( mode == 'list'){
        this._toggleBetweenListAndChartInStockchart();
        adapter.addClassToElementAtIndex(2, 'eurekers-parent-menu-active');
        adapter.removeClassFromElementAtIndex(1, 'eurekers-parent-menu-active');
        this.last_element_active = 2;
      }
    },
    computed: {
        ...mapGetters(['_g_IsUserLogged']),
        ...mapGetters(['_g_User']),
        ...mapGetters(['_g_ToggleBetweenTableAndChart']),
        ...mapGetters(['_g_ShowStockChartMenu']),
        ...mapGetters(['_g_ShowStockFinderFilter']),
        ...mapGetters({changeSelection:'_g_MenuSelectionOut'}),
        ...mapGetters({lastIndex:'_g_IndexMenu'}),
        ...mapGetters({stock_type:'_g_GetTypeStocks'}),
        ...mapGetters(['_g_chart_view']),
        ...mapGetters(['_g_stocks_view']),
        ...mapGetters(['_g_toggle_chart_stocks']),
        ...mapGetters(['_g_open_info']),
        ...mapGetters(['_g_open_fundamental']),
        ...mapGetters(['_g_open_description']),
        ...mapGetters(['_g_open_brain']),
        ...mapGetters(['_g_open_comments']),
        ...mapGetters(['_g_open_notas']),
        ...mapGetters(['_g_open_error']),
        ...mapGetters(['_g_open_order']),
        ...mapGetters(['_g_open_simulator']),
        ...mapGetters(['_g_open_filters']),
        ...mapGetters(['_g_open_favorites']),
        ...mapGetters(['_g_open_searches']),
        ...mapGetters(['_g_open_alarms']),
        ...mapGetters(['_g_TickersFinder']),
             
    },
    methods: {
        ...mapMutations(['setToggleBetweenListAndChartInStockchart']),
        ...mapMutations(['setShowStockFinderFilters']),
        ...mapMutations(['setSelectedFavoriteWorkspace']),
        ...mapMutations(['setShowStockChartMenu']),
        ...mapMutations(['isMobileDevice']),
        ...mapMutations(['setStockValue']),
        ...mapMutations(['setMenuSelectionOut']),
        ...mapMutations(['setStockchart']),
        ...mapMutations(['setChartView']),
        ...mapMutations(['setStocksView']),
        ...mapMutations(['setOpenInfo']),
        ...mapMutations(['setOpenFundamental']),
        ...mapMutations(['setOpenDescription']),
        ...mapMutations(['setOpenError']),
        ...mapMutations(['setOpenBrain']),
        ...mapMutations(['setOpenSimulator']),
        ...mapMutations(['setOpenOrder']),
        ...mapMutations(['setOpenNotas']),
        ...mapMutations(['setOpenComments']),
        ...mapMutations(['closeOtherButtons']),
        ...mapMutations(['setOpenFilters']),
        ...mapMutations(['setOpenFavorites']),
        ...mapMutations(['setOpenSearches']),
        ...mapMutations(['setOpenAlarm']),
        ...mapMutations(['setTickersFinder']),
        

        _toggleBetweenListAndChartInStockchart() {
            this.setToggleBetweenListAndChartInStockchart();
        },

        _setStockChart(){
          this.setStockchart();
        },

        _setPlatinumActive(){
          if(this.$route.params.param1 == "platinum"){
            this.$router.push('/app/graficador-dinamico')
          }else{
            this.$router.push('/app/graficador-dinamico/platinum');
          }          
        },
                
        _openFavoritesLinks() {
        },

        _closeFavoritesLinks() {
        },

        _getStocksInWorkspace() {
          let success = response =>{
            this.numberStocks = response.data.length;
          }

          let url = '/api/v1/stock-finder/stocks/favoritos/' + this.workspace.name

          const successHandler = new APICaller.SuccessHandler(success);
          const failureHandler = new APICaller.FailureHandler('168', 'Error recuperando valores.');

          this._getAPICall(url, successHandler, failureHandler);
        },

        _findFavoritesStocksByName(workspace) {
          this.workspace = workspace;
          this.numberStocks = this._getStocksInWorkspace();
          setTimeout(() => {
            if(this.numberStocks != 0){
              this.show_link_favorites = !this.show_link_favorites;          
              this.setSelectedFavoriteWorkspace(workspace.name);
              if (this.$router.history.current.name === 'Favoritos') {
                this.$vueOnToast.pop('warning', "Ya estás en favoritos");
              }
              else {
                this.$router.push('/app/graficador-dinamico/favorites/' + workspace.name);
              }
            }
            else{
              if(this._g_IsUserLogged){
                this.$vueOnToast.pop('error', "No existen tickers en este workspace", "Por favor añada tickers al workspace" + ' - Error code 003.1');
              }
            } 
          }, 200);
          
                   
        },
        _openSearchesWindow: function(){
          this.show_searches = !this.show_searches;
        },
        _closeSearchWindow: function(){
          this.show_searches = false;
          this.setMenuSelectionOut(5);
        },

        _listWorkspaces: function() {
          if(!this._g_User.is_only_authenticated_user){
            let success = response =>{
              for (let index = 0; index < response.data.folders.length; index++) {
                this.workspaces.push({
                  name: response.data.folders[index][0],
                  color: response.data.folders[index][1],
                  isFavorite: false,
                });
              }
            }
  
            let url = '/api/v1/stock-finder/favorite-folder/list'
  
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('169', 'No existen tickers en este espacio de trabajo.');
  
            this._getAPICall(url, successHandler, failureHandler);
          }
        },
        _closeFavorites: function(){
          this.show_link_favorites = false;
          this.setMenuSelectionOut(3);
        },

        _toastIniciaSesion : function(){
          this.$vueOnToast.pop('error', "Si quiere acceder a esta herramienta inicie sesión.");
        },

        _openFilters(){
          this.closeOtherButtons("filters")
          this.setOpenFilters();
          this.setShowStockFinderFilters();         

          if (this._g_open_filters == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              this.setStocksView(true);
            }
          }

        },

        _openFavorites(){
          this.closeOtherButtons("favorites")
          this.setOpenFavorites();
          
          if (this._g_open_favorites == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              this.setStocksView(true);
            }
          }

        },

        _openSearches(){
          this.closeOtherButtons("searches")
          this.setOpenSearches();

          if (this._g_open_searches == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              this.setStocksView(true);
            }
          }

        },

        _openInfo(){
          this.closeOtherButtons("info");
          this.setOpenInfo();

          if (this._g_open_info == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              
              this.setStocksView(true);
            }
          }
        },

        _openFundamental(){
          this.closeOtherButtons("fundamental");
          this.setOpenFundamental();

          if (this._g_open_fundamental == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              this.setStocksView(true);
            }
          }
        },

        _openBrain(){
          this.closeOtherButtons("brain");
          this.setOpenBrain();
          
          if (this._g_open_brain == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              
              this.setStocksView(true);
            }
          }
        },

        _openDescription(){
          this.closeOtherButtons("description");
          this.setOpenDescription();

          if (this._g_open_description == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              this.setStocksView(true);
            }
          }
        },

        _openComments(){
          this.closeOtherButtons("comments");
          this.setOpenComments();
          if (this._g_open_comments == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              this.setStocksView(true);
            }
          }
        },

        _openNotas(){
          this.closeOtherButtons("notas");
          this.setOpenNotas();
          if (this._g_open_notas == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              this.setStocksView(true);
            }
          }
        },

        _openAlarm(){
          this.closeOtherButtons("alarms");
          this.setOpenAlarm();
          if (this._g_open_alarm == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              this.setStocksView(true);
            }
          }
        },

        _openError(){
          this.closeOtherButtons("error");
          this.setOpenError();
          if (this._g_open_error == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              this.setStocksView(true);
            }
          }
        },

        _openBroker(){
          this.closeOtherButtons("broker");
          this.setOpenOrder();
          if (this._g_open_order == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              this.setStocksView(true);
            }
          }
        },

        _openSimulator(){
          this.closeOtherButtons("simulator");
          this.setOpenSimulator();
         if (this._g_open_simulator == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              this.setStocksView(true);
            }
          }
        },

        _openDescriptionSearch(){
          this.closeOtherButtons("descriptionSearch");
          this.setTickersFinder();
         if (this._g_TickersFinder == false) {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(false);
            } else{
              this.setStocksView(false);
            }
          }
          else {
            if(this._g_toggle_chart_stocks == 'chart'){
              this.setChartView(true);
            } else{
              this.setStocksView(true);
            }
          }
        },

        _toastError(){
          let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
            window.location.replace("/app/store");
          }};
          this.$vueOnToast.pop(toast);
        }
    },
}
</script>

<style lang="scss" scoped>
.mobile-stockchart-menu {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding-bottom: 40px;

  min-width: 360px;
  z-index: 20;
  ul {
    padding: 0;
    li {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);

    }
  }

  background-color: var(--custom-color-stockchart-mobile-buttons);
  color: var(--color-custom-typo-mobile-buttons-stockchart);
}

.bottom-space-margin {
  padding-bottom: 20px;
  margin-bottom: 70px;
  border: none;
}

#main-container {
  box-shadow: 0px 10px 5px #888, 0px 0px 40px #888;
}

.mdc-list-item {
  justify-content: center;
  width: 80px;
  padding: 15px 0;
  margin: 0;
  border: 1px solid var(--color-custom-border-mobile-buttons-stockchart);
  margin-bottom: -17px;
}

.mdc-list-item__text,
.mdc-list-item__text span {
  font-size: 12px;
}

.top-row {
  margin-top: 0;
}
</style>


