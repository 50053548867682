<template>
  <div id="stock-error" style="text-align: center;">
    <HeaderSidebarRight :id_header="id_header" :title_header="title_header" />

    <div class="error-report-container">
      <div class="info-ticker-box">
        <p class="text-info-ticker">Ticker: <span class="text-bold">{{ _g_SelectedTicker.ticker }}</span></p>
      </div>
      
      <div class="error-selection-container">
        <p><strong>Tipo de error:</strong></p>
        <div class="select-wrapper">
          <select 
            class="styled-select"
            v-model="type_selected">
            <option v-for="(option, index) in type_options" :value="option.value" :key="index">{{ option.text }}</option>
          </select>
          <div class="helper-text">Selecciona el tipo de error</div>
        </div>
      </div>

      <div class="error-description-container">
        <textarea 
          id="text-area-error" 
          class="error-textarea"
          rows="8" 
          placeholder="¿Cuál es el error?"></textarea>
      </div>

      <div class="btns-container">
        <button 
          id="error_button" 
          v-if="!send_message" 
          @click="_sendError" 
          class="action-btn primary-btn">
          {{ button_name }}
        </button>
        
        <button 
          v-if="send_message" 
          class="action-btn primary-btn" 
          disabled>
          {{ button_name }}
        </button>
      </div>
    </div>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import InputErrorJson from "./configs/input_error.json";
import Button from "@/components/helpers/Button.vue";
import HeaderSidebarRight from './HeaderSidebarRight.vue';
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
    mixins:[APICaller],
    name: 'Error',
    components: {
			Button,
      HeaderSidebarRight
    },
    props: {
        stock: { type: Object },
    },
    watch:{
      _g_SelectedTicker:{
        handler: function(){
          this.resetData();
        }
      }
    },
    data: () => ({
        type_selected: '',
        type_options: [
            { 'value': '', 'text': "Error*" },
            { 'value': 'wrong_dividend', 'text': "Dividendo erroneo" },
            { 'value': 'wrong_split', 'text': "Split erroneo" },
            { 'value': 'wrong_support', 'text': "Soporte erroneo" },
            { 'value': 'wrong_entry', 'text': "Entrada erronea" },
            { 'value': 'wrong_historical', 'text': "Historico erroneo" },
            { 'value': 'wrong_broker_ticker', 'text': "Ticker del broker erroneo" },
            { 'value': 'others', 'text': "Otros" },
        ],
        options_select: [
            { 'name': '', 'value': "Selecciona error" },
            { 'name': 'wrong_dividend', 'value': "Dividendo erroneo" },
            { 'name': 'wrong_split', 'value': "Split erroneo" },
            { 'name': 'wrong_support', 'value': "Soporte erroneo" },
            { 'name': 'wrong_entry', 'value': "Entrada erronea" },
            { 'name': 'wrong_historical', 'value': "Historico erroneo" },
            { 'name': 'wrong_broker_ticker', 'value': "Ticker del broker erroneo" },
            { 'name': 'others', 'value': "Otros" },
        ],
        label_name_error: '',
        component_class_error: 'error_class',

        input_error: InputErrorJson,
        button_name: "Enviar",
        button_icon: "done",
        button_key_error: "button_error",
        button_icon_id: "icon_button_error",
        send_message: false,

        id_header: 'stock-error',
        title_header: 'Reportar Error'
    }),
  mounted() {
  },
  created: function() {
  },
  computed: {
    ...mapGetters(['_g_SelectedTicker']),
    },
  methods: {

    resetData: function() {
        this.type_selected = '';
        this.send_message = false;
        var textarea = document.getElementById("text-area-error");
        if (textarea) {
          textarea.value = "";
        }
    },

    _sendError: function(){
      var textarea = document.getElementById("text-area-error");
      var text = textarea.value;

      var params = new URLSearchParams();
      params.append('ticker', this._g_SelectedTicker.ticker);
      params.append('type', this.type_selected);
      params.append('message', text);

      if(text != ""){
        let success = () =>{
          this.resetData();
          this.$vueOnToast.pop('success', 'Error reportado, muchas gracias.')
        }

        let url = '/api/v1/stock-finder/report-error'

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('249', 'Error enviando reporte de error.');

        this._postAPICall(url, params, successHandler, failureHandler);
      } else {
        this.$vueOnToast.pop('error', 'Mensaje de error vacío. Error code 005');
      }
    },

    reset_shows: function() {
        this.$emit('reset_shows');
    },
  },
}
</script>


<style lang="scss" scoped>
  @import "@/styles/basics/globals.scss";

  #stock-error {
    width: 100%;
  }

  .error-report-container {
    max-width: 600px;
    margin: 20px auto;
    margin-bottom: 20px;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background);
    box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
    width: 85%;
  }

  .info-ticker-box {
     width: 100%;
     display: flex;
     justify-content: center;
  }
  
  .text-info-ticker {
    text-align: center;
    background-color: var(--color-surface, #F3F4F7);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 8px 30px;
    border-radius: 20px;
    color: var(--color-text-primary);
    font-weight: 500;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .text-bold {
    font-weight: 700;
    color: var(--primary, #00aedd);
  }
  
  .error-selection-container {
    margin-top: 10px;
    text-align: center;
  }

  .error-selection-container p {
    margin-bottom: 12px;
    font-size: 15px;
    color: var(--color-text-primary);
  }

  .select-wrapper {
    position: relative;
    width: 100%;
    max-width: 350px;
    margin: auto;
    margin-top: 5px;
  }

  .styled-select {
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    padding-right: 30px;
    font-size: 16px;
    color: var(--color-text-primary);
    background-color: var(--color-background);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    outline: none;
    transition: border-color 0.3s;
  }

  .styled-select:focus {
    border-color: var(--primary, #00aedd);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.1);
  }

  .select-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto;
    width: 0;
    height: 15px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--color-text-secondary, #606577);
    pointer-events: none;
    display: block;
  }

  .helper-text {
    text-align: left;
    margin-left: 10px;
    margin-top: 3px;
    font-size: 12px;
    color: var(--color-text-secondary, #606577);
  }

  .error-description-container {
    margin: 25px 0;
  }

  .error-textarea {
    width: calc(100% - 20px);
    min-height: 80px;
    padding: 10px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 6px;
    font-family: inherit;
    font-size: 14px;
    color: var(--color-text-primary);
    background-color: var(--color-surface, #F3F4F7);
    resize: vertical;
  }

  .btns-container {
    margin: 25px 0;
    text-align: center;
  }

  .action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 40px;
    transition: all 0.2s ease;
    min-width: 200px;
    font-weight: 600;
  }

  .primary-btn {
    background-color: var(--primary, #00aedd);
    color: white;
    box-shadow: none;
  }

  .primary-btn:hover {
    background-color: var(--color-muted, #35add8);
    box-shadow: none;
  }

  .primary-btn:disabled {
    background-color: var(--color-muted, #e9ecf1);
    color: var(--color-text-secondary, #606577);
    cursor: not-allowed;
  }

  /* Mobile responsiveness */
  @media (max-width: 767px) {
    .error-report-container {
      padding: 15px;
      width: 90%;
    }
    
    .styled-select {
      width: calc(100% - 30px);
    }
    
    .error-textarea {
      width: calc(100% - 20px);
    }
    
    .action-btn {
      width: 100%;
    }
  }
</style>