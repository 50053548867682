<template>
  <div class="select-container-size select-filters-new">
    <div class="mdc-select mdc-select--outlined" :class="component_class">
      <div class="mdc-select__anchor" aria-labelledby="outlined-select-label" role="button" aria-haspopup="listbox" aria-expanded="false" demo-selected-text aria-controls="my-helper-text" aria-describedby="my-helper-text">
        <span class="mdc-notched-outline">
          <span class="mdc-notched-outline__leading"></span>
          <span class="mdc-notched-outline__notch">
          </span>
          <span class="mdc-notched-outline__trailing"></span>
        </span>
        <span class="mdc-select__selected-text-container">
          <span id="demo-selected-text" class="mdc-select__selected-text"></span>
        </span>
        <span class="mdc-select__dropdown-icon">
          <svg class="mdc-select__dropdown-icon-graphic" viewBox="7 10 10 5" focusable="false">
            <polygon class="mdc-select__dropdown-icon-inactive" stroke="none" fill-rule="evenodd" points="7 10 12 15 17 10"></polygon>
            <polygon class="mdc-select__dropdown-icon-active" stroke="none" fill-rule="evenodd" points="7 15 12 10 17 15"></polygon>
          </svg>
        </span>
      </div>
      <div class="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth">
          <ul class="mdc-list" role="listbox" aria-label="Food picker listbox">
            <li v-for="(item, index) in options" :key="index" class="mdc-list-item" aria-selected="false" :data-value="item.name" role="option">
              <span class="mdc-list-item__ripple"></span>
              <span class="mdc-list-item__text">
                {{item.value}}
              </span>
            </li>
          </ul>
      </div>
    </div>
  </div>
</template>



<script>
  import {MDCSelect} from '@material/select';
  import {MDCSelectHelperText} from '@material/select/helper-text';

  export default {
    name: 'SelectOutline',
    props: {
        helper_select_permission: {type: Boolean},
        label_name: {type: String},
        options: {type: Array},
        component_class: {type: String},
        helper_text_select: {type: String},
        emit_method: {type: String},
        default_value: {type: String}
    },
    data() {
      return {
        select: {},
      };
    },
    mounted() {
        setTimeout(() => {
          new MDCSelectHelperText(document.querySelector('.mdc-select-helper-text'));
          this.select = new MDCSelect(document.querySelector('.mdc-select.' + this.component_class));
          
          if (this.default_value != ''){
            this.select.foundation.setValue(this.default_value);
          }
          
          this.select.listen('MDCSelect:change', (event) => {
              this.$emit('_emit_method_order_select', [event.detail.value, this.component_class])
          });

        }, 250);
            
    },
    methods: {
    }
  }
</script>



<style lang="scss" scoped>
  @use "@material/list/mdc-list";
  @use "@material/menu-surface/mdc-menu-surface";
  @use "@material/menu/mdc-menu";
  @use "@material/select/styles";
  @use '@material/select';

  .profile .mdc-select {
    @include select.outlined-height(34px);
    width: 85%;
  }
  .mdc-select	{
    @include select.ink-color(('default':var(--color-custom-filter-inputs-font-color)));
    @include select.label-color(('default': var(--color-custom-filter-inputs-font-color),'focus':var(--color-custom-filter-inputs-font-color)));
    @include select.helper-text-color(rgba(0, 0, 0, 0.6));
    @include select.helper-text-validation-color(rgba(0, 0, 0, 0.6));
    @include select.outline-color(('default':#c7c8cc, 'hover':#343948, 'focus':#343948));
    @include select.dropdown-icon-color(('default':#343948, 'hover':#343948, 'focus':#343948));
    @include select.outlined-height(50px, 50px);
  }

  @media (max-width: 599px){
    .mdc-select {
      width: 100%;
      @include select.variable-width(100px);}  
  }

  @media (min-width: 600px) and (max-width: 839px) {
    .mdc-select {
      width: 100%;
      @include select.variable-width(180px);} 
  }

  @media (min-width: 840px){
    .mdc-select {
      width: 100%;
      @include select.variable-width(100px);}
  }
  #directionality .mdc-select--outlined .mdc-select__anchor{
    height: 20px !important;
  }
  #directionality .mdc-floating-label{
    display:none;
  }
  .mdc-text-field--focused {
    border-color: red !important;
  }
  .mdc-select-helper-text * {
    background-color: red !important;
    border-color: red !important;
  }

  #alarms{
    .class-component-select-field{
      width: 65% !important;
      margin: 0 0 30px 0;
    }
    .component_class_select_operation{
      margin-left: 9px;
    }
  }

  @media (min-width: 901px) {
    .select-filters-new.inlined {
      margin-right: 0px !important;
    }
  }
</style>
