<template>
  <div v-if="stock" id="stock-comments">
    <HeaderSidebarRight :id_header="id_header" :title_header="title_header" />
    <div v-if="_g_UserFeatures.permissions.read_forum" class="content-container">
      <div class="info-ticker-box">
        <p class="text-info-ticker">Ticker: <span class="text-bold">{{ stock.ticker }}</span></p>
      </div>

      <div class="comments-header-controls">
        <p class="comment-count-text">{{ sorted_comments.length }} COMENTARIOS</p>
        <button @click="toggleSortOrder" class="sort-button">
          <span class="material-icons">swap_vert</span>
          {{ sortOrder === 'newest_first' ? 'Más antiguos primero' : 'Más nuevos primero' }}
        </button>
      </div>

      <div id="comments-wrapper">
        <div id="no-comment-text-container" v-if="sorted_comments.length == 0">
          <p class="no-comment-text">Aún no hay comentarios para este ticker</p>
        </div>
        <div v-else>
          <div v-for="comment in sorted_comments" :key="comment.id" class="comment-wrapper">
            <div class="comment-header">
              <div class="comment-user-info">
                <span class="comment-author">{{ comment.name }}</span>
                <span class="comment-date">{{ comment.date }}</span>
              </div>
              <div v-if="comment.new > 0" class="comment-badge">
                <span class="material-icons-outlined">new_releases</span>
              </div>
            </div>
            <div class="comment-body">
              <p class="comment-text">{{ comment.message }}</p>
            </div>
            <div class="comment-footer">
              <div class="comment-actions">
                <button @click="upComment(comment)" class="comment-vote-btn upvote">
                  <span class="material-icons">sentiment_satisfied_alt</span>
                </button>
                <button @click="downComment(comment)" class="comment-vote-btn downvote">
                  <span class="material-icons">sentiment_very_dissatisfied</span>
                </button>
              </div>
              <div class="comment-score">
                <span>Puntos: <span class="score-value">{{ comment.votes }}</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div id="comment-writer" v-if="_g_UserFeatures.permissions.forum_writer">
        <div class="comment-box">
          <textarea 
            id="comment-input"
            v-model="new_comment"
            :placeholder="input_comments.input_placeholder || 'Escribir un comentario...'"
            class="comment-textarea"></textarea>
          <button 
            id="comment-button-id" 
            @click="_createComment" 
            class="action-btn primary-btn">
            <span class="material-icons">{{ button_icon }}</span>
            <span>{{ button_name || "Enviar" }}</span>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="not-available-data-container">
      <div class="info-ticker-box">
        <p class="text-info-ticker">Ticker: <span class="text-bold">{{ stock.ticker }}</span></p>
      </div>
      <p class="not-available-data typo-body-2"> Su plan actual no tiene acceso a esta herramienta. </p>
      <a href="/app/store" class="link-to-store typo-body-2"> Pulse aquí para cambiar de plan. </a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Button from "@/components/helpers/Button";
import InputComments from "./configs/input_comments.json";
import HeaderSidebarRight from './HeaderSidebarRight.vue';
import APICaller from "@/components/codeComponents/APICaller.js";
export default {
  mixins: [APICaller],
  name: 'Comments',
  components: {
    Button,
    HeaderSidebarRight
  },
  data: function () {
    return {
      comments: [],
      new_comment: '',
      button_name: "Enviar",
      button_key_comments: "button_comments",
      button_icon: "send",
      button_icon_id: "icon_button_comment",
      input_comments: InputComments,
      id_header: 'comments-header',
      title_header: 'Foro del valor',
      comments_splited: [],
      sortOrder: 'newest_first', // 'newest_first' or 'oldest_first'
    }
  },
  watch: {
    stock: {
      handler: function (newVal, oldVal) {
        if (newVal.id != oldVal.id) {
          this._getComments();
        }
      },
      deep: true
    },
  },
  mounted() {
  },
  created: function () {
  },
  computed: {
    ...mapGetters({ stock: '_g_SelectedTicker' }),
    ...mapGetters({ user: '_g_User' }),
    ...mapGetters({ active_right_component: "_g_Active_right_component" }),
    ...mapGetters(["_g_UserFeatures"]),
    sorted_comments() {
      if (this.sortOrder === 'newest_first') {
        // The API seems to return newest first already, based on the original code's implicit behavior
        return this.comments_splited;
      } else {
        // Create a reversed copy for 'oldest_first'
        return [...this.comments_splited].reverse();
      }
    },
  },
  methods: {
    _isPlatinum() {
      return this.user.is_platinum_user;
    },

    _getComments: function () {
      let success = response => {
        this.comments_splited = [];
        this.comments = response.data;
        this._spliterInfoGetComments()
      }

      let url = "/api/v1/forum/comments/" + this.stock.nid;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('044', 'Error recuperando comentarios.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _createComment: function () {
      if (this.new_comment != '') {
        var params = new URLSearchParams();
        params.append('new_comment', this.new_comment);

        let success = response => {
          if (response.data.status == 'ok') {
            this.$vueOnToast.pop('success', "Comentario", "Enviado con exito!");
            this.new_comment = '';
            this._getComments();
          } else {
            this.$vueOnToast.pop('error', "Comentario no creado", response.data.message);
          }
        }

        let url = '/api/v1/forum/comments/add/' + this.stock.nid;

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('246', 'Error añadiendo comentarios.');

        this._postAPICall(url, params, successHandler, failureHandler);
      }
    },

    _spliterInfoGetComments: function () {
      for (let i = 0; i < this.comments.length; i++) {
        let aux = this.comments[i].user.picture.split(">");
        let picture = aux[2] + ">";
        let picture_splited = picture.split(" ")
        let member_since = ""
        let date = ""
        let name = ""

        if (picture_splited[0].indexOf("Administrador") != -1) {
          picture = aux[6] + ">";
          picture_splited = picture.split(" ")

          member_since = aux[10].split("<");
          member_since = member_since[0];

          name = aux[12].split("<");
          name = name[0];

          date = aux[14].split("<");
          date = date[0];
        } else {
          member_since = aux[6].split("<");
          member_since = member_since[0];

          name = aux[8].split("<");
          name = name[0];

          date = aux[10].split("<");
          date = date[0];
        }

        let picture_typeof = picture_splited[1].split("=")
        picture_typeof = picture_typeof[1]

        let picture_src = picture_splited[2].split("=")
        picture_src = picture_src[1]

        let picture_alt = picture_splited[3].split("=")
        picture_alt = picture_alt[1] + " " + picture_splited[4] + " " + picture_splited[5]

        let picture_title = picture_splited[6].split("=")
        picture_title = picture_title[1] + " " + picture_splited[7] + " " + picture_splited[8]

        let nuevo = this.comments[i].new
        let comment_id = this.comments[i].comment_id
        let message = this.comments[i].message
        let votes = this.comments[i].user.votes.avg

        picture_alt = picture_alt.replace(/"/g, "")
        picture_title = picture_title.replace(/"/g, "")
        picture_src = picture_src.replace(/"/g, "")
        picture_typeof = picture_typeof.replace(/"/g, "")

        let commentToAdd = { "picture_typeof": picture_typeof, "picture_src": picture_src, "picture_alt": picture_alt, "picture_title": picture_title, "member_since": member_since, "name": name, "date": date, "new": nuevo, "comment_id": comment_id, "message": message, "votes": votes };
        this.comments_splited.push(commentToAdd);
      }
    },
    
    upComment: function (comment) {
      this._voteComment(comment, 1);
      this._getComments();
    },
    
    downComment: function (comment) {
      this._voteComment(comment, -1);
      this._getComments();
    },

    _voteComment: function (comment, value) {
      var params = new URLSearchParams();
      params.append('vote_value', value);
      params.append('entity_id', comment.comment_id);

      let success = () => {
        this.$vueOnToast.pop('success', "Comentario", "Votado con exito");
        this._getComments();
      }

      let url = '/api/v1/forum/comments/vote/' + this.stock.nid

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('247', 'Error votando comentarios.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    toggleSortOrder() {
      this.sortOrder = this.sortOrder === 'newest_first' ? 'oldest_first' : 'newest_first';
    },
  },
}
</script>
<style lang="scss" scoped>
  @import "@/styles/basics/globals.scss";

  #stock-comments {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .content-container {
    border-radius: 10px;
    position: relative;
    background-color: var(--color-background);
    margin: 20px auto;
    padding: 20px 20px 120px; /* Increase bottom padding significantly to ensure space for comment writer */
    display: flex;
    flex-direction: column;
    width: 85%;
    flex: 1;
    overflow: hidden; /* Hide overflow */
    height: calc(100% - 40px); /* Account for margins */
  }

  .info-ticker-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  .text-info-ticker {
    text-align: center;
    background-color: var(--color-surface, #F3F4F7);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 8px 30px;
    border-radius: 20px;
    color: var(--color-text-primary);
    font-weight: 500;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .text-bold {
    font-weight: 700;
    color: var(--primary, #00aedd);
  }

  .comments-header-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 5px; /* Adjusted margin */
    padding: 0 10px; /* Add padding to align with comments */
  }

  .comment-count-text {
    text-align: left; /* Align to left */
    font-weight: 600;
    font-size: 14px;
    color: var(--color-text-primary);
    text-transform: uppercase;
    margin: 0; /* Reset margin */
  }

  .sort-button {
    background: none;
    border: 1px solid var(--color-outline);
    color: var(--color-text-secondary);
    padding: 4px 10px;
    border-radius: 15px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: background-color 0.2s, color 0.2s;

    .material-icons {
      font-size: 16px;
    }

    &:hover {
      background-color: var(--color-active-surface);
      color: var(--primary);
    }
  }

  #comments-wrapper {
    overflow-y: auto;
    flex: 1;
    padding: 0 10px 20px; /* Add padding to bottom of comments wrapper for spacing */
    margin-bottom: 20px; /* Add margin below comments for additional separation from the input */
    display: flex;
    flex-direction: column;
  }

  .no-comment-text {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--color-text-secondary);
    text-align: center;
    font-style: italic;
    padding: 15px 0;
  }

  .comment-wrapper {
    padding: 12px 0;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
    margin-bottom: 5px; /* Keep small margin between comments */
  }

  .comment-wrapper:last-child {
     border-bottom: none; /* Remove border from last comment */
     padding-bottom: 30px; /* Extra padding on last comment */
  }

  .comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .comment-user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .comment-author {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-primary);
  }

  .comment-date {
    font-size: 12px;
    color: var(--color-text-secondary);
  }

  .comment-badge {
    color: var(--warning, #FF9B00);
  }

  .comment-body {
    margin: 8px 0;
  }

  .comment-text {
    font-size: 14px;
    line-height: 1.5;
    color: var(--color-text-primary);
    text-align: left;
    margin: 0;
    word-break: break-word;
  }

  .comment-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
  }

  .comment-actions {
    display: flex;
    align-items: center;
  }

  .comment-vote-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    color: var(--color-text-secondary);
    transition: all 0.2s;
    margin-right: 5px;
    
    &:hover {
      background-color: var(--color-active-surface);
      color: var(--primary);
    }
    
    &.upvote:hover {
      color: var(--success, #4CAF50);
    }
    
    &.downvote:hover {
      color: var(--error, #F44336);
    }
    
    .material-icons {
      font-size: 18px;
    }
  }

  .comment-score {
    font-size: 14px;
    color: var(--color-text-secondary);
    
    .score-value {
      font-weight: 600;
      color: var(--color-text-primary);
    }
  }

  #comment-writer {
    position: absolute; /* Revert back to absolute positioning */
    bottom: 0; /* Re-add positioning */
    left: 0;
    right: 0;
    padding: 15px 20px;
    background-color: var(--color-background);
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.1));
    /* Remove bottom radius as it's now part of the main container flow */
    /* border-radius: 0 0 10px 10px; */
    z-index: 10;
    box-sizing: border-box; /* Add box-sizing */
  }

  .comment-box {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .comment-textarea {
    flex: 1;
    border-radius: 20px;
    border: 1px solid var(--color-outline);
    padding: 8px 15px;
    min-height: 40px; /* Use min-height */
    height: auto;    /* Allow height to grow */
    line-height: 1.4; /* Adjust line-height for better readability */
    resize: none;
    font-family: inherit;
    background-color: var(--color-surface);
    color: var(--color-text-primary);
    transition: all 0.2s;
    width: 100%;
    
    &:focus {
      border-color: var(--primary);
      background-color: var(--color-active-surface);
      outline: none;
    }
  }
  
  .action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 40px;
    min-height: 40px; /* Ensure button height is consistent */
    transition: all 0.2s ease;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    box-sizing: border-box; /* Add box-sizing */
  }

  .primary-btn {
    background-color: var(--primary, #00aedd);
    color: white;
    min-width: 110px;
    
    &:hover {
      background-color: var(--color-muted, #BFC2CD);
    }
  }

  .not-available-data-container {
    max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    text-align: center;
    background-color: var(--color-surface);
    border-radius: 8px;
  }

  .not-available-data {
    color: var(--warning, #FF9B00);
    font-size: 16px;
    margin-bottom: 15px;
  }

  .link-to-store {
    color: var(--primary, #00aedd);
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    
    &:hover {
      text-decoration: underline;
    }
  }

  /* Mobile responsiveness */
  @media (max-width: 767px) {
    .content-container {
      padding: 10px 10px 130px; /* Even more padding for mobile */
      width: 95%;
    }
    
    #comments-wrapper {
      padding: 0 5px 30px; /* More bottom padding on mobile */
      margin-bottom: 30px; /* More bottom margin on mobile */
    }
    
    .comment-box {
      flex-direction: column;
      gap: 10px;
    }
    
    .comment-textarea {
      width: 100%;
    }
    
    .action-btn {
      width: 100%;
      min-width: auto; /* Override desktop min-width */
    }
    
    .comments-header-controls {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      padding: 0 5px; /* Adjust padding */
    }
    
    .comment-count-text {
      text-align: left;
    }
    
    .sort-button {
      align-self: flex-end; /* Move sort button to the right on mobile */
    }
  }
</style>