<template>
  <div id="description" class="description-style mobile-content">
    <div class="header-container">
      <div class="title-container">
          <span>Descripción</span>
      </div>
      <div class="close-btn-container">
        <span class="material-icons-outlined" @click="_closeComponent()">close</span>
      </div>
    </div>
    <div class="ticker-description-container" v-if="_g_UserFeatures.permissions.description_value">
      <div v-if="_g_SelectedTicker.description == null || _g_SelectedTicker.description == ''">
        <p id="ticker-description" class="ticker-description-null typo-body-2"> Este ticker no dispone de descripción </p>
      </div>
      <div v-else>
        <p class="description-description typo-body-2"><strong>{{_g_SelectedTicker.sector}} | {{_g_SelectedTicker.industria}}</strong></p>
        <p id="ticker-description" class="ticker-description description-description typo-body-2">{{_g_SelectedTicker.description}}</p>
      </div>
    </div>
    <div v-else>
      <p class="not-allowed-description"> Su plan actual no tiene acceso a esta herramienta </p>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  name: 'Description',
  components:{
  },
  data: () => ({
    id_header: 'description',
    title_header: 'Descripción',
  }),
  mounted() {
  },
  created: function() {
  },
  computed: {
      ...mapGetters(['_g_SelectedTicker', "_g_UserFeatures"]),
  },
  methods: {
    _closeComponent: function() {
      this.$emit("_closeComponentDescriptionMobile")
    },
  },
}
</script>


<style lang="scss" scoped>
  @import "@/styles/basics/globals.scss";

  .content-container{
    margin-top: 30px;
  }

  .ticker-description{
    padding: 10px;
    margin-bottom: 40px;
  }

  .ticker-name{
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    background-color: #f3f4f7;
    margin: auto;
    width: 200px;
  }

  .ticker-subtitle{
    font-size: 14px;
    padding: 10px 10px 0px 10px;
  }

  .mobile-content {
    margin-top: 15px;
    height: 460px;
    min-height: 420px;
    overflow-y: scroll;
  }

  .header-container{
    display: flex;
    justify-content: center;
    padding: 10px 0px 5px 0px !important;
  }

  .title-container{
    margin-left: 30px;
    font-size: 20px;
    font-weight: 600;
  }

  .close-btn-container{
    margin-left: auto;
    order: 2;
    margin-right: 10px;
  }

  .info-ticker-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .text-info-ticker {
    text-align: center;
    background-color: #F3F4F7;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 24px;
    margin-top: 0px;
    width: 130px;
    padding: 7px 20px;
    color: black;
  }

  .text-bold {
    font-weight: bold;
  }

  .ticker-description-null{
    text-align: center;
    margin-top: 20px;
  }

  .not-allowed-description{
    text-align: center;
    margin-top: 20px;
    color: #FF9B00;
    font-weight: 500;
  }

</style>