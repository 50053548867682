<template>
  <div class="mdc-form-field">
    <div class="mdc-checkbox" :class="checkbox_key">
      <input type="checkbox"
            class="mdc-checkbox__native-control"
            :id="checkbox_id"
            />
      <div class="mdc-checkbox__background">
        <svg class="mdc-checkbox__checkmark"
            viewBox="0 0 24 24">
          <path class="mdc-checkbox__checkmark-path"
                fill="none"
                d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
        </svg>
        <div class="mdc-checkbox__mixedmark"></div>
      </div>
      <div class="mdc-checkbox__ripple"></div>
    </div>
    <label :for="checkbox_id" class="typo-subtitle-2 chk-lbl">{{label_name}}</label>
  </div>
</template>



<script>
  import {MDCFormField} from '@material/form-field';
  import {MDCCheckbox} from '@material/checkbox';

  export default {
    name: 'CheckBox',
    props: {
      label_name: { type: String },
      checkbox_key: { type: String },
      checkbox_id: { type: String },
      checkbox_state: { type: Boolean }
    },
    data() {
      return {
        checkbox: {}
      };
    },
    computed: {

    },
    mounted() {
      this.checkbox = new MDCCheckbox(document.querySelector('.mdc-checkbox' + '.' + this.checkbox_key));
      const formField = new MDCFormField(document.querySelector('.mdc-form-field'));
      formField.input = this.checkbox;

      this.checkbox.checked = this.checkbox_state
      
      formField.input.foundation.adapter.isChecked = () => {
        this.$emit('_emit_method_checkbox', [this.checkbox.checked, this.checkbox_key]);
      }; 
    }
  }
</script>



<style lang="scss" scoped>
  @use "@material/checkbox";
  @use "@material/form-field";

  @include checkbox.core-styles;
  @include form-field.core-styles;

  $unmarked-stroke-color: #c7c8cc;
  $generate-keyframes: false;
  $marked-stroke-color: #FFFFFF;
  $marked-fill-color: #FFFFFF;

  .mdc-checkbox {
    @include checkbox.container-colors($unmarked-stroke-color, $marked-stroke-color);
    @include checkbox.ink-color(white);
    @include checkbox.focus-indicator-color(#00ADD9);
    padding-right: 6px;
    --mdc-ripple-left: 7px !important;
  }
  
  .mdc-form-field label {
    font-weight: 400;
    color: #000010;
    padding-left: 0;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .chk-lbl {
    color: var(--color-text-primary) !important;
    line-height: 40px;
    padding-bottom: 2px;
  }
</style>
