<template>
  <div id="notas" style="text-align: center">
    <HeaderSidebarRight :id_header="id_header" :title_header="title_header" />

    <div id="main-container" class="main-container">
      <div id="ticker-name-container" class="ticker-name-container">
        <p>Ticker: <b>{{_g_SelectedTicker.ticker}}</b></p>
      </div>
      <div id="notes-container" class="notes-container" v-for="(nota, notaId) in notas" :key="notaId">
        <div id="div-notas-id" class="div-notas-style" style="">
          <div id="remove-btn-container" class="remove-btn-container">
            <button id="remove_btn" class="remove-btn" @click="_removeNote(nota.nid)"><img src="../assets/img/trash.png" height=20 width=20 title="Borrar nota"></button>
          </div>
          <div id="matter-container">
            <span id="matter-text" class="matter-text">{{nota.title}}</span>
            <span id="date-text" class="date-text">{{nota.date}}</span>
          </div>
          <div id="text-container" class="text-container">
            <p id="text-note" class="text-note">{{nota.body}}</p>
          </div>
        </div>
      </div>
      <div id="add-notes-container" class="add-notes-container">
        <div id="matter-container" class="matter-container">
          <InputTextOutline @_emit_method_order_input="_getValueFromMatter" :input_label="input_matter_notas.input_label" :input_helper="input_matter_notas.input_helper" :has_focus="input_matter_notas.has_focus" :icon_class="input_matter_notas.icon_class" :input_key_to_save_user_values="input_matter_notas.input_key_to_save_user_values" :input_class_character_counter="input_matter_notas.input_class_character_counter" :permission="input_matter_notas.permission" :icon_permission="input_matter_notas.icon_permission" :character_counter_permission="input_matter_notas.character_counter_permission" :helper_permission="input_matter_notas.helper_permission" :test_field_is_numeric="input_matter_notas.test_field_is_numeric" :length="input_matter_notas.length" :is_input_textarea="input_matter_notas.is_input_textarea" class="input-style" />
        </div>
        <div id="text-note-container" class="text-note-container">
          <div id="textarea-container" class="textarea-container">
            <InputTextarea id="input_comment" @_emit_method_order_input_textarea="_getInputValue" :ref="input_notas.ref" :input_key_to_textarea_values="input_notas.input_key_to_textarea_values" :input_placeholder="input_notas.input_placeholder" class="textarea-style"/>
          </div>
          <div id="btn-check-container" class="btn-check-container">
            <button id="check-btn" class="check-btn" @click="_create_nota()">
              <span>&#10003;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>





<script>
  import { mapGetters } from "vuex";

  import InputTextarea from "@/components/helpers/InputTextarea.vue";
  import InputTextOutline from "@/components/helpers/InputTextOutline.vue";
  
  import InputMatterNotas from "./configs/input_matter_notas.json";
  import InputNotas from "./configs/input_notas.json";
  import HeaderSidebarRight from './HeaderSidebarRight.vue';
  import APICaller from "@/components/codeComponents/APICaller.js";

  export default {
    mixins: [APICaller],
    name: 'Notas',
    components: {
      InputTextarea,
      InputTextOutline,
      HeaderSidebarRight,
    },
    data(){
      return{
        input_matter_notas: InputMatterNotas,
        input_notas: InputNotas,
        value_textarea: '',
        matter_value: '',
        style_counter: 0,
        notas: {},
        id_header: 'notas',
        title_header: 'Notas',
      }
    },
    mounted() {
    },
    created: function() {
    },
    computed: {
        ...mapGetters(['_g_SelectedTicker', '_g_UserId']),
    },
    watch: {
      _g_SelectedTicker: {
        handler(newVal, oldVal) {
          if(newVal != undefined && newVal.ticker != oldVal.ticker) {
            this._getNotesForTicker()
          }
        }
      },
      deep: true
    },
    methods: {

      _getInputValue(value){
        this.value_textarea = value[0];
      },

      _getValueFromMatter(value){
        this.matter_value = value[0];
      },

      _create_nota(){
        const obj = {
          name: this.matter_value, 
          text: this.value_textarea,
        };

        if(this.matter_value == ""){
          this.$vueOnToast.pop('warning', 'Por favor, introduzca un asunto.');
        } else{
          this._post_nota(this._g_SelectedTicker.ticker, obj.name, obj.text);
        }
      },

      _post_nota(ticker, name, text) {
        const params = new URLSearchParams();
        params.append('ticker', ticker);
        params.append('title', name);
        params.append('body', text);
        params.append('uid', this._g_UserId);

        const success = response =>{
          this._updateNotesFromArray(response.data);
        }

        const url = '/api/v1/eurekers-notes/create';
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('255', 'Error guardando la nota.');

        this._postAPICall(url, params, successHandler, failureHandler);
      },

      _removeNote(id){
        const params = new URLSearchParams();
        params.append('uid', this._g_UserId);
        params.append('nid', id);
        const url = '/api/v1/eurekers-notes/delete';
        
        const success = (response) => {
          this._updateNotesFromArray(response.data);
        };

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('256', 'Error borrando la nota.');

        this._postAPICall(url, params, successHandler, failureHandler);
      },

      _getNotesForTicker() {
        const url = '/api/v1/eurekers-notes/user/' + this._g_UserId + '/get';
        const success = (response) => {
          this._updateNotesFromArray(response.data);
        };
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('058', 'Error recuperando las notas.');

        this._getAPICall(url, successHandler, failureHandler);
      },

      _updateNotesFromArray(new_notes) {
        this.notas = {};
        let note_ids = (new_notes);
        let num_notes = note_ids.length;
        for(let i = 0; i < num_notes; i++) {
          if (this._g_SelectedTicker.ticker == note_ids[i].ticker) {
            this.notas[note_ids[i].nid] = note_ids[i];
            let dateformat = new Date(parseInt(note_ids[i].timestamp)*1000);
            let day = dateformat.getDate();
            let month = dateformat.getMonth() + 1;
            let year = dateformat.getFullYear();
            this.notas[note_ids[i].nid]["date"] = day +"/"+ month +"/"+ year;
          }
        }
      }
    },
  }
</script>


<style lang="scss" scoped>

  .ticker-name-container{
    background-color: #f3f4f7; 
    width: 250px;
    height: 40px;
    margin: auto;
    text-align: center;
  }
  .ticker-name-container p{
    padding-top: 10px;
  }

  .textarea-container{
    margin-top: 5px;
  }

  .text-note-container{
    display: inline-flex;
  }

  .btn-check-container{
    margin-top: 100px;
    margin-left: 10px;
  }

  .check-btn{
    background-color: #00aedd;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 20px;
    cursor: pointer;
  }

  .check-btn:hover{
    box-shadow: 1px 3px 8px black;
  }

  .textarea-style{
    width: 320px;
  }

  .input-style{
    width: 350px;
  }

  .add-notes-container{
    margin-top: 30px;
  }

  .remove-btn-container{
    margin-left: 320px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .div-notas-style{
    border: 1px solid #e0e0e0;
    width: 380px;
    padding-left: 20px;
    margin: auto;
    margin-top: 15px;
  }

  .date-text{
    float: right;
    margin-right: 20px;
  }

  .matter-text{
    font-weight: bold;
  }

  .remove-btn{
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  .input-container-size.input-style{
    margin: auto;
  }
  #div-notas-id * {
    text-align: left;
  }

  #notas #ticker-name-container, #notas #ticker-name-container span {
    background-color: var(--custom-color-right-menu-highlight);
    color: var(--custom-color-right-menu-font);
  }

  #notas span, #notas #ticker-name-container, #text-note {
    color: var(--custom-color-right-menu-font) !important;
  }
</style>