<template>
  <div id="simulator" class="simulator-style mobile-content">
    <div class="header-container">
      <div class="title-container">
          <span>Creador de índice</span>
      </div>
      <div class="close-btn-container">
          <span class="material-icons-outlined" @click="_closeComponent()">close</span>
      </div>
    </div>
    <div v-if="_g_UserFeatures.permissions.index_creator">
      <div class="simulator-table">
        <div class="mdc-data-table mdc-data-table">
          <div class="mdc-data-table__table-container">
            <table class="mdc-data-table__table" aria-label="Dessert calories">
              <thead>
                <tr class="mdc-data-table__header-row">
                  <th v-for="(item, index) in table_header" :key="index" class="mdc-data-table__header-cell" role="columnheader" data-column-id="date" scope="col">{{item}}</th>
                </tr>
              </thead>
              <tbody class="mdc-data-table__content">
                <tr class="mdc-data-table__row">
                  <th class="mdc-data-table__cell" :style="{color: _colorProfitIndex(profit)}">{{profit}}</th>
                  <th class="mdc-data-table__cell">{{max_drawdown}}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="simulador-highchart">
        <div id="highchart-content"></div>
      </div>

      <div class="simulator-chips-container">
        <div class="simulator-chips-box" v-for="(ticker, index) in user_tickers" :key="ticker">
          <span class="">{{ticker}}</span>
          <span @click="_deleteTicker(index)" class="material-icons icon-delete">cancel</span>
        </div>
      </div>

      <div class="info-ticker-box">
        <p class="text-info-ticker">Ticker: <span class="text-bold">{{_g_SelectedTicker.ticker}}</span>        
        </p>
        <button class="material-icons-outlined simulator-button-add" @click="_addSelectedTicker(_g_SelectedTicker.ticker)">add</button>
      </div>

      <div>
        <div class="simulador-input-autocomplete">
          <AutocompleteInput
            :options="options"
            :show="show"
            :disabled="disabled_ticker_to_check"
            @select="_selectSuggestions"
            @suggestions="_autocompleteSuggestions">
            <template slot="item" scope="option">
              <article class="media">
                <p>
                  <strong>{{ option.title }}</strong>
                  <br> {{ option.description }}
                </p>
              </article>
            </template>
          </AutocompleteInput>
          <p id="autocomplete-input-label" class="label-autocomplete-input">Comprobar otro ticker</p>
        </div>
      </div>

      <hr>

      <p id="save-index-title" class="simulator-title-index">ÍNDICES GUARDADOS</p>

      <div class="simulator-chips-container">
        <div class="simulator-chips-box" v-for="index in user_indices_creator" :key="index.icid">
          <span @click="_loadIndex(index)">{{index.name}}</span>
          <span @click="_removeIndex(index)" class="material-icons icon-delete">cancel</span>
        </div>
      </div>
      <div class="simulador-input-index">
        <InputTextOutline @_emit_method_order_input="_getValueFromComponentIndexName" :input_label="input_IndexName.input_label" :input_helper="input_IndexName.input_helper" :icon_class="input_IndexName.icon_class" :input_key_to_save_user_values="input_IndexName.input_key_to_save_user_values" :input_class_character_counter="input_IndexName.input_class_character_counter" :permission="input_IndexName.permission" :icon_permission="input_IndexName.icon_permission" :character_counter_permission="input_IndexName.character_counter_permission" :helper_permission="input_IndexName.helper_permission" :test_field_is_numeric="input_IndexName.test_field_is_numeric" :is_input_textarea="input_IndexName.is_input_textarea" :length="input_IndexName.length" />
        </div>
      <div class="simulador-button-save-index">
        <Button @_emit_method_button="_saveIndex()" :button_name="button_name_save" :button_key="button_key_save" :button_icon="button_icon_save" :icon_permission="false" />
      </div>
    </div>
    <div v-else class="not-available-data-container">
      <div class="info-ticker-box">
        <p class="text-info-ticker">Ticker: <span class="text-bold">{{_g_SelectedTicker.ticker}}</span></p>
      </div>
      <p class="not-available-data typo-body-2"> Su plan actual no tiene acceso a esta herramienta. </p>
      <a href="/app/store" class="link-to-store typo-body-2"> Pulse aquí para cambiar de plan. </a>
    </div>
  </div>
</template>



<script>
import { mapMutations } from "vuex";
import Vue from 'vue';
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";
import Highcharts from "highcharts";
import AutocompleteInput from "@/components/helpers/AutocompleteInput";
import Button from "@/components/helpers/Button.vue";
import { MDCDataTable } from '@material/data-table';
import inputIndexName from './configs/input_simulator.json';
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';


export default {
  mixins:[APICaller],
  components: {
    AutocompleteInput,
    Button,
    InputTextOutline
    },
  name: 'Simulator',
  props: {
    stock:{type: Object},
  },
  data: () => ({
    input_IndexName : inputIndexName,
    show: true,
    disabled_ticker_to_check: false,
    options: [],
    user_tickers: [],
    index_creator_name: '',
    user_indices_creator: [],
    series: [{
      name: 'Indice de cartera',
      data: [0, 0]
    }],
    categories: [],
    user_portfolios: [],

    table_header: ['Rentabilidad', 'Max.Drawdown'],
    profit: 0,
    max_drawdown: 0,

    num: 2,
    button_name_save: "Guardar índice",
    button_icon_save: "none",
    button_key_save: "button_key_save",

    id_header: 'simulator',
    title_header: 'Creador de índice',
    input_IndexName_value: ''
  }),
  watch: {
    profit: function(){
      var color = this._colorProfitIndex(this.profit);
      document.getElementById("profit-text").style.color = color;
    }
  },
  computed: {
    ...mapGetters(['_g_SelectedTicker']),
    ...mapGetters(['_g_UserFeatures']),
  },
  created() {
  },
  mounted() {
    new MDCDataTable(document.querySelector('.mdc-data-table'));
    this._addDataToHighchart();
    this._loadAllIndexCreator();
    //this._APICallForList();
    this._getData();
  },

  methods: {
    //Method that selects the color of the text according to whether the profitability is positive or negative.
    _colorProfitIndex: function(profit){
      let color;
      if(profit == 0){
        color = "#B00020";
        return color;
      }
      else if(profit < 0){
        color = 'red';
        return color;
      } else{
        color= 'green';
        return color;
      }
    },

    //Method that configures the data with which the graphics will be printed
    _addDataToHighchart: function(){
      let self = this;
      Vue.nextTick(function() {
        Highcharts.chart('highchart-content',{
          chart: {
            type: 'line',
            zoomType: 'x',
          },
          title: {
            text: '',
          },
          xAxis: {
            visible: false,
          },
          yAxis: {
            title: '',
            type: 'logarithmic',
          },
          tooltip: {
            shared: true,
            crosshairs: true
          },
          series: self.series,
          colors: ['#4572A7', '#a4a5a7'],
        });
      })
    },
    //call to api to load the indexes created by the user
    _loadAllIndexCreator: function(){
        let success = response => {
                this.user_indices_creator = response.data;
                this._loadIndex(response.data[0]);
            };

        let url = '/api/v1/stock-finder/list-index-creator';
        
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('074', 'Error cargando índices.');

        this._getAPICall(url, successHandler, failureHandler);
    },

    /**_APICallForList: function(){
      let success= response => {this.user_portfolios = response.data;};

      let failure  =()=>{};

      let url = '/api/v1/portfolio/list';

      this._getAPICall(url, success, failure);

    },**/
    //method to add the selected ticker to the index that is being created
    _addSelectedTicker:function(ticker){
      if(this.user_tickers.indexOf(ticker) == -1){
        this.user_tickers.push(ticker);

        this._getData();
      } else {
        this.$vueOnToast.pop('error', "Error", "No pueden haber elementos repetidos. Este ticker ya se encuentra entre los seleccionados.");
      }
    },
    _getValueFromComponentIndexName(value) {
      this.input_IndexName_value = value[0];
      this.index_creator_name = this.input_IndexName_value;
    },
    //call to api to collect all the data about the user's tickers and indexes
    _getData: function(){
      //let params = new URLSearchParams();
      //params.append('tickers', this.user_tickers.toString());

      let query = "?tickers=" + this.user_tickers.toString();

      let success= response => {
        this.series = response.data['series'];
        this.categories = response.data['categories'][0].data;
        this.max_drawdown = response.data['max_drawdown'];
        this.profit = response.data['profit'];
        this._addDataToHighchart();
      };

      let url = "/api/v1/portfolio/calculate-portfolio-index-from-tickers" + query;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('075', 'Error cargando valores.');

      this._getAPICall(url, successHandler, failureHandler);

    },
    //method to remove one of the selected tickers
    _deleteTicker : function(index){
      this.user_tickers.splice(index, 1)
      
      this._getData();
    },
    //method to load the tickers of the selected index
    _loadIndex: function(index){
      if(index){
        this.user_tickers=index.tickers.split(',');
        this._getData();
      }  
    },
    //method to remove the index on which you click
    _removeIndex: function(index){
      let params = new URLSearchParams();
      params.append('icid', index.icid);

      let success= response => {
        if (response.data.status == 'error') {
          this.$vueOnToast.pop('error', "Error" + response.data.message);
        } else {
          this._loadAllIndexCreator();
          this.$vueOnToast.pop('success',"IC", "borrado correctamente!");
        }
      };

      let url = '/api/v1/stock-finder/delete-index-creator';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('274', 'Error borrando índice.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },
    //method to save all selected tickers in a new index
    _saveIndex: function(){
      let params = new URLSearchParams();
      params.append('name', this.index_creator_name);
      params.append('tickers', this.user_tickers);

      let success= response => {
        if (response.data.status == 'error') {
          this.$vueOnToast.pop('error', "Error" + response.data.message);
        } else {
          this.index_creator_name = '';
          this._loadAllIndexCreator();
          this.$vueOnToast.pop('success',"IC", "guardado bien!");
        }
      };

      let url = '/api/v1/stock-finder/save-index-creator';

      if(this.index_creator_name !== ''){
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('275', 'Error guardando índice.');

        this._postAPICall(url, params, successHandler, failureHandler);
      } else {
        this.$vueOnToast.pop('error',"Nombre obligatorio");
      }
    },
    _selectSuggestions: function(option) {
      this.user_tickers.push(option.title);

      this._getData();
    },
    _autocompleteSuggestions: function(option) {
      this.options = [];
      this.ticker = option;
      this.currency = 'EUR';
      this.entries_analysis = [];

      let success = response => {
        this.options = [];

        for (var key in response.data) {
          this.options.push({ title: key, description: response.data[key] });
        }
      };

      let url = '/api/v1/portfolio/waitings/autocomplete/' + option;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('076', 'Error cargando autocompletado.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _getValueFromSimulatorIndex(value) {
      this.input_simulator_value = value[0];
    },

    _closeComponent: function() {
      this.$emit("_closeComponentSimulatorMobile")
    },

    ...mapMutations(['setSelectedTicker']),
  },
}
</script>


<style lang="scss" scoped>
  @use "@material/data-table/data-table";
  @include data-table.core-styles;

  .mdc-data-table__header-cell,
  .mdc-data-table__cell {
    color: rgba(0, 0, 0, 0.6);
    background-color: #F3F4F7;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;}
  
  .mdc-data-table {
    width: 100%;}
  
  .mdc-data-table__row,
  .mdc-data-table__cell {
    background-color: white;
    color: #B00020;}
  .simulator-table {
    margin: 19px 44px 24px;
  }
  .simulador-highchart {
    margin: 0 10px;
    border: 0.5px solid rgba(0, 0, 0, 0.6);
  }
  .info-ticker-box {
     width: 100%;
     display: flex;
     justify-content: center;
  }
  .text-info-ticker {
    text-align: center;
    background-color: #F3F4F7;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 16px;
    padding: 7px 25px;

    span {
      font-size: 18px;
    }
  }
  .text-bold {
    font-weight: bold;
  }

  .simulator-button-add { 
    width: 30px;
    height: 30px;
    background-color: #00AEDD;
    border-radius: 50%;
    color: #FFFFFF;
    border: none;
    margin-left: 20px;
    box-shadow: 0px 1px 3px grey;
    padding-right: 5px;
    cursor: pointer;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
  }

  .simulator-chips-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin: 22px 10px 0;
  }

  .simulator-chips-box {
    display: flex;
    margin: 0 6px 12px;
    font-size: 14px;
    line-height: 20px;
    border: 0.5px solid rgba(0, 0, 0, 0.62);
    color: #000010;
    border-radius: 16px;
    padding: 5px 10px;
  }

  .mdc-button {
    margin-top: 20px;
  }

  .icon-delete {
    cursor: pointer;
    font-size: 20px;
    color: #606577;
    margin-left: 10px;
  }
  .simulador-input-index{
    text-align: center;
  }

  .simulador-button-save-index {
    text-align: center;
    margin-bottom: 20px;
  }

  hr {
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 20px 37px 43px;;
  }

  .simulator-title-index {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #0F0F1A;
    margin-left: 37px;
  }

  .simulator-chips-margin {
    margin: 5px 30px 30px;
  }

  .simulador-input-autocomplete {
    text-align: center;
  }
  .simulador-input-index{
    text-align:center;
    width: 300px;
    height:80px;
    margin-left: 59px;
  }

  .label-autocomplete-input {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.38);
  }
  .control {
    margin: 0;
  }

  #highchart-content {
    height: 330px;
    width: 100%;
  }
  .highcharts-root {
    width: 380px !important;
  }
  .mobile-content {
    margin-top: 15px !important;
    height: 460px;
    min-height: 420px;
    overflow-y: scroll;
  }

  .header-container{
    display: flex;
    justify-content: center;
    padding: 10px 0px 20px 0px !important;
  }

  .title-container{
    margin-left: 30px;
    font-size: 20px;
    font-weight: 600;
  }

  .close-btn-container{
    margin-left: auto;
    order: 2;
    margin-right: 10px;
  }

  .not-allowed-simulator{
    text-align: center;
    margin-top: 20px;
    color: #FF9B00;
    font-weight: 500;
  }

  .not-available-data-container{
    text-align: center;
  }

  .not-available-data{
    color: #FF9B00;
  }

  .link-to-store{
    color: #35add8;
  }
</style>