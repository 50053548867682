<template>
  <div id="fundamental" style="text-align: center;">
    <HeaderSidebarRight :id_header="id_header" :title_header="title_header" />
    
    <div v-if="_g_UserFeatures.permissions.fundamental_analisis">
      <div v-if="fundamental_available" class="fundamental-content-container">
        <div class="info-ticker-box">
          <p class="text-info-ticker">Ticker: <span class="text-bold">{{ getFormattedSelectedTickerProperty(["ticker"]) }}</span></p>
        </div>
        
        <div class="info-sections">
          <!-- Share Status Section -->
          <div v-if="fundamental_info.SharesOutstanding || fundamental_info.SharesFloat || fundamental_info.PercentInsiders || fundamental_info.PercentInstitutions" class="info-section">
            <div class="section-header">
              <h3>Estado de las acciones</h3>
            </div>
            <div class="section-content">
              <div v-if="fundamental_info.SharesOutstanding" class="info-row">
                <span class="info-label">Acciones en circulación:</span>
                <span class="info-value">{{ fundamental_info.SharesOutstanding }}</span>
              </div>
              <div v-if="fundamental_info.SharesFloat" class="info-row">
                <span class="info-label">Acciones activas:</span>
                <span class="info-value">{{ fundamental_info.SharesFloat }}</span>
              </div>
              <div v-if="fundamental_info.PercentInsiders" class="info-row">
                <span class="info-label">% de acciones propias:</span>
                <span class="info-value">{{ fundamental_info.PercentInsiders }}</span>
              </div>
              <div v-if="fundamental_info.PercentInstitutions" class="info-row">
                <span class="info-label">% de acciones en instituciones:</span>
                <span class="info-value">{{ fundamental_info.PercentInstitutions }}</span>
              </div>
            </div>
          </div>
          
          <!-- Highlights Section -->
          <div v-if="fundamental_info.BookValue || fundamental_info.PEGRatio || fundamental_info.PERatio" class="info-section">
            <div class="section-header">
              <h3>Highlights</h3>
            </div>
            <div class="section-content">
              <div v-if="fundamental_info.BookValue" class="info-row">
                <span class="info-label">BookValue:</span>
                <span class="info-value">{{ fundamental_info.BookValue }}</span>
              </div>
              <div v-if="fundamental_info.PEGRatio" class="info-row">
                <span class="info-label">PEGRatio:</span>
                <span class="info-value">{{ fundamental_info.PEGRatio }}</span>
              </div>
              <div v-if="fundamental_info.PERatio" class="info-row">
                <span class="info-label">PERatio:</span>
                <span class="info-value">{{ fundamental_info.PERatio }}</span>
              </div>
            </div>
          </div>
          
          <!-- Valuation Section -->
          <div v-if="fundamental_info.EnterpriseValue || fundamental_info.EnterpriseValueEbitda || fundamental_info.EnterpriseValueRevenue" class="info-section">
            <div class="section-header">
              <h3>Valuation</h3>
            </div>
            <div class="section-content">
              <div v-if="fundamental_info.EnterpriseValue" class="info-row">
                <span class="info-label">EnterpriseValue:</span>
                <span class="info-value">{{ fundamental_info.EnterpriseValue }}</span>
              </div>
              <div v-if="fundamental_info.EnterpriseValueEbitda" class="info-row">
                <span class="info-label">EnterpriseValueEbitda:</span>
                <span class="info-value">{{ fundamental_info.EnterpriseValueEbitda }}</span>
              </div>
              <div v-if="fundamental_info.EnterpriseValueRevenue" class="info-row">
                <span class="info-label">EnterpriseValueRevenue:</span>
                <span class="info-value">{{ fundamental_info.EnterpriseValueRevenue }}</span>
              </div>
            </div>
          </div>
          
          <!-- Employees Section -->
          <div class="info-section">
            <div class="section-header">
              <h3>Número de empleados</h3>
            </div>
            <div class="section-content">
              <div v-if="cargando_numero_empleados" class="loading-container">
                <div class="loading-spinner"></div>
              </div>
              <div v-else>
                <div v-if="no_employees_info" class="no-data-message">
                  <p>No tenemos información de este ticker</p>
                </div>
                <div class="chart-container" v-else>
                  <FundamentalChart :series="employees_data" :chart_id="employees_highchart" :title="employees_title" ref="FundamentalChartEmployees" />
                </div>
              </div>
            </div>
          </div>
          
          <!-- ESG Scores Section -->
          <div v-if="this.ESGexist && (ESGScores.TotalESG || ESGScores.EnvironmentScore || ESGScores.SocialScore || ESGScores.GovernanceScore || ESGScores.ControversyLevel)" class="info-section">
            <div class="section-header">
              <h3>ESG Scores</h3>
            </div>
            <div class="section-content">
              <div v-if="ESGScores.TotalESG" class="info-row">
                <span class="info-label">TotalESG:</span>
                <span class="info-value">{{ ESGScores.TotalESG }}</span>
              </div>
              <div v-if="ESGScores.EnvironmentScore" class="info-row">
                <span class="info-label">EnvironmentScore:</span>
                <span class="info-value">{{ ESGScores.EnvironmentScore }}</span>
              </div>
              <div v-if="ESGScores.SocialScore" class="info-row">
                <span class="info-label">SocialScore:</span>
                <span class="info-value">{{ ESGScores.SocialScore }}</span>
              </div>
              <div v-if="ESGScores.GovernanceScore" class="info-row">
                <span class="info-label">GovernanceScore:</span>
                <span class="info-value">{{ ESGScores.GovernanceScore }}</span>
              </div>
              <div v-if="ESGScores.ControversyLevel" class="info-row">
                <span class="info-label">ControversyLevel:</span>
                <span class="info-value">{{ ESGScores.ControversyLevel }}</span>
              </div>
            </div>
          </div>
          
          <!-- Website Section -->
          <div v-if="this.url" class="info-section">
            <div class="section-header">
              <h3>Página web</h3>
            </div>
            <div class="section-content">
              <div class="info-row website-row">
                <a class="website-link" @click="_openNewWindow()">{{ this.url }}</a>
              </div>
            </div>
          </div>
          
          <!-- Financials Section -->
          <div v-if="balance_data || cash_flow_data || income_statement_data" class="info-section">
            <div class="section-header">
              <h3>Financials</h3>
            </div>
            <div class="section-content">
              <!-- Balance -->
              <div v-if="balance_data" class="financial-chart-container">
                <div class="chart-header">
                  <span class="chart-title">Balance:</span>
                  <span class="chart-currency">{{ balance_currency }}</span>
                </div>
                <div class="chart-container">
                  <FundamentalChart :series="balance_data" :chart_id="balance_highchart" :title="balance_title" ref="FundamentalChartBalance" />
                </div>
              </div>
              
              <!-- Cash Flow -->
              <div v-if="cash_flow_data" class="financial-chart-container">
                <div class="chart-header">
                  <span class="chart-title">Cash Flow:</span>
                  <span class="chart-currency">{{ cash_flow_currency }}</span>
                </div>
                <div class="chart-container">
                  <FundamentalChart :series="cash_flow_data" :chart_id="cash_flow_highchart" :title="cash_flow_title" ref="FundamentalChartCashFlow" />
                </div>
              </div>
              
              <!-- Income Statement -->
              <div v-if="income_statement_data" class="financial-chart-container">
                <div class="chart-header">
                  <span class="chart-title">Resultados de ingresos:</span>
                  <span class="chart-currency">{{ income_statement_currency }}</span>
                </div>
                <div class="chart-container">
                  <FundamentalChart :series="income_statement_data" :chart_id="income_statement_highchart" :title="income_statement_title" ref="FundamentalChartIncomeStatement" />
                </div>
              </div>
            </div>
          </div>
          
          <!-- Earnings Section -->
          <div class="info-section">
            <div class="section-header">
              <h3>Ganancias por acción</h3>
            </div>
            <div class="section-content">
              <div class="chart-container">
                <FundamentalChart :series="earnings_data" :chart_id="earnings_highchart" :title="earnings_title" ref="FundamentalChartEarnings" />
              </div>
            </div>
          </div>
          
          <!-- Outstanding Shares Section -->
          <div class="info-section">
            <div class="section-header">
              <h3>Acciones en mercado</h3>
            </div>
            <div class="section-content">
              <div class="chart-container">
                <FundamentalChart :series="outstanding_shares_data" :chart_id="outstanding_shares_highchart" :title="outstanding_shares_title" ref="FundamentalChartOutstandingShares" />
              </div>
            </div>
          </div>
          
          <!-- Holders Section -->
          <div v-if="this.table_body && this.table_body.length > 0" class="info-section">
            <div class="section-header">
              <h3>Holders</h3>
            </div>
            <div class="section-content">
              <div class="table-container">
                <table class="custom-table">
                  <thead>
                    <tr>
                      <th v-for="(header, index) in table_header" :key="index">{{ header }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, rowIndex) in table_body" :key="rowIndex">
                      <td v-for="(header, colIndex) in table_header" :key="colIndex">
                        {{ row[header] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
          <!-- Officers Section -->
          <div v-if="this.table_body_officers && this.table_body_officers.length > 0" class="info-section">
            <div class="section-header">
              <h3>Ejecutivos</h3>
            </div>
            <div class="section-content">
              <div class="table-container">
                <table class="custom-table">
                  <thead>
                    <tr>
                      <th v-for="(header, index) in table_header_officers" :key="index">{{ header }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, rowIndex) in table_body_officers" :key="rowIndex">
                      <td v-for="(header, colIndex) in table_header_officers" :key="colIndex">
                        {{ row[header] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
          <!-- Activities Section -->
          <div v-if="this.table_body_activities != false" class="info-section">
            <div class="section-header">
              <h3>Participación en actividades</h3>
            </div>
            <div class="section-content">
              <div class="table-container">
                <table class="custom-table">
                  <thead>
                    <tr>
                      <th v-for="(header, index) in table_header_activities" :key="index">{{ header }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, rowIndex) in table_body_activities" :key="rowIndex">
                      <td v-for="(header, colIndex) in table_header_activities" :key="colIndex">
                        {{ row[header] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div v-else class="no-data-container">
        <div class="info-ticker-box">
          <p class="text-info-ticker">Ticker: <span class="text-bold">{{ getFormattedSelectedTickerProperty(["ticker"]) }}</span></p>
        </div>
        <p class="no-data-message">Actualmente no disponemos de un análisis fundamental de este ticker.</p>
      </div>
    </div>
    
    <div v-else>
      <div class="not-available-data-container">
        <div class="info-ticker-box">
          <p class="text-info-ticker">Ticker: <span class="text-bold">{{ getFormattedSelectedTickerProperty(["ticker"]) }}</span></p>
        </div>
        <p class="not-available-data"> Su plan actual no tiene acceso a esta herramienta. </p>
        <a href="/app/store" class="upgrade-link"> Pulse aquí para cambiar de plan. </a>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from 'vue';
import { mapGetters } from "vuex";
import HeaderSidebarRight from './HeaderSidebarRight.vue';
import FundamentalChart from './Fundamentals/FundamentalChart.vue';
import APICaller from "@/components/codeComponents/APICaller.js";
import Highcharts from "highcharts";

export default {
  mixins: [APICaller],
  name: 'Fundamental',
  components:{
    HeaderSidebarRight,
    FundamentalChart
  },
  data: function() {
    return {
      last_stock_loaded : "",
      id_header: 'fundamental-header',
      title_header: 'Análisis fundamental',
      employees_highchart: "highchart-content-employees",
      employees_data: [],
      employees_title: "Empleados",
      balance_highchart: "highchart-content-balance",
      balance_data: [],
      balance_title: "Balance",
      balance_currency: "",
      cash_flow_highchart: "highchart-content-cash-flow",
      cash_flow_data: [],
      cash_flow_title: "Cash Flow",
      cash_flow_currency: "",
      income_statement_highchart: "highchart-content-income-statement",
      income_statement_data: [],
      income_statement_title: "Income Statement",
      income_statement_currency: "",
      earnings_highchart: "highchart-content-earnings",
      earnings_data: [],
      earnings_title: "Beneficios por acción",
      outstanding_shares_highchart: "highchart-content-outstanding-shares",
      outstanding_shares_data: [],
      outstanding_shares_title: "Acciones en el mercado",
      is_shown: "",
      fundamental_available: false,
      fundamental_info: {
        SharesOutstanding: "",
        SharesFloat: "",
        PercentInsiders: "",
        PercentInstitutions: "",
        BookValue: "",
        PEGRatio: "",
        PERatio: "",
        EnterpriseValue: "",
        EnterpriseValueEbitda: "",
        EnterpriseValueRevenue: "",
      },
      table_header: ['Instituciones','Acciones actuales'],
      table_body: [],

      table_header_officers: ['Nombre','Cargo'],
      table_body_officers: [],

      table_header_activities: ['Actividad','Involucramiento'],
      table_body_activities: [],

      ESGScores: {
        TotalESG: "",
        EnvironmentScore: "",
        SocialScore: "",
        GovernanceScore: "",
        ControversyLevel: "",
      },
      ESGexist: false,
      url: "",
      cargando_numero_empleados: true,
      no_employees_info: false,
    }
  },
  watch: {
    active_right_component: {
      handler: function(newVal, oldVal) {
        if (newVal == 'fundamental') {
          this._initHighcharts();
          if(this._g_UserFeatures.permissions.fundamental_analisis){
            this._getFundamentalInfo();
          }
        }
      },
      deep: true,
    },
    selected_stock: {
      handler: function(newVal, oldVal) {
        if (this.active_right_component == "fundamental") {
          if(this._g_UserFeatures.permissions.fundamental_analisis){
            this._getFundamentalInfo();
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
  },
  created: function() {
  },
  computed: {
    ...mapGetters({selected_stock : "_g_SelectedTicker"}),
    ...mapGetters({active_right_component : "_g_Active_right_component"}),
    ...mapGetters(['_g_User']),
    ...mapGetters(['_g_UserFeatures']),
  },
  methods: {
    _initHighchartsEmployees: function () {
      var intervalInitHighChart = setInterval(() => {
        if (this.$refs.FundamentalChartEmployees != undefined) {
          this.$refs.FundamentalChartEmployees._initHighcharts();
          clearInterval(intervalInitHighChart);
        }
      }, 230);
    },
    _initHighchartsBalance: function () {
      var intervalInitHighChart = setInterval(() => {
        if (this.$refs.FundamentalChartBalance != undefined) {
          this.$refs.FundamentalChartBalance._initHighcharts();
          clearInterval(intervalInitHighChart);
        }
      }, 230);
    },
    _initHighchartsCashFlow: function () {
      var intervalInitHighChart = setInterval(() => {
        if (this.$refs.FundamentalChartCashFlow != undefined) {
          this.$refs.FundamentalChartCashFlow._initHighcharts();
          clearInterval(intervalInitHighChart);
        }
      }, 230);
    },
    _initHighchartsIncomeStatement: function () {
      var intervalInitHighChart = setInterval(() => {
        if (this.$refs.FundamentalChartIncomeStatement != undefined) {
          this.$refs.FundamentalChartIncomeStatement._initHighcharts();
          clearInterval(intervalInitHighChart);
        }
      }, 230);
    },
    _initHighchartsEarnings: function () {
      var intervalInitHighChart = setInterval(() => {
        if (this.$refs.FundamentalChartEarnings != undefined) {
          this.$refs.FundamentalChartEarnings._initHighcharts();
          clearInterval(intervalInitHighChart);
        }
      }, 230);
    },
    _initHighchartsOutstandingShares: function () {
      var intervalInitHighChart = setInterval(() => {
        if (this.$refs.FundamentalChartOutstandingShares != undefined) {
          this.$refs.FundamentalChartOutstandingShares._initHighcharts();
          clearInterval(intervalInitHighChart);
        }
      }, 230);
    },
    _initHighcharts: function () {
      this._initHighchartsBalance();
      this._initHighchartsCashFlow();
      this._initHighchartsIncomeStatement();
      this._initHighchartsEarnings();
      this._initHighchartsOutstandingShares();
      this._initHighchartsEmployees();
    },
    _sortByDate: function(data) {
      return data.sort((a, b) => {
        if (a[0] > b[0]) {
          return 1;
        }
        if (a[0] < b[0]) {
          return -1;
        }
      });
    },

    _getDataFromScrapper: function(){
      this.cargando_numero_empleados = true
      this.employees_data = [] 
      let success = (response) => {
        this.cargando_numero_empleados = false   
        if(response.data.length == 1 && response.data[0].employees == "0"){
          this.no_employees_info = true
        }else{
          this.no_employees_info = false
          let data = response.data
          data.forEach((element) =>{
            this.employees_data.push([parseFloat(element.year), parseFloat(element.employees)])
          })
          this._initHighchartsEmployees()
        }
      };

      let url = '/api/v1/stocks/scrap_employees/' + this.selected_stock.ticker;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('313', 'Error recuperando los datos de empleados.');
      const apiCallConfig = {loadingOverlay: false, timeoutMs: 0};

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },
    _addDataToHighchartBalance: function(data_feed){
      this.balance_data = [];
      Object.values(data_feed.Financials.Balance_Sheet.yearly).forEach(currentValue => {
        var newElement = [currentValue.date, parseFloat(currentValue.cash)];
        this.balance_data.push(newElement);
      });
      this.balance_data = this.balance_data.reverse();
      this.balance_data = this._sortByDate(this.balance_data);
      this._initHighchartsBalance();
    },
    _addDataToHighchartCashFlow: function(data_feed){
      this.cash_flow_data = [];
      Object.values(data_feed.Financials.Cash_Flow.yearly).forEach(currentValue => {
        var newElement = [currentValue.date, parseFloat(currentValue.endPeriodCashFlow)];
        this.cash_flow_data.push(newElement);
      });
      this.cash_flow_data = this.cash_flow_data.reverse();
      this.cash_flow_data = this._sortByDate(this.cash_flow_data);

      this._initHighchartsCashFlow();
    },
    _addDataToHighchartIncomeStatement: function(data_feed){
      this.income_statement_data = [];
      Object.values(data_feed.Financials.Income_Statement.yearly).forEach(currentValue => {
        var newElement = [currentValue.date, parseFloat(currentValue.totalRevenue)];
        this.income_statement_data.push(newElement);
      });
      this.income_statement_data = this.income_statement_data.reverse();
      this.income_statement_data = this._sortByDate(this.income_statement_data);

      this._initHighchartsIncomeStatement();
    },
    _addDataToHighchartEarnings: function(data_feed){
      this.earnings_data = [];
      Object.values(data_feed.Earnings.Annual).forEach(currentValue => {
        var newElement = [currentValue.date, parseFloat(currentValue.epsActual)];
        this.earnings_data.push(newElement);
      });
      this.earnings_data = this.earnings_data.reverse();
      this.earnings_data = this._sortByDate(this.earnings_data);

      this._initHighchartsEarnings();
    },
    _addDataToHighchartOutstandingShares: function(data_feed){
      this.outstanding_shares_data = [];
      Object.values(data_feed.outstandingShares.annual).forEach(currentValue => {
        var newElement = [currentValue.dateFormatted, parseFloat(currentValue.shares)];
        this.outstanding_shares_data.push(newElement);
      });
      this.outstanding_shares_data = this.outstanding_shares_data.reverse();
      this.outstanding_shares_data = this._sortByDate(this.outstanding_shares_data);

      this._initHighchartsOutstandingShares();
    },

    _addHoldersToTable: function(holders){
      this.table_body = [];
      for(var i=0; i < holders.length; i++){
        var name = holders[i].name;
        var acciones = holders[i].currentShares;
        this.table_body.push({'Instituciones' : name.toString(), 'Acciones actuales' : acciones.toString()});
      } 
    },

    _addOfficers: function(officers){
      this.table_body_officers = [];
      for(var i=0; i < officers.length; i++){
        var name = officers[i].Name;
        var cargo = officers[i].Title;
        this.table_body_officers.push({'Nombre' : name.toString(), 'Cargo' : cargo.toString()});
      } 

    },

    _addActivities: function(activities){
      this.table_body_activities = [];
      for(var i=0; i < activities.length; i++){
        var name = this._translateActivities(activities[i].Activity);
        var involucramiento = activities[i].Involvement;
        this.table_body_activities.push({'Actividad' : name.toString(), 'Involucramiento' : involucramiento.toString()});
      } 

    },

    _getFundamentalInfo : function() {
      var tickerName = this.selected_stock.ticker;
      if (this.last_stock_loaded != tickerName) {
        this.last_stock_loaded = tickerName;
        let success = (response) => {
          if (response.data.Highlights != undefined &&
          response.data.Valuation != undefined &&
          response.data.Financials != undefined) {
            this.fundamental_available = true;
            this.fundamental_info.SharesOutstanding = response.data.SharesStats.SharesOutstanding;
            this.fundamental_info.SharesFloat = response.data.SharesStats.SharesFloat;
            this.fundamental_info.PercentInsiders = response.data.SharesStats.PercentInsiders.toFixed(2);
            this.fundamental_info.PercentInstitutions = response.data.SharesStats.PercentInstitutions.toFixed(2);
            this.fundamental_info.BookValue = response.data.Highlights.BookValue;
            this.fundamental_info.PEGRatio = response.data.Highlights.PEGRatio;
            this.fundamental_info.PERatio = response.data.Highlights.PERatio;
            this.fundamental_info.EnterpriseValue = response.data.Valuation.EnterpriseValue;
            this.fundamental_info.EnterpriseValueEbitda = response.data.Valuation.EnterpriseValueEbitda;
            this.fundamental_info.EnterpriseValueRevenue = response.data.Valuation.EnterpriseValueRevenue;
            this.balance_currency = response.data.Financials.Balance_Sheet.currency_symbol;
            this.cash_flow_currency = response.data.Financials.Cash_Flow.currency_symbol;
            this.income_statement_currency = response.data.Financials.Income_Statement.currency_symbol;

            if(response.data.General.WebURL != undefined){
              this.url = response.data.General.WebURL;
            }
            if(response.data.Holders != null){
              this._addHoldersToTable(response.data.Holders.Institutions);
            }else{
              this._addHoldersToTable([]);
            }
            this._addOfficers(response.data.General.Officers);
            if(response.data.ESGScores != undefined){
              this.ESGexist = true;
              this._addActivities(response.data.ESGScores.ActivitiesInvolvement);
              this.ESGScores.TotalESG = response.data.ESGScores.TotalEsg;
              this.ESGScores.GovernanceScore = response.data.ESGScores.GovernanceScore;
              this.ESGScores.SocialScore = response.data.ESGScores.SocialScore;
              this.ESGScores.ControversyLevel = response.data.ESGScores.ControversyLevel;
              this.ESGScores.EnvironmentScore = response.data.ESGScores.EnvironmentScore;
            }else{
              this.table_body_activities = false;
            }

            this._addDataToHighchartBalance(response.data);
            this._addDataToHighchartCashFlow(response.data);
            this._addDataToHighchartIncomeStatement(response.data);
            this._addDataToHighchartEarnings(response.data);
            this._addDataToHighchartOutstandingShares(response.data);
            this._getDataFromScrapper();
          }            
          else {
            this.fundamental_available = false;
          }
        };
        let url = '/api/v1/stocks/fundamental/' + tickerName;

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('050', 'Error al obtener análisis fundamental.');

        this._getAPICall(url, successHandler, failureHandler);
      }
    }, 
    getFormattedSelectedTickerProperty(prop_array) {
      const selected_ticker = this.selected_stock;

      let goal = "";
      prop_array.forEach(prop => {
        goal = selected_ticker[prop];
        if(goal == undefined) {
          return "";
        }
      });

      try {
        const formattedGoal = goal.toFixed(2);
        return formattedGoal;
      }
      catch(e) {
        return goal;
      }
    },

    _translateActivities: function(activity){
      var res="";
      if(activity == "adult"){res="de adultos";}
      else if(activity == "alcoholic"){res="alcohol"}
      else if(activity == "animalTesting"){res="experimentación con animales"}
      else if(activity == "catholic"){res="católico"}
      else if(activity == "controversialWeapons"){res="armas de gran calibre"}
      else if(activity == "smallArms"){res="armas de pequeño calibre"}
      else if(activity == "furLeather"){res="piel de cuero"}
      else if(activity == "gambling"){res="juegos de azar"}
      else if(activity == "gmo"){res="transgénico"}
      else if(activity == "militaryContract"){res="contrato militar"}
      else if(activity == "nuclear"){res="nuclear"}
      else if(activity == "pesticides"){res="pesticidas"}
      else if(activity == "palmOil"){res="aceite de palma"}
      else if(activity == "coal"){res="carbón"}
      else if(activity == "tobacco"){res="tabaco"}

      return res;
    },

    _openNewWindow: function(){
        window.open(this.url, '_blank').focus();
    }
  },
}
</script>


<style lang="scss" scoped>
  @import "@/styles/basics/globals.scss";

  #fundamental {
    width: 100%;
  }

  .fundamental-content-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background);
    box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
    width: 85%;
  }

  .info-ticker-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
  
  .text-info-ticker {
    text-align: center;
    background-color: var(--color-surface, #F3F4F7);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 8px 30px;
    border-radius: 20px;
    color: var(--color-text-primary);
    font-weight: 500;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .text-bold {
    font-weight: 700;
    color: var(--primary, #00aedd);
  }
  
  .info-sections {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

  .info-section {
    background-color: var(--color-surface, #F3F4F7);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(15, 15, 26, 0.05);
    transition: transform 0.2s, box-shadow 0.2s;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(15, 15, 26, 0.08);
    }
  }

  .section-header {
    background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
    padding: 12px 20px;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
    
    h3 {
      margin: 0;
      color: var(--primary, #00aedd);
      font-size: 16px;
      font-weight: 600;
      text-align: left;
    }
  }

  .section-content {
    padding: 15px 20px;
  }

  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 0;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.04));
    
    &:last-child {
      border-bottom: none;
    }
  }

  .info-label {
    color: var(--color-text-secondary, #606577);
    font-size: 14px;
    font-weight: 500;
    flex-shrink: 0;
    margin-right: 15px;
    width: 40%;
    text-align: left;
  }

  .info-value {
    color: var(--color-text-primary);
    font-size: 14px;
    font-weight: 600;
    width: 60%;
    text-align: right;
    word-break: break-word;
  }

  .chart-container {
    margin: 15px 0;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid var(--color-outline, rgba(15, 15, 26, 0.08));
    background-color: var(--color-surface, #F3F4F7);
    height: 250px;
    position: relative;
  }

  .financial-chart-container {
    margin-bottom: 25px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .chart-title {
    color: var(--color-text-secondary, #606577);
    font-size: 14px;
    font-weight: 500;
  }

  .chart-currency {
    color: var(--color-text-primary);
    font-size: 14px;
    font-weight: 600;
    padding: 3px 8px;
    background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
    border-radius: 12px;
  }

  .table-container {
    overflow-x: auto;
    margin: 10px 0;
  }

  .website-row {
    justify-content: center;
  }
  
  .website-link {
    color: var(--primary, #00aedd);
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    padding: 30px 0;
  }

  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid var(--color-outline, rgba(0, 0, 0, 0.08));
    border-radius: 50%;
    border-top: 4px solid var(--primary, #00aedd);
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .no-data-container {
    max-width: 600px;
    margin: 20px auto;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background);
    box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
    width: 85%;
  }

  .no-data-message {
    color: var(--color-text-secondary, #606577);
    font-size: 14px;
    padding: 15px 0;
  }

  .not-available-data-container {
    max-width: 600px;
    margin: 20px auto;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background);
    box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
    width: 85%;
    text-align: center;
  }

  .not-available-data {
    color: var(--warning, #FF9B00);
    font-size: 16px;
    margin-bottom: 15px;
  }

  .upgrade-link {
    color: var(--primary, #00aedd);
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    
    &:hover {
      text-decoration: underline;
    }
  }

  #highchart-content-balance,
  #highchart-content-employees,
  #highchart-content-cash-flow,
  #highchart-content-income-statement,
  #highchart-content-earnings,
  #highchart-content-outstanding-shares {
    height: 100%;
    width: 100%;
    border: none;
    position: relative;
    overflow: visible;
  }

  .highcharts-container {
    width: 100% !important;
    height: 100% !important;
    overflow: visible !important;
  }

  .highcharts-root {
    width: 100% !important;
    height: 100% !important;
    overflow: visible !important;
  }

  /* Fix for Highcharts responsive layout in this component */
  :deep(.highcharts-container),
  :deep(.highcharts-root) {
    width: 100% !important;
    height: 100% !important;
    overflow: visible !important;
  }
  
  :deep(.highcharts-series-group) {
    overflow: visible !important;
  }
  
  :deep(.highcharts-point) {
    stroke-width: 0;
  }
  
  :deep(.highcharts-grid-line) {
    stroke-dasharray: 2, 2;
  }
  
  :deep(.highcharts-plot-border) {
    stroke-width: 0;
  }

  /* Mobile responsiveness */
  @media (max-width: 767px) {
    .fundamental-content-container,
    .no-data-container,
    .not-available-data-container {
      padding: 15px;
      width: 92%;
    }
    
    .info-row {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      padding: 10px 0;
    }
    
    .info-label {
      font-weight: 600;
      margin-bottom: 4px;
      width: 100%;
    }
    
    .info-value {
      width: 100%;
      text-align: left;
    }
    
    .section-header h3 {
      font-size: 15px;
    }
    
    .chart-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
  }

  /* Custom table styles */
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    background-color: var(--color-background);
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .custom-table th {
    background-color: var(--color-muted, #00aedd);
    color: white;
    font-weight: 600;
    text-align: left;
    padding: 12px 15px;
    border-bottom: 1px solid var(--color-outline, rgba(15, 15, 26, 0.12));
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .custom-table th:last-child {
    border-right: none;
  }

  .custom-table td {
    color: var(--color-text-primary);
    padding: 10px 15px;
    border-bottom: 1px solid var(--color-outline, rgba(15, 15, 26, 0.08));
    border-right: 1px solid var(--color-outline, rgba(15, 15, 26, 0.08));
    background-color: var(--color-background);
  }
  
  .custom-table td:last-child {
    border-right: none;
  }

  .custom-table tr:last-child td {
    border-bottom: none;
  }

  .custom-table tr:hover td {
    background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
  }
</style>