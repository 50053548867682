<template>
    <div>
        <div v-if="local_array_stocks_filters.length != 0">
            <div class="inlined" v-if="crypto_enabled==false">
                <h4 class="tittle-name">
                    Montante:
                </h4>
                <div v-if="_g_UserFeatures.permissions.amount_filter">
                    <DigitOutlineInputFilters
                    :id="'filters-second-montante-inf'"
                    :label="'Límite inferior'"
                    @inputChanged="saveMontanteInf"
                    class="material-input"
                    />
                    <DigitOutlineInputFilters
                    :id="'filters-second-montante-sup'"
                    :label="'Límite superior'"
                    @inputChanged="saveMontanteSup"
                    class="material-input"
                    />
                </div>
                <div v-else style="margin-bottom: 10px;">
                    <FilterNoPermission
                        :link="buy_link"
                    ></FilterNoPermission>
                </div>
            </div>

            <div class="inlined">
                <h4 class="tittle-name">
                    Distancia de la entrada:
                </h4>
                <div v-if="_g_UserFeatures.permissions.entry_distance_filter">
                    <SelectOutline v-for="(iSelect, selectIndex) in second_config[1].componentsConfig" :key="selectIndex" :options="iSelect.options" :helper_select_permission="iSelect.helper_select_permission" :default_value="local_array_stocks_filters[selectIndex+2].value" :helper_text_select="iSelect.helper_text_select" :component_class="iSelect.component_class" class="inlined second-mobile-select" @_emit_method_order_select="_getValueFromSelect"/>
                </div>
                <div v-else>
                    <FilterNoPermission
                        :link="buy_link"
                    ></FilterNoPermission>
                </div>
            </div>

            <div class="inlined">
                <h4 class="tittle-name">
                    Edad:
                </h4>
                <div v-if="_g_UserFeatures.permissions.years_filter">
                    <SelectOutline v-for="(iSelect, selectIndex) in second_config[2].componentsConfig" :key="selectIndex" :options="iSelect.options" :helper_select_permission="iSelect.helper_select_permission" :default_value="local_array_stocks_filters[selectIndex+4].value" :helper_text_select="iSelect.helper_text_select" :component_class="iSelect.component_class" class="inlined second-mobile-select" @_emit_method_order_select="_getValueFromSelect"/>
                </div>
                <div v-else>
                    <FilterNoPermission
                        :link="buy_link"
                    ></FilterNoPermission>
                </div>
            </div>

            <div class="inlined" v-if="crypto_enabled==false">
                <h4 class="tittle-name">
                    Dividendos:
                </h4>
                <div v-if="_g_UserFeatures.permissions.dividends_filter">
                    <CheckBox v-for="(iCheckbox, checkboxIndex) in second_config[3].componentsConfig" :key="checkboxIndex" :label_name="iCheckbox.label_name" :checkbox_key="iCheckbox.checkbox_key" :checkbox_id="iCheckbox.checkbox_id" :checkbox_state="local_array_stocks_filters[checkboxIndex+6].value" class="inlined" @_emit_method_checkbox="_getValueFromCheckbox"/>
                </div>
                <div v-else>
                    <FilterNoPermission
                        :link="buy_link"
                    ></FilterNoPermission>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import CheckBox from "@/components/helpers/CheckBox";
//import CheckBoxDisabled from "@/components/helpers/CheckBox_disabled";
import SecondConfig from "@/components/stockchart/components/Finder/configs/Second.json";
import SecondConfigCripto from "@/components/stockchart/components/Finder/configs/Second_cripto.json";
import SelectOutline from '@/components/helpers/newMaterial/SelectInputFilters.vue';
//import SelectOutlineDisabled from "@/components/helpers/SelectOutline_disabled";
import DigitOutlineInputFilters from "@/components/helpers/newMaterial/DigitOutlineInputFilters";
//import InputTextOutlineDisabled from "@/components/helpers/InputTextOutline_disabled";
import FilterNoPermission from "@/components/stockchart/components/Finder/FilterNoPermission";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
    mixins: [APICaller],
    name: 'Second_mobile',
    components: {
        CheckBox,
        SelectOutline,
        DigitOutlineInputFilters,
        //CheckBoxDisabled,
        //InputTextOutlineDisabled,
        FilterNoPermission,
        //SelectOutlineDisabled
    },
    props: {
        filters_user_json:{type: Object},
    },
    data: () => ({
        second_config: SecondConfig,
        second_config_cripto: SecondConfigCripto,
        local_array_stocks_filters: [],

        montante_permission:"",
        distancia_entrada_permission:"",
        edad_permission:"",
        dividendos_permission:"",
        changes: "",

        finder: "",
        permissions_loaded: false,
        buy_link: ""
    }),
    mounted() {
        this.buy_link = this.$detectEnvironment() + '/app/store';
    },
    created(){
        this.finder = this._g_FinderFilters(); 
        var created_interval = setInterval(() => {
            if (this.filters_user_json != "") {
                this._getFinderParameters();
                clearInterval(created_interval);
            }
        }, 200);
    },
    watch: {
        
    },
    computed: {
        ...mapGetters({crypto_enabled:'_g_crypto_enable'}),
        ...mapGetters(["_g_UserFeatures"]),
    },
    methods: {
        ...mapGetters(['_g_FinderFilters']),
        ...mapMutations(['setFinderFilters','setRechargeState']),
        _getValueFromCheckbox: function(value){
            if(this.show_criptos == false){
                if(value[1] == "key_0"){this.finder["dividends"][0] = value[0];}
                if(value[1] == "key_1"){this.finder["dividends"][1] = value[0];}
                if(value[1] == "key_2"){this.finder["dividends"][2] = value[0];}
                if(value[1] == "key_3omas"){this.finder["dividends"][3] = value[0];}
                this._updateLastSearch2();
            }
        },

         _getValueFromSelect: function(value){
            
            if(this.show_criptos){
                if(value[1] == "Entrada inferior"){ this.local_array_criptos_filters[7].value = value[0];}
                if(value[1] == "Entrada superior"){ this.local_array_criptos_filters[8].value = value[0];}

                if(value[1] == "edadInferior"){ this.local_array_criptos_filters[9].value = value[0];}
                if(value[1] == "edadSuperior"){ this.local_array_criptos_filters[10].value = value[0];}
            }else {
                if(value[1] == "Entrada inferior"){this.finder["min_entry_distance"] = value[0];}
                if(value[1] == "Entrada superior"){this.finder["max_entry_distance"] = value[0];}

                if(value[1] == "edadInferior"){this.finder["min_edad"] = parseInt(value[0]);}
                if(value[1] == "edadSuperior"){this.finder["max_edad"] = parseInt(value[0]);}
                this._updateLastSearch2();
            }
        },

        _getValueFromInput: function(value){

            if(this.show_criptos == false){
                if(value[1] == "montanteInferior"){this.finder["min_amount"] = parseInt(value[0]);}
                if(value[1] == "montanteSuperior"){this.finder["max_amount"] = parseInt(value[0]);}
                this._updateLastSearch2();
            }
        },

        _getFinderParameters: function(){
            this.finder = this._g_FinderFilters();
            this._dataToNewFormat();
        },

        _dataToNewFormat: function(){
            let max_entry_value = "";

            let max_edad = "";
            let min_edad = "";

            if(this.finder["max_entry_distance"] == 25){
                max_entry_value = "25% o más";
            }else{
                max_entry_value = this.finder["max_entry_distance"] + "%";
            }

            if(this.finder["min_edad"] == ""){
            
                min_edad = "< 1";
            }else{
                min_edad = this.finder["min_edad"].toString();
            }
            if(this.finder["max_edad"] == ""){
                max_edad = "> 25";
            }else{
                max_edad = this.finder["max_edad"].toString();
            }

            var finder = this.finder;            
            var checkbox = [
                {
                    name : "min_amount",
                    value: finder["min_amount"].toString(),                
                },
                {
                    name : "max_amount",
                    value: finder["max_amount"].toString(),                    
                },
                {
                    name : "min_entry_distance",
                    value: finder["min_entry_distance"] + "%",                    
                },
                {
                    name : "max_entry_distance",
                    value: max_entry_value,                    
                },
                {
                    name : "min_edad",
                    value: min_edad,                    
                },
                {
                    name : "max_edad",
                    value: max_edad, 
                },{
                    name : "dividend_0",
                    value: finder["dividends"][0],
                },{
                    name : "dividend_1",
                    value: finder["dividends"][1], 
                },{
                    name : "dividend_2",
                    value: finder["dividends"][2], 
                },{
                    name : "dividend_3ormore",
                    value: finder["dividends"][3],
                }
            ];
            this.local_array_stocks_filters = checkbox;

        },
        _updateLastSearch2: function(){
            this.setFinderFilters(this.finder);
            this.$emit("updateLastSearch", this.finder);
        }, 

        saveMontanteInf: function(value) {
            const res = value == "" ? 0 : value;
            this.finder["min_amount"] = parseInt(value);
            this._updateLastSearch2();
        },

        saveMontanteSup: function(value) {
            const res = value == "" ? 0 : value;
            this.finder["max_amount"] = parseInt(value);
            this._updateLastSearch2();
        },
    },
}
</script>

<style scoped>
    .inlined {
        display: inline-block;
        margin: 0 30px 0 0;
    }
    .tittle-name{
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        letter-spacing: 0.1px;

        /* 02. Secondary/400 */

        color: #626777;
        cursor: default;
    }
    .information-text{
        visibility: hidden;
        width: 120px;
        background-color: #151929;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        margin-left: 2px;
    }
    .interrogant-container:hover .information-text{
        visibility: visible;
    }

    #filters-second-montante-inf-component {
        margin-right: 30px;
    }

    @media (max-width: 900px){
        #filters-second-montante-inf-component, #filters-second-montante-sup-component, .inlined.second-mobile-select {
            margin-top: 15px;
            width: 150px;    
        }
    }
</style>