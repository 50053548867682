<template>
    <div class="first" :key="force_re_render">
        <div v-if="local_array_stocks_filters.length != 0">
            <div class="inlined" v-if="!(_g_User.roles[21] || _g_User.roles[20])">
                <div class="items-container">
                    <h4 class="tittle-name first-lower-title">
                        Rentabilidad
                    </h4>
                </div>
                <div>
                    <CheckBox class="checkbox" v-for="(cbox, cboxIndex) in first_config[0].componentsConfig" :key="cboxIndex" :label_name="cbox.label_name" :checkbox_key="cbox.checkbox_key" :checkbox_id="cbox.checkbox_id" :checkbox_state="local_array_stocks_filters[cboxIndex].value" :disabled="true" @_emit_method_checkbox="_getValueFromCheckbox" />        
                </div>
            </div>

            <div class="inlined" v-if="!(_g_User.roles[21] || _g_User.roles[20])">
                <div class="items-container">
                    <h4 class="tittle-name first-lower-title">
                        Timing
                    </h4>
                </div>
                <div>
                    <CheckBox class="checkbox" v-for="(cbox, cboxIndex) in first_config[1].componentsConfig" :key="cboxIndex" :label_name="cbox.label_name" :checkbox_key="cbox.checkbox_key" :checkbox_id="cbox.checkbox_id" :checkbox_state="local_array_stocks_filters[cboxIndex+5].value" @_emit_method_checkbox="_getValueFromCheckbox" />   
                </div>
                
            </div>

            <div class="inlined" v-if="!(_g_User.roles[21] || _g_User.roles[20])">
                <div class="items-container">
                    <h4 class="tittle-name first-lower-title">
                        Dirección
                    </h4>
                </div>
                <div>
                     <CheckBox class="checkbox" v-for="(cbox, cboxIndex) in first_config[2].componentsConfig" :key="cboxIndex" :label_name="cbox.label_name" :checkbox_key="cbox.checkbox_key" :checkbox_id="cbox.checkbox_id" :checkbox_state="local_array_stocks_filters[cboxIndex+7].value" @_emit_method_checkbox="_getValueFromCheckbox" />   
                </div>
            </div>

            <div v-if="_g_User.roles[21] || _g_User.roles[20]" class="inlined">
                <div class="items-container">
                    <h4 class="tittle-name first-lower-title">
                        Tipo
                    </h4>
                </div>
                <div class="checkbox-container">
                    <div v-for="(cbox, cboxIndex) in first_config[5].componentsConfig" :key="cboxIndex">
                        <span v-if="cbox.label_name == 'MKT'">
                            <CheckBox class="checkbox" :label_name="cbox.label_name" :checkbox_key="cbox.checkbox_key" :checkbox_id="cbox.checkbox_id" :checkbox_state="local_array_stocks_filters[cboxIndex+14].value" @_emit_method_checkbox="_getValueFromCheckbox" /> 
                        </span>
                        <span v-else>
                            <CheckBox class="checkbox" :label_name="cbox.label_name" :checkbox_key="cbox.checkbox_key" :checkbox_id="cbox.checkbox_id" :checkbox_state="local_array_stocks_filters[cboxIndex+9].value" @_emit_method_checkbox="_getValueFromCheckbox" /> 
                        </span>
                    </div>
                </div>
            </div>
            <div v-else class="inlined">
                <div class="items-container">
                    <h4 class="tittle-name first-lower-title">
                        Tipo
                    </h4>
                </div>
                <div>
                    <CheckBox class="checkbox" v-for="(cbox, cboxIndex) in first_config[4].componentsConfig" :key="cboxIndex" :label_name="cbox.label_name" :checkbox_key="cbox.checkbox_key" :checkbox_id="cbox.checkbox_id" :checkbox_state="local_array_stocks_filters[cboxIndex+9].value" @_emit_method_checkbox="_getValueFromCheckbox" /> 
                </div>
            </div>

            <div class="inlined" v-if="!(_g_User.roles[21] || _g_User.roles[20])">
                <div class="items-container">
                    <h4 class="tittle-name first-lower-title">
                        Tipo de valor
                    </h4>
                </div>
                <div>
                    <CheckBox class="checkbox" v-for="(cbox, cboxIndex) in first_config[3].componentsConfig" :key="cboxIndex" :label_name="cbox.label_name" :checkbox_key="cbox.checkbox_key" :checkbox_id="cbox.checkbox_id" :checkbox_state="local_array_stocks_filters[cboxIndex+16].value" @_emit_method_checkbox="_getValueFromCheckbox" /> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import CheckBox from "@/components/helpers/CheckBox";
//import CheckBoxDisabled from "@/components/helpers/CheckBox_disabled";
import FirstConfig from "@/components/stockchart/components/Finder/configs/First.json";
import FirstConfigCripto from "@/components/stockchart/components/Finder/configs/First_cripto.json";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
    mixins: [APICaller],
    name: 'First_mobile',
    components: {
        CheckBox,
        //CheckBoxDisabled,
    },
    props: {
        filters_user_json:{type: Object},        
    },
    data: () => ({
        first_config: FirstConfig,
        first_config_cripto: FirstConfigCripto,
        force_re_render: 0,
        active: false,
        rentabilidad_permission:"",
        timing_permission:"",
        direccion_permission:"",
        tipo_permission:"",
        tipo_valor_permission:"",
        changes: true,
        local_array_stocks_filters: [],
        finder: "",
        permissions_loaded: false,
        buy_link: ""
    }),
    mounted() {
        this.buy_link = this.$detectEnvironment() + '/app/store';
    },
    created: function() {        
        this.finder = this._g_FinderFilters();
        var created_interval = setInterval(() => {
            if (this.filters_user_json != "" && this._g_FinderFilters() != undefined && this._g_FinderFilters() != []) {
                this._getFinderParameters();
                clearInterval(created_interval);
            }
        }, 200);
    },
    computed: {
        ...mapGetters({crypto_enabled:'_g_crypto_enable'}),
        ...mapGetters(['_g_User']),
    },
     watch: {
        
    },
    methods: {
        ...mapGetters(['_g_FinderFilters', "_g_user_info"]),
        ...mapMutations(['setFinderFilters','setRechargeState']),
        _getUserAttributtes: function(uid){
            this.filters_user_json = this._g_user_info["user_features"]["dynamic_stockchart"];
        },
        _dataToNewFormat: function(){
            var finder = this.finder;        
            var checkbox = [
                {
                    name : "<35",
                    value: finder["tae_checkboxes"]["M"],                
                },
                {
                    name : "35-75",
                    value: finder["tae_checkboxes"]["A"],                    
                },
                {
                    name : "75-100",
                    value: finder["tae_checkboxes"]["B"],                    
                },
                {
                    name : "150-400",
                    value: finder["tae_checkboxes"]["C"],                    
                },
                {
                    name : ">400",
                    value: finder["tae_checkboxes"]["D"],                    
                },
                {
                    name : "mensual",
                    value: finder.es_ipo[0], 
                },{
                    name : "IPO",
                    value: finder.es_ipo[1],
                },{
                    name : "bajista",
                    value: finder["direccio"][0], 
                },{
                    name : "alcista",
                    value: finder["direccio"][1], 
                },{
                    name : "PER",
                    value: finder["entry_type"]["per"],
                },{
                    name : "RR",
                    value: finder["entry_type"]["0"],
                },{
                    name : "RRA",
                    value: finder["entry_type"]["1"],
                },{
                    name : "LT",
                    value: finder["entry_type"]["2"],
                },{
                    name : "LTA",
                    value: finder["entry_type"]["3"],
                },{
                    name  : "SE",
                    value: finder["entry_type"]["6"],
                },{
                    name  : "MKT",
                    value: finder["entry_type"]["7"],
                },{
                    name : "stock",
                    value: finder["value_type"]["Stock"],
                },{
                    name : "ETF",
                    value: finder["value_type"]["ETF"],
                },
            ];
            this.local_array_stocks_filters = checkbox;

        },
        _getValueFromCheckbox: function(value){
            if(value[1] == "key_less35"){
                this.finder["tae_checkboxes"]["M"] = value[0];
            }
            if(value[1] == "key_35-75"){
                this.finder["tae_checkboxes"]["A"] = value[0];
            }
            if(value[1] == "key_75-150"){
                this.finder["tae_checkboxes"]["B"] = value[0];
            }
            if(value[1] == "key_150-400"){
                this.finder["tae_checkboxes"]["C"] = value[0];
            }
            if(value[1] == "key_more400"){
                this.finder["tae_checkboxes"]["D"] = value[0];
            }
            if(value[1] == "key_mensual"){
                this.finder.es_ipo[0] = value[0];
            }
            if(value[1] == "key_IPO"){
                this.finder.es_ipo[1] = value[0];
            }
            if(value[1] == "key_bajista"){
                this.finder["direccio"][0] = value[0];
            }
            if(value[1] == "key_alcista"){
                this.finder["direccio"][1] = value[0];
            }
            if(value[1] == "key_stock"){
                this.finder["value_type"]["Stock"] = value[0];
            }
            if(value[1] == "key_ETF"){
                this.finder["value_type"]["ETF"] = value[0];
            }
            if(value[1] == "key_PER"){
                this.finder["entry_type"]["per"] = value[0];
            }
            if(value[1] == "key_RR"){
                this.finder["entry_type"]["0"] = value[0];
            }
            if(value[1] == "key_RRA"){
                this.finder["entry_type"]["1"] = value[0];
            }
            if(value[1] == "key_LT"){
                this.finder["entry_type"]["2"] = value[0];
            }
            if(value[1] == "key_LTA"){
                this.finder["entry_type"]["3"] = value[0];
            }
            if(value[1] == "key_SE"){
                this.finder["entry_type"]["6"] = value[0];
            }
            if(value[1] == "key_MKT"){
                this.finder["entry_type"]["7"] = value[0];
            }         
            this._updateLastSearch2();
        },
        _getFinderParameters: function(){
            this.finder = this._g_FinderFilters();
            this._dataToNewFormat();
        },
        _updateLastSearch2: function(){
            this.setFinderFilters(this.finder);
            this.$emit("updateLastSearch", this.finder);
        },       
    },
}
</script>

<style scoped>
    @media (max-width: 900px){
        .first{
            width:100%;
        }
    }
    .inlined {
        display: inline-block;
        margin: 0 30px 0 0;
    }
    .tittle-name{
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        letter-spacing: 0.1px;

        /* 02. Secondary/400 */

        color: #626777;
        margin: 10px 0 0 8px;
        cursor: default;
    }
    .interrogant-btn{
        border-radius: 100%;
        height: 16px;
        border: none;
        color: white;
        background-color: #00aedd;
        cursor: pointer;
        font-weight: bold;
        font-size: 11px;
    }
    .information-text{
        visibility: hidden;
        width: 120px;
        background-color: #151929;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        margin-left: 2px;
    }
    .interrogant-container:hover .information-text{
        visibility: visible;
    }

    .tittle-name {
        color: var(--color-typography-on-background) !important;
        margin-top: 25px;
    }
</style>