<template>
    <div class="rectangle-peak">
        <div class="portfolios-title-container">
            <div class="title-wrapper">
                <span class="material-icons-outlined header-icon">account_balance_wallet</span>
                <h3 class="name-container">Valores de mi cartera</h3>
            </div>
            <span class="close-container" @click="_emitCloseComponent">
                <span class="material-icons-outlined">close</span>
            </span>
        </div>
        <hr />
        <div class="portfolios-container">
            <div>
                <div class="menu-item" @click="_redirectToWaitingsSearch()">
                    <span class="material-icons-outlined menu-icon">schedule</span>
                    <span class="menu-text">Valores en espera</span>
                </div>
                <div class="menu-item" @click="_redirectToOpenPositionsSearch()">
                    <span class="material-icons-outlined menu-icon">show_chart</span>
                    <span class="menu-text">Valores en cartera</span>
                </div>
                <div class="menu-item" @click="_redirectToStockSearch()">
                    <span class="material-icons-outlined menu-icon">list</span>
                    <span class="menu-text">Ver todos</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import APICaller from "@/components/codeComponents/APICaller.js";
    import { mapGetters } from "vuex";

    export default{
        mixins: [APICaller],
        name: "ValoresCartera",
        components:{
        },
        data: () =>({
            portfolios: [],
        }),
        created(){

        },
        watch: {
        },
        computed:{
            ...mapGetters(['_g_portfolio_id_selected'])
        },
        mounted(){
            this._getPortfolios()
        },
        methods:{
            _getPortfolios: function(){
                let success = (response) => {            
                    this.portfolios = response.data
                };
                let url = '/api/v1/portfolio/list';

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('029.2', 'Error cargando las carteras de usuario.');

                this._getAPICall(url, successHandler, failureHandler);

            },
            _emitCloseComponent: function(){
                this.$emit("close-component")
            },
            _redirectToStockSearch: function(){
                this.$router.push('/app/graficador-dinamico/all-values/' + this._g_portfolio_id_selected);
            },
            _redirectToWaitingsSearch: function(){
                this.$router.push('/app/graficador-dinamico/waitings/' + this._g_portfolio_id_selected);
            },
            _redirectToOpenPositionsSearch: function(){
                this.$router.push('/app/graficador-dinamico/open-positions/' + this._g_portfolio_id_selected);
            },
        }
    }

</script>

<style scoped>
/* Mimic Simulator.vue modal container */
.rectangle-peak {
    position: relative;
    width: 360px; /* Match typical modal width */
    background: var(--color-background, white); /* Match Simulator light theme background */
    box-shadow: 0 4px 20px rgba(15, 15, 26, 0.15); /* Match Simulator shadow */
    border-radius: 8px;
    color: var(--color-text-primary, #0F0F1A); /* Match Simulator light theme text */
    padding: 0; /* Remove outer padding, apply to inner sections */
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Ensure border-radius clips content */
}

.portfolios-container {
    max-height: 180px;
    overflow-y: auto;
    padding: 10px 20px 20px 20px; /* Match Simulator body padding */
}

/* Scrollbar styles adapted for light theme */
.portfolios-container::-webkit-scrollbar {
    width: 8px;
}

.portfolios-container::-webkit-scrollbar-track {
    background: var(--color-surface, #F3F4F7); /* Light theme surface */
    border-radius: 4px;
}

.portfolios-container::-webkit-scrollbar-thumb {
    background-color: var(--color-text-secondary, #B0B4C3); /* Lighter grey for light theme */
    border-radius: 4px;
    border: 2px solid var(--color-surface, #F3F4F7); /* Match track */
}

/* Mimic Simulator.vue modal header */
.portfolios-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px; /* Match Simulator header padding */
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12)); /* Light theme outline */
}

.title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-icon {
    color: #00AEDD;
    font-size: 22px;
}

.name-container {
    font-weight: 600;
    font-size: 18px;
    color: var(--color-text-primary, #0F0F1A); /* Light theme text */
    margin: 0;
    letter-spacing: 0.3px;
}

/* Mimic Simulator.vue modal close button */
.close-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: var(--color-text-secondary, #606577); /* Standard secondary text */
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.close-container:hover {
    color: var(--color-text-primary, #0F0F1A); /* Light theme text */
    background-color: var(--color-surface, #F3F4F7); /* Light theme surface */
}

.close-container .material-icons-outlined {
    font-size: 20px;
}

hr {
    display: none; /* Remove hr, header border is now the separator */
}

.menu-item {
    margin-bottom: 5px;
    padding: 10px 12px;
    color: var(--color-text-secondary, #606577); /* Standard secondary text */
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.menu-item:hover {
    color: #00AEDD;
    background-color: var(--color-surface, #F3F4F7); /* Light theme surface */
}

.menu-icon {
    font-size: 20px;
    color: var(--color-text-secondary, #606577); /* Match text color */
    transition: color 0.2s ease-in-out; /* Add transition for icon color */
}

.menu-item:hover .menu-icon {
    color: #00AEDD; /* Icon color on hover */
}

.menu-text {
    font-size: 14px;
    font-weight: 500;
}
</style>