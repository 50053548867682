<template>
    <div id="alarms">
        <HeaderSidebarRight :id_header="id_header" :title_header="title_header" />
        <div class="alarm-container">
            <div class="info-ticker-box">
                <p class="text-info-ticker">Ticker: <span class="text-bold">{{ _g_SelectedTicker.ticker }}</span></p>
            </div>
            
            <div class="operation-config-container">
              <div class="select-wrapper">
                <label for="field-select" class="input-label">Crear alarma cuando el/la</label>
                <select 
                  id="field-select" 
                  class="styled-select"
                  v-model="field_selected_post"
                  @change="_getValueFromFieldSelect">
                  <option value="valor_actual">Cotización</option>
                  <option value="soport">Soporte</option>
                  <option value="entrada">Entrada</option>
                  <option value="out_price">Stop</option>
                  <option value="entry_distance">Distancia de entrada</option>
                </select>
              </div>

              <div class="select-wrapper">
                <label for="operation-select" class="input-label">sea</label>
                <select 
                  id="operation-select" 
                  class="styled-select"
                  v-model="operation_selected"
                  @change="_getValueFromOperationSelect($event)">
                  <option v-for="(option, index) in options_select_operation" 
                    :key="index" 
                    :value="option.name">
                    {{ option.value }}
                  </option>
                </select>
              </div>

              <div class="select-wrapper">
                <label for="value-input" class="input-label">{{ value_input_label }}</label>
                <input 
                  type="text" 
                  id="value-input" 
                  class="styled-input"
                  v-model="value_selected"
                  placeholder="Introduce un valor"
                  @input="_validateNumericInput($event)">
              </div>
            </div>
            
            <div class="btns-container">
              <button id="alarm-button" 
                @click="_postAlarm"  
                class="action-btn primary-btn">
                <i class="material-icons" v-if="button_icon !== 'none'">{{ button_icon }}</i>
                <span>{{ button_name }}</span>
              </button>
            </div>
            
            <hr class="separator" />
            
            <div class="pending-alarms-section">
              <div class="section-header" @click="toggleAlarmsList">
                <h3 class="section-title">Alarmas pendientes ({{ _g_SelectedTicker.ticker }})</h3>
                <i class="material-icons expand-icon" :class="{ 'expanded': showAlarmsList }">keyboard_arrow_down</i>
              </div>
              
              <div class="pending-alarms-list" v-show="showAlarmsList">
                <div v-for="(item, index) in filtered_pending_alarms" :key="index" class="pending-alarm-item">
                  <div class="alarm-item-header">
                    <p class="alarm-date">{{item.created}}</p>
                    <button class="icon-button delete-button" @click="_deleteAlarm(item.id)">
                      <i class="material-icons">delete</i>
                    </button>
                  </div>
                  <p class="alarm-description">Alarma planeada: <strong>{{item.ticker_name}}</strong> {{item.alert_field_string}} {{item.alert_mode_string}} {{item.alert_data}}</p>
                </div>
                
                <div v-if="filtered_pending_alarms.length === 0" class="no-alarms-message">
                  No hay alarmas pendientes para {{ _g_SelectedTicker.ticker }}
                </div>
              </div>
            </div>
            
            <div class="executed-alarms-section">
              <div class="section-header" @click="toggleExecutedAlarmsList">
                <h3 class="section-title">Alarmas ejecutadas ({{ _g_SelectedTicker.ticker }})</h3>
                <i class="material-icons expand-icon" :class="{ 'expanded': showExecutedAlarmsList }">keyboard_arrow_down</i>
              </div>
              
              <div class="pending-alarms-list" v-show="showExecutedAlarmsList">
                <div v-for="(item, index) in filtered_executed_alarms" :key="index" class="executed-alarm-item">
                  <div class="alarm-item-header">
                    <p class="alarm-date">{{item.created}}</p>
                    <p class="execution-date">Ejecutada: {{item.executed_date || item.modified}}</p>
                  </div>
                  <p class="alarm-description">Alarma ejecutada: <strong>{{item.ticker_name}}</strong> {{item.alert_field_string}} {{item.alert_mode_string}} {{item.alert_data}}</p>
                </div>
                
                <div v-if="filtered_executed_alarms.length === 0" class="no-alarms-message">
                  No hay alarmas ejecutadas para {{ _g_SelectedTicker.ticker }}
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderSidebarRight from './HeaderSidebarRight.vue';
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
    mixins:[APICaller],
    name: 'Alarmas',
    components: {
      HeaderSidebarRight
    },
    props: {
    },
    data: () => ({
        id_header: 'alarms_header',
        title_header: 'Alarmas',

        options_select_operation: [
          {name:"<", value: "Menor que"},
          {name: "=", value: "Igual a"},
          {name: ">", value: "Mayor que"},
        ],
        
        operation_selected: ">",
        field_selected_post: "valor_actual",
        field_selected_text: "Cotización",

        value_input_label: "este valor:",
        value_input_helper: "",
        value_selected: "0",

        button_name: "Guardar alarma",
        button_icon: "none",
        button_key: "key-alarm-button",

        all_user_alarms: [],
        uid: "",
        showAlarmsList: true,
        showExecutedAlarmsList: false,
    }),
  mounted() {
    this.initUserInfo();
    var waiting_for_uid = setInterval(() => {
      if(this.uid && this.uid != undefined && this.uid != ""){
        this._getAlarms()
        clearInterval(waiting_for_uid)
      }
    }, 200)

    var waiting_for_ticker = setInterval(()=>{
      if(this._g_SelectedTicker.resistance && this._g_SelectedTicker.resistance != undefined){
        if(!isNaN(parseFloat(this._g_SelectedTicker.resistance).toFixed(2))){
          this.value_selected = parseFloat(this._g_SelectedTicker.resistance).toFixed(2)
        }else{
          this.value_selected = "";
        }
        clearInterval(waiting_for_ticker)
      }
    }, 200)
  },
  created: function() {
  },
  watch: {
    _g_SelectedTicker(newVal, oldVal) {
      if (newVal && newVal.ticker !== oldVal?.ticker) {
        if(!isNaN(parseFloat(newVal.resistance))){
          this.value_selected = parseFloat(newVal.resistance).toFixed(2)
        } else {
          this.value_selected = "";
        }
      }
    }
  },
  computed: {
    ...mapGetters(['_g_SelectedTicker', "_g_User", "_g_UserFeatures"]),

    filtered_pending_alarms() {
      if (!this._g_SelectedTicker || !this._g_SelectedTicker.ticker) {
        return [];
      }
      return this.all_user_alarms.filter(alarm =>
        alarm.status == 0 && alarm.ticker_name === this._g_SelectedTicker.ticker
      );
    },

    filtered_executed_alarms() {
      if (!this._g_SelectedTicker || !this._g_SelectedTicker.ticker) {
        return [];
      }
      return this.all_user_alarms.filter(alarm =>
        alarm.status == 1 && alarm.ticker_name === this._g_SelectedTicker.ticker
      );
    },
  },
  methods: {
    toggleAlarmsList: function() {
      this.showAlarmsList = !this.showAlarmsList;
    },
    
    toggleExecutedAlarmsList: function() {
      this.showExecutedAlarmsList = !this.showExecutedAlarmsList;
    },
    
    _getValueFromFieldSelect: function(){
      // Update any dependent logic if needed when field changes
    },

    _validateNumericInput: function(event) {
      // Get the current input value
      let value = event.target.value;
      
      // Allow empty input
      if (value === '') {
        this.value_selected = '';
        return;
      }
      
      // Replace commas with dots for consistency
      value = value.replace(',', '.');
      
      // Only allow numbers and up to one decimal point with max 2 decimal places
      const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
      
      // Test if current value is valid
      if (regex.test(value)) {
        this.value_selected = value;
      } else {
        // For partially valid inputs like "123." (valid but incomplete)
        if (/^[0-9]+\.$/.test(value)) {
          this.value_selected = value;
          return;
        }
        
        // Try to extract a valid number from the input
        const numericValue = value.replace(/[^0-9.]/g, '');
        
        // Handle cases with multiple decimal points
        const parts = numericValue.split('.');
        if (parts.length > 2) {
          // Keep only first decimal point
          const validValue = parts[0] + '.' + parts.slice(1).join('');
          
          // Limit to 2 decimal places
          const match = validValue.match(/^([0-9]+\.[0-9]{0,2}).*/);
          if (match) {
            this.value_selected = match[1];
          } else {
            // Fallback: use the value before limiting decimals if match fails
            // This case might happen if input is like "123.456."
            const fallbackMatch = validValue.match(/^([0-9]+\.[0-9]{0,2})/);
             if(fallbackMatch){
                this.value_selected = fallbackMatch[1];
             } else {
                // If still no match (e.g., just "."), keep the cleaned numeric value
                this.value_selected = parts[0]; // Keep integer part if decimal part is invalid
             }
          }
        } else if (parts.length === 2 && parts[1].length > 2) {
          // Limit to 2 decimal places if decimal part exists and is too long
          this.value_selected = parts[0] + '.' + parts[1].substring(0, 2);
        } else if (/^[0-9]+$/.test(numericValue) || /^[0-9]+\.$/.test(numericValue) || /^[0-9]+\.[0-9]{0,2}$/.test(numericValue)) {
           // Use the cleaned numeric value if it's a valid integer, integer followed by dot, or valid decimal
           this.value_selected = numericValue;
        } else {
            // If input is completely invalid after cleaning (e.g., "."), reset or handle appropriately
            // Attempt to salvage the integer part if possible
            const intPartMatch = numericValue.match(/^[0-9]+/);
            this.value_selected = intPartMatch ? intPartMatch[0] : '';
        }
        
        // Update the input field ONLY if the model value changed due to correction
        if (event.target.value !== this.value_selected) {
            event.target.value = this.value_selected;
        }
      }
    },

    _deleteAlarm: function(id){
      let params = new URLSearchParams();
      params.append('uid', this.uid);
      params.append('alert_id', id);

      let success = response =>{
        this._getAlarms()
      }

      let url = "/api/v1/eurekers-alerts/delete"

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('241', 'Error borrando alarma.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    _getValueFromOperationSelect: function(event){
      this.operation_selected = event.target.value;
    },
    
    _getValueFromInputValue: function(event){
      this.value_selected = event.target.value;
    },

    _getAlarms: function(){
      let params = new URLSearchParams();
      params.append('uid', this.uid);

      let success = response =>{
        // Store all alarms, computed properties will handle filtering
        this.all_user_alarms = response.data || [];
      }

      let url = "/api/v1/eurekers-alerts/get-user-alerts"

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('242', 'Error recuperando alarmas.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    _postAlarm: function(){
      if(this.value_selected == ""){
        this.$vueOnToast.pop("error", "Por favor configure un valor para la alarma.")
      }else{
        let params = new URLSearchParams();
        params.append('uid', this.uid);
        params.append('ticker', this._g_SelectedTicker.ticker);
        params.append('alert_field', this.field_selected_post);
        params.append('alert_mode', this.operation_selected);
        params.append('alert_data', this.value_selected);
  
        let success = response =>{
            this.$vueOnToast.pop("success", "Alarma creada correctamente")
            this._getAlarms();
        }
  
        let url = "/api/v1/eurekers-alerts/create"
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('243', 'Error al creat la nueva alarma.');

        // Check against filtered pending alarms for *this* ticker? Or total alarms?
        // Let's assume the limit applies to total active alarms for the user.
        const totalPendingAlarms = this.all_user_alarms.filter(a => a.status == 0).length;

        if(totalPendingAlarms < this._g_UserFeatures.permissions.simultaneous_active_alerts){
          this._postAPICall(url, params, successHandler, failureHandler);
        }else{
          this.$vueOnToast.pop("error", "Ha alcanzado el número máximo de alarmas activas para su plan.");
        }
      }
    },

    initUserInfo: function(){
      this.uid = this._g_User.uid;
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "@/styles/basics/globals.scss";

  #alarms {
    width: 100%;
  }

  .alarm-container {
    max-width: 600px;
    margin: 20px auto;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background);
    box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
    width: 85%;
  }

  .info-ticker-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .text-info-ticker {
    text-align: center;
    background-color: var(--color-surface, #F3F4F7);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 8px 30px;
    border-radius: 20px;
    color: var(--color-text-primary);
    font-weight: 500;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .text-bold {
    font-weight: 700;
    color: var(--primary, #00aedd);
  }

  .operation-config-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 25px 0;
  }

  .select-wrapper, .input-wrapper {
    width: 100%;
  }
  
  .input-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-primary, #0F0F1A);
    text-align: left;
  }

  .styled-select {
    width: 100%;
    height: 40px;
    padding: 8px 15px;
    font-size: 16px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    appearance: none;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23606577'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px;
    box-sizing: border-box;
  }

  .styled-select:focus {
    outline: none;
    border-color: var(--primary, #00aedd);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.1);
  }

  .styled-input {
    width: 100%;
    height: 40px;
    padding: 8px 15px;
    font-size: 16px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    -moz-appearance: textfield; /* Firefox */
    box-sizing: border-box;
  }

  .styled-input::-webkit-outer-spin-button,
  .styled-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .styled-input:focus {
    outline: none;
    border-color: var(--primary, #00aedd);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.1);
  }

  .btns-container {
    margin: 30px 0;
    text-align: center;
  }

  .action-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 40px;
    transition: all 0.2s ease;
    min-width: 200px;
    font-weight: 600;
  }

  .primary-btn {
    background-color: var(--primary, #00aedd);
    color: white;
    box-shadow: none;
  }

  .primary-btn:hover {
    background-color: var(--color-muted, #35add8);
    box-shadow: none;
  }

  .separator {
    border: none;
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    margin: 25px 0;
  }

  .pending-alarms-section, .executed-alarms-section {
    margin-top: 20px;
  }

  .executed-alarms-section {
    margin-top: 25px;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
  }

  .section-title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text-primary, #0F0F1A);
  }

  .expand-icon {
    color: var(--color-text-secondary, #606577);
    font-size: 24px;
    transition: transform 0.3s ease;
  }
  
  .expand-icon.expanded {
    transform: rotate(180deg);
  }

  .pending-alarms-list {
    margin-top: 10px;
  }

  .pending-alarm-item, .executed-alarm-item {
    margin-bottom: 12px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 5px;
    padding: 5px 0;
  }
  
  .pending-alarm-item {
    border-left: 5px solid var(--primary, #00aedd);
  }
  
  .executed-alarm-item {
    border-left: 5px solid var(--color-success, #57a869);
  }

  .alarm-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
  }

  .alarm-date, .execution-date {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-primary, #0F0F1A);
    margin: 0;
  }
  
  .execution-date {
    font-size: 12px;
    color: var(--color-success, #57a869);
  }

  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .delete-button i {
    font-size: 18px;
    color: var(--color-text-secondary, #606577);
    transition: color 0.2s ease;
  }

  .delete-button:hover i {
    color: var(--color-error, #B00020);
  }

  .alarm-description {
    font-size: 12px;
    line-height: 1.4;
    color: var(--color-text-primary, #0F0F1A);
    padding: 0 15px 10px;
    margin: 0;
  }

  .no-alarms-message {
    text-align: center;
    padding: 20px 0;
    color: var(--color-text-secondary, #606577);
    font-style: italic;
  }

  /* Mobile responsiveness */
  @media (max-width: 767px) {
    .alarm-container {
      width: 90%;
      padding: 15px;
    }
    
    .operation-config-container {
      gap: 12px;
    }
    
    .action-btn {
      width: 100%;
    }
  }
</style>