<template>
  <div id="stockchart-menu-secundario" class="mdc-menu mdc-menu-surface stockchart-menu-secundario" style="z-index: 3; overflow-y: auto;">
    <div v-if="_g_IsUserLogged && !_g_User.is_only_authenticated_user">
      <div v-if="showPlatinumScore" class="mdc-list-item" style="background-color: white" title="Puntuación Platino">
        <div class="platinum-display">
            <span class="typo-headline-4">{{Math.round(getFormattedSelectedTickerProperty(["platinum_compatibility"]) * 100) }}</span>
            <span class="material-label typo-caption">Puntos</span>
        </div>
      </div>
      <div v-else class="mdc-list-item plat-button-container" style="background-color: white" @click="getPlatinumScoreForTicker()" title="Puntuación Platino">
        <div class="platinum-display">
          <i class="material-icons mdc-button__icon\">visibility</i>
          {{_g_UserFeatures.consumibles.platinum_eyes_remaining}}
        </div>
      </div>
    </div>
    <div v-else class="mdc-list-item" style="background-color: white; cursor: not-allowed;" title="Puntuación Platino">
      <div class="platinum-display">
        <button class="plat-score-pc-btn" @click="_noPermissionPlatinum()"><i class="material-icons mdc-button__icon\">visibility</i></button>
      </div>
    </div>
    <ul v-if="_g_IsUserLogged && _g_SelectedTicker.isEureker != false && !_g_User.is_only_authenticated_user" class="mdc-list" role="menu" aria-hidden="true" aria-orientation="vertical" tabindex="-1">
      <div v-for="(item, index) in sidebar_right_menu" :key="index">
        <li v-if="_checkBrokerDisplay(item)" class="mdc-list-item" role="menuitem" :data-componentName="item.name" :data-componentWidth="item.width" style="width: 80px;" :class="item.name" :title="item.title">
          <span>
            <span class="mdc-list-item__text">
                <span class="material-icons-outlined md32 item-icon">{{item.icon}}<div v-if="item.name == 'broker-connection'" class="broker-connection-status not-connected" id="broker-connection-icon-status"></div></span>
                <span class="material-label-ekers typo-caption">{{item.label}}</span>
            </span>
          </span>
        </li>
      </div>
    </ul>

    <ul v-if="_g_SelectedTicker.isEureker == false" class="mdc-list" role="menu" aria-hidden="true" aria-orientation="vertical" tabindex="-1">
      
      <li v-for="(item, index) in sidebar_right_menu" :key="index" class="mdc-list-item" role="menuitem" style="cursor: not-allowed" @click="_toastNoEureker()" :title="item.title">
        <span>
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32 item-icon" style="cursor: not-allowed">{{item.icon}}</span>
              <span class="material-label-ekers typo-caption">{{item.label}}</span>
          </span>
        </span>
      </li>
    </ul>

    <ul v-if="!_g_IsUserLogged || _g_User.is_only_authenticated_user" class="mdc-list" role="menu" aria-hidden="true" aria-orientation="vertical" tabindex="-1">
      
      <li v-for="(item, index) in sidebar_right_menu" :key="index" class="mdc-list-item" role="menuitem" style="cursor: not-allowed" @click="_toastError()" :title="item.title">
        <span>
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32 item-icon" style="cursor: not-allowed">{{item.icon}}</span>
              <span class="material-label-ekers typo-caption">{{item.label}}</span>
          </span>
        </span>
      </li>
    </ul>
  </div>
</template>



<script>
  import SidebarRightMenu from "@/components/stockchart/menu/configs/sidebar_right.json";
  import { MDCMenu } from '@material/menu';
  import { mapMutations, mapGetters} from "vuex";
  import APICaller from "@/components/codeComponents/APICaller.js";
import { pick } from 'highcharts';


  export default {
    mixins: [APICaller],
    name: 'StockchartMenuSecundario',
    data() {
      return {
        sidebar_right_menu: SidebarRightMenu,
        sidebar_right_menu_width: 52,
        parent_component: 'sidebar-content',
        tradingview_container: 'dragable-container',

        disable_key_component: '',
        active_key_component: '',
        active_key_component_width: 0,

        last_element_active: 11,

        platinum_score: "",
        plat_score: "",
        ticker_selected: "",
        enable_score: false,
        user_id: "",
        info_rol: "",
        link_to_store: ""
      };
    },

    watch: {
      _g_SelectedTicker: {
        handler: function(){
          this.enable_score = false;
        },
        deep: true
      }
    },
    created(){
      
      this._getUID();
    
      setTimeout(() => {
        this._getUserInfo();
      }, 1000);
    },
    mounted() {
      this.link_to_store = this.$detectEnvironment() + '/app/store';
      const menu = new MDCMenu(document.querySelector('.mdc-menu.stockchart-menu-secundario'));
      const adapter = menu.getDefaultFoundation().adapter;
      if(this._g_IsUserLogged){
        menu.listen('MDCMenu:selected', (event) => {
        
          if(!this.active) {
            if( this.last_element_active >= 0 && this.last_element_active < this.sidebar_right_menu.length){
              adapter.removeClassFromElementAtIndex(this.last_element_active, 'eurekers-parent-menu-active');
            }
            adapter.addClassToElementAtIndex(event.detail.index, 'eurekers-parent-menu-active');
            this._select_associate_component_click_event(menu, event);
            this._hide_associate_before_component_click_event(menu);
            this._show_associate_component_click_event();

            this.last_element_active = event.detail.index;
          
          }
          else if(this.active && event.detail.index != this.last_element_active){          
            if( this.last_element_active >= 0 && this.last_element_active < this.sidebar_right_menu.length){
              adapter.removeClassFromElementAtIndex(this.last_element_active, 'eurekers-parent-menu-active');
              this.setActiveRightComponent("");
            }
            adapter.addClassToElementAtIndex(event.detail.index, 'eurekers-parent-menu-active');
            this._select_associate_component_click_event(menu, event);
            this._hide_associate_before_component_click_event(menu);
            this._show_associate_component_click_event();

            this.last_element_active = event.detail.index;
          }        
          else {
            adapter.removeClassFromElementAtIndex(this.last_element_active, 'eurekers-parent-menu-active');

            this._select_associate_component_click_event(menu, event);
            this._hide_associate_before_component_click_event(menu);
            this._show_associate_component_click_event();          
          }
        });
      }

      

    },
    computed: {
      click_twice_item () {
        return this.active_key_component === this.disable_key_component;
      },
      ...mapGetters(["_g_IsUserLogged", "_g_SelectedTicker", "_g_Active_right_component", "_g_User", "_g_UserFeatures"]),

      active() {
        return this._g_Active_right_component != "";
      },

      showPlatinumScore() {
        const isPlat = this._g_UserFeatures.permissions.eurekers_brain;
        const isPlatEnabledForTicker = this._g_SelectedTicker['platinum_compatibility'] != null;
        
        return isPlat || isPlatEnabledForTicker;
      },
    },
    methods: {
      ...mapMutations(['setSidebarRight', 'setActiveRightComponent', 'setStockPlatinumCompatibilityById']),
      _checkBrokerDisplay: function(value){
        if(value.name != 'broker-connection'){
          return true;
        }else{
          if(this._g_User.is_asistente_dif){
            return false;
          }else{
            return true;
          }
        }
      },
      _clean_behavior() {
        this.active_key_component = '';
        this.last_element_active = 11;
      },      
      _select_associate_component_click_event(menu, event) {
        this.active_key_component = menu['items'][event.detail.index].dataset.componentname;
        this.active_key_component_width = menu['items'][event.detail.index].dataset.componentwidth;

        this._slide_tradingview_container(parseInt(this.active_key_component_width));
        this.setSidebarRight();
      },
      _hide_associate_before_component_click_event(menu) {
        if( this.last_element_active >= 0 && this.last_element_active < this.sidebar_right_menu.length){
          this.disable_key_component = menu['items'][this.last_element_active].dataset.componentname;

          document.getElementById(this.disable_key_component).style.display = 'none';
        }        
      },
      _show_associate_component_click_event() {
        if (this.active) {
          this._close_associate_containers_component();
          this.setActiveRightComponent("");
        } else {
          this.setActiveRightComponent(this.active_key_component);
          this._open_associate_containers_component();
          this.setActiveRightComponent(this.active_key_component);
        }
      },
      _slide_tradingview_container(_width_) {
        let _margin_ = _width_;
        let draggable_item = document.getElementById(this.tradingview_container);
        let draggable_item_style = draggable_item.currentStyle || window.getComputedStyle(draggable_item);

        draggable_item.style.marginRight = this.sidebar_right_menu_width + _margin_ + "px";
        if (parseInt(draggable_item_style.marginRight) < this.sidebar_right_menu_width)  { 
          draggable_item.style.marginRight = "px"; 
        }
      },
      _open_full_width_associate_containers_component() {
      },
      _open_associate_containers_component() {
          document.getElementById(this.active_key_component).style.display = 'block';
          document.getElementById(this.parent_component).style.width = this.active_key_component_width + 'px';
      },
      _close_associate_containers_component() {
          document.getElementById(this.active_key_component).style.display = 'none';
          document.getElementById(this.parent_component).style.width = '1px';
          document.getElementById(this.tradingview_container).style.marginRight = this.sidebar_right_menu_width + "px";
      },

      _setBrokerConnectionStatus: function(status) {
        var icon_status = document.getElementById("broker-connection-icon-status");

        if (status == "true") {
          icon_status.classList.remove("not-connected");
          icon_status.classList.add("connected");
        }
        else {
          icon_status.classList.remove("connected");
          icon_status.classList.add("not-connected");
        }
      },

      _toastError : function(){
        if(this._g_User.is_only_authenticated_user){
          let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
              window.location.replace("/app/store");
            }};
            this.$vueOnToast.pop(toast);
        } else{
          this.$vueOnToast.pop('error', "Si quiere acceder a esta herramienta inicie sesión.");
        }
      },

      _toastNoEureker: function(){
        this.$vueOnToast.pop('error', "No puede acceder a esta herramienta ya que el valor seleccionado no es Eureker.");
      },

      getFormattedSelectedTickerProperty(prop_array) {
        const selected_ticker = this._g_SelectedTicker;

        let goal = "";
        prop_array.forEach(prop => {
          goal = selected_ticker[prop];
          if(goal == undefined) {
            return "";
          }
        });

        try {
          const formattedGoal = goal.toFixed(2);
          return formattedGoal;
        }
        catch(e) {
          return goal;
        }
      },

      getPlatinumScoreForTicker() {
        if(this._g_UserFeatures.consumibles.platinum_eyes_remaining > 0){
          const isSetSelectedTicker = this._g_SelectedTicker && this._g_SelectedTicker.ticker && this._g_SelectedTicker.ticker != "";
          if(isSetSelectedTicker) {
            const stock_id = this._g_SelectedTicker.id;
            const url = '/api/v1/users/user/consume-platinum-credit-for-stock/stock/' + stock_id;
            const success = (response) => {
              const creditsuccessfullySpent = response && response.data && response.data.success;
              if(response.data.data.platinum_compatibility == 0){
                this.$vueOnToast.pop("info", "No hay información de puntuación platino para este ticker actualmente.")
              }
              else if(creditsuccessfullySpent) {
                const platCompVal = response.data.data.platinum_compatibility;
                const mutationPayload = {
                  compatibility: parseFloat(platCompVal),
                  id: parseInt(stock_id)
                };
                this.setStockPlatinumCompatibilityById(mutationPayload);
                this.platinum_score = response.data.data.platinum_score;
                this._g_UserFeatures.consumibles.platinum_eyes_remaining = this._g_UserFeatures.consumibles.platinum_eyes_remaining - 1;
                this.$vueOnToast.pop('success', 'Puede visualizar la puntuación platino. Has consumido 1 crédito.');
              }
              else {
                let toast = {type: 'info', body: 'Suscríbete al plan PLATINO. Pulsa aquí para saber más.', clickHandler: function(){
                  window.location.replace(this.link_to_store);
                }};
                this.$vueOnToast.pop(toast);
              }
            };
  
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('172', 'Error canjeando créditos platino.');
  
            this._getAPICall(url, successHandler, failureHandler);
          }
        }else{
          if(this._g_User.is_pre_eureker_user){
            let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
              window.location.replace("/app/store");
            }};
            this.$vueOnToast.pop(toast);
          } else{
            let toast = {type: 'info', body: 'Suscríbete al plan PLATINO. Pulsa aquí para saber más.', clickHandler: function(){
              window.location.replace(this.link_to_store);
            }};
            this.$vueOnToast.pop(toast);
          }
        }
      },

      _getUID(){
        let success = response =>{
          this.user_id = response.data.uid
        }

        let url = '/api/v1/users/user/uid'

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('173', 'Error recuperando la ID de usuario.');

        this._getAPICall(url, successHandler, failureHandler);
      },

      _getUserInfo: function(){
        this.platinum_score = this._g_User.user_features.platinum_score.score
        this.info_rol = this._g_User.user_features.platinum_score.info;
      },

      _noPermissionPlatinum: function(){
        if(this._g_User.is_only_authenticated_user){
          let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
              window.location.replace("/app/store");
            }};
            this.$vueOnToast.pop(toast);
        } else{
          this.$vueOnToast.pop("error", "Debe inicar sesión para poder usar esta herramienta.");
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @use "@material/list/mdc-list";
  @use "@material/menu/mdc-menu";

  @import "@/components/stockchart/styles/stockchart_menu_secundario.scss";
  @import "@/styles/basics/globals.scss";
  .mdc-menu .mdc-menu-surface .stockchart-menu-secundario{
    z-index: 0;
  }
  

  #dragable {
    height: calc(100vh);
    background: #eaeaea;
    width: 1px;
    cursor: ew-resize;
    z-index: 99;
    position: absolute;

    #trigger {
      position: absolute;
      right: 0px;
      top: 373px;
      background: #333333;
      color: white;
      width: 48px;
      border-radius: 16px;
      text-align: center;
      box-shadow: 1px 1px 2px #1976d2;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        box-shadow: 1px 1px 5px #1576d2;
      }
    }
  }

  li.eurekers-parent-menu-active {
        background: var(--color-custom-active-parent-menu);
        span.material-icons-outlined,
        span.material-label-ekers typo-caption {
            color: #00AEDD;
        }
  }
  #stockchart-menu-secundario{
    position: absolute;
    right: 0;
    overflow: hidden;
    height: 100%;
    border-left: 1px solid #d9dadb;
  }

  .material-label-ekers typo-caption{
    margin-bottom: 10%;
  }
  .mdc-list-item__text{
    margin-bottom: 25%;
  }

  .item-icon{
    margin-top: -20px;
  }

  .broker-connection-status {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 10px;
  }
  .broker-connection-status.connected {
    background: lime;
  }
  .broker-connection-status.not-connected {
    background: red;
  }

  #stockchart-menu-secundario {
    background-color: var(--color-background-surface);
  }

  #stockchart-menu-secundario .mdc-list-item span {
    color: var(--color-typography-on-background) !important;
  }

  #stockchart-menu-secundario .platinum-display span {
    color: black !important;
  }

  .platinum-display{
    display: inline-grid;
    text-align: center;
  }

  .plat-score-pc-btn {
    border: 0;
    background-color: white;
    cursor: not-allowed;
  }

  .plat-button-container {
    padding: 0;
    height: 80px;
  }
  .broker-connection{
    margin-bottom: 110px;
  }

</style>


