<template>
      <div id="stock-error" class="stock-error-style mobile-content">
        <div class="header-container">
          <div class="title-container">
              <span>Reportar error</span>
          </div>
          <div class="close-btn-container">
              <span class="material-icons-outlined" @click="_closeComponent()">close</span>
          </div>
        </div>

        <div class="error-report">
            <div class="form-item form-type-select form-item-selected">
                <div class="info-ticker-box">
                    <p class="text-info-ticker">Ticker: <span class="text-bold">{{ _g_SelectedTicker.ticker }}</span></p>
                </div>
                <!-- <SelectOutline @_emit_method_order_select="_getSelectValue" :helper_select_permission="false" :label_name="label_name_error" :component_class="component_class_error" :options="options_select" /> -->
                
                <div id="select-error-box">
                  <select class="select_material_style dynamic-stockchart-select-error-report form-select" id="edit-selected" v-model="type_selected">
                      <option v-for="(option, index) in type_options" :value="option.value" :key="index">{{ option.text }}</option>
                  </select>
                  <div id="error-helper-text" class="helper-text-error mdc-text-field-helper-text mdc-text-field-helper-text--persistent" aria-hidden="true">Selecciona el tipo de error</div>
                </div>
            </div>

            <!-- <div class="form-item form-type-textarea form-item-error-report-body">
                <div class="form-textarea-wrapper resizable textarea-processed resizable-textarea">
                    <textarea v-model="message" cols="60" rows="5" class="form-textarea required"></textarea>
                    <div class="grippie"></div>
                </div>
            </div> -->
            <div class="error-box">
              <InputTextarea id="error_input" @_emit_method_order_input_textarea="_getInputValue" :ref="input_error.ref" :input_key_to_textarea_values="input_error.input_key_to_textarea_values" :input_placeholder="input_error.input_placeholder" />

              <Button id="error_button" v-if="!send_message" @_emit_method_button="_sendError" :button_icon_permission="true" :button_icon="button_icon" :button_name="button_name" :button_key="button_key_error" :button_icon_id="button_icon_id" />
              <Button v-if="send_message" :button_name="button_name" :button_icon_permission="true" :button_icon="button_icon" :button_key="button_key_error" :button_icon_id="button_icon_id" />
            </div>
        </div>
    </div>
</template>



<script>
import { mapGetters } from "vuex";
import InputErrorJson from "./configs/input_error.json";
// import SelectOutline from "@/components/helpers/SelectOutline.vue";
import InputTextarea from "@/components/helpers/InputTextarea.vue";
import Button from "@/components/helpers/Button.vue";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
    mixins:[APICaller],
    name: 'Error',
    components: {
        InputTextarea,
        Button,
    },
    props: {
        stock: { type: Object },
    },
    data: () => ({
        type_selected: '',
        type_options: [
            { 'value': '', 'text': "Error*" },
            { 'value': 'wrong_dividend', 'text': "Dividendo errorneo" },
            { 'value': 'wrong_split', 'text': "Split errorneo" },
            { 'value': 'wrong_support', 'text': "Soporte erroneo" },
            { 'value': 'wrong_entry', 'text': "Entrada erronea" },
            { 'value': 'wrong_historical', 'text': "Historico errorneo" },
            { 'value': 'wrong_broker_ticker', 'text': "Ticker del broker errorneo" },
            { 'value': 'others', 'text': "Otros" },
        ],
        options_select: [
            { 'name': '', 'value': "Selecciona error" },
            { 'name': 'wrong_dividend', 'value': "Dividendo errorneo" },
            { 'name': 'wrong_split', 'value': "Split errorneo" },
            { 'name': 'wrong_support', 'value': "Soporte erroneo" },
            { 'name': 'wrong_entry', 'value': "Entrada erronea" },
            { 'name': 'wrong_historical', 'value': "Historico errorneo" },
            { 'name': 'wrong_broker_ticker', 'value': "Ticker del broker errorneo" },
            { 'name': 'others', 'value': "Otros" },
        ],
        label_name_error: '',
        component_class_error: 'error_class',

        input_error: InputErrorJson,
        button_name: "",
        button_icon: "done",
        button_key_error: "button_error",
        button_icon_id: "icon_button_error",

        message: '',
        send_message: false,

        id_header: 'stock-error',
        title_header: 'Reportar Error'
    }),
  mounted() {
    this._changeColorButton();
  },
  created: function() {
  },
  computed: {
    ...mapGetters(['_g_SelectedTicker']),
  },
  methods: {

    _getInputValue(value){
      this.message = value[0]  
    },

    // _getSelectValue(value) {
    //     this.type_selected = value[0]
    // },

    resetData: function() {
        this.message = '';
        this.type_selected = '';
        this.send_message = false;
    },

    _sendError: function(){
      var params = new URLSearchParams();
      params.append('ticker', this._g_SelectedTicker.ticker);
      params.append('type', this.type_selected);
      params.append('message', this.message);

      if(this.message != ""){

        let success = () =>{
          this.resetData();
          this.$vueOnToast.pop('success', 'Error reportado, muchas gracias.')
        }

        let url = '/api/v1/stock-finder/report-error'

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('248', 'Error enviando reporte de error.');

        this._postAPICall(url, params, successHandler, failureHandler);

      }else{
        this.$vueOnToast.pop('error', 'Mensaje de error vacio. Error code 005');
      }
    },

    reset_shows: function() {
        this.$emit('reset_shows');
    },

    _changeColorButton() {
      let inputError = document.getElementById("error_input");
         
      inputError.addEventListener('click', function() {
               
        for (let inputTextfieldError of document.getElementsByClassName("error_input")){
          inputTextfieldError.style.backgroundColor = "#EDF8FB";
        }
        let iconColorError = document.getElementById("icon_button_error")
        iconColorError.style.color = "#FFFFFF";
                  
        let buttonColorError = document.getElementById("error_button")
        buttonColorError.style.backgroundColor = "#00AEDD"
      });  0
      
    },

    _closeComponent: function() {
      this.$emit("_closeComponentErrorMobile")
    },
  },
}
</script>


<style lang="scss" scoped>
  @import "@/styles/basics/globals.scss";

  .error-report {
    text-align: center;
  }

  .error-report {
      margin:20px;
  }

  .select_material_style {
    margin-top: 30px; 
    width: 100%;
    background-color: transparent;
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
    height: 56px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    color: #000010;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .mdc-button {
      margin-top: 10px;
  }

  .info-ticker-box {
     width: 100%;
     display: flex;
     justify-content: center;
  }
  .text-info-ticker {
    text-align: center;
    background-color: #F3F4F7;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 26px;
    width: 130px;
    padding: 7px 20px;
  }

  .text-bold {
    font-weight: bold;
  }

  .helper-text-error {
    text-align: left;
    margin-left: 10px;
    margin-bottom: 38px;
  }

  #error-helper-text {
    margin-top: 3px;
    font-size: 12px;
  }

  .error-box {
    display: flex;
    align-items: center;
    width: auto;
  }

  .form-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #select-error-box {
    width: 100%;
  }
  .mobile-content {
    margin-top: 15px;
    height: 460px;
    min-height: 420px;
    overflow-y: scroll;
  }

  .header-container{
    display: flex;
    justify-content: center;
    padding: 10px 0px 35px 0px !important;
  }

  .title-container{
    margin-left: 30px;
    font-size: 20px;
    font-weight: 600;
  }

  .close-btn-container{
    margin-left: auto;
    order: 2;
    margin-right: 10px;
  }

</style>