<template>
  <div v-if="_g_IsUserLogged">
    <div v-if="_g_ShowStockChartMenu" class="mdc-menu mdc-menu-surface mobile-stockchart-menu" style="bottom: 0px; position: fixed; width: 100%;">
      <ul class="mdc-list top-menu-mobile-schart" role="menu" aria-hidden="true" aria-orientation="horizontal" tabindex="-1" style="display: flex; width: 100%">
        <li class="mobile-top" style="width: 25%" @click="_emitPrevTicker()">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32" style="color: white;">chevron_left</span>
          </span>
        </li>
        <li id="1"  class="mobile-top" role="menuitem" data-routername="Stockchart" style="width: 25%; background-color: #00ADD9" @click="_emitChangeChartList();">
          <span>
              <span class="material-icons-outlined md32" id="show-chart" style="color: white;">show_chart</span>
          </span>
        </li>
        <li id="2" class="mobile-top" role="menuitem" data-routername="Stockchart" style="width: 25%" @click="_emitChangeStocksList();">
          <span>
              <span class="material-icons-outlined md32" style="color: white;" id="drag-handle">drag_handle</span>
          </span>
        </li>
        <li id="3" class="mobile-top" style="width: 25%" @click="_emitNextTicker()">
          <span>
              <span class="material-icons-outlined md32" style="color: white;">chevron_right</span>
          </span>
        </li>
      </ul>
    </div>
  </div>

  <div v-else>
    <div v-if="_g_ShowStockChartMenu" class="mdc-menu mdc-menu-surface mobile-stockchart-menu" style="background-color: #F3F4F7">
      <ul class="mdc-list top-menu-mobile-schart" role="menu" aria-hidden="true" aria-orientation="horizontal" tabindex="-1" style="display: flex; width: 100%">
        <li @click="_showFilters" class="mobile-top">
          <span class="mdc-list-item__text">
              <span class="material-icons-outlined md32">chevron_left</span>
          </span>
        </li>
        <li id="1"  class="mobile-top" role="menuitem" data-routername="Stockchart" style="width: 25%; background-color: #00ADD9" @click="_emitChangeChartList();">
          <span>
              <span class="material-icons-outlined md32" id="show-chart" style="color: white;">show_chart</span>
          </span>
        </li>
        <li id="2" class="mobile-top" role="menuitem" data-routername="Stockchart" style="width: 25%" @click="_emitChangeStocksList();">
          <span>
              <span class="material-icons-outlined md32" id="drag-handle">drag_handle</span>
          </span>
        </li>
        <li id="3" class="mobile-top" style="width: 25%">
          <span>
              <span class="material-icons-outlined md32">chevron_right</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>



<script>
  import APICaller from "@/components/codeComponents/APICaller.js";
  import { mapMutations, mapGetters } from "vuex";

  export default {
    mixins:[APICaller],
    name: 'StockchartMenu',
    components: {
    },
    data() {
      return {
        workspaces: [],
        last_element_active: 1,
        show_link_favorites: false,
        sidebar_right_menu_width: 52,
        parent_component: 'sidebar-content',
        tradingview_container: 'dragable-container',
        show_searches: false,
        numberStocks: '',
        workspace: '',
        id: "1",
        selected: "chart",
      };
    },
    watch:{  
      changeSelection: {      
        handler: function() {
          var element2 = document.getElementById(this.lastIndex.toString());
          element2.classList.remove("eurekers-parent-menu-active");            
          this.last_element_active = this.lastIndex;                                   
        },
        deep: true
      },   
    },
    mounted() {
    },
    computed: {
        ...mapGetters(['_g_IsUserLogged']),
        ...mapGetters(['_g_ToggleBetweenTableAndChart']),
        ...mapGetters(['_g_ShowStockChartMenu']),
        ...mapGetters(['_g_ShowStockFinderFilter']),
        ...mapGetters({changeSelection:'_g_MenuSelectionOut'}),
        ...mapGetters({lastIndex:'_g_IndexMenu'}),
        ...mapGetters({stock_type:'_g_GetTypeStocks'}),
        ...mapGetters(['_g_toggle_chart_stocks']),
        ...mapGetters(['_g_open_info']),
        ...mapGetters(['_g_open_fundamental']),
        ...mapGetters(['_g_open_description']),
        ...mapGetters(['_g_open_brain']),
        ...mapGetters(['_g_open_comments']),
        ...mapGetters(['_g_open_notas']),
        ...mapGetters(['_g_open_error']),
        ...mapGetters(['_g_open_order']),
        ...mapGetters(['_g_open_simulator']),
             
    },
    methods: {
        ...mapMutations(['setToggleBetweenListAndChartInStockchart']),
        ...mapMutations(['setShowStockFinderFilters']),
        ...mapMutations(['setSelectedFavoriteWorkspace']),
        ...mapMutations(['setShowStockChartMenu']),
        ...mapMutations(['isMobileDevice']),
        ...mapMutations(['setStockValue']),
        ...mapMutations(['setMenuSelectionOut']),
        ...mapMutations(['setStockchart']),
        ...mapMutations(['setToggleChartStocks']),

        _toastIniciaSesion : function(){
          this.$vueOnToast.pop('error', "Si quiere acceder a esta herramienta inicie sesión.");
        },

        _setMode: function(value){
          if(value=="show-chart"){
            document.getElementById("1").style.background = "#00ADD9";
            document.getElementById("2").style.background = "";
          }else{
            document.getElementById("1").style.background = "";
            document.getElementById("2").style.background = "#00ADD9";
          }

        },

        _emitNextTicker: function(){
          this.$emit("next-ticker"); 
        },

        _emitPrevTicker: function(){
          this.$emit("prev-ticker"); 
        },

        _emitChangeChartList: function(){
          this.setToggleChartStocks("chart");
          this.$emit("change-chart-list"); 
        },

        _emitChangeStocksList: function(){
          this.setToggleChartStocks("stocks");
          this.$emit("change-chart-list"); 
        }
    },
}
</script>



<style lang="scss" scoped>
.mobile-stockchart-menu {
  background-color: var(--color-primary-primary);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  text-align: center;

  min-width: 64px;
  z-index: 20;
  ul {
    padding: 0;
    li {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);




    }
  }
}

.mobile-top {
  justify-content: center;
  width: 80px;
  padding: 14px 0;
  margin: 0;
}

.top-menu-mobile-schart {
  margin-bottom: 0;
}

.mdc-list.top-menu-mobile-schart {
  margin: 0 !important;
}
</style>


