<template>
  <div class="select-container-size">
    <div class="mdc-select mdc-select--outlined" :class="component_class">
      <div class="mdc-select__anchor fourth-filters-select" aria-labelledby="outlined-select-label" role="button" aria-haspopup="listbox" aria-expanded="false" demo-selected-text aria-controls="my-helper-text" aria-describedby="my-helper-text">        
        <span class="mdc-select__selected-text-container">
          <span id="demo-selected-text" class="mdc-select__selected-text"></span>          
        </span> 
        <div class="material-icons-outlined fourth-select-button">+</div>        
      </div>
      <div class="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth">
          <ul class="mdc-list" role="listbox" aria-label="Food picker listbox" style="max-height: 350px;">
            <li data-value="custom"></li>
            <li v-for="(item, index) in options" :key="index" class="mdc-list-item" aria-selected="false" :data-value="item.name" role="option" :title="item.name">
              <span class="mdc-list-item__ripple"></span>
              <span class="mdc-list-item__text">
                {{item.value}}
              </span>
            </li>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import {MDCSelect} from '@material/select';
  import {MDCSelectHelperText} from '@material/select/helper-text';

  export default {
    name: 'SelectOutline',
    props: {
        helper_select_permission: {type: Boolean},
        label_name: {type: String},
        options: {type: Array},
        component_class: {type: String},
        helper_text_select: {type: String},
        emit_method: {type: String},
        default_value: {type: String}
    },
    data() {
      return {
        select: {},
      };
    },
    mounted() {
        setTimeout(() => {
            new MDCSelectHelperText(document.querySelector('.mdc-select-helper-text'));
            this.select = new MDCSelect(document.querySelector('.mdc-select.' + this.component_class));
            this.select.listen('MDCSelect:change', (event) => {
                this.$emit('_emit_method_order_select', [event.detail.value, this.component_class])
            });

            if (this.default_value != ''){
              this.select.foundation.setValue(this.default_value);
            }
        }, 250);
            
    },
    methods: {
      _resetSelectValue: function() {
        this.select.foundation.setValue(this.default_value);
      },
      _setValueToCustom: function(){
        this.select.foundation.setValue("custom");
      }
    }
  }
</script>



<style lang="scss" scoped>
  @use "@material/list/mdc-list";
  @use "@material/menu-surface/mdc-menu-surface";
  @use "@material/menu/mdc-menu";
  @use "@material/select/styles";
  @use '@material/select';

  .profile .mdc-select {
    @include select.outlined-height(34px);
    width: 85%;
  }
  .mdc-select	{
    @include select.ink-color(('default':rgba(0, 0, 0, 0.6)));
    @include select.label-color(('focus':rgba(0, 0, 0, 0.6)));
    @include select.helper-text-color(rgba(0, 0, 0, 0.6));
    @include select.helper-text-validation-color(rgba(0, 0, 0, 0.6));
    @include select.outline-color(('default': rgba(0, 0, 0, 0.1)));
  }

  @media (max-width: 599px){
    .mdc-select {
      width: 100%;
      @include select.variable-width(100px);}  
  }

  @media (min-width: 600px) and (max-width: 839px) {
    .mdc-select {
      width: 100%;
      @include select.variable-width(180px);} 
  }

  @media (min-width: 840px){
    .mdc-select {
      width: 100%;
      @include select.variable-width(100px);}
  }
  #fourth{
    .mdc-select__selected-text{
      display:none;
    }
    .mdc-select__anchor{
      min-width: 30px;
      height: 25px;
      padding: 0px;
    }
    #selected-component-3{
      .mdc-select__menu.mdc-menu.mdc-menu-surface.mdc-menu-surface--fullwidth.mdc-menu-surface--open{
        min-width:300px !important;      
      }
    }
    .mdc-select__menu.mdc-menu.mdc-menu-surface.mdc-menu-surface--fullwidth.mdc-menu-surface--open{
        min-width:250px !important;      
    }
  }

  .fourth-select-button {
    background-color: var(--color-background-background-casillas) !important;
    border: 1px solid rgba(0, 0, 0, 0.3) !important;
    line-height: 20px !important;
    height: 28px !important;
    width: 28px !important;
    border-radius: 14px !important;
    margin-left: -5px !important;
    font-size: 17px;
    text-align: center !important;
    box-sizing: border-box !important;
    margin-top: 3px !important;
  }

  .fourth-filters-select {
    height: 0px !important;
  }
</style>
