<template>
  <div id="stock-info">
    <HeaderSidebarRight :id_header="id_header" :title_header="title_header" />

    <div class="info-ticker-box">
      <p class="text-info-ticker">Ticker: <span class="text-bold">{{getFormattedSelectedTickerProperty(["ticker"])}}</span></p>
    </div>

    <div class="not-available-data-container">
      <p class="not-available-data typo-body-2"> Su plan actual no tiene acceso a determinados datos. </p>
      <a href="/app/store" class="link-to-store typo-body-2"> Pulse aquí para cambiar de plan. </a>
    </div>

    <br>

    <div class="card-info" id="card-info-id">
      <div class="card-info-box">
        <p class="text-info-grey">Divisa: <span class="text-info-dark text-bold">{{getFormattedSelectedTickerProperty(["divisa"])}}</span></p>
        
        <p class="text-info-grey">Valor de la divisa: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["cotitzacio_divisa"]) }} {{ getFormattedSelectedTickerProperty(["divisa"]) }}</span></p>

        <p class="text-info-grey">Índice: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["index_valor"])}}</span></p>
      
        <p class="text-info-grey">Sector: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["sector"])}}</span></p>
  
        <p class="text-info-grey">Industria: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["industria"])}}</span></p>
    
        <p class="text-info-grey">País: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["country"])}}</span></p>

        <hr />
      </div>
      <div class="card-info-box">
        <p class="text-info-grey">Valor: <span class="not-allowed-data"> --- </span></p>

        <p class="text-info-grey">Entrada: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["real_entry"]) }} {{_selectDivisa(getFormattedSelectedTickerProperty(["divisa"]))}}</span></p>

        <p class="text-info-grey">Stop: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["out_price"]) }} {{_selectDivisa(getFormattedSelectedTickerProperty(["divisa"]))}}</span></p>
        
        <p class="text-info-grey">Cantidad: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["amount"]) }} {{_selectDivisa(this.user_portfolio.currency)}}</span></p>
  
        <p class="text-info-grey">Distancia de entrada: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["entry_distance"])}} %</span></p>
      
        <p class="text-info-grey">Acciones: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["instrument_number"])}}</span></p>

        <p class="text-info-grey">Resistencia: <span class="text-info-dark">{{ this._g_SelectedTicker.resistance }}</span></p>

        <hr />
      </div>
      <div class="card-info-box">
        <p class="text-info-grey">Tipo de entrada: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["entry_type_name"])}}</span></p>
        
        <p class="text-info-grey">Número de onda: <span class="not-allowed-data"> --- </span></p>
        
        <p class="text-info-grey">Rentabilidad: <span class="not-allowed-data"> --- </span></p>
      
        <p class="text-info-grey">Soporte: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["soport"]) }} {{_selectDivisa(getFormattedSelectedTickerProperty.divisa)}}</span></p>
        
        <p class="text-info-grey">Fecha de soporte: <span class="not-allowed-data"> --- </span></p>
      
        <p class="text-info-grey">1ª Fecha: <span class="not-allowed-data"> --- </span></p>
    
        <hr />
      </div>
      <div class="card-info-box">
        <p class="text-info-grey">Dividendos: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["avg_dividends"])}}</span></p>
    
        <p class="text-info-grey">CFD: <span class="not-allowed-data"> --- </span></p>
        
        <p class="text-info-grey">Margen CFD: <span class="not-allowed-data"> --- </span></p>
      
        <p class="text-info-grey">Fecha de análisis: <span class="not-allowed-data"> --- </span></p>
    
        <p class="text-info-grey">Inversión/Riesgo: <span class="not-allowed-data"> --- </span></p>

        <p class="text-info-grey">Compatibilidad: <span class="not-allowed-data"> --- </span></p>
        
        <hr />
      </div>
      <div class="card-info-box">
        <p class="text-info-grey">Nombre de la empresa: <span class="text-info-dark">{{ getFormattedSelectedTickerProperty(["nom"])}}</span></p>

        <p class="text-info-grey">Brain: <span class="not-allowed-data"> --- </span></p>
    
        <div v-if="this._g_SelectedTicker && this._g_SelectedTicker.description">
          <p class="text-info-grey">Descripción: 
            <span class="text-info-dark">{{ this._g_SelectedTicker.description.substring(0, this._g_SelectedTicker.description.indexOf('.')) }}</span>
            <span id="dots">...</span>
            <span id="remaining-text" class="text-info-dark">{{ this._g_SelectedTicker.description.substring(this._g_SelectedTicker.description.indexOf('.'), this._g_SelectedTicker.description.length) }}</span>
          </p>
          <div class="read-more-btn-container">
            <button id="btn_read_more" class="read-more-btn" @click="_read_more()">- Leer Más -</button>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import HeaderSidebarRight from './HeaderSidebarRight.vue';
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins:[APICaller],
  name: 'Info',
  components: {
      HeaderSidebarRight
  },
  data() {
    return {
      is_fullscreen: true,
      id_header: 'stock-info',
      title_header: 'Análisis Eurekers',
      user_id: "",
      info_rol: "",
      user_portfolio: "",
    };
  },
  mounted() {
    this._defaultPortfolio();
  },
  created: function() {
    this._getUID();
    this._getUserInfo();
  },
  computed: {
      ...mapGetters(['_g_SelectedTicker', '_g_User']),
  },
  methods: {

    getFormattedSelectedTickerProperty(prop_array) {
      const selected_ticker = this._g_SelectedTicker;

      let goal = "";
      prop_array.forEach(prop => {
        goal = selected_ticker[prop];
        if(goal == undefined) {
          return "";
        }
      });

      try {
        const formattedGoal = goal.toFixed(2);
        return formattedGoal;
      }
      catch(e) {
        return goal;
      }
    },
    
    _selectDivisa : function(valor){
      if (valor == 'EUR'){
        return '€';
      }
      else if (valor == 'EURUSD') {
        return '$';
      }
      else {
        return valor;
      }
    },
    _dataFormat: function(value){
      if(value != undefined) {
        value= value.split("-");
        let new_value = value[2] + "-" + value[1] + "-" + value[0];
        return new_value; 
      }
      else {
        return "";
      }
    },
    _dataFormat2: function(value){
      if(value != undefined) {
        let year = value.substring(0,4);
        let month = value.substring(4,6);
        let day = value.substring(6,8);
        let new_value = day + "-" + month + "-" + year;
        return new_value;
      }
      else {
        return "";
      }

    },
    _defaultPortfolio: function(){
      let success = (response) => {
        this.user_portfolio = response.data
      };

      let url = '/api/v1/portfolio-invest-preferences';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('053', 'Error al recuperar las preferencias de cartera.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _getUID: function(){
      this.user_id = this._g_User.uid;
    },

    _getUserInfo: function(){
      this.info_rol = this._g_User.user_features.platinum_score.info;
    },

    _read_more(){
      var dots = document.getElementById("dots");
      var moreText = document.getElementById("remaining-text");
      var btnText = document.getElementById("btn_read_more");

      if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "- Leer Más -";
        moreText.style.display = "none";
      } else {
        dots.style.display = "none";
        btnText.innerHTML = "- Leer Menos -";
        moreText.style.display = "inline";
      }

    },
  },
}
</script>


<style lang="scss" scoped>

  @import "@/styles/basics/globals.scss";

  p {
    margin: 0;
    padding: 0;
    line-height: 24px;
    color: #7D8294;
  }

  span {
    color: black;
  }

  a {
    text-decoration: none;
    color: #35add8;
  }

  hr {
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 22px 5%;
  }

  .info-ticker-box {
     width: 100%;
     display: flex;
     justify-content: center;
  }
  .text-info-ticker {
    text-align: center;
    background-color: #F3F4F7;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 24px;
    width: 130px;
    padding: 7px 20px;
    color: black;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-info-grey {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.25px;
    color: #7D8294;
  }

  .text-info-dark {
    color: #000000;
  }

  .card-info {
    margin: 0 35px;
    //display: flex;
    flex-wrap: wrap;
  }

  .card-info-box {
    width: 100%;
  }
  
  .is-fullscreen {
    width: 30%;
    margin: 0 10%
  }

  #text-is-fullscreen {
    margin-bottom: 50px;
  }

  #remaining-text{
    display: none;
  }

  .read-more-btn{
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: grey;
  }

  .read-more-btn-container{
    text-align: center;
  }

  #stock-info .text-info-ticker, #stock-info .text-info-ticker span {
    background-color: var(--custom-color-right-menu-highlight);
    color: var(--custom-color-right-menu-font);
  }

  .not-allowed-eurekers-analysis-container{
    text-align: center;
    padding: 20px;
  }

  .not-allowed-eurekers-analysis{
    color: #FF9B00;
  }

  .not-available-data-container{
    text-align: center;
  }

  .not-available-data{
    color: #FF9B00;
  }

  .not-allowed-data{
    color: #FF9B00 !important;
  }

  .link-to-store{
    color: #35add8;
  }
</style>