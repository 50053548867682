<template>
  <div v-if="stock" id="stock-comments" class="stocks-comments-style mobile-content">
    <div class="header-container">
      <div class="title-container">
          <span>Comentarios</span>
      </div>
      <div class="close-btn-container">
        <span class="material-icons-outlined" @click="_closeComponent()">close</span>
      </div>
    </div>
    <div v-if="_g_UserFeatures.permissions.read_forum">
      <h4 v-if="_isPlatinum()">{{comments.length}} comentarios</h4>    
      <div id="comments-body">
        <div class="info-ticker-box">
          <p class="text-info-ticker">Ticker: <span class="text-bold">{{ _g_SelectedTicker().ticker }}</span></p>
        </div>
              
        <p class="comment-count-text text-bold">{{comments.length}} COMENTARIOS</p>
        <hr class="first-line">
        <div v-if="comments.length == 0">
          <p class="no-comment-text">Aún no hay comentarios para este ticker</p>
        </div>
        <div v-else id="comments-wrapper">        
          <div v-for="comment in comments" :key="comment.comment_id" class="comment-wrapper">
            <div v-if="comment.new > 0">
              <span class="material-icons-outlined">new_releases</span>
            </div>
            <div id="comment">
              <div class="user-box">
                <i class="material-icons comment-icon-user">supervised_user_circle</i>
                <div v-html="comment.user.picture" id="comment-user"></div>
              </div>
              <p id="comment-text">{{comment.message}}</p>
              <div id="comment-rating">
                <span 
                @click="upComment(comment)"
                class="comment-icon material-icons">sentiment_satisfied_alt</span>
                <span
                @click="downComment(comment)"
                class="comment-icon material-icons">sentiment_very_dissatisfied</span>
                <span class="comment-text">Puntos: <span class="text-bold-points">{{ comment.user.votes.avg }}</span> </span>
              </div>
              <hr>
            </div>
          </div>        
        </div>                    
      </div>
      <div id="comment-writer">
        <div class="comment-box">            
            <InputTextarea id="input_comment" @_emit_method_order_input_textarea="_getInputValue" :ref="input_comments.ref" :input_key_to_textarea_values="input_comments.input_key_to_textarea_values" :input_placeholder="input_comments.input_placeholder" :disabled="_isCommentDisabled()" />
            <Button id="comment-button-id" @_emit_method_button="_createComment" :button_name="button_name" :button_key="button_key_comments" :button_icon_permission="true" :button_icon="button_icon" :button_icon_id="button_icon_id" />
        </div>
      </div>          
    </div>
    <div v-else class="not-available-data-container">
      <div class="info-ticker-box">
        <p class="text-info-ticker">Ticker: <span class="text-bold">{{stock.ticker}}</span></p>
      </div>
      <p class="not-available-data typo-body-2"> Su plan actual no tiene acceso a esta herramienta. </p>
      <a href="/app/store" class="link-to-store typo-body-2"> Pulse aquí para cambiar de plan. </a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import InputTextarea from "@/components/helpers/InputTextarea.vue";
import Button from "@/components/helpers/Button";
import InputComments from "./configs/input_comments.json";
import APICaller from "@/components/codeComponents/APICaller.js";
export default {
  mixins: [APICaller],
  name: 'Comments',
  components: {
      InputTextarea,
      Button,
  },
  data: function() {
    return {
      comments: [],
      new_comment: '',
      button_name: "",
      button_key_comments: "button_comments",
      button_icon: "done",
      button_icon_id: "icon_button_comment",
      input_comments: InputComments,
      id_header: 'comments-header',
      title_header: 'Comentarios'
    }
  },
  watch: {
    stock: {
      handler: function() {
        this._getComments();
      },
      deep: true
    }
  },
  mounted() {
    this._getComments();
    this._changeColorTextarea();  
      
    
  },
  created: function() {
  },
  computed: {
    ...mapGetters({stock: '_g_SelectedTicker'}),
    ...mapGetters({user: '_g_User'}),
    ...mapGetters(["_g_UserFeatures"]),
  },
  methods: {
    ...mapGetters(['_g_SelectedTicker']),
    _getInputValue(value) {
      this.new_comment = value[0]
    },
    _isPlatinum() {
      return this.user.is_platinum_user;
    },
    _isCommentDisabled() {
      return !this.user.is_forum_writer_user;
    },

    _getComments: function(){
      let success = response => {
        this.comments = response.data;
        setTimeout(this._changeClassStyles, 50);
      }

      let url = "/api/v1/forum/comments/" + this.stock.nid;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('043', 'Error recuperando comentarios.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _createComment: function(){
      var params = new URLSearchParams();
      params.append('new_comment', this.new_comment);

      let success = response => {
        if (response.data.status == 'ok') {
          this.$vueOnToast.pop('success', "Comentario", "Enviado con exito!");
          this._getComments();
          setTimeout(this._changeClassStyles, 50);
        } else {
          this.$vueOnToast.pop('error', "Comentario no creado", response.data.message);
        }
      }

      let url = '/api/v1/forum/comments/add/' + this.stock.nid;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('244', 'Error recuperando comentarios.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    _changeColorTextarea() {
        
      let inputComment = document.getElementById("input_comment");
         
      inputComment.addEventListener('click', function() {
             
        for (let inputTextfieldComment of document.getElementsByClassName("comments_input")){
          inputTextfieldComment.style.backgroundColor = "#EDF8FB";
        }
        let iconColorComment = document.getElementById("icon_button_comment")
        iconColorComment.style.color = "#FFFFFF";
                
        let buttonColorComment = document.getElementById("comment-button-id")
        buttonColorComment.style.backgroundColor = "#00AEDD"
      });
                  
    },
    upComment: function(comment) {
      this._voteComment(comment, 1);
      this._getComments();
    },
    downComment: function(comment) {
      this._voteComment(comment, -1);
      this._getComments();
    },

    _voteComment: function(comment, value){
      var params = new URLSearchParams();
      params.append('vote_value', value);
      params.append('entity_id', comment.comment_id);

      let success = () => {
        this.$vueOnToast.pop('success', "Comentario", "Votado con exito");
        this._getComments();
      }

      let url = '/api/v1/forum/comments/vote/' + this.stock.nid

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('245', 'Error votando comentarios.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    _changeClassStyles() {
      for (const deleteMemberSince of document.getElementsByClassName("member-since")){
        deleteMemberSince.style.display = "none";
      }
      for (const deleteLinkImg of document.getElementsByClassName("user-picture")){
        deleteLinkImg.style.display = "none";
      }
      for (const deleteAdminText of document.getElementsByClassName("forum-heading")){
        deleteAdminText.style.display = "none";
      }
      for (const textBold of document.getElementsByClassName("tthh-user")){
        textBold.style.fontWeight = "bold";
        textBold.style.textTransform = "capitalize";
      }
      for (const dateRight of document.getElementsByClassName("tthh-user-date-comment")){
        dateRight.style.float = "right";
      }
      
    },

    _closeComponent: function() {
      this.$emit("_closeComponentCommentsMobile")
    },
  },
}
</script>
<style lang="scss" scoped>
  
  .comment-box {
    display: flex;
    align-items: center;
    margin: 20px 0px;
  }
  .material-icons-outlined {
    cursor: pointer;
  }
  .info-ticker-box {
     width: 100%;
     display: flex;
     justify-content: center;
  }
  .text-info-ticker {
    text-align: center;
    background-color: #F3F4F7;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 24px;
    width: 130px;
    padding: 7px 20px;
  }
  .text-bold {
    font-weight: bold;
  }
  .comment-count-text {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    margin: 10px 0 20px;
  }
  hr {
    color: rgba(0, 0, 0, 0.12);
    margin: 20px 0 24px;
  }
  #comment-user .user-picture  {
    display: none;
  }
  #comment-user {
    font-size: 14px;
    width: 100%;
  }
  #comment-text,
  .no-comment-text {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #121212;
  }
  #comment-rating {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .comment-icon {
    margin: 0 2px;
    cursor: pointer;
  }
  .comment-text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-left: 2px;
  }
  .text-bold {
    font-weight: bold;
  }
  .user-box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  #comment {
    margin: 0 20px;
  }
  .comment-icon-user {
    font-size: 40px;
    margin-right: 15px;
  }  
  #comment-writer{
    background:white;
    border-top: 0.5px solid rgba(0, 0, 0, 0.12);
    width: 100%; 
  }
  .comment-box{
    width:95%;
    margin-left:10px;
  }
  #comments-wrapper{
    margin-bottom:200px;
  }
  .first-line{
    width: 100%;
  }
  #stock-comments{
    width:100%;
    margin-left:3px;
  }

  .mobile-content {
    margin-top: 15px;
    height: 460px;
    min-height: 420px;
    overflow-y: scroll;
  }

  .header-container{
    display: flex;
    justify-content: center;
    padding: 10px 0px 35px 0px !important;
  }

  .title-container{
    margin-left: 30px;
    font-size: 20px;
    font-weight: 600;
  }

  .close-btn-container{
    margin-left: auto;
    order: 2;
    margin-right: 10px;
  }

  .not-allowed-forum{
    text-align: center;
    color: #FF9B00;
    font-weight: 500;
  }

  .not-available-data-container{
    text-align: center;
  }

  .not-available-data{
    color: #FF9B00;
  }

  .link-to-store{
    color: #35add8;
  }

</style>