<template>
    <div v-if="show_searches" class="searches-modal-content" @click.stop>
        <div class="modal-header">
            <div class="title-wrapper">
                <span class="material-icons-outlined header-icon">folder_open</span>
                <h3 class="modal-title">Búsquedas Guardadas</h3>
            </div>
            <button class="modal-close-btn" @click="_closeSearchWindow()">
                <i class="material-icons">close</i>
            </button>
        </div>

        <div class="modal-body">
            <div v-if="_g_UserFeatures.permissions.default_searches && !isDeleting" class="default-searches-section">
                <p class="section-title">Búsquedas predeterminadas</p>
                <div class="list-container">
                    <div class="list-item" v-for="(item) in principal_searches" :key="item.name" @click="_goToGD(item.texto)">
                        <span class="item-text link-text">{{item.name}}</span>
                        <span class="material-icons-outlined item-icon-right">chevron_right</span>
                    </div>
                </div>
            </div>

            <hr v-if="_g_UserFeatures.permissions.default_searches && personal_searches.length > 0 && !isDeleting"/>

            <div v-if="!isDeleting" class="personal-searches-section">
                <p class="section-title">Mis Búsquedas</p>
                <div class="list-container" v-if="personal_searches.length > 0">
                    <div class="list-item" v-for="(item, index) in personal_searches" :key="item.name">
                        <div v-if="finder_name != item.name" class="item-content-wrapper">
                            <span class="item-text personal-search-name" @click="_changeFinder(item.name)">{{decode_finder_name(item.name)}}</span>
                            <div class="item-actions">
                                <button @click="_prepareToEdit(item.name)" class="icon-button edit-icon">
                                    <i class="material-icons">edit</i>
                                </button>
                                <button @click="_prepareToRemove(index)" class="icon-button delete-icon">
                                    <i class="material-icons">delete</i>
                                </button>
                            </div>
                        </div>
                        <div v-else class="edit-input-wrapper" @keyup.enter="_confirmEditSearchName()" @keyup.esc="_cancelOngoingOperations()">
                            <input 
                                type="text" 
                                class="vanilla-input edit-search-input" 
                                v-model="new_name" 
                                placeholder="Nuevo nombre" 
                                :maxlength="32"
                                ref="editInput" 
                                @input="_validateInputCharacters"
                            />
                            <button class="icon-button confirm-edit-btn" @click="_confirmEditSearchName()">
                                <i class="material-icons">check</i>
                            </button>
                            <button class="icon-button cancel-edit-btn" @click="_cancelOngoingOperations()">
                                <i class="material-icons">close</i>
                            </button>
                        </div>
                    </div>
                </div>
                 <div v-else class="no-items-message">
                    No hay búsquedas personales guardadas.
                </div>
            </div>

            <div v-else class="delete-confirm-view">
                <p class="delete-question-text">¿Está seguro que desea eliminar esta búsqueda?</p>
                <div class="search-to-delete-info">
                    <span class="material-icons-outlined">folder</span>
                    <span class="search-name-delete">{{decode_finder_name(deleting_search.name)}}</span>
                </div>
                <p class="delete-warning">
                  <i class="material-icons warning-icon">warning</i>
                  Esta acción no se puede deshacer.
                </p>
            </div>
        </div>

        <div class="modal-footer" v-if="isDeleting">
            <button class="action-btn secondary-btn" @click="_cancelOngoingOperations()">Cancelar</button>
            <button class="action-btn delete-btn" @click="_removeSearch(deleting_search)">Eliminar</button>
        </div>
    </div>
</template>

<script>
    import APICaller from "@/components/codeComponents/APICaller.js";
    import GetStocks from "@/components/codeComponents/GetStocks.js";
    import InputTextOutline from '@/components/helpers/InputTextOutline.vue';

    import { mapMutations, mapGetters } from "vuex";
    export default {
        mixins: [APICaller, GetStocks],
        name: "Searches",
        components:{
            InputTextOutline,
        },
        props: {
            show_searches: {type: Boolean},
        },
        data: () =>({
            principal_searches: [],
            personal_searches: [],
            last_ipos: [],
            show_button: true,
            isClosingShieldOn: false,
            isSaving: false,
            isDeleting: false,
            deleting_search: {},
            saved_search: "",
            isEditing: false,
            finder_name: "",
            new_name: "",
        }),
        created(){

        },
        computed: { 
            ...mapGetters(['_g_open_searches']),  
            ...mapGetters(['_g_toggle_chart_stocks']),
            ...mapGetters(['_g_UserFeatures']),
        },
        mounted(){
            this._listPrincipalSearches();
            this._listPersonalSearches();
            //this._getStocksIPOs();
        },
        methods: {
            ...mapMutations(['setMenuSelectionOut']),
            ...mapMutations(['setStockValue']),
            ...mapMutations(['setOptionMobile']),
            ...mapMutations(['setOpenSearches']),
            ...mapMutations(['setChartView']),
            ...mapMutations(['setStocksView']),
            

            _listPrincipalSearches: function() {
                let search1 = {name:"Ultimas IPOs", texto:"last-ipos"};
                let search2 = {name:"Mejores alcistas última semana", texto:"best-bullish-values/last_week"};
                let search3 = {name:"Mejores alcistas último mes", texto:"best-bullish-values/last_month"};
                let search4 = {name:"Mas comentados", texto:"hot-values/last_week"};

                this.principal_searches.push(search1,search2,search3,search4);
            },
            _goToGD: function (url) {
                this.$router.push('/app/graficador-dinamico/' + url);
            },
            _listPersonalSearches: function() {
                let success = (response) => {              
                    this.personal_searches = [];
                    for(let i=0; i < response.data.length; i++){
                        if(response.data[i] != "last_search"){
                            let search1 = {name:response.data[i], texto:""};
                            this.personal_searches.push(search1); 
                        }                         
                    }
                };

                let url = "/api/v1/stock-finder/finder-names";

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('070', 'Error cargando búsquedas.');

                this._getAPICall(url, successHandler, failureHandler);
            },
            decode_finder_name: function(encoded_name) {
                return encoded_name.replace(/Q1SPACEQ1/g, ' ');
            },
            _closeSearchWindow: function(){
                this._cancelOngoingOperations();
                this.$emit("_closeSearchWindow");
            },

            _getStocksIPOs: function(){
                let success = response =>{
                    this.last_ipos = response.data;
                    this.$emit("last_ipos", this.last_ipos);
                }

                let url = '/api/v1/stock-finder/last-ipos'

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('071', 'Error recuperando valores.');

                this._getAPICall(url, successHandler, failureHandler);
            },


            _prepareToRemove: function(index){
                this.isDeleting = !this.isDeleting;
                this.deleting_search = this.personal_searches[index];
            },
            _removeSearch: function(value) {         
                let success = () => {
                    this.$vueOnToast.pop("success", "Búsqueda", "eliminada con éxito.");
                    this._listPersonalSearches();
                    this._cancelOngoingOperations();
                               
                };
                let failure = () => {};

                let url = "/api/v1/stock-finder/delete-finder/" + value.name;

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('072', 'Error eliminando búsqueda.', failure);

                this._getAPICall(url, successHandler, failureHandler);
            },
            _showInput: function(){
                this.show_button = !this.show_button;
                this.isSaving = !this.isSaving;
            },
             _manageExitClicksBehavior: function() {
                console.warn("_manageExitClicksBehavior might be redundant with modal backdrop");
            },
            _turnOnClosingShield: function() {
                this.isClosingShieldOn = true;
            },
            _cancelOngoingOperations: function(){
                this.isDeleting = false;
                this.isEditing = false;
                this.finder_name = "";
                this.new_name = "";
                this.deleting_search = {};
            },
            _saveSearch(value) {                
                var search = value[0];                
                this.saved_search = search;                        
                
            },
            ...mapGetters(['_g_FinderFilters']),
            _saveSearchToApi: function(){                
                var params = new URLSearchParams();
                params.append('name', this.saved_search);
                params.append('finder', JSON.stringify(this._g_FinderFilters()));

                let success = () => {
                    this.$vueOnToast.pop("success", "Búsqueda", "Guardada con éxito!");
                    this._listPersonalSearches();
                    this._cancelOngoingOperations();
                };

                let url = "/api/v1/stock-finder/create-finder";
                
                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('272', 'Error guardando la búsqueda.');

                this._postAPICall(url, params, successHandler, failureHandler);
            },
            
            _changeFinder: function(value){
                let success = () => {
                    this.getStocks();
                    this._closeSearchWindow();
                    this.$vueOnToast.pop("success", "Búsqueda", "Cargada con éxito!");
                    this.$router.push('/app/graficador-dinamico');                                               
                };

                let url = "/api/v1/stock-finder/change-finder/" + value;

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('073', 'Error cargando búsqueda.');

                this._getAPICall(url, successHandler, failureHandler);
            },

            _isValidChar: function(searchText) {
                return /^[A-Z]+$/i.test(searchText) || /^[a-zA-Z\u00C0-\u017F]+$/.test(searchText) || /\d/.test(searchText);
            },
            _hasWhiteSpace: function(s) {
                return /\s/g.test(s);
            },

            _validateInputCharacters: function(event) {
                // Only allow letters, whitespaces, digits, hyphens, and slashes
                const validCharRegex = /^[a-zA-ZÀ-ÿ0-9\s\-\/]*$/;
                if (!validCharRegex.test(this.new_name)) {
                    // Remove invalid characters
                    this.new_name = this.new_name.replace(/[^a-zA-ZÀ-ÿ0-9\s\-\/]/g, '');
                }
            },

            _prepareToEdit: function(name){
                this.new_name = this.decode_finder_name(name);
                this.isEditing = true;
                this.finder_name = name;
                // Focus the input after the DOM updates
                this.$nextTick(() => {
                    const inputElement = this.$refs.editInput;
                    if (inputElement && typeof inputElement.focus === 'function') {
                        inputElement.focus();
                    }
                });
            },

            _confirmEditSearchName: function(){
                const trimmedNewName = this.new_name.trim();
                if(trimmedNewName === ""){
                    this.$vueOnToast.pop('error', "Error", "El nombre no puede estar en blanco.");
                    return;
                }
                if(trimmedNewName === this.decode_finder_name(this.finder_name)) {
                    this.$vueOnToast.pop('info', "Info", "El nombre no ha cambiado.");
                    this._cancelOngoingOperations();
                    return;
                }

                var params = new URLSearchParams();
                params.append('name', this.finder_name);
                params.append('new_name', trimmedNewName); // Assuming trimmedNewName is defined earlier

                let success = response =>{
                    if (response.data == null) {
                        this.$vueOnToast.pop("success", "Búsqueda", "editada con éxito.");
                        this._cancelOngoingOperations();
                        this._listPersonalSearches();
                    } else {
                        this.$vueOnToast.pop('error', "Error", response.data.message || 'No se pudo editar la búsqueda.');
                        this._cancelOngoingOperations();
                    }
                }

                let failure = () => {
                    // failure handler already calls it, which is good.
                    this._cancelOngoingOperations();
                };

                let url = "/api/v1/stock-finder/update-finder"

                const successHandler = new APICaller.SuccessHandler(success);
                // Consider adding the failure callback passed here to the FailureHandler constructor if needed
                const failureHandler = new APICaller.FailureHandler('273', 'Error guardando cambios.', failure);

                this._postAPICall(url, params, successHandler, failureHandler);
            },
        },
    }
</script>

<style lang="scss" scoped>
.searches-modal-content {
    background-color: var(--color-background, white);
    border-radius: 8px;
    width: 400px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: var(--color-text-primary, #0F0F1A);
    box-shadow: 0 4px 20px rgba(15, 15, 26, 0.15);
}

.modal-header {
    padding: 16px 20px;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-icon {
    color: var(--primary, #00AEDD);
    font-size: 22px;
}

.modal-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.modal-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-text-secondary, #606577);
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    transition: background-color 0.2s ease-in-out;
}

.modal-close-btn:hover {
    background-color: var(--color-surface, #F3F4F7);
}

.modal-close-btn i {
    font-size: 20px;
}

.modal-body {
    padding: 15px 20px;
    overflow-y: auto;
    flex-grow: 1;
}

.modal-body::-webkit-scrollbar {
    width: 8px;
}

.modal-body::-webkit-scrollbar-track {
    background: var(--color-surface, #F3F4F7);
    border-radius: 4px;
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: var(--color-text-secondary, #B0B4C3);
    border-radius: 4px;
    border: 2px solid var(--color-surface, #F3F4F7);
}

.section-title {
    font-size: 13px;
    font-weight: 600;
    color: var(--color-highlight, #00AEDD);
    text-transform: uppercase;
    margin: 0 0 10px 0;
    letter-spacing: 0.5px;
}

.list-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.list-item:hover {
    background-color: var(--color-surface, #F3F4F7);
    color: var(--primary, #00AEDD);
}

.item-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.item-text {
    font-size: 14px;
    font-weight: 500;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
}

.link-text {
    color: var(--color-text-primary, #0F0F1A);
    text-decoration: none;
}

.list-item:hover .link-text,
.list-item:hover .personal-search-name {
     color: var(--primary, #00AEDD);
}

.item-icon-right {
    font-size: 20px;
    color: var(--color-text-secondary, #606577);
}

.list-item:hover .item-icon-right {
    color: var(--primary, #00AEDD);
}

.personal-search-name {
    color: var(--color-text-primary, #0F0F1A);
}

.item-actions {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-secondary, #606577);
    transition: color 0.2s ease, background-color 0.2s ease;
}

.icon-button:hover {
    background-color: var(--color-muted, #EAECEF); 
}

.icon-button i {
    font-size: 18px;
}

.delete-icon:hover {
    color: var(--color-error, #B00020);
}

.edit-icon:hover {
    color: var(--primary, #00AEDD);
}

.confirm-edit-btn:hover {
    color: var(--color-success, #57a869);
}

.cancel-edit-btn:hover {
    color: var(--color-error, #B00020); 
}


.edit-input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.edit-input-wrapper ::v-deep .input-container {
    margin-bottom: 0 !important;
}

.edit-input-wrapper ::v-deep .text-field-input {
    height: 36px;
    font-size: 14px;
}

hr {
    border: none;
    height: 1px;
    background-color: var(--color-outline, rgba(0, 0, 0, 0.12));
    margin: 15px 0;
}

.no-items-message {
    text-align: center;
    padding: 20px;
    color: var(--color-text-secondary, #606577);
    font-style: italic;
}

.delete-confirm-view {
    padding: 10px 0;
    text-align: center;
}

.delete-question-text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.search-to-delete-info {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    background-color: var(--color-surface, #F3F4F7);
    padding: 8px 15px;
    border-radius: 6px;
    margin-bottom: 15px;
    color: var(--color-text-primary, #0F0F1A);
}

.search-name-delete {
    font-weight: 600;
}

.delete-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--color-warning, #FF9B00);
    font-size: 14px;
    margin-top: 10px;
}

.warning-icon {
    font-size: 20px;
}

.modal-footer {
    padding: 16px 20px;
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    flex-shrink: 0;
}

.action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 36px;
    transition: all 0.2s ease;
    font-weight: 600;
}

.secondary-btn {
    background-color: var(--color-surface, #F3F4F7);
    color: var(--color-text-primary, #0F0F1A);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
}

.secondary-btn:hover {
    background-color: var(--color-background, #EAECEF);
}

.delete-btn {
    background-color: var(--color-error, #B00020);
    color: white;
}

.delete-btn:hover {
    background-color: #9a001b;
}

// Style for the vanilla input used in editing
.vanilla-input {
    flex-grow: 1;
    margin-right: 10px;
    height: 36px; 
    padding: 6px 12px;
    font-size: 14px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    transition: border-color 0.2s ease;
    box-sizing: border-box;
}

.vanilla-input:focus {
    outline: none;
    border-color: var(--primary, #00AEDD);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.2);
}

.vanilla-input::placeholder {
    color: var(--color-text-secondary, #606577);
    opacity: 0.6;
}
</style>