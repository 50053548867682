<template>
<div>
  <div id="ai">
    <div v-if="_g_IsUserLogged && !_g_User.is_only_authenticated_user" id="nav-tools">
      <div class="group-tools">
        <div id="texto-no-eureker" v-if="_g_SelectedTicker.isEureker == false" class="no-eureker-container">
          <span class="text-no-eurekers center-container">
            Este ticker no es un valor de Eurekers
          </span>        
        </div>
        <div v-if="Object.keys(_g_SelectedTicker).length != 0" id="scrollable-container" class="tool-container">
          <div class="tool-mobile" v-for="(tool, index) in preferences" :key="tool.name">
            <div id="tool-name-container">
              <div class="tool-name">
                <span class="name-to-reduce"> {{ tool.sorter }} </span>
              </div>
              <span v-if="_isFixedPreferences(tool.name)"
                class="material-icons-outlined"
                @click="_closeTool(index)"
                style="font-size: 17px; margin: 3px 5px 0px 0px;"
                >close</span>
            </div>
            <div v-if="tool.sorter == 'Valor act.'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ _g_SelectedTicker.valor_actual }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'Ticker'" id="tool-description-container">
              <span><b>{{ _g_SelectedTicker.ticker }}</b></span>
            </div>
            <div v-else-if="tool.sorter == 'T. entrada'" id="tool-description-container">
              <span><b>{{ _g_SelectedTicker.entry_type_name }}</b></span>
            </div>
            <div v-else-if="tool.sorter == 'Acciones'" id="tool-description-container">
              <span><b>{{ _g_SelectedTicker.instrument_number }}</b></span>
            </div>
            <div v-else-if="tool.sorter == 'Entrada'" id="tool-description-container">
              <span><b>{{ _g_SelectedTicker.real_entry.toFixed(_fixDecimalsToTickSize()) }}</b></span>
            </div>
            <div v-else-if="tool.sorter == 'Stop'" id="tool-description-container">
              <span><b>{{ _g_SelectedTicker.out_price.toFixed(_fixDecimalsToTickSize()) }}</b></span>
            </div>
            <div v-else-if="tool.sorter == 'Soporte'" id="tool-description-container">
              <span><b>{{ _g_SelectedTicker.soport.toFixed(_fixDecimalsToTickSize()) }}</b></span>
            </div>
            <div v-else-if="tool.sorter == 'Fecha sop.'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ _g_SelectedTicker.data_soport }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'Montante'" id="tool-description-container">
              <span><b>{{ (_g_SelectedTicker.amount).toFixed(_fixDecimalsToTickSize()) }}</b></span>
            </div>
            <div v-else-if="tool.sorter == 'Divisa'" id="tool-description-container">
              <span><b>{{ _g_SelectedTicker.cotitzacio_divisa.substring(0,4) }}</b></span>
            </div>
            <div v-else-if="tool.sorter == 'Rentab.'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ (_g_SelectedTicker.tae_lt).toFixed(_fixDecimalsToTickSize()) }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'Dist. Ent.'" id="tool-description-container">
              <span><b>{{ (_g_SelectedTicker.entry_distance).toFixed(_fixDecimalsToTickSize()) }}</b></span>
            </div>
            <div v-else-if="tool.sorter == 'Margen CFD'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ _g_SelectedTicker.cfd_margin }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'Var. Anual'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{_g_SelectedTicker.tae.toFixed(_fixDecimalsToTickSize())}}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'Nº onda'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ _g_SelectedTicker.num_ona_actual }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'Sig. LT'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{_g_SelectedTicker.entrada_seg.toFixed(_fixDecimalsToTickSize())}}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == '1ª fecha'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ _g_SelectedTicker.fecha_primera_barra }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'Inv/Riesgo'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ _g_SelectedTicker.risk_reward }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'Entry tick size'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ _g_SelectedTicker.tick_size }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'Resist.'" id="tool-description-container">
              <span><b>{{ _g_SelectedTicker.resistance }}</b></span>
            </div>
            <div v-else-if="tool.sorter == 'Compat.'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ (_g_SelectedTicker.user_platinum_compatibility * 100).toFixed(2) }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'Comis. ent.'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ _printPortfolioPreference("open_fee", _g_SelectedTicker.ticker) }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'Beneficio'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ _printPortfolioPreference("benefit", _g_SelectedTicker.ticker) }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'Benef. Aseg.'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ _printPortfolioPreference("insured_benefit", _g_SelectedTicker.ticker) }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
            <div v-else-if="tool.sorter == 'F. entrada'" id="tool-description-container">
              <span v-if="!(_g_User.roles[21] || _g_User.roles[20])"><b>{{ _printPortfolioPreference("in_date", _g_SelectedTicker.ticker) }}</b></span>
              <span v-else class="not-allowed-data"><b> SIN ACCESO </b></span>
            </div>
          </div>
        </div>
        <div class="tools-container">
          <div id="add-tools-mobile" @click="_openAiWindow()">
            <div id="tool-name-container"></div>
            <div id="tool-description-container">
              <span class="material-icons-outlined" style="font-size: 15px;"><b>add</b></span>
            </div>
          </div>
          <div class="opciones">
          <AiWindow
            :show_window="show_AI_window"
            :repush_tool="close_tool"
            :coordinates="coordenadas"
            @show_window="show_AI_window = $event"
            @tool_to_add="add_tool = $event"
            @addTool="_addToolToHeader()"
          >
          </AiWindow>
        </div>
        </div>
      </div>
    </div>

    <div v-else id="nav-tools">
      <div class="group-tools">
      </div>

      <div class="tools-container" style="cursor: not-allowed">
          <div id="add-tools-mobile" style="cursor: not-allowed" @click="_toastError()">
            <div id="tool-name-container"></div>
            <div id="tool-description-container">
              <span class="material-icons-outlined" style="font-size: 15px; cursor: not-allowed"><b>add</b></span>
            </div>
          </div>
          <div class="opciones">
          <AiWindow
            :show_window="show_AI_window"
            :repush_tool="close_tool"
            :coordinates="coordenadas"
            @tools="tools=$event"
            @show_window="show_AI_window = $event"
            @tool_to_add="add_tool = $event"
            @addTool="_addToolToHeader()"
          >
          </AiWindow>
        </div>
      </div>
    
    </div>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
  </div>
</div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import AiWindow from "@/components/stockchart/components/AiWindow.vue";
import SelectWallet from "@/components/stockchart/components/configs/select_ai.json";
import APICaller from "@/components/codeComponents/APICaller.js";
import GetStocks from "@/components/codeComponents/GetStocks.js";


export default {
  mixins: [APICaller,GetStocks],
  name: "Ai_mobile",
  components: {
    AiWindow,
  },
  data: () => ({
    open_positions: [],
    preferences_portfolio: [],
    show_AI_window: false,
    add_tool: {},
    close_tool: {},
    tools: [],
    coordenadas: {},
    select_items: SelectWallet,
    get_value: "",
    user_portfolios:[],
    portfolio_selected:[],
    portfolio_id: undefined,
    loading:true,
    show_workspace: false,
    helper_text_select:"",
    preferences: [],
    preferences_to_post: {
      ticker:1,
      entry_type:1,
      instrument_number:1,
      in_price:1,
      out_price:1,
      support_price:1,
      support_date:1,
      current_price:0,
      is_ipo:0,
      amount:0,
      in_currency:0,
      benefit:0,
      insured_benefit:0,
      tthh_profit:0,
      entry_distance:0,
      in_date:0,
      cfd_margin:0,
      open_fee:0,
      resistance:0,
      anual_var:0,
      wave_number:0,
      next_entry_lt:0,
      first_trading_date:0,
      notes:0,
      risk_reward:0,
      tick_size:0,
      stop_tick_size:0,
      risk:0,
      user_platinum_compatibility: 0,
    },
    isEureker: false,
    elticker: '',
  }),
  mounted() {    
    this._preparePreferencesToPost()
    var waiting_for_portfolio = setInterval(() =>{
      if(this._g_portfolio_id_selected && this._g_portfolio_id_selected != undefined){
        this._getOpenPositions()
        this._getPortfolioPreferences()
        clearInterval(waiting_for_portfolio)
      }
    }, 20)

    var waiting_for_ticker = setInterval(()=>{
      if(this._g_SelectedTicker != undefined && this._g_SelectedTicker != {}){
        if(this._g_SelectedTicker.isEureker == false){
          this.isEureker = false;
          this.elticker = this._g_SelectedTicker;
          clearInterval(waiting_for_ticker);
        }
      }
    }, 20)
    
  },
  created() {
    setTimeout(function(){
      this._getPortfolios();
      this._defaultPortfolio();
      this._getPreferences();
      this._getPortfolioPreferences();
      this.loading = false;
    }.bind(this), 200);
    
  },
  computed: {
    ...mapGetters(["_g_SelectedTicker", "_g_IsUserLogged","_g_ToggleBetweenTableAndChart", "_g_portfolio_id_selected"]),
    ...mapGetters(['_g_ShowStockFinderFilter']),
    ...mapGetters(['_g_User']),
    
  },
  methods: {
    ...mapMutations(['setToggleBetweenListAndChartInStockchart']),
    ...mapMutations(['setStocksTable', 'setStockchart', 'setPortfolioIdSelected']),
    ...mapMutations(['setShowStockFinderFilters']),
    ...mapMutations(['setMenuSelectionOut']),

    _isFixedPreferences: function(value){
      if(value != 'Ticker' && value!='Tipo de entrada' && value!='Acciones'&& value!='Entrada'&& value!='Stop'&& value!='Soporte'&& value!='Fecha de soporte'){
        return true;
      }
      else{
        return false;
      }
    },

    _fixDecimalsToTickSize: function(){
      if(this._g_SelectedTicker.tick_size == 0.01){
        return 2;
      }else if(this._g_SelectedTicker.tick_size == 0.1){
        return 1;
      }else{
        return 3;
      }
    },

    ...mapMutations(['setRechargeState',"setRefreshTable"]),

    _preparePreferencesToPost: function(){
      this.preferences.forEach(element =>{
        if(element.field == "ticker"){
          this.preferences_to_post.ticker = "ticker";
        }else if(element.field == "entry_type"){
          this.preferences_to_post.entry_type = "entry_type";
        }else if(element.field == "instrument_number"){
          this.preferences_to_post.instrument_number = "instrument_number";
        }else if(element.field == "in_price"){
          this.preferences_to_post.in_price = "in_price";
        }else if(element.field == "out_price"){
          this.preferences_to_post.out_price = "out_price";
        }else if(element.field == "support_price"){
          this.preferences_to_post.support_price = "support_price";
        }else if(element.field == "support_date"){
          this.preferences_to_post.support_date = "support_date";
        }else if(element.field == "valor_actual"){
          this.preferences_to_post.current_price = "current_price";
        }else if(element.field == "amount"){
          this.preferences_to_post.amount = "amount";
        }else if(element.field == "exchange_rate"){
          this.preferences_to_post.in_currency = "in_currency";
        }else if(element.field == "tthh_profit"){
          this.preferences_to_post.tthh_profit = "tthh_profit";
        }else if(element.field == "entry_distance"){
          this.preferences_to_post.entry_distance = "entry_distance";
        }else if(element.field == "resistance"){
          this.preferences_to_post.resistance = "resistance";
        }else if(element.field == "anual_var"){
          this.preferences_to_post.anual_var = "anual_var";
        }else if(element.field == "wave_number"){
          this.preferences_to_post.wave_number = "wave_number";
        }else if(element.field == "next_entry_lt"){
          this.preferences_to_post.next_entry_lt = "next_entry_lt";
        }else if(element.field == "first_trading_date"){
          this.preferences_to_post.first_trading_date = "first_trading_date";
        }else if(element.field == "risk_reward"){
          this.preferences_to_post.risk_reward = "risk_reward";
        }else if(element.field == "tick_size"){
          this.preferences_to_post.tick_size = "tick_size";
        }else if(element.field == "stop_tick_size"){
          this.preferences_to_post.stop_tick_size = "stop_tick_size";
        }else if(element.field == "user_platinum_compatibility"){
          this.preferences_to_post.user_platinum_compatibility = "user_platinum_compatibility";
        }else if(element.field == "open_fee"){
          this.preferences_to_post.open_fee = "open_fee";
        }else if(element.field == "benefit"){
          this.preferences_to_post.benefit = "benefit";
        }else if(element.field == "insured_benefit"){
          this.preferences_to_post.insured_benefit = "insured_benefit";
        }else if(element.field == "in_date"){
          this.preferences_to_post.in_date = "in_date";
        }
      })
    },
    
    _openAiWindow: function() {
      this.show_AI_window = !this.show_AI_window;
    },
    _addToolToHeader: function() {
      this.preferences.push(this.add_tool);
      this._preparePreferencesToPost();
      setTimeout(function(){
        document.getElementById("scrollable-container").scrollLeft += 3000; 
        //esto puede ser cualquier numero mayor que el total para que el scroll se haga completamente
      }.bind(this), 10);

      let params = new URLSearchParams();
      params.append('preferences', JSON.stringify(this.preferences_to_post));

      let success = response =>{
        this.setRefreshTable();
      }

      let url = "/api/v1/update-preferences"

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('237', 'Error actualizando preferencias de cartera.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },
    _closeTool: function(index) {
      this.close_tool = this.preferences[index];
      this.preferences.splice(index, 1);

      if(this.close_tool.field == "valor_actual"){
        this.preferences_to_post["current_price"] = 0
      }else if(this.close_tool.field == "exchange_rate"){
        this.preferences_to_post["in_currency"] = 0
      }else{
        this.preferences_to_post[this.close_tool.field] = 0;
      }

      let params = new URLSearchParams();
      params.append('preferences', JSON.stringify(this.preferences_to_post));

      let success = response =>{
        this.setRefreshTable();
      }

      let url = "/api/v1/update-preferences"

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('238', 'Error actualizando preferencias de cartera.');

      this._postAPICall(url, params, successHandler, failureHandler);

    },
    _getValueFromSelect: function(value) {
      this.get_value = value[0];
    },
    _changePortfolioSelected: function(value){
      this.portfolio_id = value[0];
      this.setPortfolioIdSelected(this.portfolio_id);
      let success = (response) => {               
        this.getStocks();              
      };

      let url = '/api/v1/change-portfolio-invest-preferences/' + this.portfolio_id;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('028', 'Error cargando las preferencias.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _getPortfolios: function(){
      let success = (response) => {            
        response.data.forEach(element => {
          this.user_portfolios.push({"name": element.idc, "value": element.name});
        });
      };
      let url = '/api/v1/portfolio/list';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('029', 'Error cargando las carteras de usuario.');

      this._getAPICall(url, successHandler, failureHandler);

    },
    _defaultPortfolio: function(){
      let success = (response) => {               
        this.portfolio_selected.push({"name": response.data.idc, "value": response.data.name});
      };

      let url = '/api/v1/portfolio-invest-preferences';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('030', 'Error cargando las preferencias de cartera.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _select: function(value){
      if(value == 0){
        return this._getPortfolios();
      }
      else{
        if(this.portfolio_selected == []){
          this._getPortfolios();
        }
        return this._defaultPortfolio();
      }
    },
    _setfullscreen(){
      if (document.fullscreenElement) {
        document.exitFullscreen()
          .then(() => this.$vueOnToast.pop('success',"Cambio en el modo de pantalla"))
          .catch((err) => this.$vueOnToast.pop('error',"Error pasando a pantalla completa"));
      } else {
        document.documentElement.requestFullscreen();
      }
    },
    _getPreferences: function() {
      if(!this._g_User.is_only_authenticated_user){
        let success = response => {
          this.preferences = response.data;
          this._preparePreferencesToPost();
          //this.tools = response.data;
        }
        
        let url = '/api/v1/stock-finder/preferences'
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('031', 'Error cargando valores.');
  
        this._getAPICall(url, successHandler, failureHandler);
      }
    },
    _toastError : function(){
      if(this._g_User.is_only_authenticated_user){
        let toast = {type: 'warning', body: 'Su plan actual no tiene acceso a esta herramienta. PULSE AQUÍ para cambiar de plan.', clickHandler: function(){
          window.location.replace("/app/store");
        }};
        this.$vueOnToast.pop(toast);
      } else{
        this.$vueOnToast.pop('error', "Si quiere acceder a esta herramienta inicie sesión.");
      }
    },
    _goToStockchart: function(){
      this.setStockchart();
      if(this._g_ShowStockFinderFilter == true){
        this.setMenuSelectionOut(0);  
        this.setShowStockFinderFilters();
      }
    },

    _goToStockTable: function(){
      this.setStocksTable();
      if(this._g_ShowStockFinderFilter == true){
        this.setMenuSelectionOut(0);  
        this.setShowStockFinderFilters();
      }
    },

    _getOpenPositions() {
      let success = response => {
        this.open_positions = response.data
        this._createArrayToOpenPosition()
      }
       
      let url = '/api/v1/portfolio/open_positions/'+ this._g_portfolio_id_selected;
        
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('032', 'Error cargando posiciones abiertas.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _timeConverter: function(UNIX_timestamp){
      let a = new Date(UNIX_timestamp * 1000);
      let year = a.getFullYear();
      let month = a.getMonth() + 1;
      let date = a.getDate();
      let time = date + '-' + month + '-' + year;
      return time;
    },

    _createArrayToOpenPosition: function(){
      this.open_positions.forEach(element => {
        let objectToAdd = {};
        objectToAdd["ticker"] = element.ticker
        objectToAdd["benefit"] = element.benefit
        objectToAdd["insured_benefit"] = element.insured_benefit
        objectToAdd["in_date"] = this._timeConverter(element.in_date)
        objectToAdd["open_fee"] = element.open_fee
        this.preferences_portfolio.push(objectToAdd)
      })
    },

    _getPortfolioPreferences: function(){
      if(!this._g_User.is_only_authenticated_user){
        let success = response =>{
          if(response.data.indexOf("open_fee") != -1){
            this.preferences.push({field: "open_fee", help_text: "", name:"Comisión de entrada", sorter:"Comis. ent.", subfix: "", type: ""})
          }
          if(response.data.indexOf("benefit") != -1){
            this.preferences.push({field: "benefit", help_text: "", name:"Beneficio", sorter:"Beneficio", subfix: "", type: ""})
          }
          if(response.data.indexOf("insured_benefit") != -1){
            this.preferences.push({field: "insured_benefit", help_text: "", name:"Beneficio asegurado", sorter:"Benef. Aseg.", subfix: "", type: ""})
          }
          if(response.data.indexOf("in_date") != -1){
            this.preferences.push({field: "in_date", help_text: "", name:"Fecha de entrada", sorter:"F. entrada", subfix: "", type: ""})
          }
        }
  
        let url = "/api/v1/portfolio-preferences"
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('033', 'Error cargando preferencias.');
  
        this._getAPICall(url, successHandler, failureHandler);
      }
    },
    
    _printPortfolioPreference: function(type, ticker){
      let flag = false
      this.preferences_portfolio.forEach(element => {
        if(element.ticker == ticker){
          flag = element[type]
          return true
        }
      })
      if(!isNaN(parseFloat(flag))){
        return flag
      }else{
        return "-"
      }
    },
    _sendPositionToWorkspace(x, y){
      this.show_workspace = true;
      let sendPositionToWorkspaceInterval = setInterval(() => {
        if (this.$refs.WorkspaceReff != undefined) {
          var posicionX = x;	
          var posicionY = y;	
          this.$refs.WorkspaceReff._updateWorkspacePosition(posicionX, posicionY);	
          clearInterval(sendPositionToWorkspaceInterval);
        }
      }, 120);
    },
    _initWorkspaces: function() {
      let success = response => {
        if (response.data == [] || response.data.folders == undefined) {
          this.workspaces = [];
        }
        else {
          for (let index = 0; index < response.data.folders.length; index++) {
            this.workspaces.push({
              name: response.data.folders[index][0],
              color: response.data.folders[index][1],
              isFavorite: false,
            });
            this.workspaces_names.push(response.data.folders[index][0]);
            this.workspaces_colors.push(response.data.folders[index][1]);
          }
        }
      };

      let url = '/api/v1/stock-finder/favorite-folder/list';
            
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('318', 'Error inicializando workspaces.');

      this._getAPICall(url, successHandler, failureHandler);
            
    },

    _closeFavoriteWorkspacesWindow() {
      this.show_workspace = false;
    },

    _favorite_added: function(stock) {
      for (var i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i].ticker == stock.ticker) {
          stock.favorites = true;
          this.stocks[i] = stock;
        }
      }
      this._initWorkspaces();
    },

    _favorite_removed: function(stock) {
      for (var i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i].ticker == stock.ticker) {
          if (stock.folder_name.length == 1) {
            stock.favorites = false;
          }
          else {
            stock.favorites = true;
          }
          this.stocks[i] = stock;
        }
      }
    },
    _showFavorites: function(){
        this.show_workspace = true;
    },
  },
};
</script>

<style lang="scss" scoped>
$base_background_color: var(--color-background-surface);
$background_casillas: var(--color-background-background-casillas);

#fullscreen-button-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  order: 4;
  height: 100%;
  width: 80px;
  text-align: center;
  flex-shrink: 0;
}

#fullscreen-button-container:hover {
  background-color: var(--primary-100);
  cursor: pointer;
}

#fullscreen-button-container span {
  font-size: 30px;
  line-height: 77px;
}

.chart-page {
  z-index: 4 !important;
  overflow:hidden;
}
hr{
  background-color: red;
}
.tools-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
  order: 3;
  display: flex;
  z-index: 5;
}
.group-tools {
  display: flex;
  flex-grow: 1;
  order: 3;
  flex-direction: row;
  overflow-x: auto;
}
.cartera {
  order: 1;
  width: 160px;
  height: 100%;
  padding: 0 5px;
  flex-shrink: 0;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
}
#ai {
  background-color: $base_background_color;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 15;
  overflow:visible;
}
#nav-tools {
  height: 77px;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: show;
}
.opciones {
  height:80%;
  position:absolute;
  z-index: 6;
  margin-top:69px;
  float: left;
}
.last-one {
  position: absolute;
  display: grid;
  z-index: 999999;
}
.white-window {
  float: left;
  width: 130px;
}
.tool {
  width: 110px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  margin: 9px 5px 0 0;
  display: inline-block;
  background-color: $background_casillas;
}

.tool-mobile {
  width: 96px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  margin: 9px 5px 0 0;
  display: inline-block;
  background-color: $background_casillas;
}
.tool-name {
  font-size: 15px;
  width: 80%;
  margin-left: 10px;
  text-align: center;
}

#tool-name-container {
  width: 100%;
  height: 40%;
  background: var(--color-primary-800);
  border-radius: 4px 4px 0px 0px;
  display: inline-flex;
  color: var(--color-typography-on-primary);//2 variables con el mismo color, he puesto una de las 2.
}

#tool-description-container {
  text-align: center;
  font-size: 15px;
  color: var(--color-typography-on-text); //3 variables con el mismo color, he puesto una de las 3.
}
#add-tools {
  margin: 9px 5px;
  float: right;
  width: 119px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  background-color: $background_casillas;
}
#add-tools-mobile {
  margin: 9px 5px;
  float: right;
  width: 80px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  background-color: $background_casillas;
}
.name-to-reduce{
  font-size: 11px;
}
.tool-container {
  order: 1;
  flex-grow: 1;
  white-space: nowrap;
  text-align: end;
  overflow-x: auto;
  overflow-y: hidden;  
}
@media screen and (min-width: 900px) {
  .tool-container{
    margin-left: 200px;
  }
}
#cartera-element {
  margin-top: 15px !important;
}
.buttons-container{
  display: inline-flex;
  order:2;
  text-align: center;
  margin-left:15px;
}
.show_chart_container{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.show_chart_container button.active{
  background-color: #EDF8FB; 
}

.show_chart_container button{
  border-radius: 50%;
  height: 44px;
  width: 45px;
  background-color: #f3f4f7;
  border-style: solid;
  border: 0.5px solid grey;
}

.drag_handle_container{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left:50px;
}

.drag_handle_container button{
  border-radius: 50%;
  height: 44px;
  width: 45px;
  background-color: #f3f4f7;
  border-style: solid;
  border: 0.5px solid grey;
}

.drag_handle_container button.active{
  background-color: #EDF8FB; 
}

.no-eureker-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #E39B0F33;
  width: 100%;
  text-align: center;
  margin: 7px;
  border: 2px solid #E39B0F;
  border-radius: 5px;
}

.ticker-no-eurekers{
  font-weight: bold;
  margin-right: 20px;
}

.not-allowed-data{
  color: #FF9B00;
}

</style>
