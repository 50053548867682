<template>
 <div @click="_manageExitClicksBehavior()" v-if="show_searches" id="searches-container" class="container">
    <div @click="_turnOnClosingShield()" class="searches-containerr">
        <div class="header-container">
            <div class="title-container">
                <span>Búsquedas guardadas</span>
            </div>
            <div class="close-btn-container">
                <span class="material-icons-outlined" @click="_closeSearchWindow()">close</span>
            </div>
        </div>
        <div v-if="!isDeleting" id="primary-searches-container" class="primary-searches-container">
            <div class="names-items-container" v-if="_g_UserFeatures.permissions.default_searches">
                <div class="name-items-container" v-for="(item) in principal_searches" :key="item.name">
                    <div>
                        <a class="link-text" @click="_goToGD(item.texto)">{{item.name}}</a>
                    </div>
                </div>
            <hr />
            </div>
            <div v-else>
                <p class="not-allowed-searches typo-body-2"> Su plan actual no tiene acceso a las búsquedas predeterminadas </p>
            </div>
            <div style="display: grid;">
                <div class="saved-search" v-for="(item, index) in personal_searches" :key="item.name">
                       <div v-if="finder_name != item.name">
                            <span class="personal-searches" @click="_changeFinder(item.name)">{{decode_finder_name(item.name)}}</span>
                            <span @click="_prepareToRemove(index)" class="material-icons-outlined delete-icon-style">delete</span>
                            <span @click="_prepareToEdit(item.name)" class="material-icons-outlined edit-icon-style">edit</span>
                            <br>
                        </div>
                        <div v-else style="display: inline-flex;" @keyup.enter="_confirmEditSearchName()"  @keyup.esc="_cancelOngoingOperations()">
                            <InputTextOutline style="width:150px;" @_emit_method_order_input="_changeFinderName" :input_label="item.name" input_helper="" :has_focus="true" icon_class="edit-finder-icon" input_key_to_save_user_values="input_edit_finder" input_class_character_counter="input_finder_character_counter" :permission="true" :icon_permission="false" :character_counter_permission="false" :helper_permission="true" :test_field_is_numeric="false" :length="32" :is_input_textarea="false" />
                            <span>
                                <input class="material-icons-outlined" 
                                @click="_confirmEditSearchName()"
                                type="button" value="check" style="font-size: 35px; background-color: transparent; border-color:transparent !important; border-radius: 50%; margin-left: 10px; color: #f3f4f7; box-shadow: 0px 2px 5px black; background-color: #242632; padding:0 !important; border:0;"> 
                            </span>                        
                        </div>
                </div>
            </div>
        </div>
        <div v-else class="delete-search-container">
            <div>
                <p class="delete-question-text"> ¿Está seguro que desea eliminar esta búsqueda? </p>
                <br>
                <div class="searches-delete-container">
                    <span class="material-icons-outlined">folder</span>
                    <p class="search-name-delete">{{deleting_search.name}}</p>
                </div>
                <br>
                <br>
            </div>
            <div class="inputs-container">
            <button class="input-accept-button" @click="_removeSearch(deleting_search)">ELIMINAR</button>
            <button class="input-cancel-button" @click="_manageExitClicksBehavior()">Cancelar</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import APICaller from "@/components/codeComponents/APICaller.js";
    import GetStocks from "@/components/codeComponents/GetStocks.js";
    import InputTextOutline from '@/components/helpers/InputTextOutline.vue';

    import { mapMutations, mapGetters } from "vuex";
    export default {
        mixins: [APICaller, GetStocks],
        name: "SearchesMobile",
        components:{
            InputTextOutline,
        },
        props: {
            show_searches: {type: Boolean},
        },
        data: () =>({
            principal_searches: [],
            personal_searches: [],
            last_ipos: [],
            show_button: true,
            isClosingShieldOn: false,
            isSaving: false,
            isDeleting: false,
            deleting_search: {},
            saved_search: "",
            isEditing: false,
            finder_name: "",
            new_name: "",
        }),
        created(){

        },
        computed: {
            ...mapGetters(['_g_open_searches']),  
            ...mapGetters(['_g_toggle_chart_stocks']),   
            ...mapGetters(['_g_UserFeatures']),
        },
        mounted(){
            this._listPrincipalSearches();
            this._listPersonalSearches();
            //this._getStocksIPOs();
        },
        methods: {
            ...mapMutations(['setMenuSelectionOut']),
            ...mapMutations(['setStockValue']),
            ...mapMutations(['setOptionMobile']),
            ...mapMutations(['setOpenSearches']),
            ...mapMutations(['setChartView']),
            ...mapMutations(['setStocksView']),
            

            _listPrincipalSearches: function() {
                let search1 = {name:"Ultimas IPOs", texto:"last-ipos"};
                let search2 = {name:"Mejores alcistas última semana", texto:"best-bullish-values/last_week"};
                let search3 = {name:"Mejores alcistas último mes", texto:"best-bullish-values/last_month"};
                let search4 = {name:"Mas comentados", texto:"hot-values/last_week"};

                this.principal_searches.push(search1,search2,search3,search4);
            },
            _goToGD: function (url) {
                this.$router.push('/app/graficador-dinamico/' + url);
            },
            _listPersonalSearches: function() {
                let success = (response) => {              
                    this.personal_searches = [];
                    for(let i=0; i < response.data.length; i++){
                        if(response.data[i] != "last_search"){
                            let search1 = {name:response.data[i], texto:""};
                            this.personal_searches.push(search1); 
                        }                         
                    }
                };

                let url = "/api/v1/stock-finder/finder-names";

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('066', 'Error recuperando busquedas.');

                this._getAPICall(url, successHandler, failureHandler);

            },
            decode_finder_name: function(encoded_name) {
                return encoded_name.replace(/Q1SPACEQ1/g, ' ');
            },
            _closeSearchWindow: function(){
                this.setOpenSearches();
                if (this._g_open_searches == false) {
                    if(this._g_toggle_chart_stocks == 'chart'){
                        this.setChartView(false);
                    } else{
                        this.setStocksView(false);
                    }
                } 
                this.$emit("show_searches", this.show_searches);
            },

            _getStocksIPOs: function(){
                let success = response =>{
                    this.last_ipos = response.data;
                    this.$emit("last_ipos", this.last_ipos);
                }

                let url = '/api/v1/stock-finder/last-ipos'

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('067', 'Error recuperando ultimas ipos.');

                this._getAPICall(url, successHandler, failureHandler);
            },


            _prepareToRemove: function(index){
                this.isDeleting = !this.isDeleting;
                this.deleting_search = this.personal_searches[index];
            },
            _removeSearch: function(value) {         
                let success = () => {
                    this._listPersonalSearches();
                    this._cancelOngoingOperations();
                               
                };

                let url = "/api/v1/stock-finder/delete-finder/" + value.name;

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('068', 'Error borrando búsqueda.');

                this._getAPICall(url, successHandler, failureHandler);
            },
            _showInput: function(){
                this.show_button = !this.show_button;
                this.isSaving = !this.isSaving;
            },
             _manageExitClicksBehavior: function() {
                let that = this;
                if(!this.isSaving && !this.isDeleting && !this.isEditing){
                    setTimeout(function() {
                        if (!that.isClosingShieldOn) {
                            that.$emit('_closeSearchWindow');                            
                        }
                        that.isClosingShieldOn = false;

                    }, 10);
                } else {
                    setTimeout(function() {
                        if(!that.isClosingShieldOn) {
                            that._cancelOngoingOperations();
                        }
                        that.isClosingShieldOn = false;
                    },10);
                }

            },
            _turnOnClosingShield: function() {
                this.isClosingShieldOn = true;
            },
            _cancelOngoingOperations: function(){
                this.show_button = true;
                this.isSaving = false;
                this.isDeleting = false;
                this.isEditing = false;
                this.finder_name = "";
                this.new_name = "";
            },
            _saveSearch(value) {                
                var search = value[0];                
                this.saved_search = search;                        
                
            },
            ...mapGetters(['_g_FinderFilters']),
            _saveSearchToApi: function(){                
                var params = new URLSearchParams();
                params.append('name', this.saved_search);
                params.append('finder', JSON.stringify(this._g_FinderFilters()));

                let success = () => {
                    this.$vueOnToast.pop("success", "Búsqueda", "Guardada con éxito!");
                    this._listPersonalSearches();
                    this._cancelOngoingOperations();
                };

                let url = "/api/v1/stock-finder/create-finder";
                
                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('270', 'Error guardando la búsqueda.');

                this._postAPICall(url, params, successHandler, failureHandler);
            },
            ...mapMutations(['setRechargeState']),
            _changeFinder: function(value){

                let success = () => {
                    this.getStocks()
                    this._closeSearchWindow();
                    this.$vueOnToast.pop("success", "Búsqueda", "Cargada con éxito!");                               
                };

                let url = "/api/v1/stock-finder/change-finder/" + value;

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('069', 'Error cargando búsqueda.');

                this._getAPICall(url, successHandler, failureHandler);
            },

            _isValidChar: function(searchText) {
                return /^[A-Z]+$/i.test(searchText) || /^[a-zA-Z\u00C0-\u017F]+$/.test(searchText) || /\d/.test(searchText);
            },
            _hasWhiteSpace: function(s) {
                return /\s/g.test(s);
            },
            _prepareToEdit: function(name){
                this.isEditing = true;
                this.finder_name = name;

            },

            _confirmEditSearchName: function(){
                if(this.new_name == ""){
                    this.$vueOnToast.pop('error', "Error El nombre no puede estar en blanco.");
                }else{
                    var params = new URLSearchParams();
                    params.append('name', this.finder_name);
                    params.append('new_name', this.new_name)
                    
                    let success = response =>{
                        this._listPersonalSearches();
                        this._cancelOngoingOperations();
                        this.$vueOnToast.pop("success", "Búsqueda editada con exito.");
                    }

                    let url = "/api/v1/stock-finder/update-finder"

                    const successHandler = new APICaller.SuccessHandler(success);
                    const failureHandler = new APICaller.FailureHandler('271', 'Error guardando cambios.');

                    this._postAPICall(url, params, successHandler, failureHandler);
                }
            },

            _changeFinderName: function(value){
                this.new_name = value[0]
            },
        },
    }
</script>

<style lang="scss">
    #searches-container{
        margin-top: 15px;
        height: 480px;
        min-height: 480px;
        overflow-y: scroll;
    }

    @media (min-width: 901px) {
    .searches-containerr {
        position: absolute;
        min-height: 500px;
        width: 320px;
        background: #F3F4F7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        top: 348px;
        left: 150px;
        border-radius: 10px;
        margin-top: 0 !important;
    }
    .searches-container:before {
        border: 25px solid #F3F4F7;
        content: '';
        border-left-color: transparent;
        border-bottom-color: transparent;
        border-top-color: transparent;
        position: absolute;
        left: -40px;
        bottom: 325px;
        border-radius: 10px;
    }
    }

    .saved-searches-container{
        display: flex;
    }
    .title-search{
        font-weight: bold;
        font-size: 14px;
        margin-left: 51px;
    }
    .material-icons-outlined{
        cursor: pointer;
    }
    .icon-close {
        margin-top: 12px !important;
    }
    .input-search-value{
        width:200px; 
    }
    .search-items-container{
        display:block; 
        margin: 0 0 0 50px; 
        font-size:14px;
    }
    .link-text{
        color: black;
        text-decoration: none;
        line-height : 40px;
    }
    .link-text :visited{
        color:black;
    }
    hr{
        width: 85%;
        border-top: 1px solid grey;
        opacity: 0.3;
        border-bottom: none;
        margin-top: 1px;
    }
    #workspace-favorites-fullscreen {
        position: fixed;
        top: 0px;
        background: rgba(0, 0, 0, 0.15);
        width: 100%;
        height: 100%;
        z-index: 9999;
        left: 0px;
    }
    .personal-searches :hover{
        cursor: pointer;
    }
    .save-search-btn{
        background-color: #00aedd;
        color: white;
        border-radius: 15px;
        font-size: 11px;
        font-weight: bold;
        border: none;
        width: 190px;
        height: 30px;
        margin-top: 10px;
        cursor:pointer;
    }
    .input-accept-button{
        width:83px;
        height:32px;
        color: white;
        background-color:#00aedd;
        border:none;    
        border-radius: 25px;
        margin-left: 15px;
        font-weight: bold;
        cursor:pointer;
    }
    .input-cancel-button{
        width:83px;
        height:32px;
        color: #0E5F85;
        border:none;    
        background-color: transparent;
        border-radius: 25px;
        margin-left: 15px;
        font-weight: bold;
        cursor:pointer;
    }
    .names-items-container{
        margin-left:50px;
    }
    .saved-search{
        margin-left:50px;
        display:inline;
        margin-top: 8px;
    }
    .add-search{
        margin-bottom: 20px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .save-button-container{
        text-align: center; 
    }
    .delete-search-container{
        text-align: center; 
        margin-top: 120px;
    }
    .personal-searches:hover{
        color:#00aedd;
        cursor:pointer;
    }
    .delete-question-text{
        font-weight: bold;
        font-size: 14px;
    }
    .searches-delete-container{
        display: inline-flex;
    }
    .inputs-container{
        display: inline-grid;
    }
    .search-name-delete{
        margin-left: 5px;
    }

    .header-container{
        display: flex;
        justify-content: center;
        padding: 10px 0px 5px 0px !important;
    }

    .title-container{
        margin-left: 30px;
        font-size: 20px;
        font-weight: 600;
    }

    .close-btn-container{
        margin-left: auto;
        order: 2;
        margin-right: 10px;
    }

    .not-allowed-searches{
        text-align: center;
        margin-top: 20px;
        color: #FF9B00;
    font-weight: 500;
    }
</style>