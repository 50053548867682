<template>
    <div @click="_manageExitClicksBehavior()" v-if="show_workspace" id="workspace-favorites-fullscreen" class="workspace-favorites-fullscreen-dark"> 
        <div @click="_turnOnClosingShield()" id="primary-div"  class="user-workspaces" style="background-color: #f3f4f7; box-shadow: 0px 2px 5px black; border-radius: 2%;">
            <div class="workspace-container-style">
            <header>
                <input
                @click="_manageExitClicksBehavior()"
                type="button" class="material-icons-outlined" style="background-color: #f3f4f7; border: none; color: #616263; float: right; margin-right: 8px; cursor: pointer;" value="close">
                <h3 class="title-favorites">FAVORITOS</h3>
            </header>
            <div v-if="!isDeleting">
                <div>
                    <div v-for="workspace in workspaces" :key="workspace.name">
                        <div v-if="currentlyEditing.name != workspace.name">
                            <div
                            id="workspace-select-favorite-icon" style="margin-left: 20px;"
                            @click="_invertWorkspaceFavoriteStatus(workspace)">
                                <span v-if="workspace.isFavorite" class="material-icons-outlined">radio_button_checked</span>
                                <span v-else class="material-icons-outlined">radio_button_unchecked</span>
                            </div>
                            <span class="material-icons-outlined" :style="{ color: workspace.color }" style="margin-left: 10px;">star</span>
                            <span style="margin-left: 10px;">{{workspace.name}}</span>
                            <div class="icons-container" style="float: right; margin-right:10px;">
                                <span
                                v-if="workspace.name != 'Favorites'"
                                @click="_editWorkspacePrepare(workspace)"
                                class="material-icons-outlined">edit</span>
                                <span
                                v-if="workspace.name != 'Favorites'"
                                @click="_deleteWorkspacePrepare(workspace)"
                                class="material-icons-outlined" style="margin-left: 5px">delete</span>
                            </div>
                        </div>
                        <div class="edit-workspace-favorite"
                        @keyup.esc="_editWorkspaceCancel()"
                        @keyup.enter="_editWorkspaceConfirm()"
                        v-else>
                            <!--
                            <v-swatches v-model="editWorkspaceColor" v-slot:trigger><span class="material-icons-outlined" :style="{ color: editWorkspaceColor }">star</span></v-swatches>
                            -->   
                            <span class="material-icons-outlined edit-star-style" :style="{ color: editWorkspaceColor }">star</span>
                            <div style="display: inline-flex;">
                                <span>                          
                                    <InputTextOutline style="width:150px;" @_emit_method_order_input="_changeWorkspaceName" :input_label="workspace.name" :input_helper="input_edit_favorites.input_helper" :has_focus="input_edit_favorites.has_focus" :icon_class="input_edit_favorites.icon_class" :input_key_to_save_user_values="input_edit_favorites.input_key_to_save_user_values" :input_class_character_counter="input_edit_favorites.input_class_character_counter" :permission="input_edit_favorites.permission" :icon_permission="input_edit_favorites.icon_permission" :character_counter_permission="input_edit_favorites.character_counter_permission" :helper_permission="input_edit_favorites.helper_permission" :test_field_is_numeric="input_edit_favorites.test_field_is_numeric" :length="input_edit_favorites.length" :is_input_textarea="input_edit_favorites.is_input_textarea" />                                
                                </span>
                                <span>
                                    <input class="material-icons-outlined" 
                                    @click="_editWorkspaceConfirm()"
                                    type="button" value="check" style="font-size: 35px; background-color: transparent; border-color:transparent !important; border-radius: 50%; margin-left: 10px; color: #f3f4f7; box-shadow: 0px 2px 5px black; background-color: #242632; padding:0 !important; border:0;"> 
                                </span>                                                               
                            </div>
                        </div>
                    </div>
                    <div>
                        <div v-if="!isCreating && !isEditing" class="add-button-container">
                            <span
                            @click="_createWorkspacePrepare()"
                            class="material-icons-outlined" style="font-size: 35px; background-color: transparent; border-radius: 50%; margin-top: 50px; color: #f3f4f7; box-shadow: 0px 2px 5px black; background-color: #242632;">add</span>
                        </div>
                        <div v-else-if="isEditing">
                        </div>                       
                        <div v-else>                            
                            <div class="workspace-name-container" style="display: inline-flex;">
                                <div style="width: 50%; float: right;">
                                    <v-swatches v-model="createWorkspaceColor" shapes="circles" show-fallback fallback-input-type="color" v-slot:trigger><span class="material-icons-outlined" :style="{ color: createWorkspaceColor }">star</span></v-swatches>   
                                </div>
                                <div style="width: 80%;" @keyup.enter="_createWorkspaceConfirm()">
                                    <InputTextOutline style="width: 100%;" @_emit_method_order_input="_getValueFromComponentWorkspace" :input_label="input_favorites.input_label" :input_helper="input_favorites.input_helper" :has_focus="input_favorites.has_focus" :icon_class="input_favorites.icon_class" :input_key_to_save_user_values="input_favorites.input_key_to_save_user_values" :input_class_character_counter="input_favorites.input_class_character_counter" :permission="input_favorites.permission" :icon_permission="input_favorites.icon_permission" :character_counter_permission="input_favorites.character_counter_permission" :helper_permission="input_favorites.helper_permission" :test_field_is_numeric="input_favorites.test_field_is_numeric" :length="input_favorites.length" :is_input_textarea="input_favorites.is_input_textarea" />
                                </div>
                                <div class="add-container">
                                    <input class="material-icons-outlined" 
                                    @click="_createWorkspaceConfirm()"
                                    type="button" value="check" style="font-size: 35px; background-color: transparent; border-color:transparent !important; border-radius: 50%; margin-left: 10px; color: #f3f4f7; box-shadow: 0px 2px 5px black; background-color: #242632; padding:0 !important; border:0;">
                                </div>                           
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="remove-text-container">
                    <strong><p>¿Está seguro que desea eliminar<br>este listado de favoritos?</p></strong>
                </div>
                <div class="remove-star-containter">
                    <span class="material-icons-outlined" :style="{ color: currentlyDeleting.color }">star</span>
                    <span>{{currentlyDeleting.name}}</span>
                </div>
                <div class="buttons-container" style="text-align: center;">
                    <div class="remove-button-container">
                        <input
                        @click="_deleteWorkspaceConfirm()"
                        type="button" value="ELIMINAR">
                    </div>
                    <div class="cancel-button-container">
                        <input
                        @click="_deleteWorkspaceCancel()"
                        type="button" value="Cancelar">
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import VSwatches from 'vue-swatches';
import "vue-swatches/dist/vue-swatches.css";
import APICaller from "@/components/codeComponents/APICaller.js";
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';
import inputFavorites from './configs/favorites-workspace.json';
import inputEditFavorites from './configs/edit-favorites-workspace.json';
import { mapGetters } from "vuex";
import GetStocks from "@/components/codeComponents/GetStocks.js";

export default {
    mixins: [APICaller, GetStocks],
    components: { 
        VSwatches,
        InputTextOutline
    },
    name: 'Workspaces',
    props: {
        stock: { type: Object },
        stocks: { type: Array },
        show_workspace: { type: Boolean},
    },

    data: () => ({
        input_favorites : inputFavorites,
        input_edit_favorites : inputEditFavorites,
        workspaces: [],
        isClosingShieldOn: false,
        isCreating: false,
        isDeleting: false,
        isEditing: false,
        createWorkspaceNewName: "",
        editWorkspaceNewName: "",
        currentlyDeleting: {},
        currentlyEditing: {},
        createWorkspaceColor: "#000000",
        editWorkspaceColor: "#000000",       
    }),

    watch: {
        // Update workspace isFavorite property each time a new stock is selected
        stock: {
            handler() {
                this._updateViewStockFavoriteWorkspaces();                
            },
            deep: true,
        },
    },

    created() {
    },
    
    computed: {
        ...mapGetters(["_g_UserFeatures"]),
    },

    mounted() {
        this._initWorkspaces(); 
    },

    updated(){
        this._updateViewStockFavoriteWorkspaces();
    },

    methods: {

        /*
         *   Initialization
         */

        // Gets the favorite workspaces from the API and stores them in this.workspaces
        _initWorkspaces: function() {
            this.workspaces = [];
            let success = response => {
                for (let index = 0; index < response.data.folders.length; index++) {
                    this.workspaces.push({
                        name: response.data.folders[index][0],
                        color: response.data.folders[index][1],
                        isFavorite: false,
                    });
                }
            };

            let url = '/api/v1/stock-finder/favorite-folder/list';
            
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('097', 'Error cargando lista de favoritos.');

            this._getAPICall(url, successHandler, failureHandler);
            
        },

        /*
         *   Operations - Creation, edition, deletion, set/unset as favorite for stock
         */

        // #Creation

        // Terminates ongoing edition without saving if there's any, displays creation menu
        _createWorkspacePrepare: function() {
            if(this._g_UserFeatures.permissions.extra_favorites > this.workspaces.length - 1){
                this._editWorkspaceCancel();
                this.createWorkspaceColor = this._generateRandomHexColor();
                this.isCreating = true;
            }else{
                let toast = {type: 'warning', body: 'Con su plan actual no puede crear más espacios de trabajo. PULSE AQUÍ para mejorar su plan.', clickHandler: function(){
                  window.location.replace("/app/store");
                }};
                this.$vueOnToast.pop(toast);
            }
        },

        // Confirms new workspace creation to database with an API call and updates the view
        _createWorkspaceConfirm: function() {
            if(this.createWorkspaceNewName != '') {
                let params = new URLSearchParams();
                params.append('name', this.createWorkspaceNewName);
                params.append('color', this.createWorkspaceColor);

                let url = '/api/v1/stock-finder/favorite-folder/create';

                let newName = this.createWorkspaceNewName;
                let newColor = this.createWorkspaceColor;

                let success = ()=>{
                    this._updateViewAddNewWorkspace(newName, newColor);
                    this.$vueOnToast.pop('success', "Espacio de trabajo " + newName, "Favorito creado con exito");
                };

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('281', 'Error creando el espacio de trabajo.');

                this._postAPICall(url, params, successHandler, failureHandler);

                this._createWorkspaceCancel();
            }
        },

        // Terminates ongoing creation
        _createWorkspaceCancel: function() {
            this.isCreating = false;
            this.createWorkspaceNewName = "";
        },
        _getValueFromComponentWorkspace(value){
            this.createWorkspaceNewName = value[0];
        }, 
        _changeWorkspaceName:function(value){
             this.editWorkspaceNewName = value[0];
        },       
        // #Deletion

        // Terminates ongoing edition and creation without saving if there's any, displays deletion menu
        _deleteWorkspacePrepare: function(workspace) {
            this._editWorkspaceCancel();
            this._createWorkspaceCancel();
            this.isDeleting = true;
            this.currentlyDeleting = workspace;
        },

        // Confirms workspace deletion to database with an API call and updates the view
        _deleteWorkspaceConfirm: function() {
            let params = new URLSearchParams();
            params.append('name', this.currentlyDeleting.name);

            let url = '/api/v1/stock-finder/favorite-folder/delete';

            let nowDeleting = this.currentlyDeleting;

            let success = () => {
                this.$vueOnToast.pop('success', "Espacio de trabajo " + nowDeleting.name, "Favorito eliminado con exito");

                this._updateViewDeleteWorkspace(nowDeleting);
                this._updateViewRemoveWorkspaceFromStocks();
                this._deleteWorkspaceCancel();
            };

            let failure = () => {
                this._deleteWorkspaceCancel();
            };

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('282', 'Error eliminando espacio de trabajo.', failure);

            this._postAPICall(url, params, successHandler, failureHandler);
        },

        // Terminates ongoing deletion without deleting
        _deleteWorkspaceCancel: function() {
            this.isDeleting = false;
            this.currentlyDeleting = {};
        },

        // #Edition

        // Terminates ongoing creation without saving if there's any, displays edition menu
        _editWorkspacePrepare: function(workspace) {
            this._createWorkspaceCancel();
            this.isEditing = true;
            this.currentlyEditing = workspace;
            this.editWorkspaceColor = workspace.color;
        },

        // Confirms workspace edition to database with an API call and updates the view
        _editWorkspaceConfirm: function() {
            if (!this.editWorkspaceNewName == '') {
                var params = new URLSearchParams();
                params.append('name', this.currentlyEditing.name);
                params.append('new_name', this.editWorkspaceNewName);

                let url = '/api/v1/stock-finder/favorite-folder/update';

                let currentlyEditing = this.currentlyEditing;
                let newName = this.editWorkspaceNewName;

                let success = () => {
                    this.$vueOnToast.pop('success', "Espacio de trabajo " + newName, "Favorito editado con exito");
                    this._updateViewEditWorkspaceFromStocks();
                    this._updateViewEditWorkspace(currentlyEditing, newName);
                    this._editWorkspaceCancel();
                };

                let failure = () => {
                    this._editWorkspaceCancel();
                };

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('283', 'Error guardando cambios.', failure);

                this._postAPICall(url, params, successHandler, failureHandler);
            }
            else {
                this._editWorkspaceCancel();
            }
        },


        // Terminates ongoing edition
        _editWorkspaceCancel: function() {
            this.isEditing = false;
            this.editWorkspaceNewName = "";
            this.currentlyEditing = {};
        },


        // #Set/Unset favorite

        // Decides whether to set or unset a workspace as favorite
        // for the currently selected stock based on its current state 
        _invertWorkspaceFavoriteStatus: function(workspace) {
            let paramsApiRequest = {};
            paramsApiRequest.ticker = this.stock.ticker;
            paramsApiRequest.direccio = this.stock.direccio;
            paramsApiRequest.folder_name = workspace.name;
            
            let params = new URLSearchParams();
            params.append('stock', JSON.stringify(paramsApiRequest));
            

            if(!workspace.isFavorite) {
                this._setWorkspaceAsFavorite(workspace, params);                
            } else {
                this._unsetWorkspaceAsFavorite(workspace, params);
            }
        },

        // Sets a workspace as favorite for the currently selected stock and updates the view
        _setWorkspaceAsFavorite(workspace, params) {
            let success = () => {
                this.$vueOnToast.pop('success', "Ticker " + workspace.name, "Añadido a favoritos con exito!");
                this._updateViewAddFavoriteWorkspaceToStock(workspace);
                this._updateViewInvertWorkspaceIsFavorite(workspace);
                this.$emit('added_to_favorite');
            };

            let url = '/api/v1/stock-finder/save/favorites';

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('284', 'Error agregando ticker a favoritos.');

            this._postAPICall(url, params, successHandler, failureHandler);
        },

        // Unsets a workspace as favorite from the currently selected stock and updates the view
        _unsetWorkspaceAsFavorite(workspace, params) {
            let success = () => {
                this.$vueOnToast.pop('success', "Ticker " + workspace.name, "Eliminado de favoritos con exito!");
                this._updateViewRemoveFavoriteWorkspaceFromStock(workspace);
                this._updateViewInvertWorkspaceIsFavorite(workspace);
                this.$emit('added_to_favorite');
            };

            let url = '/api/v1/stock-finder/delete/favorites';

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('285', 'Error eliminando el ticker de favoritos.');

            this._postAPICall(url, params, successHandler, failureHandler);
        },

        /*_isInViewport: function(){
            var y = this.$refs.pico.getBoundingClientRect().top;
            return y <= window.innerHeight;
        },*/

        //Cancels active creation, deletion and edition operations and emptying related local vars
        _cancelOngoingOperations: function() {
            this.isCreating = false;
            this.isDeleting = false;
            this.isEditing = false;
            this.currentlyDeleting = {};
            this.currentlyEditing = {};
        },


        /*
         *   Real time view updates
         */

        // Sets the isFavorite valiconFavoriteue of each workspace based on the currently selected stock
        _updateViewStockFavoriteWorkspaces: function() {
            for(let workspace in this.workspaces){
                if( this.stock.folder_name.indexOf(this.workspaces[workspace].name) != -1) {
                    this.workspaces[workspace].isFavorite = true;
                }
                else {
                    this.workspaces[workspace].isFavorite = false;
                }
            }
        },

        // Locally adds a new workspace to the displayed list
        _updateViewAddNewWorkspace: function(name, color) {
            this.workspaces.push({
                name: name,
                isFavorite: false,
                color: color,
            });
        },

        // Locally removes a workspace from the displayed list
        _updateViewDeleteWorkspace: function(nowDeleting) {
            let workspaceIndex = this.workspaces.indexOf(nowDeleting);
            this.workspaces.splice(workspaceIndex, 1);
        },

        // Locally removes a workspace from every stock that's linked to it
        _updateViewRemoveWorkspaceFromStocks : function(){
            for(let stock in this.stocks){
                let stockIndex = this.stocks[stock].folder_name.indexOf(this.currentlyDeleting.name);
                if(stockIndex != -1){
                    this.stocks[stock].folder_name.splice(stockIndex ,1);
                }
            }
        },

        // Locally edits a workspace from the displayed list
        _updateViewEditWorkspace: function(currentlyEditing, newName) {
            currentlyEditing.name = newName;
        },

        // Locally edits a workspace in every stock that's linked to it
        _updateViewEditWorkspaceFromStocks: function() {
            for(let stock in this.stocks){
                let stockIndex = this.stocks[stock].folder_name.indexOf(this.currentlyEditing.name);
                if(stockIndex != -1){
                    this.stocks[stock].folder_name.pop(this.currentlyEditing.name);
                    this.stocks[stock].folder_name.push(this.editWorkspaceNewName);
                }
            }
        },

        // Locally sets or unsets a given workspace as favorite for the currently selected stock
        _updateViewInvertWorkspaceIsFavorite: function(workspace){
            workspace.isFavorite = !workspace.isFavorite;
        },

        // Locally adds a favoriteiconFavorite workspace to the currently selected stock
        _updateViewAddFavoriteWorkspaceToStock: function(workspace) {
            this.stock.folder_name.push(workspace.name);
        },

        // Locally removes a favorite workspace from the currently selected stock
        _updateViewRemoveFavoriteWorkspaceFromStock: function(workspace) {
            for (var i = 0; i < this.stock.folder_name.length; i++) {
                if (workspace.name == this.stock.folder_name[i]) {
                    this.stock.folder_name.splice(i, 1);
                }
            }
        },


        /*
         *   Handle window closing
         */

        // Decides the behavior of a click on a closing triggering position
        // If there's an ongoing operation it will be cancelled
        // If there's not then the window will close
        _manageExitClicksBehavior: function() {
            let that = this;
            if(!this.isEditing && !this.isCreating && !this.isDeleting){
                setTimeout(function() {
                    if (!that.isClosingShieldOn) {
                        that.$emit('_closeFavoriteWorkspacesWindow');
                    }
                    that.isClosingShieldOn = false;

                }, 10);
            } else {
                setTimeout(function() {
                    if(!that.isClosingShieldOn) {
                        that._cancelOngoingOperations();
                    }
                    that.isClosingShieldOn = false;
                },10);
            }

        },
        

        // Sets a flag necessary for window closing behaviour
        _turnOnClosingShield: function() {
            this.isClosingShieldOn = true;
        },


        /*
         *   Misc
         */

        //Generates a random color for the swatch
        _generateRandomHexColor: function() {
            let randomColor = Math.floor(Math.random()*16777215).toString(16);
            return "#" + randomColor;
        },
  },
}
</script>


<style lang="scss">

    .workspace-favorites-fullscreen-dark{
        position: fixed;
        height: 100vh;
        width: 100vw;
        z-index: 1001;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5) !important;
    }

    .workspace-name-container{
        text-align:center; 
        margin-top:15px;
    }

    .workspace-container-style{
        position: absolute;
        min-height: 400px;
        width: 110%;
        background: #F3F4F7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        top: 140px;
        left: 10%;
    }

    @media screen and(max-width: 350px){
        .workspace-container-style{
            width: 80%
        }

    }

    @media screen and(min-width: 351px) and(max-width: 391px){
        .workspace-container-style{
            width: 110%;
            left: 10%;
        }

    }

    @media screen and(min-width: 392px) and(max-width: 500px){
        .workspace-container-style{
            width: 120%;
            left: 5%;
        }

    }

    .edit-workspace-favorite{
        text-align: center;
        margin-top: 20px;
    }

    .edit-star-style{
        margin-left: 10px;
        margin-top: 15px;
    }

    .user-workspaces {
        width: 300px;
        z-index: 999999;
        display: block !important;
        color: black;
        text-align: left;
        position: absolute;
        text-decoration: none;
        background-color: #f3f4f7;
        border-radius: 5px;       
    }

    .title-favorites{
        margin-top: 10px; 
        margin-left: 20px;
        font-size: 15px;
    }

    .add-button-container{
        text-align: center;
        position:relative;
    }

    .close {
        position: absolute;
        right: 4px;
        cursor:pointer;
    }

    #workspace-select-favorite-icon {
        display: inline-block;
    }
    .remove-text-container{
        text-align:center;
        font-size:13px;
        margin-top:50px;
    }
    .remove-star-container{
        text-align:center;
        margin-top:25px;
        margin-bottom:25px;
    }
    .remove-button-container input{
        width:140px;
        height:35px;
        color:white;
        font-weight:bold;
        background-color:#00aedd;
        border-radius:30px;
        border-color:transparent;
       
    }
    .cancel-button-container input{
        border:none;
        color:#2e81a5;
        font-weight:bold;
        margin-top:15px;

    }

    .remove-star-containter{
        text-align: center;
        margin-bottom: 20px;
    }
</style>